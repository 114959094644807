import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { DateTime } from 'luxon';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { createWo } from 'store/actions/installer';
import { getIsFetchingState } from 'store/selectors/installer';

// utils
import { t } from 'utils/dictionary';
import { isCommaSeparatedList, isIntInput, isLegitPhoneChar, isNumericInput } from 'utils/helpers/inputHelper';
import { getDateTime } from 'utils/timezone';
import { selectBasicStyle, selectBasicTheme } from 'utils/helpers/styleHelper';

// components
import { DateTimePickerOverlay } from 'components';
import { ButtonSpinner, TextInput } from 'components/common';

import './style.scss';

export const WoForm = ({ accounts, installers, selectedWo, title }) => {
    const now = useMemo(() => getDateTime(), []);
    const workTypes = useMemo(() => [{ value: 1, label: t('lblInstallation') }, { value: 2, label: t('lblRepair') }], []);
    const formInitialState = useMemo(() => ({
        account: null,
        address:'',
        contactName: '',
        contactPhone: '',
        devices: '',
        endDate: now,
        installer: null,
        lat: '',
        lng: '',
        name: '',
        numberOfDevices: '1',
        startDate: now,
        woType: workTypes[0],
    }), [now, workTypes]);

    const [form, setForm] = useState(formInitialState);
    const [isValid, setIsValid] = useState(false);
    const [errList, setErrList] = useState([]);

    const isFetching = useSelector(getIsFetchingState);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedWo) {
            const { name, contactName, contactPhone, address, lat, lng,
                numberOfDevices, accountId, userId, devices, workType, endDate,
                startDate } = selectedWo;
            setForm({
                account: accounts.find((account)=> account.value === accountId),
                address,
                contactName,
                contactPhone,
                devices: devices?.map((device) => device.unitId) || [],
                endDate: DateTime.fromISO(endDate),
                installer: installers.find((installer)=> installer.value === userId),
                lat: String(lat  || ''),
                lng: String(lng  || ''),
                name,
                startDate: DateTime.fromISO(startDate),
                numberOfDevices: String(numberOfDevices || 1),
                woType: workType === 'Repair' ? workTypes[1] : workTypes[0] // hopefully to be changed when id is passed instead of string
            });
        } else {
            setForm(formInitialState);
        }
        return (()=> {
            setErrList([]);
        });
    }, [selectedWo, accounts, installers, workTypes, formInitialState]);

    useEffect(() => {
        let isValid = true;
        Object.entries(form).forEach(([key, value]) => {
            if (!value || (['devices'].includes(key) && value.length === 0)) { // reuired fields - all + at least 1 device
                isValid = false;
            }
        });
        setIsValid(isValid);
    }, [form]);

    const isLogicValid = useCallback(() => {
        let errArray = [];
        if (form.startDate >= form.endDate) {
            errArray.push(t('lblEndDateErr'));
        }
        const numDevices = parseInt(form.numberOfDevices);
        const deviceIds = form.devices.split(',');
        if (numDevices !== deviceIds.length) {
            errArray.push(t('lblDeviceNumErr', { deviceNum: numDevices }));
        }
        setErrList(errArray);
        return (errArray.length === 0);
    },[form]);

    const onCreateWo = useCallback(() => {
        if (isFetching) return;
        setErrList([]);
        const { name, installer, account, woType, startDate, endDate, address, lat, lng,
            contactName,contactPhone, numberOfDevices, devices } = form;
        if (!isLogicValid()) return;
        const params = {
            name,
            workType: woType?.value,
            contactName,
            contactPhone,
            accountId: account?.value,
            installerUserId: installer?.value,
            address,
            lat: parseFloat(lat),
            lng: parseFloat(lng),
            startDate,
            endDate,
            numberOfDevices: parseInt(numberOfDevices),
            deviceList: devices
        };

        dispatch(createWo(params));
    }, [form, dispatch, isFetching, isLogicValid]);

    const onUpdateWo = useCallback(() => {}, []); // tbd

    const onSelect = useCallback((value, action) => {
        setForm((prev) => ({ ...prev, [action.name]: value }));
    }, []);

    const onChangeInput = useCallback(({ target }) => {
        switch (target.name) {
            case 'numberOfDevices':
                if (!isIntInput(target.value)) return;
                break;
            case 'lat':
            case 'lng':
                if (!isNumericInput(target.value)) return;
                break;
            case 'devices':
                if (!isCommaSeparatedList(target.value)) return;
                break;
            case 'contactPhone':
                if (!isLegitPhoneChar(target.value)) return;
                break;
            default:
                break;
        }
        setForm((prev) => ({ ...prev, [target.name]: target.value }));
    }, []);

    const onStartDateChange = useCallback((date) => {
        setForm((prev) => ({ ...prev, startDate: date }));
    }, []);

    const onEndDateChange = useCallback((date) => {
        setForm((prev) => ({ ...prev, endDate: date }));
    }, []);

    return (
        <div className="wo-form">
            <h4>{title}</h4>
            <div className="form-row">
                <TextInput
                    label={t('lblWoName')}
                    name='name'
                    value={form.name}
                    onChange={onChangeInput}
                />
                <div className="form-block">
                    <label>{t('lblWoType')}</label>
                    <Select
                        options={workTypes}
                        onChange={onSelect}
                        value={form.woType}
                        theme={selectBasicTheme}
                        styles={selectBasicStyle}
                        name="woType"
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-block">
                    <label>{t('lblAccount')}</label>
                    <Select
                        options={accounts}
                        onChange={onSelect}
                        value={form.account}
                        theme={selectBasicTheme}
                        styles={selectBasicStyle}
                        name="account"
                    />
                </div>
                <div className="form-block">
                    <label>{t('lblInstaller')}</label>
                    <Select
                        options={installers}
                        onChange={onSelect}
                        value={form.installer}
                        theme={selectBasicTheme}
                        styles={selectBasicStyle}
                        name="installer"
                    />
                </div>
            </div>
            <div className='form-row'>
                <TextInput
                    label={t('lblNumOfDevices')}
                    name='numberOfDevices'
                    value={form.numberOfDevices}
                    onChange={onChangeInput}
                />
                <TextInput
                    label={t('lblDeviceIds')}
                    subLabel={`(${t('lblCommaSeparated')})`}
                    name="devices"
                    value={form.devices}
                    onChange={onChangeInput}
                />
            </div>
            <div className='form-row'>
                <div className="form-block">
                    <label>{t('lblStartDate')}</label>
                    <DateTimePickerOverlay
                        onApply={onStartDateChange}
                        selected={form.startDate}
                        fromDate={now.minus({ year: 2 })}
                        toDate={now.plus({ year: 1 })}
                        step={5}
                    />
                </div>
                <div className="form-block">
                    <label>{t('lblDueDate')}</label>
                    <DateTimePickerOverlay
                        onApply={onEndDateChange}
                        selected={form.endDate}
                        fromDate={now.minus({ year: 2 })}
                        toDate={now.plus({ year: 1 })}
                        step={5}
                    />
                </div>
            </div>
            <div className="form-row">
                <TextInput
                    label={t('lblContactName')}
                    name='contactName'
                    value={form.contactName}
                    onChange={onChangeInput}
                />
                <TextInput
                    label={t('lblContactPhone')}
                    name='contactPhone'
                    value={form.contactPhone}
                    onChange={onChangeInput}
                />
            </div>
            <div className='form-row'>
                <TextInput
                    label={t('lblAddress')}
                    name='address'
                    value={form.address}
                    onChange={onChangeInput}
                />
            </div>
            <div className='form-row'>
                <TextInput
                    label={t('lblLat')}
                    name='lat'
                    value={form.lat}
                    onChange={onChangeInput}
                />
                <TextInput
                    label={t('lblLng')}
                    name='lng'
                    value={form.lng}
                    onChange={onChangeInput}
                />
            </div>
            <div className="installer-footer">
                <div className="err-container">
                    {errList.map((err) =>
                        <div key={err}>{`- ${err}`}</div>)}
                </div>
                <ButtonSpinner
                    label={title}
                    onClick={selectedWo ? onUpdateWo : onCreateWo}
                    classes={['button-v1']}
                    disabled={!isValid}
                    isLoading={isFetching}
                />
            </div>
        </div>
    );
};