import axios from 'axios';
import FileSaver from 'file-saver';
import JSZip from 'jszip';



// urls = ['url-to-get-blob-content']
export const downloadBlobs = async (urls) => {
    const blobs = [];
    try {
        const requests = urls.map((url) => axios.get(url, { responseType: 'blob' }));
        const responses = await axios.all(requests);
        responses.forEach(({ data, config }) => {
            const url = urls.find((url) => url === config.url);
            const blob = new Blob([data], { type: data.type });
            blobs.push({ url, content: blob });
        });
    } catch (error) {
        console.error(error);
        return;
    }
    return blobs;
};

export const saveFiles = (files) => {
    if (Array.isArray(files)) {
        files.forEach((file) => {
            FileSaver.saveAs(file.content, file.name);
        });
    } else {
        FileSaver.saveAs(files.content, files.name);
    }
};

// export const zipFiles = (filesTemp, jsZip) => {
//     const zip = jsZip ?? new JSZip();
//     filesTemp.forEach((fTemp) => {
//         const { folder, type, content, name } = fTemp;
//         if (Array.isArray(folder)) {
//             const zipFolder = zip.folder(name);
//             zipFiles(folder, zipFolder);
//         } else {
//             const fileName = type ? `${name}.${type}` : name;
//             zip.file(fileName, content);
//         }
//     });
//     return zip.generateAsync({ type: 'blob' });
// };
export const addFileToZip = (zip, tempFile) => {
    const { content, name, type } = tempFile;
    const fileName = type ? `${name}.${type}` : name;
    return zip.file(fileName, content);
};

export const zipFiles = (tempFiles) => {
    const zip = new JSZip();
    if (Array.isArray(tempFiles)) {
        tempFiles.forEach((tFile) => {
            addFileToZip(zip, tFile);
        });
    } else {
        addFileToZip(zip, tempFiles);
    }
    return zip.generateAsync({ type: 'blob' });
};


// ----
export const downloadZipAndSave = async (tempFiles, fileName) => {
    try {
        const urls = tempFiles.filter((f) => f.url).map((f) => f.url);
        const urlsContent = await downloadBlobs(urls);
        tempFiles.forEach((fTemp) => {
            fTemp.content = urlsContent.find((c) => c.url === fTemp.url)?.content;
        });
        const zipContent = await zipFiles(tempFiles.filter((fTemp) => fTemp.content));
        saveFiles({ content: zipContent, name: `${fileName ?? 'media'}.zip` });
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
};
