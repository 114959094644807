/* eslint-disable no-underscore-dangle */
import MessageListener from './MessageListener';

class IframeMessage {

    // Example ['https://trusted.com', 'https://trusted2.com']
    senders = [];

    toOrigin = '';

    isChild = false;

    _messageListenerAdded = false;

    _listeners = []

    constructor() {
        if (!IframeMessage.instance) { IframeMessage.instance = this; }
        return IframeMessage.instance;
    }

    send = (message, toOrigin) => {
        const target = toOrigin || this.toOrigin;
        if (target && typeof target === 'string') {
            if (this.isChild) {
                window.parent.postMessage(this.__createBoxMessage(message), target);
            } else {
                window.postMessage(this.__createBoxMessage(message), target);
            }
        }
    }

    sendToIframe = (message, origin, frame) => {
        frame?.contentWindow?.postMessage(this.__createBoxMessage(message), origin);
    }

    addSender = (domain) => {
        if (!this.senders.includes(domain)) {
            this.senders.push(domain);
        }
    }

    addListener = (callback) => {
        if (typeof callback === 'function') {
            if (!this._messageListenerAdded) {
                this.__addMessageEventListener();
            }
            const listener = new MessageListener(callback);
            this._listeners.push(listener);
            return listener.id;
        }
        return false;
    }

    removeListener = (id) => {
        const index = this._listeners.findIndex((l) => l.id === id);
        if (index !== -1) {
            this._listeners.splice(index, 1);
        }
        if (!this._listeners.length) {
            this.__removeMessageEventListener();
        }
    }

    removeAllListeners = () => {
        this.__removeMessageEventListener();
        this._listeners = [];
    }

    // Privates methods
    __onReceive = (event) => {
        const { data, origin, isTrusted } = event;
        const { senders, _listeners } = IframeMessage.instance;
        if (!isTrusted || !senders.includes(origin)) return;
        const parsedData = this.__parseData(data);
        if (parsedData) {
            _listeners.forEach((listener) => { listener.callback(parsedData.message); });
        }
    }

    __addMessageEventListener = () => {
        if (!this._messageListenerAdded) {
            window.addEventListener('message', this.__onReceive, false);
            this._messageListenerAdded = true;
        }
    }

    __removeMessageEventListener = () => {
        window.removeEventListener('message', this.__onReceive);
        this._messageListenerAdded = false;
    }

    __createBoxMessage = (message) => JSON.stringify({ message, header: IframeMessage.header })

    __parseData = (data) => {
        let parsed;
        try {
            const d = JSON.parse(data);
            if (d.header === IframeMessage.header && typeof d.message.action === 'string') {
                parsed = d;
            }
        } catch (error) {
            // parsed = undefined;
        }
        return parsed;
    }
}

IframeMessage.header = 'MESSAGE_FROM_IFRAME';

const iframeMessage = new IframeMessage();

export default iframeMessage;
