import { useCallback, useMemo } from 'react';
import { CloseButton, Modal } from 'react-bootstrap';
import { Button, Icon } from 'components/common';
import { colors } from 'styles/theme';
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { classAttribute } from 'utils/helper';
import { formatDateMillis, intlFormats } from 'utils/timezone';
import './style.scss';

const BaseModal = (props) => {

    const {
        children,
        className,
        closeButtonSettings,
        eventTime,
        headerButtons,
        headerText,
        icon,
        moreActions,
        onClickClose,
        renderFooter,
        resourceName,
        stateComponent,
        testAttributes,
    } = props;

    const buttonColours = {
        default: colors.greensea,
        disabled: colors.grayDarker,
    };

    const modalClasses = useMemo(() => classAttribute([
        'base-modal',
        className ?? '',
    ]), [className]);

    const onFooterCloseClick = useCallback(() => {
        onClickClose(closeButtonSettings?.withSave);
    }, [closeButtonSettings, onClickClose]);

    const onHeaderCloseClick = useCallback(() => {
        onClickClose(false);
    }, [onClickClose]);

    const subtitleText = useMemo(() => {
        if (resourceName && eventTime) {
            const date = formatDateMillis(eventTime, intlFormats.DATE_SHORT);
            return `${t('lblResource')} ${resourceName}. ${t('lblOccurredOn')} ${date} ${t('lblAt')} ${formatDateMillis(eventTime, intlFormats.TIME_24_WITH_SECONDS)}`;
        }
        return null;
    }, [resourceName, eventTime]);

    return (
        <Modal
            className={modalClasses}
            onHide={onClickClose}
            show
            { ...testAttributes }
        >
            <Modal.Header
                {...setAttr.ui(testBy.UI.HEADER)}
            >
                <div className="modal-header-container">
                    <div className="modal-icon">
                        <Icon
                            className={testBy.CLASS.ICON_HEADER}
                            width="100%"
                            height="100%"
                            {...icon}
                        />
                    </div>
                    <div>
                        <div className="modal-title-row">
                            <span className="modal-title">{headerText}</span>
                            {stateComponent?.()}
                        </div>
                        <div className="modal-subtitle" {...setAttr.ui(testBy.UI.SUBTITLE)}>
                            {subtitleText}
                        </div>
                    </div>
                    <div className="actions-section">
                        {Array.isArray(headerButtons) && headerButtons.map((button, idx) => {
                            const disabled = !(button.onClick);
                            const color = button.stroke ? 'transparent' : (disabled ? buttonColours.disabled : (button.color ?? buttonColours.default));
                            const stroke = button.stroke ? (disabled ? buttonColours.disabled : buttonColours.default) : 'transparent';
                            const classes = classAttribute([
                                'action-item',
                                disabled ? 'disabled' : '',
                            ]);
                            return <div
                                key={idx}
                                className={classes}
                                {...button.testAttributes}
                            >
                                <Icon
                                    name={button.name}
                                    color={color}
                                    stroke={stroke}
                                    onClick={button.onClick}
                                    height={button.height}
                                    width={button.width}
                                    title={button.title}
                                    style={button.style}
                                />
                                {button.contents}
                            </div>;
                        })}
                        {Array.isArray(moreActions) && moreActions.map((action, idx) => <div key={idx} className="action-item">{action}</div>)}
                    </div>
                </div>
                <CloseButton
                    onClick={onHeaderCloseClick}
                    {...setAttr.action(testBy.ACTION.CLOSE)}
                />
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer
                {...setAttr.ui(testBy.UI.FOOTER)}
            >
                {renderFooter?.()}
                <Button
                    label={closeButtonSettings?.label ? closeButtonSettings.label : t('lblClose')}
                    onClick={onFooterCloseClick}
                    classes={['close-button', closeButtonSettings?.class]}
                    testAttributes={setAttr.action(testBy.ACTION.CLOSE)}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default BaseModal;
