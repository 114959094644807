// Init the translate
import dictionary from 'utils/dictionary/i18next.init';


export * from 'utils/dictionary/constants';
export { useTranslation } from 'react-i18next';
export * from 'i18next';

export default dictionary;




/**
 * Return translation for label.
 * @param {String|Array} keys - @optional Easeyer away to chain nested keys with variables by passing them as array.
 * But it can be also string with '.' as a key seperator like 'key1.key2.key3' or mixing array and keys with seperators [varKey, 'details', 'more.data'].
 * @param {Boolean} returnObjects - @optional Only if you are expecting to receive object or array, default is false.
 */
const translate = (lbl, options) => {
    const defOptions = {
        keys: undefined,
        returnObjects: false,
        ...options
    };

    const { keys, ...rest } = defOptions;

    let nestedKeys = '';

    if (typeof keys === 'string' || typeof keys === 'number' || Array.isArray(keys)) {
        nestedKeys = `${keys?.join?.('.') ?? keys}`;
    }

    let key = lbl;
    if (nestedKeys) {
        key += `.${nestedKeys}`;
    }

    const lblVal = dictionary.t(key, { ...rest });
    return lblVal;
};

export const pf_t = {
    categoryName: (catId) => translate('lblEventCategories', { keys: [catId, 'name'] }),
    eventDescriptions: {
        byType: (typeId) => {
            const { message1 } = translate('lblEventTypes', { keys: typeId, returnObjects: true });
            return message1 ?? '';
        },
        overSpeed: (speed, speedLimit, overSpeed) => {
            if (speed) {
                if (speedLimit && overSpeed) {
                    return translate('lblEventTypes', { keys: [32, 'message3'], speed, speedLimit, overSpeed });
                }
                return translate('lblEventTypes', { keys: [32, 'message2'], speed });
            }
            return translate('lblEventTypes', { keys: [32, 'message1'] });
        }
    },
    eventName: (typeId) => translate('lblEventTypes', { keys: [typeId, 'name'] }),
    timeAgo: (amount) => ({
        hours: translate('lblTimeAgo', { keys: 'hours', mValue: amount }),
        minutes: translate('lblTimeAgo', { keys: 'minutes', mValue: amount }),
    }),
    weatherCondition: (condition) => {
        const conditions  = translate('lblWeatherCondition', { returnObjects: true });
        return conditions[condition] ?? (typeof condition === 'string' && condition.length > 0 ? condition : 'N/A');
    }
};
