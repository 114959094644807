import { createSelector } from 'reselect';
// Utils
import { reducerNames } from 'store/constants';
import { t } from 'utils/dictionary';



export const getIncidentTypesState = (state) => state[reducerNames.INCIDENT_TYPES].incidentTypes;


// Memo selectors
export const getIncidentTypes = createSelector(getIncidentTypesState, (groups) => groups.map((group) => {
    const { groupId } = group;
    const items = group.items.map((item) => ({ ...item, name: t(`lblEventTypes.${item.id}.name`) }));
    return {
        ...group,
        groupName: t(`lblEventCategories.${groupId}.name`),
        items
    };
}));
