import { setAttr, testBy } from 'utils/constants';
import { getExclusiveRangeMs } from 'utils/local_date_time';
// Relative constants for Safety module
// TODO - move file to global constants

export const periodIds = {
    TODAY: 'TODAY',
    THIS_WEEK: 'THIS_WEEK',
    THIS_MONTH: 'THIS_MONTH',
    LAST_7_DAYS: 'LAST_7_DAYS',
    LAST_30_DAYS: 'LAST_30_DAYS',
    CUSTOM: 'CUSTOM'
};

const { TODAY, THIS_WEEK, THIS_MONTH, LAST_7_DAYS, LAST_30_DAYS, CUSTOM } = periodIds;

const defaultPeriodId = TODAY;
const defaultPeriodIdDriver = THIS_WEEK;

const periodOptionFunctions = {
    [TODAY]: () => getExclusiveRangeMs.today(),
    [THIS_WEEK]: () => getExclusiveRangeMs.thisWeek(),
    [THIS_MONTH]: () => getExclusiveRangeMs.thisMonth(),
    [LAST_7_DAYS]: () => getExclusiveRangeMs.last7Days(),
    [LAST_30_DAYS]: () => getExclusiveRangeMs.last30Days(),
};

export const rangeMsFromPeriod = (period) => {
    if (period.id === CUSTOM) {
        // The range is fixed for custom period, returning the custom range
        return period.rangeMs;
    }
    // The rest of period functions are relative to now so returning the updated range
    return periodOptionFunctions[period.id ?? defaultPeriodId]();
};

const periodOptionsWithoutTestAttributes = [
    { id: TODAY, lblKey: 'lblToday' }, // t('lblToday')
    { id: THIS_WEEK, lblKey: 'lblThisWeek' }, // t('lblThisWeek')
    { id: THIS_MONTH, lblKey: 'lblThisMonth' }, // t('lblThisMonth')
    { id: LAST_7_DAYS, lblKey: 'lblLast7Days' }, // t('lblLast7Days')
    { id: LAST_30_DAYS, lblKey: 'lblLast30Days' }, // t('lblLast30Days')
    { id: CUSTOM, lblKey: 'lblCustom' }, // t('lblCustom')
];

const testActionValue = (id) => {
    const value = Object.values(testBy.ACTION).find((action) => action.endsWith(id) );
    if (value) {
        return value;
    }
    throw new Error(`Trying to use undefined period action. Please add missing SELECT_${id} to src/utils/constants/tests.js testBy.ACTION`);
};

export const periodOptions = periodOptionsWithoutTestAttributes.map((o) => ({ ...o, testAttributes: setAttr.action(testActionValue(o.id)) }));

export const [defaultPeriodOption] = periodOptionsWithoutTestAttributes.filter((option) => option.id === defaultPeriodId);
export const [defaultPeriodOptionDriver] = periodOptionsWithoutTestAttributes.filter((option) => option.id === defaultPeriodIdDriver);
