import React, { useCallback, useMemo } from 'react';
import { Icon } from 'components/common';
import { setAttr, testBy } from 'utils/constants';
import { classAttribute } from 'utils/helper';
import './style.scss';

const ModalInfoRow = ({ children, className, flow, highlight, iconObj, label, subLabel, title, testAttributes }) => {

    let renderedChildren = children;
    let renderedLabel = label;

    const rowClasses = useMemo(() => classAttribute([
        'modal-info-row',
        flow ? 'flow': '',
        className ?? ''
    ]), [className, flow]);

    const renderedSubLabel = useMemo(() => subLabel ? <span className="label-non-bold"> {subLabel}</span> : null, [subLabel]);

    const renderLabel = useCallback((props) => {
        const { children, className, ...rest } = props;
        const labelClasses = classAttribute([
            'info-row-label',
            className ?? '',
            highlight ? 'highlight' : '',
        ]);
        return <span className={labelClasses} {...rest} {...setAttr.ui(testBy.UI.LABEL)}>{children}{renderedSubLabel}</span>;
    }, [highlight, renderedSubLabel]);

    const renderChildren = useCallback((content) => {
        const icon = iconObj ? <Icon {...iconObj} /> : null;
        const wrappedContent = icon ? <span className="iconWrapper"><span>{content}</span><span>{icon}</span></span> : content;
        return <span className="info-row-value" title={title} {...setAttr.ui(testBy.UI.VALUE)}>{wrappedContent}</span>;
    }, [iconObj, title]);

    if (typeof label === 'string') {
        renderedLabel = renderLabel({ children: label });
        renderedChildren = renderChildren(children);
    } else if (Array.isArray(children)) {
        children.some((obj, idx) => {
            if (obj.type === 'label') {
                const { children: content } = obj.props;
                if (content !== null) {
                    renderedLabel = renderLabel(obj.props);
                    renderedChildren = renderChildren(children.slice().splice(idx - 1, 1));
                    return true;
                }
            }
            return false;
        });
    }

    return (
        <div
            className={rowClasses}
            {...{ ...setAttr.ui(testBy.UI.ROW), ...testAttributes }}
        >
            {renderedLabel}
            {renderedChildren}
        </div>
    ) ;
};

export default ModalInfoRow;
