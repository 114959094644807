import React from 'react';
import { PageContainer } from 'layout/containers';
import { LanguageSettings } from './language_settings/LanguageSettings';
import { RaiseErrorBomb } from 'components/errors';
import FilesDownload from 'pages/playground/files_download/FilesDownload';
import './style.scss';



// This module is for DEVELOPMENT only (do not import from it file for others moduls)
export class PlaygroundPage extends React.PureComponent {
    render() {
        return (
            <PageContainer
                classes={['playground-page']}
            >
                <LanguageSettings />
                <FilesDownload />
                <RaiseErrorBomb seconds={3} />
            </PageContainer>
        );
    }
}
