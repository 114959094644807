import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentModal } from 'store/selectors/popup';
import CollisionModal from 'components/modal/collision_modal/CollisionModal';
import TheftModal from 'components/modal/theft_modal/TheftModal';
import ViolationModal from 'components/modal/violation_modal/ViolationModal';
import GalleryModal from 'components/modal/gallery_modal/GalleryModal';
import { modalsIds } from 'components/modal/ModalsConstants';
import VideoPlayerModal from 'components/modal/video_player_modal/VideoPlayerModal';
import MapModal from 'components/modal/map_modal/MapModal';
import DriverModal from './driver_modal/DriverModal';

const ModalManager = () => {

    const modal = useSelector(getCurrentModal);

    const getModal = () => {
        if(modal) {
            switch(modal.id) {
                case modalsIds.VIOLATION_MODAL:
                    return <ViolationModal data={ modal.data } />;
                case modalsIds.VIDEO_PLAYER_MODAL:
                    return <VideoPlayerModal data={ modal.data } />;
                case modalsIds.COLLISION_MODAL:
                    return <CollisionModal data={ modal.data } />;
                case modalsIds.GALLERY_MODAL:
                    return <GalleryModal data={ modal.data } />;
                case modalsIds.THEFT_MODAL:
                    return <TheftModal data={ modal.data } />;
                case modalsIds.MAP_MODAL:
                    return <MapModal data={ modal.data } />;
                case modalsIds.DRIVER_MODAL:
                    return <DriverModal data={ modal.data } />;
                default:
                    return null;
            }
        }
        return null;
    };

    return getModal();

};

export default ModalManager;
