import { useMemo } from 'react';
import { classAttribute } from 'utils/helper';
import { v4 as uuidV4 } from 'uuid';
import './style.scss';


export default function TextInput({ classNames = [], id, label, name, type, subLabel, ...rest }) {

    const classes = useMemo(() => classAttribute([
        'text-input-container',
    ].concat(classNames)), [classNames]);

    const inputId = id === undefined ? uuidV4() : id;

    return (
        <div className={classes}>
            <label htmlFor={inputId}>
                {label}
                {subLabel && <span className="sub-label">{subLabel}</span>}
            </label>
            <input
                id={inputId}
                name={name}
                className="pf-input"
                type={type === undefined ? 'text' : type} // explicit override
                {...rest}
            />
        </div>
    );
}
