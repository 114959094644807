import BaseModal from 'components/common/base_modal/BaseModal';
import Button from 'components/common/buttons/Button';
import Checkbox, { checkboxStates } from 'components/common/checkbox/Checkbox';
import ContextPopover from 'components/common/context_popover/ContextPopover';
import DropdownBox from 'components/common/dropdowns/DropdownBox';
import Icon from 'components/common/icons/Icon';
import IncidentIcon from 'components/common/icons/IncidentIcon';
import PrinterObject from 'components/common/printer_object/PrinterObject';
import ResourceIcon from 'components/common/icons/ResourceIcon';
import SearchInput from 'components/common/search_input/SearchInput';
import Selection from 'components/common/dropdowns/selection/Selection';
import SendInput from 'components/common/send_input/SendInput';
import SeverityIcon from 'components/common/icons/severity_icon/SeverityIcon';
import SimpleSelect from 'components/common/dropdowns/simple_select/SimpleSelect';
import Text from 'components/common/text/Text';
import TextInput from 'components/common/text_input/TextInput';
import ToggleSwitch from 'components/common/toggle_switch/ToggleSwitch';
export * from 'components/common/split-button/SplitButton';
export * from 'components/common/split-button/SplitButtonInput';
export * from 'components/common/spinner/Spinner';
export * from 'components/common/buttons/ButtonSpinner';
export * from 'components/common/footers/Footer';

const Icons = {
    Incident: IncidentIcon,
    Resource: ResourceIcon,
    Severity: SeverityIcon,
};

export {
    BaseModal,
    Button,
    Checkbox,
    checkboxStates,
    ContextPopover,
    DropdownBox,
    Icon,
    Icons,
    PrinterObject,
    SearchInput,
    Selection,
    SendInput,
    SimpleSelect,
    Text,
    TextInput,
    ToggleSwitch,
};
