import React, { useCallback, useEffect, useState } from 'react';
import { StreetViewPanorama } from '@react-google-maps/api';
import { isValidCoordinate } from 'utils/helper';
import { Map } from 'components/google_maps';

const StreetViewMap = ({ position, ...props }) => {

    const [ swMapReady, setSwMapReady ] = useState(false);
    const [ streetView, setStreetView ] = useState(null);

    const validPosition = isValidCoordinate(position);

    useEffect(() => {
        if (swMapReady && validPosition) {
            setStreetView(<StreetViewPanorama options={{ position: position, visible: true }} />);
        }
    }, [swMapReady]);

    const onSwMapLoad = useCallback((map) => {
        setSwMapReady(true);
        if (validPosition) {
            map.setCenter(position);
        }
    }, [validPosition]);

    return(
        <Map onLoad={onSwMapLoad} {...props}>
            {streetView}
        </Map>
    );
};

export default StreetViewMap;
