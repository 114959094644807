
const SERVER_API_URL = {
    LOCAL: 'http://localhost:5202',
    DEV: 'https://pwflingressunitydevwe.westeurope.cloudapp.azure.com',
    QA: 'https://pwflingressunityqawe.westeurope.cloudapp.azure.com',
    TEST: 'https://test.com/fleetcore.api',
};

// Should be replace with appConfig.js file from the build public folder
const devConfig = {
    SERVER_API_URL: SERVER_API_URL.QA,
    // SERVER_API_URL: SERVER_API_URL.LOCAL,
    MAP: {
        KEY: 'gme-pointerdobrasilcomercial',
        VERSION: 'weekly',
        LIBRARIES: ['geometry', 'drawing', 'places', 'visualization']
    },
    logLevel: 0, // NOT_LOGGIN: 0, TRACE: 1, DEBUG: 2, INFO: 3, WARN: 4, ERROR: 5, FATAL: 6
    devMode: {
        log: false,
        fallbackUI: true,
        playground: true,
    }
};

const testConfig = {
    SERVER_API_URL: SERVER_API_URL.TEST,
    MAP: {
        KEY: '--',
        VERSION: 'quarterly',
        LIBRARIES: ['geometry', 'drawing', 'places', 'visualization']
    },
};

const defConfig = {
};

const mergeConfig = (config, def = defConfig) => ({ ...def, ...config });

export const setConfig = (NODE_ENV) => {
    switch (NODE_ENV) {
        case 'production':
            global.config = mergeConfig(global.appConfig);
            break;
        case 'development':
            global.config = mergeConfig(devConfig);
            break;
        case 'test':
            global.config = mergeConfig(testConfig);
            break;
        default:
            break;
    }
};
