import { api } from 'services';
import { APIS } from 'utils/constants';
import { actions } from 'store/slices/resources';
// import { getMockResourceDetailsState } from 'store/selectors/resources';



export default actions;

export const fetchResources = () => async (dispatch) => {
    const { data, status } = await api.get(APIS.RESOURCES.BY_ACCOUNT);
    if (status === 200) {
        dispatch(actions.setResources(data.data));
    }
};

export const fetchResourceDetails = (resourceId) => async (dispatch) => {
    const params = { resourceId };
    const { data, status } = await api.get(APIS.RESOURCES.DETAILS, { params });
    if (status === 200) {
        dispatch(actions.setResourceDetails(data.data));
    }
};

export const fetchRealTimeLocation = async (resourceId) => {
    const params = { resourceId };
    const { data, status } = await api.get(APIS.RESOURCES.REAL_TIME_LOCATION, { params });
    if (status === 200) {
        return data.data;
    }
    return {};
};
