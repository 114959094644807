import React, { useMemo } from 'react';
// Utils
import { iconsName } from 'assets';
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { classAttribute } from 'utils/helper';
import PropTypes, { propTypes } from 'utils/propTypes';
// Components
import { Icon } from 'components/common';
import { Link } from 'react-router-dom';
import './style.scss';


const BreadCrumb = ({ link, active }) => {

    const classes = useMemo(() => classAttribute([
        'bread-crumb',
        active ? 'active' : '',
    ]), [active]);

    return link.disabled ?
        <span key={link.id}>{t(link.lblKey)}</span>
        :
        <Link
            key={link.id}
            className={classes}
            to={link.path}
            {...setAttr.action(testBy.ACTION.NAVIGATE, active)}
            {...setAttr.ui(testBy.UI.LINK)}
        >
            {t(link.lblKey)}
        </Link>;
};

BreadCrumb.propTypes = {
    active: PropTypes.bool,
    link: propTypes.link
};


const BreadCrumbs = ({ links }) => {

    const renderedLinks = useMemo(() => Array.isArray(links) ? links.map((link, position) => {
        const first = position === 0;
        return (
            <React.Fragment
                key={`${link.path}-separator`}
            >
                {
                    !first ? (
                        <Icon
                            name={iconsName.BREAD_CRUMB_SEP}
                            key={`${link.id}-separator`}
                            height={16}
                            useDefault
                        />
                    ) : null
                }
                <BreadCrumb
                    link={link}
                    active={position === (links.length - 1)}
                />
            </React.Fragment>
        );
    }) : null, [links]);

    return (
        <div
            className="bread-crumbs-container"
            {...setAttr.feature(testBy.FEATURE.BREADCRUMBS)}
        >
            {renderedLinks}
        </div>
    );
};


BreadCrumbs.propTypes = {
    links: PropTypes.arrayOf(propTypes.link)
};

export default BreadCrumbs;
