import React from 'react';
// Utils
import { iconsName } from 'assets';
// Components
import { Icon } from 'components/common';
import './style.scss';



// Dropdown icon
export const IconDropdown = (props) => (
    <Icon
        width={12}
        height={18}
        name={iconsName.DROPDOWN_ARROW}
        {...props}
    />
);

// Nav icon
export const IconLeft = (props) => (
    <Icon
        width={24}
        height={24}
        name={iconsName.NAV_ARROW_L}
        {...props}
    />
);

// Nav icon
export const IconRight = (props) => (
    <Icon
        width={24}
        height={24}
        name={iconsName.NAV_ARROW_R}
        {...props}
    />
);
