import React from 'react';
import { t } from 'utils/dictionary';
import { severities } from 'utils/entities';
import ModalInfoRow from 'components/modal/components/modal_info_row/ModalInfoRow';


const ModalSeverityInfoRow = ({ severity }) => {
    const { color, lblKey } = severities[severity] ?? {};

    return (
        <ModalInfoRow label={t('lblSeverity')}>
            <span style={{ color: color ?? 'white' }}>{t(lblKey ?? '')}</span>
        </ModalInfoRow>
    );
};

export default ModalSeverityInfoRow;
