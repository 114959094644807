

// Supported languages
export const supportedLanguages = ['en', 'es', 'pt', 'de', 'zh'];

const localeIds = { EN_US: 1, ES_ES: 2, PT_BR: 3, EN_ZA: 4, DE: 5, ZH_CN: 6 };

const objToList = (obj) => Object.values(obj).filter((item) => typeof item === 'object').map((item) => ({ ...item }));

// The "langId" is the same language id as in Pointer Connect system for supporting and relation between languages and user langId.
export const locales = {
    enUS: {
        id: localeIds.EN_US,
        langId: 1,
        language: 'English',
        name: 'English - United States',
        locale: 'en-us',
        langName: 'en',
        lblName: 'lblLocale.en-us', // t('lblLocale.en-us')
    },
    esES: {
        id: localeIds.ES_ES,
        langId: 3,
        language: 'Spanish',
        name: 'Spanish - Spain (Traditional)',
        locale: 'es-es',
        langName: 'es',
        lblName: 'lblLocale.es-es', // t('lblLocale.es-es')
    },
    ptBR: {
        id: localeIds.PT_BR,
        langId: 7,
        language: 'Portuguese',
        name: 'Portuguese - Brazil',
        locale: 'pt-br',
        langName: 'pt',
        lblName: 'lblLocale.pt-br', // t('lblLocale.pt-br')
    },
    enZA: {
        id: localeIds.EN_ZA,
        langId: 8,
        language: 'English',
        name: 'English (South Africa)',
        locale: 'en-za',
        langName: 'ens',
        lblName: 'lblLocale.en-za', // t('lblLocale.en-za')
    },
    de: {
        id: localeIds.DE,
        langId: 15,
        language: 'German',
        name: 'German (Standard)',
        locale: 'de',
        langName: 'de',
        lblName: 'lblLocale.de', // t('lblLocale.de')
    },
    zhCN: {
        id: localeIds.CH,
        langId: 16,
        language: 'Chinese',
        name: 'Chinese (Simplified, People\'s Republic of China)',
        locale: 'zh',
        langName: 'zh',
        lblName: 'lblLocale.zh-cn', // t('lblLocale.zh-cn'')
    },
    toList: () => objToList(locales),
    byLangId: (id) => locales.toList().find((l) => l.langId === id),
};
