import { useCallback, useState, useMemo } from 'react';
import PropTypes from 'utils/propTypes';
import { useSelector } from 'react-redux';
import { getSearchData } from 'store/selectors/safety';
// Utils
import { t } from 'utils/dictionary';
import { iconsName } from 'assets';
import { getDateTime } from 'utils/timezone';
import { testBy, setAttr } from 'utils/constants';
// Components
import { DropdownInput, DateTimePickerOverlay, LocationPicker } from 'components';
import { Button, ButtonSpinner } from 'components/common';
import { ModalBlock, ModalBlockTitle, ModalInfoRow } from 'components/modal/components';
import './style.scss';





const mapGroupOption = ({ groupName, groupId }) => ({
    id: groupId,
    label: groupName,
    value: groupName?.toLowerCase(),
    items: []
});

const mapItemOption = ({ name, id, groupId }) => ({ groupId, id, label: name, value: name?.toLowerCase() });

const mapAutoCompleteOptions = (data) => {
    const groups = data.map((data) => {
        const group = mapGroupOption(data);
        const items = data.items.map((item) => mapItemOption({ ...item, groupId: group.id }));
        return { ...group, items };
    });
    return groups;
};

const fields = {
    RESOURCE: 'resource',
    DRIVER: 'driver',
    DATE: 'date',
    LOCATION: 'location'
};

const validate = (field) => Boolean(field?.id && field?.groupId);
const validateDate = (date) => Boolean(date?.isValid);

const getFormSchema = () => ({
    [fields.RESOURCE]: { required: true, validate },
    [fields.DRIVER]: { validate },
    [fields.DATE]: { validate: validateDate }
});

const getForm = () => ({
    [fields.RESOURCE]: undefined,
    [fields.DRIVER]: undefined,
    [fields.DATE]: getDateTime(),
    [fields.LOCATION]: undefined,
});

const isEmpty = (value) => value?.id === undefined || value?.id === null;
const getSomeError = (validation) => Object.values(validation).some((valid) => !valid.all);

const getFormValidation = (form, schema) => Object.entries(form).reduce((validation, [key, field]) => {
    const { required, validate } = schema[key] ?? {};
    // Getting validation
    const empty = isEmpty(field);
    const valid = {
        required: !required || !empty,
        value: (!required && empty) || (validate?.(field) ?? true)
    };
    valid.all = Object.values(valid).every((isValid) => isValid);
    return ({ ...validation, [key]: valid });
}, {});



export const CreateManualEvent = (props) => {

    const now = useMemo(() => getDateTime(), []);

    const { onCancel, onSave, isLoading } = props;

    const data = useSelector(getSearchData);

    const resources = mapAutoCompleteOptions(data.resources);
    const drivers = mapAutoCompleteOptions(data.drivers);

    const [form, setForm] = useState(getForm());

    const onClickSave = useCallback(() => {
        onSave?.(form);
    }, [onSave, form]);

    const onSelect = useCallback(({ name, item }) => {
        setForm((prev) => ({ ...prev, [name]: item }));
    }, []);

    const onApplyDate = useCallback((date) => {
        setForm((prev) => ({ ...prev, [fields.DATE]: date }));
    }, []);

    const onApplyLocation = useCallback((location) => {
        setForm((prev) => ({ ...prev, [fields.LOCATION]: location }));
    }, []);

    const onLocationInputChange = useCallback(({ value, location }) => {
        onApplyLocation(location);
    }, [onApplyLocation]);

    const valid = getFormValidation(form, getFormSchema());
    const someError = getSomeError(valid);
    const date = form[fields.DATE];

    return (
        <ModalBlock>
            <ModalBlockTitle className='title' title={t('lblCreateCollisionEvent')} icon={{ name: iconsName.COLLISION, stroke: '#000' }} />
            <ModalInfoRow label={t('lblLicensePlate')}>
                <DropdownInput
                    placeholder={t('lblLicensePlate')}
                    items={resources}
                    name={fields.RESOURCE}
                    selected={form[fields.RESOURCE]}
                    onSelect={onSelect}
                />
            </ModalInfoRow>
            <ModalInfoRow label={t('lblDriver')}>
                <DropdownInput
                    placeholder={t('lblDriver')}
                    items={drivers}
                    name={fields.DRIVER}
                    selected={form[fields.DRIVER]}
                    onSelect={onSelect}
                />
            </ModalInfoRow>
            <ModalInfoRow label={t('lblTime')}>
                <DateTimePickerOverlay
                    onApply={onApplyDate}
                    selected={date}
                    fromDate={now.minus({ year: 2 })}
                    toDate={now}
                    step={5}
                />
            </ModalInfoRow>
            <ModalInfoRow label={t('lblLocation')}>
                <LocationPicker
                    placeholder={t('lblUnknown')}
                    position={form[fields.LOCATION]}
                    onApply={onApplyLocation}
                    onLocationInputChange={onLocationInputChange}
                />
            </ModalInfoRow>
            <div
                {...setAttr.ui(testBy.UI.FOOTER)}
                className='manual-event-footer'
            >
                <Button
                    label={t('lblCancel')}
                    onClick={onCancel}
                    classes={['button-v1']}
                    testAttributes={setAttr.action(testBy.ACTION.CANCEL)}
                />
                <ButtonSpinner
                    label={t('lblSave')}
                    onClick={onClickSave}
                    classes={['button-v1']}
                    disabled={someError}
                    isLoading={isLoading}
                    testAttributes={setAttr.action(testBy.ACTION.SAVE, someError)}
                />
            </div>
        </ModalBlock>
    );
};


CreateManualEvent.propTypes = {
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    isLoading: PropTypes.bool
};