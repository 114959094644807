import { createSlice } from '@reduxjs/toolkit';
import { reducerNames } from 'store/constants';
import themes, { THEMES_NAMES, createRandomTheme } from 'styles/theme';


const initialState = {
    collections: { ...themes },
    themeName: THEMES_NAMES.DEFAULT,
};

const slice = createSlice({
    name: reducerNames.THEME,
    initialState,
    reducers: {
        setTheme: (state, { type, payload }) => {
            if (payload === THEMES_NAMES.RANDOM) {
                state.collections[payload] = createRandomTheme();
            }
            state.themeName = payload;
        },
    }
});


export default slice.reducer;

export const { name, actions, getInitialState } = slice;
