// Utils
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import './style.scss';


export const FilterInfo = (props) => {
    const {
        onClickClearAll,
        filterInfo = {},
        name
    } = props;

    const { active, filteredRows, totalRows, search } = filterInfo;

    if (filteredRows === undefined || totalRows === undefined) return null;

    return (
        <div
            className="filter-info"
            {...setAttr.feature(testBy.FEATURE.FILTER_INFO)}
        >
            <div>
                {(active || search) ? <span className="primary">{`${t('lblFiltered')}: `}</span> : null}
                <span>{`${t('lblTotalOf')} `}</span>
                {(active || search) ? <span className="primary">{`${filteredRows}/`}</span> : null}
                <span>{`${totalRows} ${name ?? t('lblResources')}`}</span>
                {
                    active ? (
                        <span
                            className="action-btn"
                            onClick={onClickClearAll}
                            {...setAttr.action(testBy.ACTION.CLEAR)}
                        >
                            {t('lblClearFilters')}
                        </span>
                    ) : null
                }
            </div>
        </div>
    );
};
