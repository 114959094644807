import React, { useState, useEffect, useCallback } from 'react';
import { Text } from 'components/common';
import './style.scss';

const SelectionInfo = (props) => {
    const { info, links, initialLinkIndex=0, onClickLink } = props;

    const [ linkIndex, setLinkIndex ] = useState(initialLinkIndex);

    useEffect(() => {
        setLinkIndex(initialLinkIndex);
    }, [initialLinkIndex]);

    const renderLink = useCallback((index) => {
        const link = links[index] ?? '';
        if (link.length > 0) {
            return (
                <Text
                    className='select-link'
                    onClick={(e) => {
                        onClickLink?.(index, e);
                        index = (index + 1) % links.length;
                        setLinkIndex(index);
                    }}
                    text= {link}
                />
            );
        }
        return null;
    }, [links, onClickLink]);

    return (
        <div className='selection-info'>
            <Text text={info} className="greedy" />
            {renderLink(linkIndex)}
        </div>
    );
};

export default SelectionInfo;