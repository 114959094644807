import React from 'react';
import { ModalBlock } from 'components/modal/components';
import ProgressBarContainer from 'components/progress_bars/ProgressBarContainer';
// Utils
import { useLengthUnit, useSpeedUnit } from 'hooks';
import { pf_t, t } from 'utils/dictionary';
import { classAttribute } from 'utils/helper';
import './style.scss';

const WeatherStatusIndicator = ({ className, title, fValue, value, variant }) => {
    const header = `${title} ${fValue}`;

    const classes = classAttribute([
        'weather-status-indicator',
        className ?? '',
        variant ?? '',
    ]);

    return <div className={classes}>
        <div>{header}</div>
        <ProgressBarContainer
            background="#D9D9D9"
            min={0}
            max={100}
            value={value}
            minimise
        />
    </div>;
};

const ModalWeather = ({ weatherData }) => {

    const mLength = useLengthUnit();
    const mSpeed = useSpeedUnit();

    const windSpeed = weatherData.windSpeed != null ? mSpeed.f(weatherData.windSpeed) : 'N/A';
    const visibility = weatherData.visibility != null ? mLength.f(weatherData.visibility / 1000) : 'N/A';
    const precipitation = weatherData.precipitation != null ? `${weatherData.precipitation} in` : 'N/A';

    return (
        <ModalBlock className="weather">
            <div className="weather-condition-cell">
                <span className="weather-condition-label">{t('lblCondition')}:</span>
                <span>{pf_t.weatherCondition(weatherData.condition)}</span>
            </div>
            <div className="weather-status-indicators">
                <WeatherStatusIndicator
                    className="top"
                    title={t('lblPrecipitation')}
                    fValue={precipitation}
                    value={weatherData.precipitationBar ?? 0}
                    variant={weatherData.precipitationRisk ?? ''}
                />
                <WeatherStatusIndicator
                    title={t('lblVisibility')}
                    fValue={visibility}
                    value={weatherData.visibilityBar ?? 0}
                    variant={weatherData.visibilityRisk ?? ''}
                />
                <WeatherStatusIndicator
                    className="bottom"
                    title={t('lblWindSpeed')}
                    fValue={windSpeed}
                    value={weatherData.windSpeedBar ?? 0}
                    variant={weatherData.windSpeedRisk ?? ''}
                />
            </div>
        </ModalBlock>
    );
};

export default ModalWeather;
