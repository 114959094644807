import React from 'react';
import Filter from 'pages/safety_page/features/filter/Filter';
import { actions } from 'store/slices/dashboard';
import { filtersSelectors } from 'store/selectors/dashboard';

const DashboardFilter = (props) => (
    <Filter
        applyFiltersAction={actions.applyFilters}
        clearFiltersAction={actions.clearFilters}
        enableDisableFilters={actions.enableDisableFilters}
        removeFilterAction={actions.removeFilter}
        resetFiltersAction={actions.resetFilters}
        setSearchAction={actions.setFilterSearch}
        updateFilterSelectionsAction={actions.updateFilterSelections}

        filterEnabledSelector={filtersSelectors.filterEnabled}
        filterManagerSelector={filtersSelectors.filtersManager}
        filterSummarySelector={filtersSelectors.filterSummary}
        filterTrailSelector={filtersSelectors.filterTrailData}
    />
);

export default DashboardFilter;
