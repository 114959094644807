import { useMemo } from 'react';
import { classAttribute } from 'utils/helper';
import './style.scss';


export const ProgressBar = (props) => {

    const { max, value, className } = props;
    const timeProgress = (max > 0) ? (((value / max) || 0) * 100) : 100;

    const classes = useMemo(() => classAttribute([
        'progress-value',
        className ?? '',
    ]), [className]);

    const style = {
        width: `${timeProgress}%`
    };

    return (
        <div className={classes} style={style} />
    );
};

const ProgressBarContainer = ({ background, children, disabled, minimise, onChange, value, max, min }) => {

    const classes = useMemo(() => classAttribute([
        'progress-bar-container',
        minimise ? 'minimised' : '',
    ]), [minimise]);

    return (
        <div className={classes} style={{ backgroundColor: background }}>
            <div className="progress-wrapper">
                {children}
                {disabled ? null : <ProgressBar
                    max={max}
                    value={value}
                />}
            </div>
            {disabled ? null : <input
                className="progress-input"
                type="range"
                min={min}
                max={max}
                value={value}
                onChange={onChange}
                readOnly={typeof onChange !== 'function'}
            />}
        </div>
    );
};

export default ProgressBarContainer;
