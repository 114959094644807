import { configureStore } from '@reduxjs/toolkit';
import updateDashboardFilterListener from 'store/middlewares/dashboard';
import updateIncidentsParamsListener from 'store/middlewares/safety';
import updateDriversParamsListener from 'store/middlewares/safetyDrivers';
import rootReducer from 'store/rootReducer';



const listeners = [
    updateDashboardFilterListener.middleware,
    updateIncidentsParamsListener.middleware,
    updateDriversParamsListener.middleware
];


export const configureAppStore = (preloadedState) => {
    const store = configureStore({
        reducer: rootReducer,
        devTools: process.env.NODE_ENV === 'development',
        preloadedState,
        // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(moreMiddleware...),
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend([...listeners]),
    });
    return store;
};
