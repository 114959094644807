import { useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
// Utils
import { t } from 'utils/dictionary';
import { formatDateMillis, intlFormats } from 'utils/timezone';
// Redux
import { getDashboardChartData } from 'store/selectors/safety';
// Components
import { HeaderWrapper } from 'layout/containers';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, Bar, Brush } from 'recharts';



const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
        const items = payload.map((data, i) => {
            const { name, value, color } = data;
            return (
                <span key={name ?? i}>
                    <span style={{ color }}>{`${name}: ${value}`}</span>
                </span>
            );
        });

        let date = payload[0]?.payload?.date;
        const sum = payload.reduce((sum, data) => sum + data.value, 0);
        if (date) {
            date = formatDateMillis(date, intlFormats.DATE_SHORT);
        }
        return (
            <div className="custom-bar-chart-tooltip">
                {date}
                {items}
                <span>{`${t('lblTotal')}: ${sum}`}</span>
            </div>
        );
    }
    return null;
};


const IncidentGraph = (props) => {

    const { period } = props;
    const { data, bars, sliderRange } = useSelector(getDashboardChartData);

    const [lastRange, setLastRange] = useState(undefined);

    useEffect(() => {
        setLastRange(undefined);
    }, [period.id]);

    const onBrushChange = useCallback((range) => {
        setLastRange(range);
    }, []);

    const brush = useMemo(() => {
        const { startIndex, endIndex } = lastRange ?? sliderRange;
        if (data.length <= 14) return null;
        return (
            <Brush
                onChange={onBrushChange}
                dataKey="dateStr"
                height={4}
                stroke="none"
                fill='#e6eded'
                startIndex={startIndex}
                endIndex={endIndex}
                travellerWidth={0}
                className='brush-chart'
            />
        );
    }, [onBrushChange, data, lastRange, sliderRange]);

    return (
        <HeaderWrapper header={t('lblDailyViolations')}>
            <ResponsiveContainer minHeight={230}>
                <BarChart
                    data={data}
                    margin={{
                        top: 12,
                        right: 20,
                        left: -10,
                        bottom: 10,
                    }}
                >
                    <XAxis dataKey="dateStr" />
                    <YAxis
                        interval='preserveStartEnd'
                        tickCount={4}
                        scale='linear'
                    // tick={{ fontSize: 12 }}
                    />
                    <Tooltip cursor={{ fill: 'rgba(233, 233, 233, 0.3)' }} content={<CustomTooltip />} />
                    {brush}
                    {
                        bars.map(({ dataKey, stackId, color, name }) => (
                            <Bar
                                key={dataKey}
                                dataKey={dataKey}
                                stackId={stackId}
                                fill={color}
                                name={name}
                                barSize={16}
                            />
                        ))
                    }
                </BarChart>
            </ResponsiveContainer>
        </HeaderWrapper>
    );
};


export default IncidentGraph;