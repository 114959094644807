import React, { useCallback } from 'react';
// Utils
import { iconsName } from 'assets';
import { formatDateMillis, intlFormats, getDiffBetweenDates } from 'utils/timezone';
import { t } from 'utils/dictionary';
import { useLengthUnit } from 'hooks';
// Components
import { RecycleList } from 'components';
import { Text, Icon } from 'components/common';
import './style.scss';




// Mock trips
const mockTrips = {
    startRoute: '2023-04-02T22:00:00+00:00',
    endRoute: '2023-04-03T07:21:29+00:00',
    distance: 25,
    tripsCount: 3,
    alertCount: 221,
    trips: [
        {
            id: 1,
            start: 1680479463620,
            end: 1680479761760,
            driverName: 'Vladi C',
            distance: 3,
            alertCount: 32,
            isCurrent: false,
            startAddress: 'כפר סבא זאב גולד 13',
            endAddress: 'כפר סבא דרך המוביל 3'
        },
        {
            id: 2,
            start: 1680497649930,
            end: 1680498142080,
            driverName: 'Vladi C',
            distance: 3,
            alertCount: 47,
            isCurrent: false,
            startAddress: 'כפר סבא דרך המוביל 3',
            endAddress: 'כפר סבא יבנה 14'
        },
        {
            id: 3,
            start: 1680499416180,
            end: 1680501246990,
            distance: 19,
            alertCount: 142,
            isCurrent: false,
            safetyScore: 10,
            ecoScore: 82
        },
        {
            id: 4,
            start: 1680499416180,
            end: 1680501246990,
            distance: 19,
            alertCount: 142,
            isCurrent: false,
            safetyScore: 10,
            ecoScore: 82
        },
        {
            id: 5,
            start: 1680499416180,
            end: 1680501246990,
            distance: 19,
            alertCount: 142,
            isCurrent: false,
            safetyScore: 10,
            ecoScore: 82
        },
        {
            id: 6,
            start: 1680499416180,
            end: 1680501246990,
            distance: 19,
            alertCount: 142,
            isCurrent: false,
            safetyScore: 10,
            ecoScore: 82
        },
        {
            id: 7,
            start: 1680499416180,
            end: 1680501246990,
            distance: 19,
            alertCount: 142,
            isCurrent: false,
            safetyScore: 10,
            ecoScore: 82
        },
        {
            id: 8,
            start: 1680499416180,
            end: 1680501246990,
            distance: 19,
            alertCount: 142,
            isCurrent: false,
            safetyScore: 10,
            ecoScore: 82
        },
        {
            id: 9,
            start: 1680499416180,
            end: 1680501246990,
            distance: 19,
            alertCount: 142,
            isCurrent: false,
            safetyScore: 10,
            ecoScore: 82
        }
    ],
};


export const TripListItem = (props) => {

    const { trip, resource, top, height } = props;
    const {
        start,
        end,
        distance,
        distanceF,
        durationF,
        startAddress,
        endAddress,
        safetyScore,
        ecoScore
    } = trip;

    // const rowFields = [
    //     resource?.name,
    //     `${t('lblDateAtTime', { date: formatDateMillis(start, intlFormats.DATE_SHORT), time: formatDateMillis(start, intlFormats.TIME_WITH_SECONDS) })}`,
    //     `${durationF}, ${distanceF}`
    // ];

    return (
        <div
            className='trips-card-container'
            style={{ top, height }}
        >
            <div>
                <div className='card-row'>
                    <Text
                        text={resource?.name ?? 'N/A'}
                        className='text-v1'
                    />
                    <Text text={` | `} className='text-v1' />
                    <Text
                        text={`${t('lblDateAtTime', { date: formatDateMillis(start, intlFormats.DATE_SHORT), time: formatDateMillis(start, intlFormats.TIME_WITH_SECONDS) })}`}
                        className='text-v2'
                    />
                    <Text text={` | `} className='text-v1' />
                    <Text
                        text={`${durationF}, ${distanceF}`}
                        className='text-small'
                    />
                </div>
                <div className='card-row'>
                    <Icon
                        title={t('lblTripStart')}
                        name={iconsName.UNDERLINE_ARROW}
                        fill="#4F4E4E"
                        width='20px'
                        height='20px'
                    />
                    <Text
                        text={startAddress ?? 'N/A'}
                        // text={'Amal St 11, Rosh Haayin, Israel'}
                        className='text-v3'
                    />
                </div>
                <div className='card-row'>
                    <Icon
                        title={t('lblTripEnd')}
                        name={iconsName.FINISH_FLAG}
                        fill="#4F4E4E"
                        width='20px'
                        height='20px'
                    />
                    <Text
                        text={endAddress ?? 'N/A'}
                        className='text-v3'
                    />
                </div>
            </div>
            <div>
                <Text
                    title={t('lblSafetyScore')}
                    text={safetyScore ?? 'N/A'}
                    className='text-large'
                />
            </div>
        </div>
    );
};


const CARD_SPACE = 8;
const CARD_HEIGHT = 84;

export const TripsList = (props) => {

    const { resource, trips = mockTrips.trips, selectedId } = props;

    const mLength = useLengthUnit();

    const formatDuration = useCallback((trip) => {
        const { hours, minutes } = getDiffBetweenDates(trip.end, trip.start, ['hours', 'minutes', 'seconds']);
        let format = '';
        if (hours) { format = `${t('lblHoursShort', { count: hours })} `; }
        if (minutes) { format += t('lblMinutesShort', { count: minutes }); }
        return format;
    }, []);

    const renderRow = useCallback((props) => {

        const { dataIndex, data, top, height } = props;
        const trip = data[dataIndex];

        const selected = Boolean(selectedId && (selectedId === trip?.id));
        const distanceF = mLength.f(trip.distance);
        const durationF = formatDuration(trip);

        return (
            <TripListItem
                selected={selected}
                trip={{ ...trip, distanceF, durationF }}
                resource={resource}
                {...props}
                top={top + CARD_SPACE}
                height={height - CARD_SPACE}
            />
        );
    }, [resource, selectedId, mLength, formatDuration]);

    return (trips.length === 0) ? (
        <div className='empty-trips-list-container'>
            <div>
                <Text text={`${t('lblNoTripsAvailable')}`} />
            </div>
            <div>
                <Icon
                    color={'gray'}
                    width={100}
                    height={100}
                    name={iconsName.TRIP_ROUTE}
                />
            </div>
        </div>
    ) : (
        <div className='trips-list-container'>
            <RecycleList
                rowComponent={renderRow}
                items={trips}
                rowHeight={CARD_HEIGHT + CARD_SPACE}
                offScreenRow={10}
            />
        </div>
    );
};
