import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT_USER } from 'store/actionTypes';
import { reducerNames } from 'store/constants';
import { getMeasureSystem, measureSystemIds } from 'utils/helpers';

const initialState = {
    account: {},
    site: {},
    measureSystem: getMeasureSystem(measureSystemIds.DEFAULT),
    fetching: { definition: false }
};

const slice = createSlice({
    name: reducerNames.USER,
    initialState,
    reducers: {
        authUserSuccess: (state, { type, payload }) => ({ ...state, ...payload }),
        setFetching: (state, { type, payload }) => {
            const { name, isFetching } = payload;
            state.fetching[name] = isFetching;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(LOGOUT_USER, (state, action) => initialState);
    }
});


export default slice.reducer;

export const { name, actions, getInitialState } = slice;
