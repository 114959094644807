import { createSelector } from 'reselect';
import { iconsName } from 'assets';
import { getFiltersSelectors, setFiltersFromState } from 'store/common/filters';
import { reducerNames } from 'store/constants';
import { getDriversState } from 'store/selectors/drivers';
import { getResourcesState } from 'store/selectors/resources';
import { t } from 'utils/dictionary';

export const getSafetyPeriodState = (state) => state[reducerNames.SAFETY_DRIVERS].period;
const getParamsState = (state) => state[reducerNames.SAFETY_DRIVERS].params;
export const getPagingState = (state) => state[reducerNames.SAFETY_DRIVERS].paging;
const getItemsState = (state) => state[reducerNames.SAFETY_DRIVERS].items;
export const getFetchingState = (state) => state[reducerNames.SAFETY_DRIVERS].fetching;
const getKpisState = (state) => state[reducerNames.SAFETY_DRIVERS].kpis;
const getFiltersManagerState = (state) => state[reducerNames.SAFETY_DRIVERS].filtersManager;
export const getSearchValueState = (state) => state[reducerNames.SAFETY_DRIVERS].searchValue;
const getSearchParamsState = (state) => state[reducerNames.SAFETY_DRIVERS].searchParams;

export const getSearchParams = createSelector([getSearchParamsState], (params) => params);

export const getDriverItems = createSelector(
    [getItemsState, getSearchValueState],
    (items, search) => [...items]
);

export const getParams = createSelector(
    [getSafetyPeriodState, getParamsState],
    (period, params) => ({ ...params, period })
);

export const getSortOption = createSelector(
    [getParamsState],
    (params) => {
        const { sort, isDesc } = params;
        return { dataKey: sort, isDesc };
    }
);

export const getKpis = createSelector(
    [getKpisState],
    (kpis) => {
        const items = kpis.map(({ trend, count, category }) => ({
            value: count,
            name: t(`lblDriverKPI.${category}`),
            trend,
            icon: { name: iconsName.USER_AVATAR }
        }));
        return items;
    }
);

// Filters
const getDriversFilters = createSelector(
    [getFiltersManagerState, getDriversState, getResourcesState],
    setFiltersFromState
);
export const filtersSelectors = getFiltersSelectors(getFiltersManagerState, getDriversFilters, getPagingState, getSearchValueState);

export const getSafetyDriversPaging = createSelector(
    [getPagingState, getItemsState],
    (paging, items) => {
        const { maxCount, filteredRows, totalRows, pageNumber } = paging;
        const itemsCount = items.length;
        const nextPage = Math.ceil(itemsCount / maxCount) + 1;
        return {
            ...paging,
            itemsCount,
            canPage: itemsCount < filteredRows,
            nextPage
        };
    }
);
