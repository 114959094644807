import { api } from 'services';
import { actions } from 'store/slices/incidentTypes';
import { APIS } from 'utils/constants';

export default actions;

export const fetchIncidentTypes = () => async (dispatch) => {
    const { data, status } = await api.get(APIS.INCIDENTS.TYPES);
    if (status === 200) {
        dispatch(actions.setIncidentTypes(data.data));
    }
};