import { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';

// redux
import { useSelector } from 'react-redux';
import { getSearchValueState } from 'store/selectors/installer';

// utils & constants
import { t } from 'utils/dictionary';

// components
import { WoActionsBar } from 'components/wo_actions_bar/WoActionsBar';
import { WoCard } from 'components/wo_card/WoCard';

import './style.scss';

export const WoList = ({ accounts, wos, installers }) => {
    const [groupedWos, setGroupedWos] = useState({});
    const search = useSelector(getSearchValueState);

    const getInstallerName = useCallback((userId) =>  installers.find((installer)=> installer.value === userId)?.label || 'N/A', [installers]);

    const compareByDate = ( a, b ) => {
        const aEndDate = DateTime.fromISO(a.endDate);
        const bEndDate = DateTime.fromISO(b.endDate);
        if ( aEndDate < bEndDate ) {
            return 1;
        }
        if ( aEndDate > bEndDate ){
            return -1;
        }
        return 0;
    };

    const nameInstallerSearch = useCallback((wo) => {
        const woInstaller = getInstallerName(wo.userId);
        return (wo.name.toLowerCase().includes(search.toLowerCase()) || woInstaller.toLowerCase().includes(search.toLowerCase()));
    } ,[search, getInstallerName]);

    useEffect(() => {
        // sort - latest date first
        let filteredWos = [...wos].sort(compareByDate);

        if (search) {
            // filter by wo name or wo installer name
            filteredWos = filteredWos.filter(nameInstallerSearch);
        }
        // group by account
        const wosByAccount = Object.groupBy(filteredWos, ({ accountId }) => accounts.filter((account) => account.value === accountId)[0]?.label || t('lblUnassigned'));
        setGroupedWos(wosByAccount);
    }, [accounts, wos, search, nameInstallerSearch]);

    return (
        <div className="wo-list-tab">
            <WoActionsBar search={search} />
            <div className="wo-tab-title">
                <span>{t('lblWorkOrders')}</span>
            </div>
            <div className="list-container">
                {Object.keys(groupedWos).length > 0 ?
                    <div className="wo-list">
                        {Object.keys(groupedWos).map((key) => (
                            <div key={`group-${key}`}>
                                <div className="group-title">{key}</div>
                                {groupedWos[key].map((wo) => (
                                    <WoCard
                                        key={wo.id}
                                        wo={wo}
                                        getInstallerName={getInstallerName}
                                    />
                                ))}
                            </div>))}
                    </div>
                    :
                    <div className="empty-state"><div>{t('woListEmpty')}</div></div>
                }
            </div>
        </div>
    );
};