import React from 'react';
import { confirmable, createConfirmation } from 'react-confirm';
import BaseDialog from 'components/common/dialogs/BaseDialog';

const AlertDialogModal = ({ show, proceed, confirmation, title, okText }) =>
    (<BaseDialog
        show={show}
        onOkClick={() => proceed(false)}
        title={title}
        okText={okText}
    >
        {confirmation}
    </BaseDialog>
    );

const confirmLow = createConfirmation(confirmable(AlertDialogModal));

export const AlertDialog = (message, options = {}) =>
    confirmLow(Object.assign({ confirmation: message }, options));
