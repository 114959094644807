import { batch } from 'react-redux';
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
// Utils
import { rangeMsFromPeriod } from 'pages/safety_page/constants';
import actions, { fetchIncidents } from 'store/actions/safety';
import * as selectors from 'store/selectors/safety';
import { filterActionMatcher } from 'store/slices/safety';


const updateIncidentsParamsListener = createListenerMiddleware();

updateIncidentsParamsListener.startListening({
    matcher: isAnyOf(
        actions.nextPaging,
        actions.setIncidentStatus,
        actions.updateParams,
        actions.setSearchParams,
        filterActionMatcher
    ),
    effect: async (action, listenerApi) => {

        const isUpdateParams = isAnyOf(
            actions.updateParams,
            actions.setSearchParams,
            filterActionMatcher
        )(action);

        const isPaging = action.type === actions.nextPaging.type;
        const isStatusChanged = action.type === actions.setIncidentStatus.type;

        listenerApi.cancelActiveListeners();
        await listenerApi.delay(500);

        const state = listenerApi.getState();
        const { pageNumber, maxCount } = selectors.getPagingState(state);
        const { period, ...paramsData } = selectors.getParams(state);
        const searchParams = selectors.getSearchParams(state);

        let params = { ...paramsData };
        if (!params.startTime || !params.endTime || isUpdateParams) {
            const rangeMs = rangeMsFromPeriod(period);
            params = { ...params, ...rangeMs };
        }
        const paramsWitSearch = { ...params, ...searchParams };
        const updateParameters = async () => {
            listenerApi.dispatch(actions.setFetching({ isPaging: false, isLoading: true }));
            if (pageNumber > 1) {
                listenerApi.dispatch(actions.resetPaging());
            }
            const { data, status } = await fetchIncidents({ ...paramsWitSearch, pageNumber: 1, maxCount });
            if (listenerApi.signal.aborted) {
                return;
            }

            if (status === 200) {
                const { incidents, filteredRows, totalRows } = data.data;
                batch(() => {
                    listenerApi.dispatch(actions.setItems(incidents));
                    listenerApi.dispatch(actions.setPagingDataInfo({ filteredRows, totalRows }));
                });
            }

            batch(() => {
                listenerApi.dispatch(actions.setParams(params));
                listenerApi.dispatch(actions.setFetching({ isPaging: false, isLoading: false }));
            });
        };

        if (isUpdateParams) {
            await updateParameters();
        }

        if (isStatusChanged && params.statuses) {
            await updateParameters();
        }

        if (isPaging) {
            listenerApi.dispatch(actions.setFetching({ isPaging: true, isLoading: false }));
            const { data, status } = await fetchIncidents({ ...paramsWitSearch, pageNumber, maxCount });
            if (listenerApi.signal.aborted) {
                return;
            }

            if (status === 200) {
                const { incidents, filteredRows, totalRows } = data.data;
                batch(() => {
                    listenerApi.dispatch(actions.addItems(incidents));
                    listenerApi.dispatch(actions.setPagingDataInfo({ filteredRows, totalRows }));
                });
            }

            listenerApi.dispatch(actions.setFetching({ isPaging: false, isLoading: false }));
        }

    },
});

export default updateIncidentsParamsListener;
