import { createSelector } from 'reselect';
import { reducerNames } from 'store/constants';
import { getUnitLabelKey } from 'utils/helpers';
import { t } from 'utils/dictionary';


/*------------ STATES -------------*/
export const getAccountState = (state) => state[reducerNames.USER].account;
const getUserSiteState = (state) => state[reducerNames.USER].site;
const getUserSiteMapState = (state) => state[reducerNames.USER].site.map;
export const getFetchingState = (state) => state[reducerNames.USER].fetching;
export const getUserLocaleState = (state) => state[reducerNames.USER].locale;
export const getUserMeasurementsState = (state) => state[reducerNames.USER].measureSystem;


/*------------ SELECTORS -------------*/
export const getUserInfo = createSelector(
    [getAccountState, getUserSiteState],
    (account, site) => {
        const { loginName } = site ?? {};
        const info = { account, loginName };
        return info;
    });

export const getSiteDefaultCoordinates = createSelector(
    [getUserSiteMapState],
    (map) => {
        const { lat, lng } = map?.coverage ?? {};
        const coords = { lat, lng };
        return coords;
    });


export const getUserMeasurements = createSelector(
    [getUserMeasurementsState],
    (measurements) => {
        const units = measurements.units.map((u) => {
            const lblKeys = getUnitLabelKey(u);
            return { ...u, abbr: t(lblKeys?.lblAbbr, u.unitName) };
        });
        return { ...measurements, units };
    });
