import React, { useCallback, useMemo } from 'react';
// Utils
import { t } from 'utils/dictionary';
import { formatDateMillis } from 'utils/timezone';
import { useSpeedUnit } from 'hooks';
// Components
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ReferenceLine, Line } from 'recharts';
import { DateTime } from 'luxon';
import './style.scss';


const ModalCollisionGraph = ({ chartData }) => {

    const digitsAfterPoint = 2;
    const mSpeed = useSpeedUnit();

    const lineChartObj = useMemo(() => {
        const { data, incidentTime } = chartData;
        if (Array.isArray(data) && incidentTime > 0) {
            const arr = [...data];
            const lines = [
                { dataKey: 'rms', color: 'blue', name: t('lblRMS') },
                { dataKey: 'speed', color: 'green', name: t('lblSpeed') },
            ];
            const index = arr.findIndex((d) => d.date === incidentTime);

            if (index === -1) { arr.push({ date: incidentTime }); }

            arr.sort((val1, val2) => {
                if (val1.date > val2.date) { return 1; }
                if (val1.date < val2.date) { return -1; }
                return 0;
            });

            const convertSpeed = (speed) => {
                let converted = mSpeed.c(speed);
                if (typeof converted === 'number') {
                    converted = Number(converted?.toFixed?.(digitsAfterPoint));
                }

                return converted;
            };

            const retTimeLineEvents = arr.map((data) => ({ ...data, speed: convertSpeed(data.speed), current: data.date === incidentTime, date: formatDateMillis(data.date, DateTime.TIME_24_WITH_SECONDS) }));

            return { data: retTimeLineEvents, lines, incidentTime: formatDateMillis(incidentTime, DateTime.TIME_24_WITH_SECONDS) };
        }
        return {};
    }, [chartData, mSpeed]);

    const legendFormatter = useCallback((value) => <span style={{ color: '#000000' }}>{value}</span>, []);

    const { data, lines, incidentTime } = lineChartObj;
    /*
    const [brushRange, setBrushRange] = useState({});

    useEffect(() => {
        setBrushRange({});
    }, [period.label]);

    useEffect(() => {
        if (data.length > 14) {
            setBrushRange({ startIndex: data.length - 14, endIndex: data.length - 1 });
        }
    }, [data.length]);

    const onBrushChange = useCallback((range) => {
        setBrushRange(range);
    }, []);
    */
    if (!data || !lines) {
        return (
            <div className="modal-collision-graph-unavailable">
                <div className="widget-mask"><p>{t('lblTimelineGraphUnvailable')}</p></div>
            </div>
        );
    }

    return (
        <div className="modal-collision-graph">
            <ResponsiveContainer>
                <LineChart
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend iconType="plainline" wrapperStyle={{ right: -60 }} layout="vertical" verticalAlign='middle' width={80} formatter={legendFormatter} />
                    {data[0] ? <ReferenceLine x={incidentTime} stroke="red" /> : null}
                    {
                        lines?.map(({ dataKey, color, name }) => (
                            <Line
                                type="monotone"
                                key={dataKey}
                                dataKey={dataKey}
                                stroke={color}
                                name={name}
                                dot={false}
                            />
                        ))
                    }
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ModalCollisionGraph;
