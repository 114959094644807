import React, { useCallback, useMemo } from 'react';
import FilterChip from 'components/filters/filter_chip/FilterChip';
import { testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { classAttribute } from 'utils/helper';
import './style.scss';

const FilterTrail = ({ data, clearFilters, removeFilter, disabled }) => {

    const renderChips = useCallback(() => {
        if (Array.isArray(data)) {
            let chips = [];
            let key = 0;
            const rest = data.slice(2);
            if (rest.length > 0) {
                chips.push(<FilterChip
                    key={key++}
                    disabled={disabled}
                    id={JSON.stringify(rest)}
                    removeFilter={removeFilter}
                />);
            }
            const addChip = ((slice) => {
                const datum = slice[0] ?? {};
                const id = Object.keys(datum)[0];
                if (id) {
                    chips.push(<FilterChip
                        key={key++}
                        disabled={disabled}
                        id={JSON.stringify(id)}
                        removeFilter={removeFilter}
                        text={datum[id]}
                    />);
                }
            });
            addChip(data.slice(1, 2));
            addChip(data.slice(0, 1));
            if (data.length > 0) {
                chips.push(<FilterChip
                    key={key++}
                    clearFilters={clearFilters}
                    disabled={disabled}
                    id={0}
                    text={t('lblClearAll')}
                />);
            }
            return chips;
        }
        return null;
    }, [clearFilters, data, disabled, removeFilter]);

    const classNames = useMemo(() => classAttribute([
        'filter-trail',
        testBy.CLASS.FILTER_TRAIL,
    ]), []);

    return (
        <div className={classNames}>
            {renderChips()}
        </div>
    );
};

export default FilterTrail;
