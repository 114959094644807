import React, { useCallback, useMemo } from 'react';
// Utils
import { setAttr, testBy } from 'utils/constants';
import PropTypes, { actionBarProps, propTypes } from 'utils/propTypes';
// Components
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Icon, Text } from 'components/common';
import './style.scss';


export const renderPopover = ({ content, title, id, ...rest }) => (
    <Popover id={`popover-${id}`} {...rest}>
        {title ? <Popover.Header>{title}</Popover.Header> : null}
        <Popover.Body>
            {content}
        </Popover.Body>
    </Popover>
);


export const ActionBar = (props) => {
    const { icon, onClick, popover, overlayProps, ...rest } = props;

    const handleClick = useCallback(() => {
        onClick?.({ ...rest });
    }, [onClick, rest]);

    const trigger = popover ? ['click'] : [];

    const action = useMemo(() => (
        <Icon
            width={24}
            height={24}
            {...icon}
            onClick={handleClick}
        />
    ), [icon, handleClick]);

    if (popover) {
        return (
            <OverlayTrigger
                trigger={trigger}
                placement="bottom"
                overlay={renderPopover(popover)}
                {...overlayProps}
            >
                {action}
            </OverlayTrigger>
        );
    }

    return action;
};

ActionBar.propTypes = actionBarProps;



export const ActionsBar = (props) => {
    const { actions = [] } = props;

    const ActionIcons = useMemo(() => actions.map((action, i) => (
        <ActionBar key={action.id ?? i} {...action} />
    )), [actions]);

    return (
        <div className='actions-bar' {...setAttr.ui(testBy.UI.ACTION_BAR)}>
            {ActionIcons}
        </div>
    );
};

ActionsBar.propTypes = {
    actions: PropTypes.arrayOf(propTypes.actionBarProps)
};



export const Bar = (props) => {
    const { title, children, actions } = props;
    return (
        <div className="widget-bar">
            <Text className='title' text={title} />
            {children}
            <ActionsBar
                actions={actions}
            />
        </div>
    );
};

Bar.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    actions: PropTypes.arrayOf(propTypes.actionBarProps)
};


export const WidgetBox = (props) => {

    const {
        variant,
        title,
        actions,
        className,
        children
    } = props;

    return (
        <div
            {...setAttr.feature(testBy.FEATURE.WIDGET_BOX)}
            className={`widget-box ${className ?? ''} ${variant ?? ''}`}
        >
            <Bar
                title={title}
                actions={actions}
            />
            {children}
        </div>
    );
};

WidgetBox.propTypes = {
    variant: PropTypes.string,
    title: PropTypes.string,
    actions: PropTypes.arrayOf(propTypes.actionBarProps),
    className: PropTypes.string,
    children: PropTypes.node
};