import { DAY, getDateTime } from 'utils/timezone';

const _getDatesRange = ({ fromMillis, duration, startOf = 'day' } = {}) => {
    const fromDate = getDateTime(fromMillis);
    let toDate = fromDate;
    if (startOf) {
        toDate = toDate.startOf(startOf);
    }
    if (duration) {
        toDate = toDate.plus(duration);
    }
    let range = { startTime: fromDate, endTime: toDate };
    if (toDate < fromDate) {
        range = { startTime: toDate, endTime: fromDate };
    }
    return range;
};

const _datesRangeToExclusiveRangeMs = ({ startTime, endTime }) => ({ startTime: startTime.toMillis(), endTime: endTime.toMillis() - 1 });

export const getExclusiveRangeMs = {
    today: () => {
        const datesRange = _getDatesRange();
        return _datesRangeToExclusiveRangeMs(datesRange);
    },
    thisWeek: () => {
        const datesRange = _getDatesRange({ startOf: 'week' });
        return _datesRangeToExclusiveRangeMs(datesRange);
    },
    thisMonth: () => {
        const datesRange = _getDatesRange({ startOf: 'month' });
        return _datesRangeToExclusiveRangeMs(datesRange);
    },
    last7Days: () => {
        const datesRange = _getDatesRange({ duration: { days: -6 }});
        return _datesRangeToExclusiveRangeMs(datesRange);
    },
    last14DaysOrMore: (startTimeMs, endTimeMs) => {
        const todayStart = getDateTime().startOf('day');
        const _13daysAgoStartMs = todayStart.minus({ days: 13 }).toMillis();
        const minStartTimeMs = Math.min(startTimeMs, _13daysAgoStartMs);
        const maxEndTimeMs = Math.max(endTimeMs, todayStart.plus({ days: 1 }).toMillis() - 1);
        return { startTime: minStartTimeMs, endTime: maxEndTimeMs };
    },
    last30Days: () => {
        const datesRange = _getDatesRange({ duration: { days: -29 }});
        return _datesRangeToExclusiveRangeMs(datesRange);
    },
    last30DaysFrom: (startTimeMs) => {
        const datesRange = _getDatesRange({ fromMillis: startTimeMs, duration: { days: -30 }});
        return _datesRangeToExclusiveRangeMs(datesRange);
    },
};

export const isMoreThanOneDayExclusiveRangeMs = ({ startTime, endTime }) => (endTime - startTime) > (DAY - 1);
