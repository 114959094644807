import { useMemo } from 'react';
import { classAttribute } from 'utils/helper';
import './style.scss';

const Card = ({ children, className, onClick, testAttributes }) => {

    const classes = useMemo(() => classAttribute([
        'pf-card',
        className ?? '',
    ]), [className]);

    return (
        <div
            className={classes}
            onClick={onClick}
            {...testAttributes}
        >
            {children}
        </div>
    );
};

export default Card;
