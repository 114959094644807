import { createSelector } from 'reselect';
import { reducerNames } from 'store/constants';


/*------------ STATES -------------*/
export const getThemeNameState = (state) => state[reducerNames.THEME].themeName;
export const getCollectionsState = (state) => state[reducerNames.THEME].collections;


/*------------ SELECTORS -------------*/
export const getSelectedTheme = createSelector(
    [getThemeNameState, getCollectionsState],
    (name, themes) => themes[name]
);


