import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT_USER } from 'store/actionTypes';
import { reducerNames } from 'store/constants';


const initialState = {
    resources: [],
    resourceDetails: {},
    mockResourceDetails: { resourceId: 555 },
};


const slice = createSlice({
    name: reducerNames.RESOURCES,
    initialState,
    reducers: {
        setResources: (state, action) => {
            state.resources = action.payload;
        },
        setResourceDetails: (state, action) => {
            state.resourceDetails = action.payload;
        },
        clearResourceDetails: (state, action) => {
            state.resourceDetails = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(LOGOUT_USER, (state, action) => initialState);
    }
});


export default slice.reducer;

export const { actions, name, getInitialState } = slice;
