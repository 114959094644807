import React from 'react';
// Components
import { Icon } from 'components/common';
import { setAttr, testBy } from 'utils/constants';
import './style.scss';

const ModalBlockTitle = ({ icon, title, className }) => (
    <div
        className="block-title"
        {...setAttr.ui(testBy.UI.TITLE)}
    >
        <Icon
            width={32}
            height={32}
            {...icon}
        />
        <span className={className}>{title}</span>
    </div>
);

export default ModalBlockTitle;
