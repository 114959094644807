import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
// Utils
import { setAttr, testBy } from 'utils/constants';
import { filterKeys } from 'utils/entities';
import { t } from 'utils/dictionary';
// Redux
import actions, { fetchSeverityKpis, fetchIncidentsCountPerType } from 'store/actions/safety';
import { filtersSelectors, getFetchingState, getSeverityKpis, getFilterTypeIds,
    getIncidentsPaging, getSearchValueState, getSafetyPeriodState } from 'store/selectors/safety';
import { getIncidentTypes } from 'store/selectors/incidentTypes';
import { getSafetyBreadCrumbs } from 'store/selectors/navigation';
// Components
import { IncidentTable, IncidentTypeGraph, IncidentsFilter } from 'pages/safety_page/features';
import { Collapsible, SeverityKpis, InfoBar, SearchWithAutocomplete, PageTopBar } from 'components';
import { FilterInfo } from 'components/filters';
import { addFilterOption } from 'store/common/filters';
import './style.scss';

const IncidentsScreen = (props) => {

    const dispatch = useDispatch();

    const incidentTypes = useSelector(getIncidentTypes);
    const fetching = useSelector(getFetchingState);
    const kpis = useSelector(getSeverityKpis);
    const filterInfo = useSelector(filtersSelectors.filterInfo);
    const paging = useSelector(getIncidentsPaging);
    const search = useSelector(getSearchValueState);
    const { filters } = useSelector(filtersSelectors.filtersManager);
    const filteredIds = useSelector(getFilterTypeIds);
    const filtered = filteredIds.reduce((arr, filteredIds) => [...arr, ...filteredIds.items], []);

    const severityData = useMemo(() => [
        { id: 2 },
        { id: 1 },
        { id: 0 },
    ], []);

    const getSelectedCategoriesIds = useCallback(() => {
        const typesIndex = filters.findIndex((f) => f.name === filterKeys.TYPES);
        const typesFilter = filters[typesIndex];

        const selections = Object.keys(typesFilter.selections);
        if (selections) {
            const categoriesIds = selections?.map((selection) => +selection.substring(12));
            return categoriesIds;
        }

        return [];
    }, [filters]);

    const getSelectedSeveritiesIds = useCallback(() => {
        const severitiesIndex = filters.findIndex((f) => f.name === filterKeys.SEVERITIES);
        const severitiesFilter = filters[severitiesIndex];

        const selections = Object.keys(severitiesFilter.selections);
        if (selections) {
            const severitiesIds = selections?.map((selection) => +selection.substring(11));
            return severitiesIds;
        }

        return [];
    }, [filters]);

    useEffect(() => () => {
        dispatch(actions.clearIncidentsScreen());
    }, []);

    const onPeriodChange = useCallback((period) => {
        dispatch(actions.updateParams(period.rangeMs));
        dispatch(fetchSeverityKpis(period.rangeMs));
        dispatch(fetchIncidentsCountPerType(period.rangeMs));
    }, []);

    const onClickSort = useCallback((header) => {
        const { sortKey, sort } = header;
        const isDesc = sort.dataKey === sortKey ? !sort.isDesc : sort.isDesc;
        dispatch(actions.updateParams({ isDesc, sort: sortKey }));
    }, []);

    const onLoadMore = useCallback(() => {
        if (fetching.isPaging || !paging.canPage) return;
        if (paging.nextPage === paging.pageNumber) return;
        dispatch(actions.nextPaging());
    }, [fetching.isPaging, paging]);

    const onClickClearAllFilters = useCallback(() => {
        dispatch(actions.clearFilters());
    }, []);

    const addFilter = useCallback((id, filters) => {
        const f = addFilterOption(id, filters);
        batch(() => {
            dispatch(actions.updateFilterSelections(f));
            dispatch(actions.applyFilters());
        });
    }, [dispatch]);

    const removeFilterOption = useCallback((id) => {
        batch(() => {
            dispatch(actions.removeFilter(id));
            dispatch(actions.applyFilters());
        });

    }, [dispatch]);

    const onKpiCardClick = useCallback((id) => {
        let ids = [id];
        let isRemoveCategory = false;

        const seletedCategoriesIds = getSelectedCategoriesIds();
        const selectedSeveritiesIds = getSelectedSeveritiesIds();

        if (seletedCategoriesIds.includes(id)) {
            isRemoveCategory = true;
            if (selectedSeveritiesIds.length > 0) {
                dispatch(actions.changeFilterGroupSelection({ ids: selectedSeveritiesIds, data: severityData, filterName: 'severities', filterKey: filterKeys.SEVERITIES, isRemove: true }));
                dispatch(actions.applyFilters());
                return;
            }
        }
        else {
            ids = ids.concat(seletedCategoriesIds);
        }

        dispatch(actions.changeFilterGroupSelection({ ids, data: incidentTypes, filterName: 'types_group', filterKey: filterKeys.TYPES, isRemove: isRemoveCategory }));
        dispatch(actions.applyFilters());
    }, [dispatch, incidentTypes, getSelectedCategoriesIds, getSelectedSeveritiesIds, severityData]);

    const onKpiSeverityClick = useCallback((id, severiry) => {
        let ids = [severiry];
        let isRemoveSeverity = false;

        const seletedCategoriesIds = getSelectedCategoriesIds();
        const selectedSeveritiesIds = getSelectedSeveritiesIds();

        if (selectedSeveritiesIds.includes(severiry)) {
            isRemoveSeverity = true;
            if (selectedSeveritiesIds.length === 1 && seletedCategoriesIds.length === 1) {
                dispatch(actions.changeFilterGroupSelection({ ids: seletedCategoriesIds, data: incidentTypes, filterName: 'types_group', filterKey: filterKeys.TYPES, isRemove: true }));
            }
        }
        else {
            ids = ids.concat(selectedSeveritiesIds);
        }

        if (!seletedCategoriesIds.includes(id)) {
            dispatch(actions.changeFilterGroupSelection({ ids: [id], data: incidentTypes, filterName: 'types_group', filterKey: filterKeys.TYPES }));
        }

        dispatch(actions.changeFilterGroupSelection({ ids, data: severityData, filterName: 'severities', filterKey: filterKeys.SEVERITIES, isRemove: isRemoveSeverity }));
        dispatch(actions.applyFilters());
    }, [dispatch, incidentTypes, getSelectedCategoriesIds, getSelectedSeveritiesIds, severityData]);

    const onClickBarChart = useCallback((data) => {
        const add = !filtered.includes(data.id);
        const filterTypes = filters.find((f) => f.name === filterKeys.TYPES);
        if (add) {
            addFilter(`types_${data.id}`, filterTypes);
        } else {
            removeFilterOption(`${filterKeys.TYPES}_${data.id}`);
        }
    }, [filtered, filters, addFilter, removeFilterOption]);

    const onSearch = useCallback((params, value) => {
        batch(() => {
            dispatch(actions.setSearchParams(params));
            // Better to receive back the search value from the server for syncing and highlighting the results.
            // Currently it will highlight immediately on the existing data
            dispatch(actions.setSearchValue(value));
        });
    }, []);

    const onClearSearch = useCallback(() => {
        dispatch(actions.setSearchParams(null));
        dispatch(actions.setSearchValue(''));
    }, []);

    const breadCrumbs = useSelector(getSafetyBreadCrumbs);
    const curPeriod = useSelector(getSafetyPeriodState);

    return (
        <div
            className='incident-list-screen'
            { ...setAttr.screen(testBy.SCREEN.SAFETY_INCIDENTS) }
        >
            <PageTopBar
                breadCrumbs={breadCrumbs}
                showPeriodFilter
                onPeriodChange={onPeriodChange}
                isLoading={fetching.isLoading}
                curPeriod={curPeriod}
                setPeriod = {actions.setPeriod}
            />
            <section>
                <Collapsible>
                    <div
                        className='section-top'
                    >
                        <SeverityKpis
                            kpis={kpis}
                            onCardClick={onKpiCardClick}
                            onSeverityClick={onKpiSeverityClick}
                            selectedCategories={getSelectedCategoriesIds()}
                            selectedSeverities={getSelectedSeveritiesIds()}
                        />
                        <IncidentTypeGraph
                            onClickXAxis={onClickBarChart}
                            onClickBar={onClickBarChart}
                        />
                    </div>
                </Collapsible>
                <div className='section-bottom'>
                    <InfoBar>
                        <div className="info-group">
                            <SearchWithAutocomplete
                                featureAttrValue={testBy.FEATURE.FILTER_SEARCH}
                                onSearch={onSearch}
                                onClear={onClearSearch}
                                search={search}
                            />
                            <FilterInfo
                                name={t('lblIncidents')}
                                onClickClearAll={onClickClearAllFilters}
                                filterInfo={filterInfo}
                            />
                        </div>
                        <IncidentsFilter />
                    </InfoBar>
                    <IncidentTable
                        onClickSort={onClickSort}
                        onLoadMore={onLoadMore}
                        isLoading={fetching.isPaging}
                        markText={search}
                    />
                </div>
            </section>
        </div>
    );
};


export default IncidentsScreen;
