import React  from 'react';
import { iconsName } from 'assets';
import { Icon } from 'components/common';

const ToggleSwitch = ({ handleClick, testAttributes, tooltip, value, ...iconProps }) => (
    <Icon
        name={value ? iconsName.TOOGLE_ON : iconsName.TOGGLE_OFF }
        onClick={handleClick}
        {...testAttributes}
        title={tooltip}
        {...iconProps}
    />
);

export default ToggleSwitch;
