import React from 'react';




const ErrorBomb = ({ boom }) => {
    const error = () => ''();
    return boom ? error() : null;
};


class TimerWatch {

    count = 0;

    constructor(settings = {}) {
        const { seconds = 5, onChange, onEnd } = settings;
        this.seconds = seconds;
        this.onChange = onChange;
        this.onEnd = onEnd;
    }

    start = () => {
        if (this.intervalId) return;
        this.count = 0;
        this.intervalId = setInterval(() => {
            this.count++;
            this.onChange?.(this.count);
            if (this.count === this.seconds) {
                this.reset();
                this.onEnd();
            }
        }, 1000);
    };

    reset = () => {
        clearInterval(this.intervalId);
        this.intervalId = undefined;
        this.count = 0;
    };

}


export class RaiseErrorBomb extends React.PureComponent {

    constructor(props) {
        super(props);
        const { seconds } = props;
        this.state = { countDown: seconds, boom: false };
        this.timer = new TimerWatch({
            seconds,
            onChange: this.onTimerChange,
            onEnd: this.onTimerEnd
        });
    }

    componentDidMount() {
        const { auto } = this.props;
        if (auto) {
            this.timer.start();
        }
    }

    componentWillUnmount() {
        this.timer.reset();
    }

    onTimerChange = (time) => {
        const { seconds } = this.props;
        this.setState({ countDown: seconds - time });
    };

    onTimerEnd = () => {
        const { seconds } = this.props;
        this.setState({ boom: true, countDown: seconds });
    };

    onClickStartTimer = () => {
        this.timer.start();
    };

    onClickJsError = () => {
        'bad syntax'();
    };

    render() {
        const {
            // message = 'Something wrong going to happen...',
            message = '',
        } = this.props;

        const { countDown, boom } = this.state;

        return (
            <div>
                <div>{message}</div>
                <button
                    onClick={this.onClickStartTimer}
                >
                    {`Error timer ${countDown}`}
                </button>
                <button
                    onClick={this.onClickJsError}
                >
                    {`Error`}
                </button>
                <ErrorBomb boom={boom} />
            </div>
        );
    }
}

RaiseErrorBomb.defaultProps = {
    seconds: 10
};
