import { useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
// Redux
import { getIncidentsChartDataByType } from 'store/selectors/safety';
// Utils
import { setAttr, testBy } from 'utils/constants';
import { pf_t } from 'utils/dictionary';
import { getEventIcon } from 'utils/entities';
// Components
import { Icon } from 'components/common';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, Bar, Brush } from 'recharts';
import './style.scss';

const IncidentIcon = (props) => {
    const { x, y, payload, onClick, filtered } = props;

    const handleClick = useCallback(() => {
        onClick?.(payload);
    }, [onClick, payload]);

    const icon = getEventIcon(payload.value);
    const width = 20;
    const height = 20;
    return (
        <Icon
            width={width}
            height={height}
            x={x - (width / 2)}
            y={y}
            title={pf_t.eventName(payload.value)}
            {...icon}
            strokeOpacity={(!filtered.length || filtered?.includes(payload.value)) ? 1 : 0.4}
            onClick={handleClick}
            {...setAttr.action(testBy.ACTION.CHART_ICON)}
        />
    );
};

const CustomTooltip = ({ active, payload }) => {
    if (active && payload?.length) {
        const { name, value, color, payload: data } = payload[0]; // Bar props
        return (
            <div className="custom-bar-chart-tooltip">
                <Icon
                    width={24}
                    height={24}
                    {...getEventIcon(data.id ?? 0)}
                />
                <span style={{ color }}>{`${pf_t.eventName(data.id ?? 0)}`}</span>
                <span style={{ color: '#344955' }}>{`${name}: ${value}`}</span>
            </div>
        );
    }
    return null;
};

const CustomBar = ({ filtered, payload, ...rest }) => {
    const { background, count, index, name, tooltipPayload, tooltipPosition, ...rectAttrs } = rest;
    return <rect
        fillOpacity={(!filtered.length || filtered?.includes(payload.id)) ? 1 : 0.4}
        name={name}
        {...rectAttrs}
    />;
};

const IncidentTypeGraph = (props) => {

    const chartBar = useSelector(getIncidentsChartDataByType);
    const { data, bars, sliderRange, filtered } = chartBar;
    const { onClickXAxis, onClickBar } = props;
    const [lastRange, setLastRange] = useState(undefined);

    const onBrushChange = useCallback((range) => {
        setLastRange(range);
    }, []);

    const getItemById = useCallback((id) => data.find((item) => item.id === id), [data]);

    const handleClickBar = useCallback((item) => {
        onClickBar?.(getItemById(item.id));
    }, [onClickBar, getItemById]);

    const onClickXTick = useCallback((payload) => {
        onClickXAxis?.(getItemById(payload.value));
    }, [onClickXAxis, getItemById]);

    const brush = useMemo(() => {
        const { startIndex, endIndex } = lastRange ?? sliderRange;
        if (data.length <= 18) return null;
        return (
            <Brush
                onChange={onBrushChange}
                dataKey="dateStr"
                height={4}
                stroke="none"
                fill="#e6eded"
                startIndex={startIndex}
                endIndex={endIndex}
                travellerWidth={0}
                className="brush-chart"
            />
        );
    }, [onBrushChange, data, lastRange, sliderRange]);

    return (
        <div
            className="chart-incidents-types"
            {...setAttr.feature(testBy.FEATURE.INCIDENT_TYPE_CHART)}
        >
            <ResponsiveContainer
                width="99%"
            >
                <BarChart
                    data={data}
                    margin={{
                        top: 6,
                        right: 30,
                        left: 0,
                        bottom: 4,
                    }}
                >
                    <XAxis
                        dataKey="id"
                        tick={<IncidentIcon filtered={filtered} />}
                        height={36}
                        tickLine={false}
                        onClick={onClickXTick}
                    />
                    <YAxis
                        interval="preserveStartEnd"
                        tickCount={4}
                        scale="linear"
                    />
                    <Tooltip
                        cursor={{ fill: 'rgba(233, 233, 233, 0.3)' }}
                        content={<CustomTooltip />}
                    />
                    {brush}
                    {
                        bars.map(({ dataKey, stackId, color, name }) => (
                            <Bar
                                key={dataKey}
                                dataKey={dataKey}
                                stackId={stackId}
                                fill={color}
                                name={name}
                                barSize={16}
                                onClick={handleClickBar}
                                shape={<CustomBar
                                    className="cursor"
                                    filtered={filtered}
                                    {...setAttr.action(testBy.ACTION.CHART_BAR)}
                                />}
                            />
                        ))
                    }
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default IncidentTypeGraph;
