import React, { useMemo } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { isValidCoordinate } from 'utils/helper';
import './style.scss';



const Map = ({ children, center, ...rest }) => {

    const mapCenter = useMemo(() => isValidCoordinate(center ?? {}) ? center : Map.defaultProps.center, [center]);

    const { isLoaded } = useLoadScript({
        id: 'pf-google-map',
        googleMapsClientId: global.config.MAP.KEY,
        version: global.config.MAP.VERSION,
        libraries: global.config.MAP.LIBRARIES,
        // googleMapsApiKey: we should use different api keys for different modules
    });

    return (isLoaded ?
        <GoogleMap
            mapContainerClassName='map-container'
            center={mapCenter}
            {...rest}
        >
            {children}
        </GoogleMap>
        : <div style={{ height: 'inherit' }} />
    );
};


Map.defaultProps = {
    zoom: 12,
    options: {
        gestureHandling: 'greedy'
    },
    center: { lat: 31.9470166, lng: 34.9081175 }
};


// export default Map;
export default React.memo(Map);
