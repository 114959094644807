import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
// Utils
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { periodIds } from 'pages/safety_page/constants';
// Redux
import actions, { fetchSafetyDriversKpi } from 'store/actions/safetyDrivers';
import { filtersSelectors, getFetchingState, getKpis, getSafetyDriversPaging,
    getSearchValueState, getSafetyPeriodState } from 'store/selectors/safetyDrivers';
import { getSafetyBreadCrumbs } from 'store/selectors/navigation';
// Components
import { Collapsible, SimpleKpis, InfoBar, SearchWithAutocomplete, PageTopBar } from 'components';
import { DriverTable, DriversFilter } from 'pages/safety_page/features';
import { FilterInfo } from 'components/filters';
import './style.scss';

export const DriversScreen = (props) => {

    const dispatch = useDispatch();

    const fetching = useSelector(getFetchingState);
    const kpis = useSelector(getKpis);
    const filterInfo = useSelector(filtersSelectors.filterInfo);
    const paging = useSelector(getSafetyDriversPaging);
    const search = useSelector(getSearchValueState);

    useEffect(() => () => {
        dispatch(actions.clearState());
    }, []);

    const onPeriodChange = useCallback((period) => {
        dispatch(actions.updateParams(period.rangeMs));
        dispatch(fetchSafetyDriversKpi(period.rangeMs));
    }, []);

    const onClickSort = useCallback((header) => {
        const { sortKey, sort } = header;
        const isDesc = sort.dataKey === sortKey ? !sort.isDesc : sort.isDesc;
        dispatch(actions.updateParams({ isDesc, sort: sortKey }));
    }, []);

    const onLoadMore = useCallback(() => {
        if (fetching.isPaging || !paging.canPage) return;
        if (paging.nextPage === paging.pageNumber) return;
        dispatch(actions.nextPaging());
    }, [fetching.isPaging, paging]);

    const onClickClearAllFilters = useCallback(() => {
        dispatch(actions.clearFilters());
    }, []);

    const onSearch = useCallback((params, value) => {
        batch(() => {
            dispatch(actions.setSearchParams(params));
            // Better to receive back the search value from the server for syncing and highlighting the results.
            // Currently it will highlight immediately on the existing data
            dispatch(actions.setSearchValue(value));
        });
    }, []);

    const onClearSearch = useCallback(() => {
        dispatch(actions.setSearchParams(null));
        dispatch(actions.setSearchValue(''));
    }, []);

    const breadCrumbs = useSelector(getSafetyBreadCrumbs);
    const curPeriod = useSelector(getSafetyPeriodState);

    return (
        <div
            className='incident-list-screen'
            { ...setAttr.screen(testBy.SCREEN.SAFETY_DRIVERS) }
        >
            <PageTopBar
                breadCrumbs={breadCrumbs}
                showPeriodFilter
                onPeriodChange={onPeriodChange}
                isLoading={fetching.isLoading}
                curPeriod={curPeriod}
                excludedPeriodIds={[ periodIds.TODAY ]}
                setPeriod = {actions.setPeriod}
            />
            <section>
                <Collapsible>
                    <div className='section-top'>
                        <SimpleKpis kpis={kpis} />
                    </div>
                </Collapsible>
                <div className='section-bottom'>
                    <InfoBar>
                        <div className="info-group">
                            <SearchWithAutocomplete
                                featureAttrValue={testBy.FEATURE.FILTER_SEARCH}
                                onSearch={onSearch}
                                onClear={onClearSearch}
                                typeSearchDisable
                                idSearchDisable
                                search={search}
                            />
                            <FilterInfo
                                name={t('lblDrivers')}
                                onClickClearAll={onClickClearAllFilters}
                                filterInfo={filterInfo}
                            />
                        </div>
                        <DriversFilter />
                    </InfoBar>
                    <DriverTable
                        onClickSort={onClickSort}
                        onLoadMore={onLoadMore}
                        isLoading={fetching.isPaging}
                        markText={search}
                    />
                </div>
            </section>
        </div>
    );
};
