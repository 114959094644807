import React, { useMemo, useCallback, useState, useRef } from 'react';
// Utils
import { t } from 'utils/dictionary';
import { setAttr, testBy } from 'utils/constants';
// Components
import { Popover, Overlay } from 'react-bootstrap';
import './style.scss';


const { UI, ACTION } = testBy;



export const HoverTooltip = (props) => {

    const ref = useRef();
    const { children, tooltip, className, enableHover, overlayProps } = props;

    const [hover, setHover] = useState(false);
    const [hoverTooltip, setHoverTooltip] = useState(false);

    const onMouseEnter = useCallback(() => {
        setHover(true);
    }, []);

    const onMouseLeave = useCallback(() => {
        setHover(false);
    }, []);

    const onMouseEnterTooltip = useCallback(() => {
        setHoverTooltip(true);
    }, []);

    const onMouseLeaveTooltip = useCallback(() => {
        setHoverTooltip(false);
    }, []);

    const renderPopover = useCallback((props) => (
        <Popover
            id="hoverTooltip"
            className='hover-tooltip'
            onMouseEnter={onMouseEnterTooltip}
            onMouseLeave={onMouseLeaveTooltip}
            {...setAttr.ui(UI.TOOLTIP)}
            {...props}
        >
            <Popover.Body>
                {tooltip}
            </Popover.Body>
        </Popover>
    ), [tooltip, onMouseEnterTooltip, onMouseLeaveTooltip]);

    if (!children) return null;

    const show = hover || (enableHover && hoverTooltip);

    return (
        <>
            <div
                className={`tooltip-target ${className ?? ''}`}
                ref={ref}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                {...setAttr.action(ACTION.TOOLTIP_TRIGGER)}
            >
                {children}
            </div>
            <Overlay
                placement="right"
                show={show}
                target={ref.current}
                rootClose
                {...overlayProps}
            >
                {renderPopover}
            </Overlay>
        </>
    );
};


export const TooltipItems = (props) => {

    const { items = [], title } = props;

    const navItems = useMemo(() => items.map((item) => {
        const { id, lblKey, row } = item;
        return (
            <div key={id} className='tooltip-row'>
                {row?.(item) ?? <span>{t(lblKey)}</span>}
            </div>
        );
    }), [items]);

    return (
        <div className='tooltip-window'>
            <span className='tooltip-title'>{title}</span>
            {navItems}
        </div>
    );
};
