import { createSelector } from 'reselect';
import { reducerNames } from 'store/constants';

export const getDriversState = (state) => state[reducerNames.DRIVERS].drivers;
export const getDriverDetailsState = (state) => state[reducerNames.DRIVERS].driverDetails;
export const getDriverScoreState = (state) => state[reducerNames.DRIVERS].driverScore;
export const getDriverMileageState = (state) => state[reducerNames.DRIVERS].driverMileage;

export const getDriverKpis = createSelector(
    [getDriverMileageState],
    (data) => {
        const tripsKpis = [
            { id: 1, lblName: 'lblSafetyScore', value: data.score },
            { id: 2, lblName: 'lblDispatchDriven', value: data.distanceTraveled },
            { id: 3, lblName: 'lblCollisions', value: data.collisions },
            { id: 4, lblName: 'lblIncidentsDivPerMeasure', value: data.incidentsPerKM },
        ];
        return  tripsKpis;
    });
