import { useEffect, useState } from 'react';
import { useSpeedUnit } from 'hooks';
import { getAccidentDetails, getAccidentTimeLineData, getAccidentWeather } from 'store/actions/safety';
import { t } from 'utils/dictionary';
import { severities } from 'utils/entities';
import { formatDateMillis, intlFormats } from 'utils/timezone';

const useCollisionDetails = (incidentId, licensePlate) => {
    // const incidentId = 651932;

    const [ accidentId, setAccidentId ] = useState(0);
    const [ address, setAddress ] = useState('');
    const [ airBag, setAirBag ] = useState('');
    const [ chartData, setChartData ] = useState({});
    const [ points, setPoints ] = useState({});
    const [ position, setPosition ] = useState({});
    const [ summaryTxt, setSummaryTxt ] = useState('');
    const [ impactOrientation, setImpactOrientation ] = useState('init');
    const [ weather, setWeather] = useState({});

    const mSpeed = useSpeedUnit();

    useEffect(() => {
        if (incidentId) {
            const promises = [
                getAccidentDetails(incidentId),
                getAccidentTimeLineData(incidentId),
                getAccidentWeather(incidentId),
            ];
            Promise.all(promises).then((responses) => {
                const details = responses[0]?.data ?? {};
                const timeLine = responses[1]?.data ?? [];
                const weather = responses[2]?.data ?? {};

                if (Array.isArray(timeLine)) {
                    let pointsAfter = [];
                    let pointsBefore = [];
                    timeLine.forEach((obj) => {
                        if (obj.date > details.date) {
                            pointsAfter.push({ lat: obj.lat, lng: obj.lng });
                        }
                        if (obj.date < details.date) {
                            pointsBefore.push({ lat: obj.lat, lng: obj.lng });
                        }
                    });
                    setPoints({
                        after: pointsAfter,
                        before: pointsBefore
                    });

                    setChartData({
                        data: timeLine,
                        incidentTime: details.date ?? 0
                    });
                }

                if (weather && typeof weather === 'object') {
                    setWeather(weather);
                }

                const accidentId = details.accidentSK ?? 0;
                if (accidentId > 0) {
                    setAccidentId(accidentId);

                    const address = details.address ?? 'N/A';
                    setAddress(address);

                    setAirBag(details.airBag ?? 'N/A');

                    setPosition({ lat: details.lat, lng: details.lng });

                    const impactOrientation = details.impactOrientation?.toLowerCase() ?? 'init';
                    setImpactOrientation(impactOrientation);

                    const severity = t(severities[details.accidentSeverity]?.lblKey ?? 'N/A');
                    const dttm = formatDateMillis(details.date, intlFormats.DATETIME_SHORT);
                    let tParams = { license: licensePlate, severity, dttm };
                    let variant = 0;
                    if (impactOrientation.length > 0) {
                        tParams.orientation = impactOrientation;
                        variant += 1;
                    }
                    if (details.maxG !== undefined) {
                        tParams.force = details.maxG;
                        variant += 2;
                    }
                    if ((details.duration ?? 0) > 0) {
                        tParams.duration = details.duration;
                        variant +=4;
                    }

                    let firstSentence = '';
                    if (variant === 0) {
                        firstSentence = t('lblCollisionDetails', tParams);
                    } else if (variant === 1) {
                        firstSentence = t('lblCollisionDetails_pO', tParams);
                    } else if (variant === 2) {
                        firstSentence = t('lblCollisionDetails_pF', tParams);
                    } else if (variant === 3) {
                        firstSentence = t('lblCollisionDetails_pO_pF', tParams);
                    } else if (variant === 4) {
                        firstSentence = t('lblCollisionDetails_pD', tParams);
                    } else if (variant === 5) {
                        firstSentence = t('lblCollisionDetails_pO_pD', tParams);
                    } else if (variant === 6) {
                        firstSentence = t('lblCollisionDetails_pF_pD', tParams);
                    } else if (variant === 7) {
                        firstSentence = t('lblCollisionDetails_pAll', tParams);
                    }
                    const formattedSpeed = (details.vehicleState === 1) ? mSpeed.f(details.speedBefore ?? 0) : '';
                    const secondSentence = (details.vehicleState === 1) ?
                        (details.address ?
                            t('lblCollisionDetailsDrivingWithAddress', { address: details.address, speed: formattedSpeed }) :
                            t('lblCollisionDetailsDriving', { speed: formattedSpeed })
                        ) : (details.address ?
                            t('lblCollisionDetailsParkingWithAddress', { address: details.address }) :
                            t('lblCollisionDetailsParking')
                        );

                    const thirdSentence = (details.accidentSeverity > 0 && details.rollover > 0) ?
                        t('lblCollisionDetailsRolloverLikely') :
                        t('lblCollisionDetailsRolloverUnLikely');

                    setSummaryTxt(`${firstSentence}. ${secondSentence}. ${thirdSentence}.`);
                }
            });
        }
    }, [incidentId, licensePlate, mSpeed]);

    return { accidentId, address, airBag, chartData, points, position, summaryTxt, impactOrientation, weather };
};

export default useCollisionDetails;
