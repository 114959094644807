import { useCallback, useState } from 'react';
// Utils
import { downloadBlobs, zipFiles, saveFiles } from 'utils/helpers/fileHelper';
import { t } from 'utils/dictionary';
// Components
import { Button } from 'components/common';


const FilesDownload = (props) => {
    const { } = props;
    const [file, setFiles] = useState({});

    const onClick = useCallback(async () => {
        try {
            const mockFilesTemp = [
                { type: 'jpeg', name: 'image1', url: 'https://www.wilmarinc.com/hubfs/fleet%20meaning-1.jpeg' },
                { type: 'jpeg', name: 'image2', url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStqQhs5U22nTg9qSV1FpBdJt7K_6w20sm68Q&usqp=CAU' },
                { type: 'mp4', name: 'video1', url: 'https://media.istockphoto.com/id/1448603708/video/assignment-target-clipboard-checklist-project-task-management-and-effective-time-animation.mp4?s=mp4-640x640-is&k=20&c=93Qb_vH11E2DTJZ6q16lKE2TL1C9DDGsMq-hjdJKrE8=' },
            ];
            const urls = mockFilesTemp.map((f) => f.url);
            const urlsContent = await downloadBlobs(urls);
            mockFilesTemp.forEach((fTemp) => {
                fTemp.content = urlsContent.find((c) => c.url === fTemp.url)?.content;
            });
            const zipContent = await zipFiles(mockFilesTemp.filter((fTemp) => fTemp.content));
            saveFiles({ content: zipContent, name: 'testFiles.zip' });
        } catch (error) {
            console.error(error);
        }

    }, []);

    const onChange = useCallback((e) => {
        const { name, value } = e.target;
        setFiles((prev) => ({ ...prev, name, url: value }));
    }, []);

    return (
        <div>
            FilesDownload
            <div>
                <input
                    name='file1'
                    onChange={onChange}
                />
            </div>
            <Button
                label={t('lblDownload')}
                onClick={onClick}
            />
        </div>
    );
};

export default FilesDownload;
