import { Suspense, useEffect, useCallback } from 'react';
import { Provider } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Utils
import { setConfig } from 'configuration';
import { configureAppStore } from 'store/appStore';
import { api } from 'services';
// Components
import { BrowserRouter } from 'react-router-dom';
import AppLayout from 'layout';
import AppInitialLoads from 'app_root/AppInitialLoad';
import ModalManager from 'components/modal/ModalManager';
import { TimeZoneProvider } from 'components/providers';
import { ErrorBoundary } from 'components/errors';


setConfig(process.env.NODE_ENV);
api.init({ baseUrl: global?.config?.SERVER_API_URL ?? '' });
const store = configureAppStore();


function AppRoot() {

    const { i18n } = useTranslation();

    const onLanguageChanged = useCallback((langName) => {
        sessionStorage.setItem('langName', langName);
    }, []);

    useEffect(() => {
        i18n.on('languageChanged', onLanguageChanged);
        return () => {
            i18n.off('languageChanged', onLanguageChanged);
        };
    }, [onLanguageChanged, i18n]);

    return (
        <ErrorBoundary boundary='App root' root>
            <Suspense>
                <Provider store={store}>
                    <BrowserRouter basename={global.config.BASE_ROUTE}>
                        <AppInitialLoads config={global.config} />
                        <TimeZoneProvider>
                            <AppLayout />
                            <ModalManager />
                        </TimeZoneProvider>
                    </BrowserRouter>
                </Provider>
            </Suspense>
        </ErrorBoundary>
    );
}

export default AppRoot;
