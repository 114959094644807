import React, { useCallback } from 'react';
// Utils
import { t } from 'utils/dictionary';
import { setAttr, testBy } from 'utils/constants';
// Components
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FilterPopover, FilterTrail } from 'components/filters';
import { ToggleSwitch } from 'components/common';
import './style.scss';


const FilterTrigger = React.forwardRef((props, ref) => {

    const { renderPopover, placement = 'bottom' } = props;

    return (
        <OverlayTrigger
            trigger={['click']}
            rootClose
            placement={placement}
            overlay={renderPopover()}
        >
            <div
                {...setAttr.action(testBy.ACTION.TOGGLE_FILTER_PANEL)}
                className="filter-trigger"
                ref={ref}
            >
                <span className="icon filter" />
                {t('lblFilter')}
            </div>
        </OverlayTrigger>
    );
});


const FilterWidget = (props) => {

    const { clearFilters, removeFilter, filterEnabled, filterTrailData, enableDisableFilters, placement, ...filterProps } = props;

    const triggerRef = React.createRef();

    const handleClick = useCallback((e) => {
        enableDisableFilters(!filterEnabled);
    }, [enableDisableFilters, filterEnabled]);

    const renderPopover = useCallback((popoverProps) => (
        <Popover id={`popover-filter`} {...popoverProps}>
            <Popover.Body>
                <FilterPopover
                    triggerRef={triggerRef}
                    {...filterProps}
                />
            </Popover.Body>
        </Popover>
    ), [filterProps, triggerRef]);

    const hasFilters = Array.isArray(filterTrailData) && filterTrailData.length > 0;

    return (
        <div
            className='filter-container'
            { ...setAttr.feature(testBy.FEATURE.FILTER_WIDGET) }
        >
            <FilterTrail data={filterTrailData} clearFilters={clearFilters} removeFilter={removeFilter} disabled={!filterEnabled} />
            <FilterTrigger renderPopover={renderPopover} ref={triggerRef} placement={placement} />
            <ToggleSwitch
                handleClick={handleClick}
                testAttributes={setAttr.action(testBy.ACTION.TOGGLE_FILTER, !hasFilters)}
                tooltip={filterEnabled ? t('lblFilterToggleOn') : t('lblFilterToggleOff')}
                value={filterEnabled}
                useDefault
            />
        </div>
    );
};


export default FilterWidget;
