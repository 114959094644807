import { createSelector } from 'reselect';
// Utils
import { pf_t, t } from 'utils/dictionary';
import { reducerNames } from 'store/constants';
import { eventTypes, filterKeys, getCategoryIcon } from 'utils/entities';
import { isValidCoordinate } from 'utils/helper';
import { formatDateMillis, getDiffBetweenDates } from 'utils/timezone';
//
import { getFiltersSelectors, setFiltersFromState } from 'store/common/filters';
import { getDriversState } from 'store/selectors/drivers';
import { getResourcesState } from 'store/selectors/resources';
import { getIncidentTypes } from 'store/selectors/incidentTypes';

const { COLLISION, THEFT, VIOLATION } = eventTypes.categoryIds;


// --- STATES ---
export const getSafetyPeriodState = (state) => state[reducerNames.SAFETY].period;
const getDashboardKpisState = (state) => state[reducerNames.SAFETY].dashboard.kpis;
const getIncidentsCountPerRangeState = (state) => state[reducerNames.SAFETY].dashboard.countPerRange;
const getIncidentsCountPerTypeState = (state) => state[reducerNames.SAFETY].countPerType;
export const getFetchingState = (state) => state[reducerNames.SAFETY].fetching;
const getFiltersManagerState = (state) => state[reducerNames.SAFETY].filtersManager;
export const getIncidentLocationsState = (state) => state[reducerNames.SAFETY].incidentsLocations;
const getIncidentsDataState = (state) => state[reducerNames.SAFETY].incidentsData;
export const getPagingState = (state) => state[reducerNames.SAFETY].paging;
export const getParamsState = (state) => state[reducerNames.SAFETY].params;
const getSearchParamsState = (state) => state[reducerNames.SAFETY].searchParams;
const getSeverityKpisState = (state) => state[reducerNames.SAFETY].severityKpisData;
export const getVideoModalData = (state) => state[reducerNames.SAFETY].videoModalData;
export const getSearchValueState = (state) => state[reducerNames.SAFETY].searchValue;

// --- SELECTORS ---

// Returning weighted incidents coordinates with additional property for indicate valid (coordinates)
export const getIncidentsLocations = createSelector(
    [getIncidentLocationsState],
    (incidentsLocations) => incidentsLocations.locations.map((location) => {
        const { lat, lng } = location;
        return { ...location, isValid: isValidCoordinate({ lat, lng }) };
    })
);

export const getIncidentsKpis = createSelector(
    [getDashboardKpisState],
    (kpisData) => {
        const { data, start, end } = kpisData;
        const categoryIds = [COLLISION, THEFT, VIOLATION];

        const tempKpi = { value: 0, trend: undefined };
        const summaryKpi = {
            value: 0,
            name: t('lblIncidentsPerDay')
        };

        // No data yet, just returning defaults kpis cards
        if (!data.length) {
            const placeholder = categoryIds.map((id) => ({
                ...tempKpi,
                id,
                name: pf_t.categoryName(id)
            }));
            placeholder.push(summaryKpi);
            return placeholder;
        }

        const categoryKpis = data.filter((d) => d.categoryId);
        const summary = data.find((d) => !d.categoryId);

        const total = summary?.count ?? categoryKpis.reduce((sum, kpi) => sum + kpi.count, 0);
        const kpis = categoryKpis.map(({ count, categoryId, trend }) => ({
            ...tempKpi,
            icon: getCategoryIcon(categoryId),
            value: count,
            name: pf_t.categoryName(categoryId),
            trend
        }));

        // Rounding up (the days count in avg per days) for selection with startOf like (today, this week, this moth, etc..)
        // Rounding down (the days count in avg per days) for selection with last fixed range like (last 7 day, last 30 days, etc...)
        const days = Math.abs(getDiffBetweenDates(start, end).days);
        const daysRound = Math.ceil(days);
        // Calc the avg with the real value of days (not the rounded value)
        const avg = (total / daysRound) || 0;
        const toFix = (avg % 1) > 0;
        const value = toFix ? avg?.toFixed(1) : avg;
        summaryKpi.trend = summary?.trend;
        summaryKpi.value = value;
        summaryKpi.name = `${total}/${daysRound} ${t('lblIncidentsPerDay')}`;

        return [...kpis, summaryKpi];
    }
);

export const getDashboardChartData = createSelector(
    [getIncidentsCountPerRangeState],
    (countPerRange) => {
        const { items, ranges, timeResolution } = countPerRange;
        const format = timeResolution === 'hours' ? { hour: 'numeric' } : { month: 'numeric', day: 'numeric' };
        const data = [];
        const dataKeys = {
            [VIOLATION]: 'Violations',
            [COLLISION]: 'Accidents',
            [THEFT]: 'Accidents',
        };
        const bars = [
            { dataKey: dataKeys[VIOLATION], categories: [VIOLATION], color: '#95E0E6', stackId: 'a', name: t('lblViolations') },
            { dataKey: dataKeys[COLLISION], categories: [COLLISION, THEFT], color: '#18715E', stackId: 'a', name: t('lblCollisionsThefts') },
        ];

        ranges.forEach(({ start }) => {
            const item = items.find((item) => item.start === start);
            let dateStr = formatDateMillis(start, format);
            const chartItem = { date: start, dateStr };

            Object.entries(dataKeys).forEach(([catId, dataKey]) => {
                const count = item?.categories?.find((c) => c.categoryId === Number(catId))?.count ?? 0;
                chartItem[dataKey] = (chartItem[dataKey] ?? 0) + count;
            });
            data.push(chartItem);
        });

        // Slider position start from left to right
        // But we need to position the slider from the end (desc order by date)
        const endIndex = Math.max(data.length - 1, 0);
        const startIndex = Math.max(endIndex - 13, 0);
        const sliderRange = { startIndex, endIndex };
        return { data, bars, sliderRange };
    }
);

// Filters
const getIncidentsFilters = createSelector(
    [getFiltersManagerState, getDriversState, getResourcesState, getIncidentTypes],
    setFiltersFromState
);

export const getSearchData = createSelector(
    [getDriversState, getResourcesState, getIncidentTypes],
    (drivers, resources, types) => ({ drivers, resources, types }));


export const filtersSelectors = getFiltersSelectors(getFiltersManagerState, getIncidentsFilters, getPagingState, getSearchValueState);

export const getFilterTypeIds = createSelector(
    [filtersSelectors.filtersManager],
    (fm) => {
        const filterTypes = fm.filters.find((f) => f.name === filterKeys.TYPES);
        return Object.entries(filterTypes.appliedSelections).map((([key, group]) => {
            const { selections } = group;
            const items = Object.keys(selections).map((item) => Number(item.replace(`${filterKeys.TYPES}_`, '')));
            return {
                id: Number(key.replace(`${filterKeys.TYPES}_group_`, '')),
                items
            };

        }));
    }
);

export const getIncidentsChartDataByType = createSelector(
    [getIncidentsCountPerTypeState, getIncidentTypes, getFilterTypeIds],
    (countPerType, incidentsCategories, filterTypes) => {
        const filtered = [];
        filterTypes.forEach((f) => { filtered.push(...f.items); });

        const bars = [
            { dataKey: 'count', color: '#95E0E6', name: t('lblCount') },
        ];

        const countByTypes = [...countPerType];
        countByTypes.sort(function (a, b) {
            return a.id - b.id;
        });

        const allTypes = incidentsCategories.reduce((all, cat) => [...all, ...cat.items], []);
        allTypes.sort((a, b) => a.id - b.id); // should sort by name?

        const data = allTypes.map(({ id }) => {
            const countType = countByTypes.find((ct) => ct.id === id);
            return { id, name: pf_t.eventName(id), count: countType?.count ?? 0 };
        });

        const startIndex = 0;
        const endIndex = Math.min(data.length - 1, 15);
        const sliderRange = { startIndex, endIndex };
        return { data, bars, sliderRange, filtered };
    }
);

export const getSeverityKpis = createSelector(
    [getSeverityKpisState],
    (data) => {
        const kpisArray = [];
        const categoryIds = [COLLISION, THEFT, VIOLATION];

        for (const id of categoryIds) {
            const categoryItems = data?.filter((item) => item.categoryId === id);
            const totalSeveritiesPerCategory = categoryItems.reduce((total, item) => total + item.incidentCount, 0);
            const severitiesArray = categoryItems.map((item) => ({ count: item.incidentCount, level: item.severityLevel }));
            const kpisObj = {
                value: totalSeveritiesPerCategory,
                name: pf_t.categoryName(id),
                severities: severitiesArray,
                trend: categoryItems[0]?.trend,
                id
            };
            kpisArray.push(kpisObj);
        }
        return kpisArray;
    }
);

export const getParams = createSelector(
    [getSafetyPeriodState, getParamsState],
    (period, params) => ({ ...params, period })
);

export const getSearchParams = createSelector([getSearchParamsState], (params) => params);

export const getSortOption = createSelector(
    [getParamsState],
    (params) => {
        const { sort, isDesc } = params;
        return { dataKey: sort, isDesc };
    }
);

export const getIncidentsItems = createSelector(
    [getIncidentsDataState, getSearchValueState],
    (items, search) => [...items]
);

export const getIncidentsPaging = createSelector(
    [getPagingState, getIncidentsDataState],
    (paging, items) => {
        const { maxCount, filteredRows, totalRows, pageNumber } = paging;
        const itemsCount = items.length;
        const nextPage = Math.ceil(itemsCount / maxCount) + 1;
        return {
            ...paging,
            itemsCount,
            canPage: itemsCount < filteredRows,
            nextPage
        };
    }
);
