import React, { useCallback } from 'react';
// Utils
import { setAttr, testBy } from 'utils/constants';
// Components
import { Text } from 'components/common';
import { filterUtils } from 'components/filters/filter_schema';
import './style.scss';

const FilterTab = (props) => {
    const { name, label, selected, hasChanges, hasSelections, onClick } = props;

    const handleClick = useCallback((e) => {
        onClick?.({ name, label, selected }, e);
    }, [onClick, name, label, selected]);

    const renderMarker = useCallback(() => {
        if (!hasChanges && !hasSelections) {
            return null;
        }
        const markerClasses = 'selectionMarker' + ((hasSelections && !hasChanges) ? ' applied' : '');
        return (<span className={markerClasses}></span>);
    }, [hasChanges, hasSelections]);

    return (
        <div
            {...setAttr.ui(testBy.UI.TAB)}
            key={name}
            onClick={handleClick}
            className={`tab ${selected ? 'selected' : ''}`}
        >
            <Text text={label} upperCase />
            {renderMarker()}
        </div>
    );
};

const FilterTabs = (props) => {
    const { filters, selectedTab, onClick } = props;

    const handleClick = useCallback((tab, e) => {
        onClick?.(filters, tab, e);
    }, [onClick, filters]);

    const renderFilterTabs = () => filters.map((filter) => (
        <FilterTab
            key={filter.name}
            onClick={handleClick}
            selected={selectedTab === filter.name}
            hasChanges={filterUtils.hasPendingChanges(filter)}
            hasSelections={filterUtils.hasAppliedChanges(filter)}
            {...filter}
        />
    ));

    return (
        <div className='filter-tabs-container'>
            {renderFilterTabs()}
        </div>
    );
};

export default FilterTabs;
