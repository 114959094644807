import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// Utils
import PropTypes from 'utils/propTypes';
// Components
import { BreadCrumbs } from 'components';
import { PeriodFilter } from 'pages/safety_page/features';
import { Spinner } from 'components/common';
import './style.scss';


const PageTopBar = (props) => {

    const { children, onPeriodChange, showPeriodFilter, isLoading, breadCrumbs,
        curPeriod, excludedPeriodIds, setPeriod } = props;

    const location = useLocation();
    const links = breadCrumbs[location.pathname] ?? [];
    const dispatch = useDispatch();

    const onSelectPeriod = useCallback((period) => {
        dispatch(setPeriod(period));
        onPeriodChange?.(period);
    }, [dispatch, onPeriodChange, setPeriod]);

    return (
        <div className="page-top-bar">
            <div className="breadcrumbs-wrapper">
                <BreadCrumbs links={links} />
                <Spinner isLoading={isLoading} />
            </div>
            <div className="top-bar-right">
                {children}
                {
                    showPeriodFilter ? (
                        <PeriodFilter
                            onSelectPeriod={onSelectPeriod}
                            selected={curPeriod}
                            excludedPeriodIds={excludedPeriodIds}
                        />
                    ) : null
                }
            </div>
        </div>
    );
};

PageTopBar.propTypes = {
    children: PropTypes.node,
    onPeriodChange: PropTypes.func,
    showPeriodFilter: PropTypes.bool,
    isLoading: PropTypes.bool,
    breadCrumbs: PropTypes.shape({}),
    curPeriod: PropTypes.shape({}),
    excludedPeriodIds: PropTypes.arrayOf(PropTypes.string),
    setPeriod: PropTypes.func
};

export default PageTopBar;
