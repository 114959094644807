import React from 'react';
// Components
import { PageContainer } from 'layout/containers';
import { Text } from 'components/common';
import './style.scss';



export class PageExample extends React.PureComponent {
    render() {

        return (
            <PageContainer>
                <div className='page-example'>
                    <Text text='Page example' />
                </div>
            </PageContainer>
        );
    }
}
