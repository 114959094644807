import { iconsName } from 'assets';



export const ROUTES = {
    HOME: {
        id: 1,
        path: '',
        lblKey: 'lblHomePage',
    },
    SAFETY: {
        id: 2,
        path: '/safety',
        icon: { name: iconsName.GRAPH_DESKTOP },
        lblKey: 'lblSafetySecurity', // t('lblSafetySecurity')
        subIds: [3, 4, 5]
    },
    SAFETY_DASHBOARD: {
        id: 3,
        path: '/safety/dashboard',
        icon: { name: iconsName.GRAPH_DESKTOP },
        lblKey: 'lblIncidentDashboard', // t('lblIncidentDashboard')
        subIds: [4, 5]
    },
    SAFETY_INCIDENTS: {
        id: 4,
        path: '/safety/incidents',
        icon: { name: iconsName.LIST },
        lblKey: 'lblIncidentList', // t('lblIncidentList')
    },
    SAFETY_DRIVERS: {
        id: 5,
        path: '/safety/drivers',
        icon: { name: iconsName.STEERING_WHEEL },
        lblKey: 'lblDriversList', // t('lblDriversList')
    },
    SETTINGS: {
        id: 6,
        path: '/settings',
        icon: { name: iconsName.SETTINGS },
        lblKey: 'lblSettings', // t('lblSettings')
    },
    SUSTAINABILITY: {
        id: 7,
        path: '/sustainability',
        icon: { name: iconsName.ECOLOGY_GLOBE_LEAF, stroke: '#fff' },
        lblKey: 'lblSustainability', // t('lblSustainability')
        subIds: [8]
    },
    SUSTAINABILITY_SUSTAINABILITY_HOME: {
        id: 8,
        path: '/sustainability/home',
        icon: { name: iconsName.ECOLOGY_GLOBE_LEAF, stroke: '#fff' },
        lblKey: 'lblHome',//t('lblHome')
    },
    PROVISIONING: {
        id: 9,
        path: '/multiple_provisioning',
    },
    INSTALLER: {
        id: 10,
        path: '/installer',
    },

    // -------- DEV -------
    PLAYGROUND: {
        id: 1000,
        path: '/playground',
        icon: { name: iconsName.CODE_BLOCK },
        lblKey: 'Playground',
        subIds: [1001]
    },
    ICONS: {
        id: 1001,
        path: '/playground/icons',
        icon: { name: iconsName.ADD_IMAGE },
        lblKey: 'Icons',
    },
};

export const URLParams = {
    HIDE_VIEWS: 'hideviews',
    LANG_ID: 'langid',
    TOKEN: 'tk',
    getParamList: (val) => val?.split?.(',').map((val) => val.trim())
};

export const errorCode = {
    PAGE_NOT_FOUND: 1,
    UNAUTHORIZED: 2,
    FATAL_UI: 3,
    // EXPIRED_TOKEN: 3,
    // ACTIVITY_IDLING: 4,
};
