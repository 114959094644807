import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// Utils
import { testBy, ROUTES } from 'utils/constants';
// Redux
import { fetchDrivers } from 'store/actions/drivers';
import { fetchResources } from 'store/actions/resources';
import { fetchIncidentTypes } from 'store/actions/incidentTypes';
// Components
import { useLocation, Navigate } from 'react-router-dom';
import { PageContainer } from 'layout/containers';
import { IncidentDashboard, IncidentsScreen, DriversScreen } from 'pages/safety_page/features';
import { ErrorBoundary } from 'components/errors';

export const SafetyPage = (props) => {

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(fetchDrivers());
        dispatch(fetchResources());
        dispatch(fetchIncidentTypes());
    }, [dispatch]);

    const getScreen = (name, props) => {
        switch (name) {
            // Temperley redirecting to safety/dashboard from safety landing page
            // case ROUTES.SAFETY.path:
            //     return <LandingScreen location={location} {...props} />;
            case ROUTES.SAFETY_DASHBOARD.path:
                return <IncidentDashboard location={location} {...props} />;
            case ROUTES.SAFETY_INCIDENTS.path:
                return <IncidentsScreen location={location} {...props} />;
            case ROUTES.SAFETY_DRIVERS.path:
                return <DriversScreen location={location} {...props} />;
            default:
                return <Navigate replace to={`${ROUTES.SAFETY.path}/dashboard`} />;
        }
    };

    return (
        <PageContainer
            name={testBy.PAGE.SAFETY}
        >
            <ErrorBoundary boundary='Safety page'>
                {getScreen(location.pathname, {})}
            </ErrorBoundary>
        </PageContainer>
    );
};
