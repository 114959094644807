import React, { useCallback, useMemo } from 'react';
import DropdownBootstrap from 'react-bootstrap/Dropdown';
import { classAttribute } from 'utils/helper';
import './style.scss';


const Item = ({ children, onClick, active, testAttributes, ...rest }) => {

    const handleClick = useCallback((e) => {
        onClick?.(rest, e);
    }, [onClick, rest]);

    return (
        <DropdownBootstrap.Item
            onClick={handleClick}
            active={active}
            {...testAttributes}
        >
            {children}
        </DropdownBootstrap.Item>
    );
};

const Divider = DropdownBootstrap.Divider;


const EmptyMenu = ({ className, renderButton, testAttributes }) =>
    <DropdownBootstrap
        className={className}
    >
        <DropdownBootstrap.Toggle
            size="sm"
            {...testAttributes}
        >
            {renderButton?.()}
        </DropdownBootstrap.Toggle>
    </DropdownBootstrap>;

const Box = ({ buttonTestAttributes, children, classes = [], hideMenu, renderButton }) => {

    const dropdownClasses = useMemo(() => classAttribute([
        'basic-dropdown',
    ].concat(classes)), [classes]);

    return hideMenu ?
        <EmptyMenu
            className={dropdownClasses}
            renderButton={renderButton}
            testAttributes={buttonTestAttributes}
        /> :
        <DropdownBootstrap
            className={dropdownClasses}
        >
            <DropdownBootstrap.Toggle
                size="sm"
                {...buttonTestAttributes}
            >
                {renderButton?.()}
            </DropdownBootstrap.Toggle>
            <DropdownBootstrap.Menu>
                {children}
            </DropdownBootstrap.Menu>
        </DropdownBootstrap>;
};

const Dropdown = {
    Box,
    Item,
    Divider,
};

export default Dropdown;
