import React, { useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Utils
import { t } from 'utils/dictionary';
import { formatDateMillis } from 'utils/timezone';
import { iconsName } from 'assets';
import { modalsIds } from 'components/modal/ModalsConstants';
import { api } from 'services';
import { setAttr, testBy } from 'utils/constants';
// Store
import { closeModal } from 'store/actions/popup';
import { getUserInfo } from 'store/selectors/user';
//Components
import { BaseModal, Icon } from 'components/common';
import { ModalContainer } from 'components/modal/components';
import ImageGallery from 'react-image-gallery';
import { AlertDialog } from 'components/common/dialogs/alert_dialog/AlertDialog';
import { ConfirmationDialog } from 'components/common/dialogs/confirmation_dialog/ConfirmationDialog';
import { getEventIcon } from 'utils/entities';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import './style.scss';

const GalleryModal = ({ data }) => {
    const inputRef = useRef();
    const galleryRef = useRef();
    const dispatch = useDispatch();
    const { loginName } = useSelector(getUserInfo);
    const [imgData, setImgData] = useState(data.imgData ?? []);
    const [currentSlide, setCurrentSlide] = useState(data.currentIdx ?? 0);
    const [isLoading, setIsLoading] = useState(null);

    const onImageLoad = useCallback(() => {
        if (isLoading !== false) return;

        const nextIdx = imgData?.length ?? 0;
        galleryRef.current.slideToIndex(nextIdx > 0 ? nextIdx-1 : 0);
    }, [imgData?.length, isLoading]);

    const onClickClose = useCallback(() => {
        dispatch(closeModal(modalsIds.GALLERY_MODAL));
    }, [dispatch]);

    const onSlide = useCallback((idx) => {
        setCurrentSlide(idx);
    }, []);

    const onDeleteClick = useCallback(async () => {
        const result = await ConfirmationDialog(t('lblConfirmDeleteImage'), { title: t('lblConfirmation'), okText: t('lblDelete') });
        if (result) {
            const idForDelete = imgData[currentSlide].id;
            const resp = await api.deleteImage(data.id, idForDelete, loginName);
            if (resp === true) {
                setImgData((d) => d.filter((i) => i.id !== idForDelete));
                setCurrentSlide((n) => n > 0 ? n - 1 : n);
            } else {
                console.log(resp);
                await AlertDialog(t('lblFailedDelete'));
            }
        }
    }, [data, currentSlide, loginName, imgData]);

    const renderCustomControls = useCallback(() => {
        const description = imgData.length ? imgData[currentSlide].descr : '';
        return (
            <>
                <Icon
                    name={iconsName.DELETE}
                    className="image-gallery-delete-button"
                    height={60}
                    width={60}
                    onClick={onDeleteClick}
                    {...setAttr.action(testBy.ACTION.DELETE)}
                />
                <div className="gallery-current-text">{description}</div>
            </>
        );
    }, [imgData, currentSlide, onDeleteClick]);

    const onSelectFileClick = () => {
        inputRef.current.click();
    };

    const headerButtons = [
        {
            name: iconsName.ADD_IMAGE,
            width: 32,
            height: 32,
            onClick: (imgData.length > 49 ? null : onSelectFileClick),
            testAttributes: setAttr.action(testBy.ACTION.ADD, imgData.length > 49),
            title: t('lblAddImage'),
        },
    ];

    const uploadImage = useCallback(async (e) => {
        if(!imgData.length) {
            setIsLoading(true);
        }
        const { files } = e.target;
        if (files && files.length) {
            const description = `${t('lblAddedBy', { userName: loginName })} ${formatDateMillis()}`;
            const resp = await api.uploadImages(files[0], description, data.id, loginName);
            if (Array.isArray(resp) && resp.length > 0) {
                const img = URL.createObjectURL(files[0]);
                setImgData((d) => [...d, { id: resp[0], original: img, thumbnail: img, descr: description }]);
            } else {
                console.log(resp);
                const fileName = files[0].name;
                const message = resp?.code === 1 ? t('lblInvalidFileUpload', { fileName }) : t('lblFailedUpload', { fileName });
                await AlertDialog(message);
            }
        }
        setIsLoading(false);
    }, [data, loginName, imgData]);

    return (
        <BaseModal
            onClickClose={onClickClose}
            headerButtons={headerButtons}
            icon={getEventIcon(data.type)}
            className="gallery-modal"
            headerText={t('lblPossibleCollision')}
            resourceName={data.resourceName}
            testAttributes={setAttr.feature(testBy.FEATURE.MODAL_GALLERY)}
        >
            <ModalContainer>
                {!isLoading && <ImageGallery
                    ref={galleryRef}
                    onImageLoad={onImageLoad}
                    items={imgData}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    onSlide={onSlide}
                    thumbnailPosition="left"
                    renderCustomControls={renderCustomControls}
                    startIndex={currentSlide}
                />}
                <input ref={inputRef} type="file" accept="image/png, image/jpeg" style={{ display: 'none' }} onChange={uploadImage} />
            </ModalContainer>
        </BaseModal>);
};

export default GalleryModal;
