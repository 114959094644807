// import * as types from 'store/actionsTypes';
import { actions } from 'store/slices/themes';
import * as selectors from 'store/selectors/theme';
import { setCssVariables } from 'utils/domHelper';
import { themeToCssVariables } from 'styles/theme';


export default actions;

export const setApplicationTheme = (name) => (dispatch, getState) => {
    const themes = selectors.getCollectionsState(getState());
    const theme = themes[name];
    if (!theme) return;
    setCssVariables(themeToCssVariables(theme));
    dispatch(actions.setTheme(name));
};
