import React from 'react';
// Utils
import { useLengthUnit } from 'hooks';
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
// Components
import { ModalBlock } from 'components/modal/components';
import { KpiCard1 } from 'components';
import './style.scss';

export const TripsKpis = ({ kpis, className }) => {

    const mLength = useLengthUnit();

    const items = kpis.map((item) => {
        if([2,4].includes(item.id)){
            return { ...item, tParams: { systemId: mLength.unit.systemId ?? 1 }, value: mLength.f(item.value) };
        }
        return item;
    });

    return (
        <ModalBlock className={className}>
            {
                items.map(({ value, lblName, id, tParams }) => (
                    <KpiCard1
                        key={id}
                        label={t(lblName, tParams)}
                        value={value}
                        className={id === 1 ? 'score' : ''}
                        testAttributes={setAttr.ui(testBy.UI.KPI_DRIVER_CARD)}
                    />
                ))
            }
        </ModalBlock>
    );
};
