import { createSlice } from '@reduxjs/toolkit';
import { reducerNames } from 'store/constants';
import { dataKeys, filterKeys } from 'utils/entities';
import { getFilterActionMatcher, getFiltersInitialState, getFiltersReducers } from 'store/common/filters';
import { filterSchema, filterType } from 'components/filters/filter_schema';
import { defaultPeriodOption } from 'pages/safety_page/constants';
import { LOGOUT_USER } from 'store/actionTypes';

const filters = filterSchema([
    filterType(filterKeys.RESOURCES),
    filterType(filterKeys.DRIVERS),
    filterType(filterKeys.TYPES),
    filterType(filterKeys.SEVERITIES),
    filterType(filterKeys.STATUSES),
    filterType(filterKeys.MEDIA),
]);

const initialState = {
    period: defaultPeriodOption,
    dashboard: {
        kpis: { data: [], start: undefined, end: undefined },
        countPerRange: {
            items: [],
            ranges: [],
            timeResolution: 'days'
        },
    },
    incidentsLocations: {
        locations: [],
        heatmapPrecision: 2,
    },
    fetching: {},
    ...getFiltersInitialState(filters, dataKeys.resourceName),
    searchParams: null,
    searchValue: '',
    paging: {
        pageNumber: 1,
        maxCount: 100,
        filteredRows: undefined,
        totalRows: undefined
    }, // TODO - get this info from API response
    incidentsData: [],
    videoModalData: {},
    severityKpisData: [],
    countPerType: []
};


const slice = createSlice({
    name: reducerNames.SAFETY,
    initialState,
    reducers: {
        resetState: () => initialState,
        setFetching: (state, action) => {
            state.fetching = { ...state.fetching, ...action.payload };
        },
        setPeriod: (state, action) => {
            const { period } = state;
            state.period = { ...period, ...action.payload };
        },
        // Dashboard screen
        clearDashboardScreen: (state, action) => {
            state.dashboard = initialState.dashboard;
        },
        fetchIncidentsFailure: (state, action) => {
            // Update state for errors
        },
        setIncidentsCount: (state, action) => {
            state.dashboard.kpis = action.payload;
        },
        setCountPerRange: (state, action) => {
            state.dashboard.countPerRange = action.payload;
        },
        // Incidents screen
        clearIncidentsScreen: (state, action) => {
            state.incidentsData = initialState.incidentsData;
            state.severityKpisData = initialState.severityKpisData;
            state.countPerType = initialState.countPerType;
            state.paging = initialState.paging;
        },
        ...getFiltersReducers(initialState),
        fetchIncidentsLocations: (state, action) => {
            state.incidentsLocations.locations = action.payload;
        },
        setItems: (state, action) => {
            state.incidentsData = action.payload;
        },
        addItems: (state, action) => {
            state.incidentsData = state.incidentsData.concat(action.payload);
        },
        updateParams: (state, action) => {
            const { params } = state;
            if (!action.payload) return state;
            state.params = { ...params, ...action.payload };
        },
        setParams: (state, action) => {
            state.params = action.payload;
        },
        setSearchParams: (state, action) => {
            state.searchParams = action.payload;
        },
        setSearchValue: (state, action) => {
            state.searchValue = action.payload;
        },
        nextPaging: (state) => {
            state.paging.pageNumber++;
        },
        setPagingDataInfo: (state, action) => {
            const { filteredRows, totalRows } = action.payload ?? {};
            state.paging.filteredRows = filteredRows;
            state.paging.totalRows = totalRows;
        },
        resetPaging: (state) => {
            state.paging = initialState.paging;
        },
        setVideoModalData: (state, action) => {
            state.videoModalData = action.payload;
        },
        clearVideoModalData: (state, action) => {
            state.videoModalData = {};
        },
        setIncidentsSeverity: (state, action) => {
            const { data } = action.payload;
            state.severityKpisData = data;
        },
        setTypeChartBar: (state, action) => {
            state.countPerType = action.payload;
        },
        setIncidentStatus: (state, action) => {
            const { id, status } = action.payload;
            const { incidentsData } = state;
            const index = incidentsData.findIndex((inc) => inc.id === id);
            const incident = incidentsData[index];

            state.incidentsData[index] = { ...incident, statusId: status };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(LOGOUT_USER, (state, action) => initialState);
    }
});


export default slice.reducer;
export const { name, actions, getInitialState } = slice;
export const filterActionMatcher = getFilterActionMatcher(slice.actions);
