import { useCallback, useMemo } from 'react';
// Utils
import { t } from 'utils/dictionary';
import './style.scss';



const OptionItem = (props) => {
    const { item, onClick, selected } = props;
    const { testAttributes, ...clickData } = item;

    const handleClick = useCallback(() => {
        onClick?.(clickData);
    }, [clickData, onClick]);

    return (
        <div
            key={item.id}
            className={`panel-item ${selected ? 'selected' : ''}`}
            onClick={handleClick}
            { ...testAttributes }
        >
            {t(item.lblKey)}
        </div>
    );
};

export const OptionsList = (props) => {
    const { selectedId, onClick, options = [] } = props;

    const list = useMemo(() => options.map((option) => (
        <OptionItem
            key={option.id}
            item={option}
            selected={selectedId === option.id}
            onClick={onClick}
        />
    )), [selectedId, onClick, options]);

    return (
        <div>
            {list}
        </div>
    );
};
