import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { testBy } from 'utils/constants';

// Components
import { PageContainer } from 'layout/containers';
import { ErrorBoundary } from 'components/errors';
import { WoList } from 'components/wo_list/WoList';
import { WoForm } from 'components/wo_form/WoForm';

// Redux
import { getInstallers, getWos } from 'store/actions/installer';
import { getInstallersState, getRightPaneState, getSelectedWoState, getWorkOrdersState } from 'store/selectors/installer';
import { getAccountsState } from 'store/selectors/provision';
import { fetchAccounts } from 'store/actions/provision';

// Style
import './style.scss';
import { paneIds } from './constants';
import { t } from 'i18next';

export const InstallerPage = () => {
    const dispatch = useDispatch();

    const wos = useSelector(getWorkOrdersState);
    const installers = useSelector(getInstallersState);
    const accounts = useSelector(getAccountsState);
    const selectedWo = useSelector(getSelectedWoState);
    const rightPaneId = useSelector(getRightPaneState);

    useEffect(() => {
        if (installers.length === 0) {
            dispatch(getInstallers());
        }
    }, [dispatch, installers]);

    useEffect(() => {
        if (accounts.length === 0) {
            dispatch(fetchAccounts());
        }
    }, [dispatch, accounts]);

    useEffect(()=> {
        if (wos.length === 0) {
            dispatch(getWos());
        }
    }, [dispatch, wos]);

    const renderRightPane = (paneId) => {
        switch (paneId) {
            case paneIds.CREATE_WO:
                return (<WoForm
                    accounts={accounts}
                    installers={installers}
                    title={t('lblCreateWo')}
                />);
            case paneIds.GET_WO:
                return (<WoForm
                    accounts={accounts}
                    installers={installers}
                    selectedWo={selectedWo}
                    title={t('lblUpdateWo')}
                />);
            case paneIds.HOME:
            default:  // to be replaced with an actual general message
                return (<div>
                    <span>{t('explanationMsg')}</span>
                </div>);
        }
    };

    return (
        <PageContainer
            name={testBy.PAGE.INSTALLER}
        >
            <ErrorBoundary boundary="Installer page">
                <div className="installer-page">
                    <WoList
                        accounts={accounts}
                        wos={wos}
                        installers={installers}
                    />
                    <div className="wo-right-pane">
                        {renderRightPane(rightPaneId)}
                    </div>
                </div>
            </ErrorBoundary>
        </PageContainer>
    );
};