import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { Button, Icon } from 'components/common';
import { iconsName } from 'assets';
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { classAttribute } from 'utils/helper';
import './style.scss';

const BaseDialog = ({ show, onOkClick, onCancelClick, children, title, className, okText, cancelText, testAttributes }) => {

    const classes = classAttribute([
        'base-dialog',
        className ?? '',
    ]);

    const cancelButton = useMemo(() =>
        ((typeof onCancelClick === 'function') ? (
            <Button
                label={cancelText ?? t('lblCancel')}
                onClick={onCancelClick}
                classes={['close-button']}
            />
        ) : null)
    , [onCancelClick, cancelText]);

    return (
        <Modal
            size="small"
            show={show}
            onHide={onCancelClick}
            backdrop="static"
            centered
            className={classes}
            {...testAttributes}
        >
            <Modal.Header
                closeButton={typeof onCancelClick === 'function'}
                {...setAttr.ui(testBy.UI.HEADER)}
            >
                <Icon name={iconsName.SETTINGS} width={24} height={24} />
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer
                {...setAttr.ui(testBy.UI.FOOTER)}
            >
                <Button
                    label={okText ?? t('lblOK')}
                    onClick={onOkClick}
                    classes={['close-button']}
                    disabled={typeof onOkClick !== 'function'}
                />
                {cancelButton}
            </Modal.Footer>
        </Modal>
    );
};

export default BaseDialog;