
export const APIS = {
    CORE: {
        AUTH: ('/token'), // Core API
        GET_IMAGE: 'https://unity-qa.powerfleet.com/fleetcore.api/api/downloads/getImageNew', // Core API
    },
    CONFIGURATIONS: {
        DEFINITION: '/api/Configurations/GetDefinition',
    },
    DRIVERS: {
        BY_ACCOUNT: '/api/Drivers/GetDriversByAccount',
        DETAILS: '/api/Drivers/GetDriverDetails',
        GET_DRIVER_SCORE: '/api/Drivers/GetDriverScore',
        GET_SAFETY_DRIVERS: '/api/Drivers/GetDrivers',
        GET_SAFETY_KPI: '/api/Drivers/GetDriverSafetyCount',
        GET_DRIVER_MILEAGE: '/api/Drivers/GetDriverMileageAndIncidentCount'
    },
    INCIDENTS: {
        BY_ACCOUNT: '/api/Incidents/GetIncidentsByAccount',
        COUNT_BY_ACCOUNT: '/api/Incidents/GetNumIncidentsByAccount',
        COUNT_PER_DAY: '/api/Incidents/GetNumIncidentsPerDay',
        COUNT_BY_RANGE: '/api/Incidents/GetNumIncidentsByRanges',
        COUNT_PER_TYPE: '/api/Incidents/GetNumIncidentsPerTypes',
        TYPES: '/api/Incidents/GetIncidentTypes',
        SEVERITIES: '/api/Incidents/GetIncidentsBySeverity',
        LOCATIONS: '/api/Incidents/GetIncidentsLocations',
        DRIVER_REOCCURRENCE: '/api/Incidents/GetDriverIncidentReOccurrence',
        SET_INCIDENT_STATE: '/api/Incidents/SetIncidentState',
        GET_INCIDENT_STATE: '/api/Incidents/GetIncidentState',
        INSERT_MANUAL_INCIDENT: '/api/Incidents/InsertManualIncident',
    },
    INSTALLER: {
        GET_WO: '/api/Installer/workOrders',
        GET_INSTALLERS: '/api/Installer/installers',
        CREATE_WO: '/api/Installer/workOrders',
    },
    RESOURCES: {
        BY_ACCOUNT: '/api/Resources/GetResourcesByAccount',
        DETAILS: '/api/Resources/GetResourceDetails',
        REAL_TIME_LOCATION: '/api/Resources/GetRealTimeLocation',
    },

    ACCIDENTS: {
        TIME_LINE_DATA: '/api/Accidents/GetAccidentTimeLineData',
        DETAILS: '/api/Accidents/GetAccidentDetails',
        GET_ACCIDENT_MEDIA: '/api/Accidents/GetAccidentMedia',
        WEATHER: '/api/Accidents/GetAccidentWeather',
    },
    MEDIA: {
        GET_IMAGES: '/api/Media/GetImages',
        DELETE_IMAGES: '/api/Media/DeleteImages',
        UPLOAD_IMAGES: '/api/Media/UploadImages',
        GET_VIDEOS: '/api/Media/GetVideos',
    },
    TRIP_INFO: '/api/Trips/GetTripInfo',
    COMMANDS: {
        SEND_COMMAND: '/api/Commands/SendCommand',
        GET_COMMAND_STATUS: '/api/Commands/GetCommandStatus',
        GET_COMMANDS: '/api/Commands/GetCommands',
    },
    PROVISION: {
        GET_PROVISION_ACCOUNTS:'/api/Provisioning/GetAccountsForProvision'
    }
};
