export const measureSystemIds = {
    DEFAULT: 1,
    METRIC: 2,
    IMPERIAL: 3,
    US_CUSTOMARY: 4,
    SI: 5,
};

export const unitIds = {
    LENGTH_L4: 1, // Length in level 4 for kilometers & miles
    LENGTH_L3: 2, // Length in level 3 for meters & yards
    WEIGHT: 3,
    TEMPERATURE: 4,
    VOLUME: 5,
    SPEED: 6,
    DISTANCE_CONSUM: 7,
    CONSUM_PER_DISTANCE: 8,
    CONSUM_PER_TIME: 9,
};

const { DEFAULT, METRIC, IMPERIAL, US_CUSTOMARY, SI } = measureSystemIds;
const { LENGTH_L3, LENGTH_L4, WEIGHT, TEMPERATURE, VOLUME, SPEED, DISTANCE_CONSUM, CONSUM_PER_DISTANCE, CONSUM_PER_TIME } = unitIds;

const formatVars = [
    'val', // Unit value
    'abbr', // Unit short name
];

export const unitFormats = {
    VAL_ABBR: 'val abbr',
    ABBR_VAL: 'abbr val',
};

// Enriching unit with translation labels or using directly unitName as default if not exist.
const lblKeys = [
    {
        systemIds: [DEFAULT, METRIC, SI],
        unitId: LENGTH_L4,
        lblAbbr: 'lblUnitsAbbr.kilometers', // t('lblUnitsAbbr.kilometers')
    },
    {
        systemIds: [DEFAULT, METRIC, SI],
        unitId: LENGTH_L3,
        lblAbbr: 'lblUnitsAbbr.meters', // t('lblUnitsAbbr.meters')
    },
    {
        systemIds: [DEFAULT, METRIC, SI],
        unitId: WEIGHT,
        lblAbbr: 'lblUnitsAbbr.kilograms', // t('lblUnitsAbbr.kilograms')
    },
    {
        systemIds: [DEFAULT, METRIC],
        unitId: TEMPERATURE,
        lblAbbr: 'lblUnitsAbbr.celsius', // t('lblUnitsAbbr.celsius')
    },
    {
        systemIds: [DEFAULT, METRIC, SI],
        unitId: VOLUME,
        lblAbbr: 'lblUnitsAbbr.liters', // t('lblUnitsAbbr.liters')
    },
    {
        systemIds: [DEFAULT, METRIC, SI],
        unitId: SPEED,
        lblAbbr: 'lblUnitsAbbr.kilometersPerHour', // t('lblUnitsAbbr.kilometersPerHour')
    },
    {
        systemIds: [DEFAULT, METRIC, SI],
        unitId: DISTANCE_CONSUM,
        lblAbbr: 'lblUnitsAbbr.kilometersPerLiter', // t('lblUnitsAbbr.kilometersPerLiter')
    },
    {
        systemIds: [DEFAULT, METRIC, SI],
        unitId: CONSUM_PER_DISTANCE,
        lblAbbr: 'lblUnitsAbbr.litersPerKilometer', // t('lblUnitsAbbr.litersPerKilometer')
    },
    {
        systemIds: [DEFAULT, METRIC, SI],
        unitId: CONSUM_PER_TIME,
        lblAbbr: 'lblUnitsAbbr.litersPerHour', // t('lblUnitsAbbr.litersPerHour')
    },
    {
        systemIds: [IMPERIAL, US_CUSTOMARY],
        unitId: LENGTH_L4,
        lblAbbr: 'lblUnitsAbbr.miles', // t('lblUnitsAbbr.miles')
    },
    {
        systemIds: [IMPERIAL, US_CUSTOMARY],
        unitId: LENGTH_L3,
        lblAbbr: 'lblUnitsAbbr.yards', // t('lblUnitsAbbr.yards')
    },
    {
        systemIds: [IMPERIAL, US_CUSTOMARY],
        unitId: WEIGHT,
        lblAbbr: 'lblUnitsAbbr.pounds', // t('lblUnitsAbbr.pounds')
    },
    {
        systemIds: [IMPERIAL, US_CUSTOMARY],
        unitId: TEMPERATURE,
        lblAbbr: 'lblUnitsAbbr.fahrenheit', // t('lblUnitsAbbr.fahrenheit')
    },
    {
        systemIds: [IMPERIAL],
        unitId: VOLUME,
        lblAbbr: 'lblUnitsAbbr.gallons', // t('lblUnitsAbbr.gallons')
    },
    {
        systemIds: [IMPERIAL, US_CUSTOMARY],
        unitId: SPEED,
        lblAbbr: 'lblUnitsAbbr.milesPerHour', // t('lblUnitsAbbr.milesPerHour')
    },
    {
        systemIds: [IMPERIAL],
        unitId: DISTANCE_CONSUM,
        lblAbbr: 'lblUnitsAbbr.milesPerGallon', // t('lblUnitsAbbr.milesPerGallon')
    },
    {
        systemIds: [IMPERIAL],
        unitId: CONSUM_PER_DISTANCE,
        lblAbbr: 'lblUnitsAbbr.gallonsPerMile', // t('lblUnitsAbbr.gallonsPerMile')
    },
    {
        systemIds: [IMPERIAL, US_CUSTOMARY],
        unitId: CONSUM_PER_TIME,
        lblAbbr: 'lblUnitsAbbr.gallonsPerhour', // t('lblUnitsAbbr.gallonsPerhour')
    },
    {
        systemIds: [US_CUSTOMARY],
        unitId: VOLUME,
        lblAbbr: 'lblUnitsAbbr.gallons', // t('lblUnitsAbbr.gallons')
    },
    {
        systemIds: [US_CUSTOMARY],
        unitId: DISTANCE_CONSUM,
        lblAbbr: 'lblUnitsAbbr.milesPerGallon', // t('lblUnitsAbbr.milesPerGallon')
    },
    {
        systemIds: [US_CUSTOMARY],
        unitId: CONSUM_PER_DISTANCE,
        lblAbbr: 'lblUnitsAbbr.gallonsPerMile', // t('lblUnitsAbbr.gallonsPerMile')
    },
    {
        systemIds: [SI],
        unitId: TEMPERATURE,
        lblAbbr: 'lblUnitsAbbr.kalvin', // t('lblUnitsAbbr.kalvin')
    }
];

const measureSystems = [
    {
        id: DEFAULT,
        name: 'Default system',
        units: [
            {
                systemId: DEFAULT,
                systemName: 'Default system',
                measureName: 'Length',
                unitName: 'KM',
                unitId: LENGTH_L4,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: DEFAULT,
                systemName: 'Default system',
                measureName: 'Length',
                unitName: 'Meters',
                unitId: LENGTH_L3,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: DEFAULT,
                systemName: 'Default system',
                measureName: 'Weight',
                unitName: 'Kg',
                unitId: WEIGHT,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: DEFAULT,
                systemName: 'Default system',
                measureName: 'Temperature',
                unitName: '°C',
                unitId: TEMPERATURE,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: DEFAULT,
                systemName: 'Default system',
                measureName: 'Volume',
                unitName: 'Liter',
                unitId: VOLUME,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: DEFAULT,
                systemName: 'Default system',
                measureName: 'Speed',
                unitName: 'Km/H',
                unitId: SPEED,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: DEFAULT,
                systemName: 'Default system',
                measureName: 'Consumption',
                unitName: 'KM/Liter',
                unitId: DISTANCE_CONSUM,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: DEFAULT,
                systemName: 'Default system',
                measureName: 'Consumption',
                unitName: 'Liter/KM',
                unitId: CONSUM_PER_DISTANCE,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: DEFAULT,
                systemName: 'Default system',
                measureName: 'Consumption',
                unitName: 'Liters/hour',
                unitId: CONSUM_PER_TIME,
                factorA: 1,
                factorB: 0
            }
        ]
    },
    {
        id: METRIC,
        name: 'Metric',
        units: [
            {
                systemId: METRIC,
                systemName: 'Metric',
                measureName: 'Length',
                unitName: 'KM',
                unitId: LENGTH_L4,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: METRIC,
                systemName: 'Metric',
                measureName: 'Length',
                unitName: 'Meters',
                unitId: LENGTH_L3,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: METRIC,
                systemName: 'Metric',
                measureName: 'Weight',
                unitName: 'Kg',
                unitId: WEIGHT,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: METRIC,
                systemName: 'Metric',
                measureName: 'Temperature',
                unitName: '°C',
                unitId: TEMPERATURE,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: METRIC,
                systemName: 'Metric',
                measureName: 'Volume',
                unitName: 'Liter',
                unitId: VOLUME,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: METRIC,
                systemName: 'Metric',
                measureName: 'Speed',
                unitName: 'Km/H',
                unitId: SPEED,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: METRIC,
                systemName: 'Metric',
                measureName: 'Consumption',
                unitName: 'KM/Liter',
                unitId: DISTANCE_CONSUM,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: METRIC,
                systemName: 'Metric',
                measureName: 'Consumption',
                unitName: 'Liter/KM',
                unitId: CONSUM_PER_DISTANCE,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: METRIC,
                systemName: 'Metric',
                measureName: 'Consumption',
                unitName: 'Liters/hour',
                unitId: CONSUM_PER_TIME,
                factorA: 1,
                factorB: 0
            }
        ]
    },
    {
        id: IMPERIAL,
        name: 'Imperial',
        units: [
            {
                systemId: IMPERIAL,
                systemName: 'Imperial',
                measureName: 'Length',
                unitName: 'Mile',
                unitId: LENGTH_L4,
                factorA: 0.62137119,
                factorB: 0
            },
            {
                systemId: IMPERIAL,
                systemName: 'Imperial',
                measureName: 'Length',
                unitName: 'Yards',
                unitId: LENGTH_L3,
                factorA: 1.0936,
                factorB: 0
            },
            {
                systemId: IMPERIAL,
                systemName: 'Imperial',
                measureName: 'Weight',
                unitName: 'Lb(Pound)',
                unitId: WEIGHT,
                factorA: 2.20462262185,
                factorB: 0
            },
            {
                systemId: IMPERIAL,
                systemName: 'Imperial',
                measureName: 'Temperature',
                unitName: '°F',
                unitId: TEMPERATURE,
                factorA: 1.8,
                factorB: 32
            },
            {
                systemId: IMPERIAL,
                systemName: 'Imperial',
                measureName: 'Volume',
                unitName: 'Gallon',
                unitId: VOLUME,
                factorA: 0.21996924,
                factorB: 0
            },
            {
                systemId: IMPERIAL,
                systemName: 'Imperial',
                measureName: 'Speed',
                unitName: 'Mile/H',
                unitId: SPEED,
                factorA: 0.62137119,
                factorB: 0
            },
            {
                systemId: IMPERIAL,
                systemName: 'Imperial',
                measureName: 'Consumption',
                unitName: 'Mile/Gallon',
                unitId: DISTANCE_CONSUM,
                factorA: 2.824809,
                factorB: 0
            },
            {
                systemId: IMPERIAL,
                systemName: 'Imperial',
                measureName: 'Consumption',
                unitName: 'Gallon/Mile',
                unitId: CONSUM_PER_DISTANCE,
                factorA: 0.354006,
                factorB: 0
            },
            {
                systemId: IMPERIAL,
                systemName: 'Imperial',
                measureName: 'Consumption',
                unitName: 'Gallon/hour',
                unitId: CONSUM_PER_TIME,
                factorA: 1,
                factorB: 0
            }
        ]
    },
    {
        id: US_CUSTOMARY,
        name: 'US Customary',
        units: [
            {
                systemId: US_CUSTOMARY,
                systemName: 'US Customary',
                measureName: 'Length',
                unitName: 'Mile',
                unitId: LENGTH_L4,
                factorA: 0.62137119,
                factorB: 0
            },
            {
                systemId: US_CUSTOMARY,
                systemName: 'US Customary',
                measureName: 'Length',
                unitName: 'Yards',
                unitId: LENGTH_L3,
                factorA: 1.0936,
                factorB: 0
            },
            {
                systemId: US_CUSTOMARY,
                systemName: 'US Customary',
                measureName: 'Weight',
                unitName: 'Lb(Pound)',
                unitId: WEIGHT,
                factorA: 2.20462262185,
                factorB: 0
            },
            {
                systemId: US_CUSTOMARY,
                systemName: 'US Customary',
                measureName: 'Temperature',
                unitName: '°F',
                unitId: TEMPERATURE,
                factorA: 1.8,
                factorB: 32
            },
            {
                systemId: US_CUSTOMARY,
                systemName: 'US Customary',
                measureName: 'Volume',
                unitName: 'Gallon',
                unitId: VOLUME,
                factorA: 0.26417203,
                factorB: 0
            },
            {
                systemId: US_CUSTOMARY,
                systemName: 'US Customary',
                measureName: 'Speed',
                unitName: 'Mile/H',
                unitId: SPEED,
                factorA: 0.62137119,
                factorB: 0
            },
            {
                systemId: US_CUSTOMARY,
                systemName: 'US Customary',
                measureName: 'Consumption',
                unitName: 'Mile/Gallon',
                unitId: DISTANCE_CONSUM,
                factorA: 2.352146,
                factorB: 0
            },
            {
                systemId: US_CUSTOMARY,
                systemName: 'US Customary',
                measureName: 'Consumption',
                unitName: 'Gallon/Mile',
                unitId: CONSUM_PER_DISTANCE,
                factorA: 0.425144,
                factorB: 0
            },
            {
                systemId: US_CUSTOMARY,
                systemName: 'US Customary',
                measureName: 'Consumption',
                unitName: 'Gallon/hour',
                unitId: CONSUM_PER_TIME,
                factorA: 1,
                factorB: 0
            }
        ]
    },
    {
        id: SI,
        name: 'SI',
        units: [
            {
                systemId: SI,
                systemName: 'SI',
                measureName: 'Length',
                unitName: 'KM',
                unitId: LENGTH_L4,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: SI,
                systemName: 'SI',
                measureName: 'Length',
                unitName: 'Meters',
                unitId: LENGTH_L3,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: SI,
                systemName: 'SI',
                measureName: 'Weight',
                unitName: 'Kg',
                unitId: WEIGHT,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: SI,
                systemName: 'SI',
                measureName: 'Temperature',
                unitName: '°K',
                unitId: TEMPERATURE,
                factorA: 1,
                factorB: 273.15
            },
            {
                systemId: SI,
                systemName: 'SI',
                measureName: 'Volume',
                unitName: 'Liter',
                unitId: VOLUME,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: SI,
                systemName: 'SI',
                measureName: 'Speed',
                unitName: 'Km/H',
                unitId: SPEED,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: SI,
                systemName: 'SI',
                measureName: 'Consumption',
                unitName: 'KM/Liter',
                unitId: DISTANCE_CONSUM,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: SI,
                systemName: 'SI',
                measureName: 'Consumption',
                unitName: 'Liter/KM',
                unitId: CONSUM_PER_DISTANCE,
                factorA: 1,
                factorB: 0
            },
            {
                systemId: SI,
                systemName: 'SI',
                measureName: 'Consumption',
                unitName: 'Liters/hour',
                unitId: CONSUM_PER_TIME,
                factorA: 1,
                factorB: 0
            }
        ]
    }
];

// Helper methods

export const getMeasureSystem = (id) => measureSystems.find((system) => system.id === id);

// Just renaming the types to units
export const measureSystemAdapter = (measureSystem) => {
    const { types, ...rest } = measureSystem;
    return { units: types, ...rest };
};

// Formula for converting value base on unit factorA & factorB
const formula = (value, { factorA, factorB }) => value * factorA + factorB;

// Foramt method
const toUnitFormat = (val, unit, formatTemp = '') => {
    const { abbr, unitName } = unit;
    const properties = {
        val: val ?? '',
        abbr: abbr ?? unitName ?? ''
    };
    let format = formatTemp;
    formatVars.forEach((fVar) => {
        format = format.replace(fVar, properties[fVar]);
    });
    return format;
};

// Converting unit value from default measurements system (METRIC) to target measure system according to unit factors.
export const convertUnit = (value, unit = {}) => {
    const { factorA, factorB } = unit;
    if (typeof value !== 'number') return value;
    return formula(value, { factorA: factorA ?? 1, factorB: factorB ?? 0 });
};


// Format value according unit parameter.
export const formatUnit = (value, unit, { format, toFix } = {}) => {
    let val = convertUnit(value, unit);
    if (typeof val === 'number') {
        val = Number(val?.toFixed?.(toFix ?? 2));
    }
    val = toUnitFormat(val, unit, format ?? unitFormats.VAL_ABBR);
    return val;
};

export const getUnitLabelKey = ({ systemId, unitId }) => lblKeys.find((lbl) => lbl.unitId === unitId && lbl.systemIds.includes(systemId));
