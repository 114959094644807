import { useCallback, useRef, useState, useEffect, useMemo } from 'react';
// Utils
import { iconsName } from 'assets';
import { periodOptions, rangeMsFromPeriod, periodIds } from 'pages/safety_page/constants';
import { setAttr, testBy } from 'utils/constants';
// Components
import { SimpleSelect } from 'components/common';
import { PeriodPicker } from 'components';

const { CUSTOM } = periodIds;

const PeriodFilter = (props) => {

    const ref = useRef();
    const { onSelectPeriod, selected, excludedPeriodIds } = props;

    const [showPicker, setPickerShow] = useState(false);

    const filterOptions = useMemo(() =>
        (Array.isArray(excludedPeriodIds) ?
            periodOptions.filter((o) => !excludedPeriodIds.includes(o.id)) : periodOptions)
    , [excludedPeriodIds]);

    const selectPeriod = useCallback((period) => {
        // Setting period rangeMs and triggering onSelectPeriod event
        onSelectPeriod?.({ ...period, rangeMs: rangeMsFromPeriod(period) });
    }, [onSelectPeriod]);

    useEffect(() => {
        if (!selected) return;
        selectPeriod(selected);
    }, []);

    const validateApply = useCallback((period, compare) => {
        const { rangeMs } = period;
        // Applying period with rangeMs
        if (rangeMs?.startTime && rangeMs?.endTime) {
            if (compare?.rangeMs) {
                // Applying when period is different then compare
                const { startTime, endTime } = compare.rangeMs;
                return rangeMs.startTime !== startTime || rangeMs.endTime !== endTime;
            }
            return true;
        }
    }, []);

    const openPicker = useCallback(() => {
        setPickerShow(true);
    }, []);

    const closePicker = useCallback(() => {
        setPickerShow(false);
    }, []);

    const onClickOption = useCallback((period) => {
        if (period.id === CUSTOM) {
            // Open period picker and getting the user custom period when 'onApply' event trigger
            openPicker();
            return;
        }
        if (period.id === selected.id && selected.rangeMs) return;
        selectPeriod(period);
    }, [selectPeriod, openPicker, selected]);

    const onApplyPicker = useCallback((period) => {
        const valid = validateApply(period, selected);
        if (valid) { selectPeriod(period); }
        closePicker();
    }, [selectPeriod, closePicker, selected, validateApply]);

    return (
        <div
            ref={ref}
            onClick={(selected.id === CUSTOM && !showPicker) ? openPicker : undefined}
            {...setAttr.feature(testBy.FEATURE.PERIODS_FILTER)}
        >
            <SimpleSelect
                onClickOption={onClickOption}
                options={filterOptions}
                selectedId={selected?.id}
                icon={{ name: iconsName.CALENDAR, useDefault: true }}
                hideMenu={selected.id === CUSTOM}
                buttonTestAttributes={setAttr.action(testBy.ACTION.TOGGLE_PERIODS_FILTER)}
            />
            {
                showPicker ? (
                    <PeriodPicker
                        overlayProps={{
                            onHide: closePicker,
                            target: ref.current,
                            show: showPicker,
                        }}
                        selected={selected}
                        onApply={onApplyPicker}
                        onCancel={closePicker}
                        validateApply={validateApply}
                    />
                ) : null
            }
        </div>
    );
};

export default PeriodFilter;
