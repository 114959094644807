import React from 'react';
import './style.scss';

const ModalContainer = ({ children }) => (
    <div className="modal-container">
        {children}
    </div>
);

export default ModalContainer;
