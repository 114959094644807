import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { iconsName } from 'assets';
import { pf_t, t } from 'utils/dictionary';
import { isValidCoordinate, toFirstCapitalString } from 'utils/helper';
import { allowedFormats, getNowMillis, millisToAllowedFormat } from 'utils/timezone';
import { useSpeedUnit } from 'hooks';
import { openModal } from 'store/actions/popup';
import CategoryModal from 'components/modal/category_modal/CategoryModal';
import { ModalBlock, ModalCoordsInfoRow, ModalDriverInfo, ModalInfoRow, ModalInfoSection, ModalResourceInfo } from 'components/modal/components';
import { modalsIds, getCommandStatusName } from 'components/modal/ModalsConstants';
import { Button, Icon } from 'components/common';
import { AlertDialog } from 'components/common/dialogs/alert_dialog/AlertDialog';
import useCommandSender from 'components/modal/hooks/use_command_sender/useCommandSender';
import useModalState from 'components/modal/hooks/use_modal_state/useModalState';
import TheftMap from 'pages/safety_page/features/maps/theft_map/TheftMap';
import { setAttr, testBy } from 'utils/constants';




const TheftModal = ({ data }) => {

    const dispatch = useDispatch();

    const [lastLocation, setLastLocation] = useState();
    const [lastPosition, setLastPosition] = useState({});

    const modalState = useModalState(data.id, data.categoryId, data.resourceId);
    const [buttonLabel, commandState, sendCommand] = useCommandSender(data.id, data.resourceId, modalState.lastLocation?.immobilizerStatus, modalState.command, modalState.currentStateId);
    const mSpeed = useSpeedUnit();

    const eventName = pf_t.eventName(data.type);

    const hasLastLocation = useMemo(() => lastLocation?.lastTime > 0 && ((lastLocation?.address ?? '').length > 0 || isValidCoordinate(lastPosition)),
        [lastLocation, lastPosition]);

    useEffect(() => {
        // make sure we always request the trip by setting lastPosition.eventTime
        let lastPosition = { eventTime: modalState.lastLocation?.lastTime ?? data.eventTime ?? getNowMillis() };
        if (modalState.lastLocation && typeof modalState.lastLocation === 'object') {
            setLastLocation({ ...modalState.lastLocation, eventTime: modalState.lastLocation.lastTime });
            const objPosition = { lat: modalState.lastLocation.lat, lng: modalState.lastLocation.lng };
            if (isValidCoordinate(objPosition)) {
                lastPosition = { ...lastPosition, ...objPosition };
            }
        }
        setLastPosition(lastPosition);
    }, [data.eventTime, modalState.lastLocation]);

    const renderCommandButton = () => {
        if((modalState?.currentStateId && modalState.currentStateId > 1) || !buttonLabel) return null;

        const iconName = commandState === 2 ? iconsName.COMMAND_SENT :
            commandState === 4 ? iconsName.COMMAND_ACK : iconsName.COMMAND_FAILED;
        const iconTitle = getCommandStatusName(commandState);
        const buttonDisabled = commandState === 2;
        return (<Button
            label={buttonLabel}
            onClick={sendCommand}
            classes={['command-button']}
            disabled={buttonDisabled}
        >
            {commandState != null && <Icon name={iconName} title ={iconTitle} color="transparent" stroke="#000000" width={32} height={32} />}
        </Button>);
    };

    const lastKnownLocationInfoRows = useCallback(() => {
        if (hasLastLocation) {
            const statusParts = [
                t('lblMovingAtSpeed', { speed: mSpeed.f(lastLocation.speed ?? 0) }),
                toFirstCapitalString(lastLocation.state),
            ].filter((part) => part.length > 0);

            const info = [
                { label: modalState.resolved ? t('lblTheftClosedOn') : t('lblLastUpdate'), value: formattedTime(lastLocation.lastTime) },
                { label: modalState.resolved ? t('lblLastEventLocation') : t('lblLastKnownLocation'), value: (lastLocation.address ? lastLocation.address : `${lastLocation.lat} ${lastLocation.lng}`) },
                { label: modalState.resolved ? t('lblLastEventState') : t('lblLastKnownState'), value: statusParts.join(' / ') },
            ];

            const iconObj = isValidCoordinate(lastPosition) ? {
                name: iconsName.PIN, useDefault: true, title: t('lblOpenMap') , onClick: () => {
                    dispatch(openModal({ id: modalsIds.MAP_MODAL, data: { ...data, eventName, info, position: lastPosition }}));
                }
            } : null;

            return (<>
                <ModalInfoRow label={info[0].label} highlight>{info[0].value}</ModalInfoRow>
                {lastLocation.address ?
                    <ModalInfoRow label={info[1].label} iconObj={iconObj} highlight>{info[1].value}</ModalInfoRow> :
                    <ModalCoordsInfoRow label={info[1].label} lat={lastLocation.lat} lng={lastLocation.lng} iconObj={iconObj} highlight />
                }
                <ModalInfoRow label={info[2].label} highlight>{info[2].value}</ModalInfoRow>
            </>);
        }
    }, [lastLocation, hasLastLocation, mSpeed]);

    const formattedTime = (time) => millisToAllowedFormat(time ?? 0, allowedFormats.DATE_TIME_LONG);

    const onCloseAndSave = useCallback(async () => {
        if(buttonLabel && modalState.currentStateId) {
            sendCommand(true);
        }
        if (hasLastLocation) {
            modalState.saveState(lastLocation);
        } else {
            await AlertDialog(t('lblUnrecoverableError'));
        }
    }, [lastLocation, hasLastLocation, modalState, buttonLabel]);

    return (
        <CategoryModal
            modalId={modalsIds.THEFT_MODAL}
            eventName={t('lblPossibleTheft')}
            stateComponent={modalState.stateComponent}
            data={data}
            renderFooter={renderCommandButton}
            handleCloseAndSaveClick={onCloseAndSave}
            needSave={modalState.needSave}
            hasMessage={modalState.hasMessage}
            testAttributes={setAttr.feature(testBy.FEATURE.MODAL_THEFT)}
        >
            <ModalInfoSection>
                <ModalDriverInfo
                    driverId={data.driverId}
                    driverName={data.driverName}
                />
                <ModalBlock
                    testAttributes={setAttr.ui(testBy.UI.INFO_DETAILS)}
                >
                    {lastKnownLocationInfoRows()}
                    <ModalInfoRow label={t('lblTheftType')}>{eventName}</ModalInfoRow>
                    <ModalInfoRow label={t('lblTheftDetected')}>{formattedTime(data.eventTime)}</ModalInfoRow>
                    <ModalInfoRow label={t('lblTheftAddress')}>{data.address}</ModalInfoRow>
                    <ModalCoordsInfoRow label={t('lblTheftCoordinates')} lat={data.lat} lng={data.lng} />
                    <ModalInfoRow label={t('lblIncidentId')}>{data.id}</ModalInfoRow>
                    {/* <ModalInfoRow label={t('lblClosestLandmark')}>[Yard 123 (13mi)]</ModalInfoRow> */}
                </ModalBlock>
                <ModalResourceInfo
                    resourceId={data.resourceId}
                />
            </ModalInfoSection>
            <ModalBlock fullSize>
                <TheftMap event={data} resolved={modalState.resolved} lastLocation={lastLocation} />
            </ModalBlock>
        </CategoryModal>
    );
};

export default TheftModal;
