import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Utils
import { MINUTE } from 'utils/timezone';
import { t } from 'utils/dictionary';
import { setAttr, testBy } from 'utils/constants';
// Redux
import actions, { fetchDashboardData } from 'store/actions/safety';
import { getSafetyPeriodState, getIncidentsKpis } from 'store/selectors/safety';
import { getSafetyBreadCrumbs } from 'store/selectors/navigation';
// Components
import { PageTopBar, SimpleKpis } from 'components';
import { DashboardFilter, IncidentsView, SafetyMap, IncidentGraph } from 'pages/safety_page/features';
import './style.scss';

const { SCREEN } = testBy;
const IncidentDashboard = (props) => {

    const dispatch = useDispatch();
    const intervalId = useRef();
    const curPeriod = useSelector(getSafetyPeriodState);
    const kpis = useSelector(getIncidentsKpis);

    useEffect(() => () => {
        stopPeriodicUpdate();
        dispatch(actions.clearDashboardScreen());
    }, []);

    const getDashboardData = useCallback((period) => {
        dispatch(fetchDashboardData(period.rangeMs));
    }, []);

    const startPeriodicUpdate = useCallback((period, timeMillis = MINUTE) => {
        if (intervalId.current) return;
        getDashboardData(period);
        intervalId.current = setInterval(() => {
            getDashboardData(period);
        }, timeMillis);
    }, [getDashboardData]);

    const stopPeriodicUpdate = useCallback(() => {
        clearInterval(intervalId.current);
        intervalId.current = null;
    }, []);

    const onPeriodChange = useCallback((period) => {
        stopPeriodicUpdate();
        startPeriodicUpdate(period);
    }, [stopPeriodicUpdate, startPeriodicUpdate]);

    const breadCrumbs = useSelector(getSafetyBreadCrumbs);

    return (
        <div
            className='incident-management-screen'
            {...setAttr.screen(SCREEN.SAFETY_DASHBOARD)}
        >
            <PageTopBar
                breadCrumbs={breadCrumbs}
                showPeriodFilter
                onPeriodChange={onPeriodChange}
                curPeriod={curPeriod}
                setPeriod = {actions.setPeriod}
            >
                <DashboardFilter />
            </PageTopBar>
            <section>
                <div className='section-left'>
                    <SimpleKpis kpis={kpis} />
                    <IncidentsView period={curPeriod} />
                </div>
                <div className='section-right'>
                    <IncidentGraph period={curPeriod} />
                    <div className='spacer v' />
                    <SafetyMap caption={t(curPeriod.lblKey)} />
                </div>
            </section>
        </div>
    );
};

export default IncidentDashboard;
