import { useCallback, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { iconsName } from 'assets';
import { ContextPopover, Icon, SendInput } from 'components/common';
import { t } from 'utils/dictionary';
import { classAttribute } from 'utils/helper';
import './style.scss';

const useSendSMS = (mobilePhone, smsAction) => {

    const [smsFeedback, setSmsFeedback] = useState();
    const smsFeedbackError = useMemo(() => ({ message: t('lblMessageSendError'), style: 'error' }), []);
    const smsTriggerRef = useRef();

    const toggleSmsMessage = useCallback(() => {
        smsTriggerRef.current?.click();
        setSmsFeedback(null);
    }, []);

    const sendSMS = useCallback((body) => {
        /* axios.post('https://rest.clicksend.com/v3/sms/send', {
            messages: [{
                body: body,
                to: mobilePhone,
                // TODO: for US/CAN this field must be omitted (defaults to shared num, replies sent to account email by default) or a purchased num
                // from: 'PWFone',
            }]
        }, {
            headers: { Authorization: 'Basic Y2FybG85MDg3QHlhaG9vLmNvbTo3MTJFQTk2Mi05MDdELTYzRkMtMTA2RC1CRjY3Njg4M0MwNzY=' }
        }).then((response) => {
            const { data, status } = response;
            if (status === 200) {
                console.log(data.data.messages);
                setSmsFeedback({ message: t('lblMessageSent'), style: 'info' });
            } else {
                console.log(response);
                setSmsFeedback(smsFeedbackError);
            }
        }).catch((error) => {
            console.log(error);
            setSmsFeedback(smsFeedbackError);
        }).finally(() => {
            setTimeout(toggleSmsMessage, 1000);
        }); */
        axios.post('https://api.twilio.com/2010-04-01/Accounts/AC0aa27fa55ece1f5aa15594e95ebb0191/Messages.json', {
            Body: body,
            To: mobilePhone,
            From: '+447782361669' // twilio trial account number
        }, {
            headers: {
                'Authorization': 'Basic QUMwYWEyN2ZhNTVlY2UxZjVhYTE1NTk0ZTk1ZWJiMDE5MTowYzA0NzQ4NDUxYTM2YzhiZDljNDRjNDIxMGUzMWNkMA==',
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            const { data, status } = response;
            if (status === 201) {
                console.log(data);
                setSmsFeedback({ message: t('lblMessageSent'), style: 'info' });
            } else {
                console.log(response);
                setSmsFeedback(smsFeedbackError);
            }
        }).catch((error) => {
            console.log(error);
            setSmsFeedback(smsFeedbackError);
        }).finally(() => {
            setTimeout(toggleSmsMessage, 1000);
        });
    }, [mobilePhone, smsFeedbackError, toggleSmsMessage]);

    const smsButton = useMemo(() => {
        if (mobilePhone && smsAction) {
            const body = <SendInput
                autoFocus
                // TODO: if a non https://en.wikipedia.org/wiki/GSM_03.38 character is included the message is encoded in Unicode and message max length is reduced to 70
                maxLength={70}
                name="send-sms"
                onClickSend={sendSMS}
                placeholder={t('lblEnterMessage')}
            />;

            let footer = null;
            if (smsFeedback) {
                const feedbackClasses = classAttribute([
                    'feedback',
                    smsFeedback.style ?? '',
                ]);
                footer = <span className={feedbackClasses}>{smsFeedback.message ?? ''}</span>;
            }

            const headerIcon = <Icon
                name={iconsName.NOTES}
                fill="transparent"
                stroke="#000"
            />;
            return <span>
                <Icon
                    name={iconsName.ALERT_BELL}
                    onClick={toggleSmsMessage}
                    useDefault
                />
                <ContextPopover
                    ref={smsTriggerRef}
                    body={body}
                    className="sms-popover"
                    footer={footer}
                    headerIcon={headerIcon}
                    placement="bottom-end"
                    title={t('lblMessage')}
                />
            </span>;
        }
        return null;
    }, [mobilePhone, sendSMS, smsAction, smsFeedback, toggleSmsMessage]);

    return smsButton;
};

export default useSendSMS;
