import { createSlice } from '@reduxjs/toolkit';
import { paneIds } from 'pages/installer_page/constants';
import { reducerNames } from 'store/constants';

const initialState = {
    isFetching: false,
    installers: [],
    rightPaneId: paneIds.HOME,
    searchValue: '',
    workOrders: [],
};

const slice = createSlice({
    name: reducerNames.INSTALLER,
    initialState,
    reducers: {
        setWorkOrders: (state, action) => {
            state.workOrders = action.payload;
        },
        setInstallers: (state, action) => {
            state.installers = action.payload;
        },
        setSearchValue: (state, action) => {
            state.searchValue = action.payload;
        },
        setRightPane: (state, action) => {
            state.rightPaneId = action.payload;
        },
        setIsFetching: (state, action) => {
            state.isFetching = action.payload;
        },
        setSelectedWo: (state, action) => {
            state.workOrders = state.workOrders.map((wo) => ({ ...wo, isSelected: wo.id === action.payload }));
        },
    },
});


export default slice.reducer;

export const { name, getInitialState, actions } = slice;