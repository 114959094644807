import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FilterWidget } from 'components';
import { filterUtils } from 'components/filters/filter_schema';


const Filter = (props) => {

    const {
        applyFiltersAction,
        clearFiltersAction,
        enableDisableFilters,
        removeFilterAction,
        resetFiltersAction,
        setSearchAction,
        updateFilterSelectionsAction,
        filterEnabledSelector,
        filterManagerSelector,
        filterSummarySelector,
        filterTrailSelector,
        placement,
    } = props;

    const dispatch = useDispatch();
    const { filters, defaultTab, searches } = useSelector(filterManagerSelector);

    const onSearchOptionsChange = useCallback(({ name, value }) => {
        dispatch(setSearchAction({ name, value }));
    }, []);

    const onClickSearchClear = useCallback(({ name }) => {
        dispatch(setSearchAction({ name, value: '' }));
    }, []);

    return (
        <div className='filter-controller'>
            <FilterWidget
                placement={placement}
                clearFilters={() => dispatch(clearFiltersAction())}
                removeFilter={(id) => dispatch(removeFilterAction(id))}
                filterEnabled={useSelector(filterEnabledSelector)}
                filterTrailData={useSelector(filterTrailSelector)}
                enableDisableFilters={(value) => dispatch(enableDisableFilters(value))}
                filters={filters}
                defaultTab={defaultTab}
                searches={searches}
                applyFilters={() => dispatch(applyFiltersAction())}
                resetFilters={() => dispatch(resetFiltersAction())}
                updateFilter={(filter) => dispatch(updateFilterSelectionsAction(filterUtils.filterWithSelections(filter)))}
                getSelectionSummary={(selectedTab) => useSelector(filterSummarySelector(selectedTab))}
                onSearchOptionsChange={onSearchOptionsChange}
                onClickSearchClear={onClickSearchClear}
            />
        </div>
    );
};

export default Filter;
