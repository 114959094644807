import React from 'react';
import { classAttribute } from 'utils/helper';
import './style.scss';

const Text = React.forwardRef((props , ref) => {
    const { text, charsForTitle, variant, className, upperCase, title, ...rest } = props;

    const classes = classAttribute([
        'text-variant',
        className ?? '',
    ]);
    const forcedTitle = title ?? (text && charsForTitle && text.trim().length > charsForTitle ? text : '');
    return (
        <span ref ={ref} className={classes} {...rest} title={forcedTitle}>
            {upperCase ? text?.toUpperCase?.() : text}
        </span>
    );
},
);

export default Text;
