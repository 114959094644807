import { api } from 'services';
import { APIS } from 'utils/constants';


export const fetchTripInfo = async (params) => {
    const { data, status, error } = await api.get(APIS.TRIP_INFO, { params });
    return {
        items: [],
        success: status === 200 && !error,
        params,
        ...data?.data
    };
};