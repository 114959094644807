import { createSelector } from 'reselect';
import { reducerNames } from 'store/constants';

export const getInstallersState = (state) => state[reducerNames.INSTALLER].installers;
export const getWorkOrdersState = (state) => state[reducerNames.INSTALLER].workOrders;
export const getSearchValueState = (state) => state[reducerNames.INSTALLER].searchValue;
export const getRightPaneState = (state) => state[reducerNames.INSTALLER].rightPaneId;
export const getIsFetchingState = (state) => state[reducerNames.INSTALLER].isFetching;

export const getSelectedWoState = createSelector(
    [getWorkOrdersState],
    (wos) => {
        const wo = wos.find((w) => w.isSelected === true);
        return wo || null;
    }
);