import React, { useCallback, useEffect, useRef, useState }  from 'react';
import { useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { iconsName } from 'assets';
import Icon from 'components/common/icons/Icon';
import { getSearchData, getParamsState } from 'store/selectors/safety';
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import './style.scss';

const MIN_CHARS = 2;

const SearchWithAutocomplete = ({ onSearch, onClear, typeSearchDisable, idSearchDisable, search, featureAttrValue, placeholder }) => {

    const selectRef = useRef();
    const searchData = useSelector(getSearchData);
    const selectedFilter = useSelector(getParamsState);
    const [inputText, setInputText] = useState(search ?? '');
    const [options, setOptions] = useState([]);
    const [menuSelectionStarted, setMenuSelectionStarted] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);

    const searchInObject = (arr, text, filteredIds) => {
        const result = [];
        const filters = filteredIds ? filteredIds.split(',') : [];
        arr.forEach((d) => {
            d.items.forEach((n) => {
                if(n.name.toLowerCase().includes(text) && (!filters.length || filters.includes(String(n.id)))) {
                    result.push({ label: n.name, value: n.name, id: n.id });
                }
            });
        });
        return result;
    };

    useEffect(() => {
        const result = [];
        if(inputText && inputText.length > MIN_CHARS ) {
            result.push({ label: t('lblDrivers'),
                options: searchInObject(searchData.drivers, inputText.toLowerCase(), selectedFilter.drivers) });
            result.push({ label: t('lblResources'),
                options: searchInObject(searchData.resources, inputText.toLowerCase(), selectedFilter.resources) });
            if(!typeSearchDisable)
                result.push({ label: t('lblTypes'),
                    options: searchInObject(searchData.types, inputText.toLowerCase(), selectedFilter.types) });
        }
        setOptions(result);
    }, [inputText, searchData, selectedFilter, typeSearchDisable]);

    const handleInputChange = useCallback((inputText, meta) => {
        setMenuSelectionStarted(false);
        if (meta.action !== 'input-blur' && meta.action !== 'menu-close' && meta.action !== 'set-value') {
            setInputText(inputText);
            if(inputText.length === 0 && filterApplied) {
                onClear();
                setFilterApplied(false);
            }
        }
    }, [onClear, filterApplied]);

    const handleValueChange = useCallback((v) => {
        setInputText(v.value);
        setMenuSelectionStarted(false);
    }, []);

    const getSearchResult = useCallback((opt, text) => {
        function resultsFromOptions(options) {
            return Array.isArray(options) && options.length > 0 ?
                options.map((x) => x.id).join(',') : '';
        }

        let result = {
            searchDrivers: resultsFromOptions(opt[0].options),
            searchResources: resultsFromOptions(opt[1].options)
        };
        if(!typeSearchDisable) {
            result = { ...result, searchTypes: resultsFromOptions(opt[2].options) };
        }
        if(!idSearchDisable && !isNaN(text)) {
            result = { ...result, ids: text };
        }
        return result;
    }, [typeSearchDisable, idSearchDisable]);

    const performSearch = useCallback(() => {
        if(inputText.length > MIN_CHARS && options.length > 0) {
            onSearch(getSearchResult(options, inputText), inputText);
            setFilterApplied(true);
            selectRef.current.blurInput();
        }
    },
    [options, onSearch, inputText, getSearchResult]);

    const hasOptions = options.some((g) => g.options && g.options.length);

    const onEnterClick = useCallback((e) => {
        if(e.keyCode === 13 && !menuSelectionStarted) {
            performSearch();
            e.preventDefault();
        }

        if(selectRef.current.state.prevProps.menuIsOpen && hasOptions &&
                (e.keyCode === 38 || e.keyCode === 40)) {
            setMenuSelectionStarted(true);
        }
    }, [hasOptions, menuSelectionStarted, performSearch]);

    const clear = useCallback(() => {
        setInputText('');
        if(filterApplied) {
            onClear?.();
            setFilterApplied(false);
        }
    }, [onClear, filterApplied]);

    const handleOnClick = useCallback(() => {
        setMenuSelectionStarted(false);
    }, []);

    const rightButtons = useCallback(() =>
        <>
            {inputText && <Icon
                name={iconsName.CANCEL}
                onClick={clear}
                height={16}
                useDefault
                {...setAttr.action(testBy.ACTION.CANCEL)}
            />}
            <Icon
                name={iconsName.SEARCH}
                onClick={inputText && inputText.length > MIN_CHARS ? performSearch : undefined}
                useDefault
                {...setAttr.action(testBy.ACTION.SEARCH)}
            />
        </>, [inputText, performSearch, clear]);

    const Menu = useCallback((props) => (hasOptions && <components.Menu { ...props }></components.Menu>)
        , [hasOptions]);

    return <div
        { ...setAttr.feature(featureAttrValue) }
    >
        <Select
            className='search-with-autocomplete'
            classNamePrefix={featureAttrValue}
            onClick={handleOnClick}
            inputValue={inputText}
            value={null}
            options={options}
            isSearchable={true}
            openMenuOnClick={false}
            placeholder={placeholder || t('lblSearch')}
            onSelectResetsInput={false}
            onBlurResetsInput={false}
            onCloseResetsInput={false}
            ref={selectRef}
            onInputChange={handleInputChange}
            onChange={handleValueChange}
            onKeyDown={onEnterClick}
            components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: rightButtons,
                Menu
            }}
            styles={customStyles}
        />
    </div>;
};

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        minHeight: '36px',
        height: '36px',
        boxShadow: 'none',
        borderColor:'#e6eded',
        '&:hover': {
        }
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '36px',
    }),
    input: (provided) => ({
        ...provided,
        input: {
            opacity: '1 !important',
            width: '195px !important'
        },
        fontWeight: '400',
        fontSize: '14px',
        color: '#4f4e4e'
    }),
    placeholder: (provided) => ({
        ...provided,
        fontWeight: '400',
        fontSize: '14px'
    }),
    option: (provided) => ({
        ...provided,
        fontWeight: '400',
        fontSize: '14px'
    }),
};

export default SearchWithAutocomplete;
