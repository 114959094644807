import { useCallback } from 'react';
import { confirmable, createConfirmation } from 'react-confirm';
import BaseDialog from 'components/common/dialogs/BaseDialog';
import { setAttr, testBy } from 'utils/constants';

const ConfirmationDialogModal = ({ show, proceed, confirmation, title, okText, cancelText }) => {

    const onCancelClick = useCallback(() => proceed(false), [proceed]);
    const onOkClick = useCallback(() => proceed(true), [proceed]);

    return <BaseDialog
        show={show}
        onCancelClick={onCancelClick}
        onOkClick={onOkClick}
        title={title}
        okText={okText}
        cancelText={cancelText}
        testAttributes={setAttr.feature(testBy.FEATURE.DIALOG_CONFIRMATION)}
    >
        {confirmation}
    </BaseDialog>;
};

const confirmLow = createConfirmation(confirmable(ConfirmationDialogModal));

export const ConfirmationDialog = (message, options = {}) =>
    confirmLow(Object.assign({ confirmation: message }, options));

