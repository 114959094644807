import { useCallback } from 'react';
import { DateTime } from 'luxon';

// redux
import { useDispatch } from 'react-redux';
import { setRightPane, setSelectedWo } from 'store/actions/installer';

// utils & constants
import { formatDateTime } from 'utils/timezone';
import { paneIds } from 'pages/installer_page/constants';
import { t } from 'utils/dictionary';

import './style.scss';

export const WoCard = ({ wo, getInstallerName }) => {
    const dispatch = useDispatch();
    const { devices, id, endDate, name, userId, workType, isSelected } = wo;

    const onSelectWo = useCallback(() => {
        dispatch(setSelectedWo(id));
        dispatch(setRightPane(paneIds.GET_WO));
    } ,[id, dispatch]);

    const getWoStatus = useCallback((devicesList) => {
        const readyDevices = devicesList?.filter((device) => device.status !== 0) || [];
        return `${readyDevices.length}/${devicesList?.length || 0}`;
    },[]);

    const parsedEndDate = formatDateTime(DateTime.fromISO(endDate), DateTime.DATETIME_SHORT);
    const installerName = getInstallerName(userId);
    const woStatus = getWoStatus(devices);

    return (
        <div className={`wo-container ${isSelected ? 'selected' : ''}`} onClick={onSelectWo}>
            <div className="wo-col large">
                <div>
                    <span>{`${t('lblWoName')}: `}</span>
                    <span title={name}>{name}</span>
                </div>
                <div>
                    <span>{`${t('lblDueDate')}: `}</span>
                    <span title={parsedEndDate}>{parsedEndDate}</span>
                </div>
            </div>
            <div className="wo-col">
                <div>
                    <span>{`${t('lblInstaller')}: `}</span>
                    <span title={installerName}>{installerName}</span>
                </div>
                <div>
                    <span>{`${t('lblWoType')}: `}</span>
                    <span title={workType}>{workType}</span>
                </div>
            </div>
            <div className="wo-col small">
                <div>
                    <span>{`${t('lblStatus')}: `}</span>
                    <span title={woStatus}>{woStatus}</span>
                </div>
                <div></div>
            </div>
        </div>);
};