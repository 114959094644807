import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { iconsName } from 'assets';
import { ROUTES } from 'utils/constants';


export default PropTypes;


const iconsNameLst = Object.values(iconsName);
const routeIds = Object.values(ROUTES).map((route) => route.id);


// ENTITIES SHAPES
const user = {
    account: PropTypes.shape({
        id: PropTypes.number,
        userSwitches: PropTypes.number,
        businessDepartmentId: PropTypes.number,
        accountSwitches: PropTypes.number,
        type: PropTypes.number,
    }),
    loginName: PropTypes.string
};

const incident = {
    eventTime: PropTypes.number,
    driverName: PropTypes.string,
    severity: PropTypes.oneOf([0, 1, 2]),
    // videos: PropTypes.string, ?
    address: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    type: PropTypes.number,
    resourceName: PropTypes.string,
};

export const iconProps = {
    name: PropTypes.oneOf(iconsNameLst),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    color: PropTypes.string,
    style: PropTypes.shape({}),
    viewBox: PropTypes.string,
    className: PropTypes.string,
    useDefault: PropTypes.bool,
    title: PropTypes.string,
    variant: PropTypes.number,
};

const link = {
    key: PropTypes.string,
    id: PropTypes.oneOf(routeIds),
    path: PropTypes.string.isRequired,
    icon: PropTypes.shape(iconProps),
    lblKey: PropTypes.string,
    subIds: PropTypes.arrayOf(PropTypes.oneOf(routeIds)),
};

const coordinate = {
    lat: PropTypes.number,
    lng: PropTypes.number,
};

const tripPoint = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    eventTime: PropTypes.number.isRequired
};

const mapLayerOptions = {
    clickable: PropTypes.bool,
    draggable: PropTypes.bool,
    opacity: PropTypes.number,
    visible: PropTypes.bool,
    zIndex: PropTypes.number
};

const markerOptions = {
    ...mapLayerOptions,
    collisionBehavior: PropTypes.string,
    crossOnDrag: PropTypes.bool,
    icon: PropTypes.shape({}),
    label: PropTypes.string,
    optimized: PropTypes.bool,
    position: PropTypes.shape(coordinate),
    title: PropTypes.string,
};

const polylineOptions = {
    ...mapLayerOptions,
    editable: PropTypes.bool,
    geodesic: PropTypes.bool,
    icons: PropTypes.arrayOf(PropTypes.shape({})),
    path: PropTypes.arrayOf(PropTypes.shape(coordinate)),
    strokeColor: PropTypes.string,
    strokeOpacity: PropTypes.number,
    strokeWeight: PropTypes.number,
};

// COMPONENTS PROPS
export const buttonProps = {
    label: PropTypes.string,
    classes: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node,
    onClick: PropTypes.func,
    data: PropTypes.any,
    disabled: PropTypes.bool,
    style: PropTypes.shape({}),
    uppercase: PropTypes.bool,
};

export const spinnerProps = {
    isLoading: PropTypes.bool,
    size: PropTypes.oneOf(['sm', '']),
    className: PropTypes.string
};

export const actionBarProps = {
    icon: PropTypes.shape(iconProps),
    onClick: PropTypes.func,
    popover: PropTypes.shape({
        content: PropTypes.node,
        className: PropTypes.string
    }),
    overlayProps: PropTypes.shape({}),
};

export const propTypes = {
    dateTime: PropTypes.instanceOf(DateTime),
    dateRange: PropTypes.shape({
        from: PropTypes.instanceOf(DateTime),
        to: PropTypes.instanceOf(DateTime)
    }),
    user: PropTypes.shape(user),
    iconProps: PropTypes.shape(iconProps),
    link: PropTypes.shape(link),
    coordinate: PropTypes.shape(coordinate),
    tripPoint: PropTypes.shape(tripPoint),
    polylineOptions: PropTypes.shape(polylineOptions),
    markerOptions: PropTypes.shape(markerOptions),
    buttonProps: PropTypes.shape(buttonProps),
    spinnerProps: PropTypes.shape(spinnerProps),
    incident: PropTypes.shape(incident),
    actionBarProps: PropTypes.shape(actionBarProps)
};