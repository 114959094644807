import React, { useEffect, useMemo } from 'react';
// Utils
import { useTrip } from 'pages/safety_page/features/maps/trip_map/useTrip';
import { isValidCoordinate } from 'utils/helper';
// Components
import TripMap from 'pages/safety_page/features/maps/trip_map/TripMap';
import { TripMarker } from 'pages/safety_page/features/maps/trip_map/TripUiItems';



const ViolationMap = (props) => {

    const {
        eventTime,
        resourceId,
        position,
    } = props;

    const { loadTrip, loaded, items, isLoading } = useTrip();

    useEffect(() => {
        if (!loaded) {
            loadTrip({ resourceId, start: eventTime });
        }
    }, [loaded, loadTrip, resourceId, eventTime]);

    const points = useMemo(() => items.filter(isValidCoordinate), [items]);

    return (
        <TripMap
            center={position}
            points={points}
            isLoading={isLoading}
            hideTripMarkers
        >
            <TripMarker point={position} options={{ zIndex: 3 }} />
        </TripMap>
    );
};

export default ViolationMap;
