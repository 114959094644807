import { useCallback, useMemo, useEffect, useRef, forwardRef } from 'react';
// Utils
import { t } from 'utils/dictionary';
import { durationFromObject, getDateTime, formatDateTime, intlFormats, outRangeDateTimes, sameMillis } from 'utils/timezone';
import { isElementVisibleInView } from 'utils/domHelper';
// import { setAttr, testBy } from 'utils/constants';
// Components
import './style.scss';




const durationToTimeUnits = (duration, units = ['hours', 'minutes']) => durationFromObject(duration).shiftTo(...units).toObject();

const minutesDurationStepsToTime = (minutes, step) => {
    const intervalCount = ~~(minutes / step);
    return new Array(intervalCount).fill(0)
        .reduce((lst, _, i) => [...lst, durationToTimeUnits({ minutes: step * i })], []);
};

const getTimeIntervals = (step) => {
    const minutes = durationFromObject({ day: 1 }).as('minutes');
    return minutesDurationStepsToTime(minutes, step);
};

const Time = (props) => {
    const { date, onClick, active, format, disabled } = props;

    const handleClick = useCallback(() => {
        if (disabled) return;
        onClick?.(date);
    }, [date, onClick, disabled]);

    const timeFormat = useMemo(() => format?.(date)  ?? formatDateTime(date, intlFormats.TIME_SIMPLE), [date, format]);
    const cs = `${active ? 'selected' : ''} ${disabled ? 'disabled' : ''}`;

    return (
        <div
            className={`panel-item time-item ${cs}`}
            onClick={handleClick}
        >
            {timeFormat}
        </div>
    );
};

const TimeList = forwardRef((props, ref) => {

    const { onClick, format, dateTimes = [], toDate, fromDate, selectedIndex } = props;

    const list = useMemo(() => dateTimes.map((date, i) => {
        const disabled = outRangeDateTimes(date, fromDate, toDate);
        return (
            <Time
                key={+date}
                date={date}
                active={selectedIndex === i}
                onClick={onClick}
                format={format}
                disabled={disabled}
            />
        );
    }), [onClick, dateTimes, format, toDate, fromDate, selectedIndex]);

    return (
        <div className='time-list-picker' ref={ref}>
            {list}
        </div>
    );
});


export const TimePicker = (props) => {

    const listRef = useRef();

    const {
        step = 15,
        selected = getDateTime(),
        onSelect,
        ...rest
    } = props;

    const timeIntervals = useMemo(() => getTimeIntervals(step), [step]);
    const dateTimes = useMemo(() => timeIntervals.map((time) => selected.set(time)), [timeIntervals, selected]);
    const selectedIndex = useMemo(() => dateTimes.findIndex((date) => sameMillis(selected, date)), [dateTimes, selected]);

    useEffect(() => {
        if (selectedIndex === -1) return;
        const listElm = listRef.current;
        const itemElm = listElm?.children?.[selectedIndex];
        const visible = isElementVisibleInView(itemElm, listElm, false, false);
        if (visible || !itemElm) return;
        listElm?.scrollTo?.({
            top: (selectedIndex * itemElm.clientHeight) - ~~(listElm.clientHeight / 2),
            left: 0,
            behavior: selectedIndex ? 'instant' : 'smooth'
        });
    }, [selectedIndex]);

    return (
        <div className='time-panel'>
            <div className='panel-title'>{t('lblTime')}</div>
            <TimeList
                ref={listRef}
                dateTimes={dateTimes}
                onClick={onSelect}
                selectedIndex={selectedIndex}
                {...rest}
            />
        </div>
    );
};
