import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Text } from 'components/common';
import { setAttr, testBy } from 'utils/constants';
import { classAttribute } from 'utils/helper';
import './style.scss';

const ChipBox = ({ className, disabled, id, onClickBox, removeFilter, testAttributes, text }) => {

    const classes = useMemo(() => classAttribute([
        'filter-chip',
        (typeof onClickBox === 'function') ? 'action' : '',
        className ?? '',
        disabled ? 'disabled' : '',
        testBy.CLASS.FILTER_CHIP,
    ]), [className, disabled, onClickBox]);

    const onRemove = useCallback((e) => {
        removeFilter?.(id, e);
    }, [id, removeFilter]);

    return <div className={classes} onClick={onClickBox} {...testAttributes}>
        <Text title={text} text={text} />
        {id ? <span className="icon-remove" onClick={onRemove}></span> : null}
    </div>;
};

const FilterChip = ({ clearFilters, disabled, id, removeFilter, text }) => {
    const othersRef = useRef();

    useEffect(() => {
        if (othersRef.current) {
            othersRef.current.classList.toggle('show', false);
        }
    });

    const onDisplay = useCallback(() => {
        if (othersRef.current) {
            othersRef.current.classList.toggle('show');
        }
    }, []);

    const otherFiltersClasses = useMemo(() => classAttribute([
        'other-filters-container',
        testBy.CLASS.FILTER_OTHER_CHIPS,
    ]), []);

    try {
        const parsed = JSON.parse(id);
        if (Array.isArray(parsed)) {
            return (
                <div>
                    <ChipBox
                        disabled={disabled}
                        onClickBox={onDisplay}
                        testAttributes={setAttr.action(testBy.ACTION.TOGGLE_OTHER_FILTER_CHIPS)}
                        text={`+ ${parsed.length}`}
                    />
                    <div className={otherFiltersClasses} ref={othersRef}>{
                        parsed.map((filter) => {
                            const id = (filter !== null && typeof filter === 'object') ? Object.keys(filter)[0] ?? null : null;
                            if (id !== null) {
                                return (
                                    <FilterChip
                                        key={id}
                                        disabled={disabled}
                                        id={JSON.stringify(id)}
                                        removeFilter={removeFilter}
                                        text={filter[id]}
                                    />
                                );
                            }
                            return null;
                        })
                    }</div>
                </div>
            );
        }
        if (parsed === 0) {
            return <ChipBox
                className="clear-all"
                disabled={disabled}
                onClickBox={clearFilters}
                testAttributes={setAttr.action(testBy.ACTION.CLEAR)}
                text={text}
            />;
        }
        return <ChipBox
            disabled={disabled}
            id={parsed}
            removeFilter={removeFilter}
            text={text}
        />;
    } catch (e) {
        return <ChipBox
            disabled={disabled}
            text={text}
        />;
    }
};

export default FilterChip;
