import React, { useEffect, useState } from 'react';
// Utils
import { getSvgUrl } from 'assets';
import { classAttribute } from 'utils/helper';
import { iconProps } from 'utils/propTypes';
import './style.scss';

const Icon = React.forwardRef((props, ref) => {

    const { name, src, width, height, color, style, viewBox, className, useDefault, title, variant, ...rest } = props;

    const [ url, setUrl ] = useState();

    useEffect(() => {
        if (src) {
            setUrl(src);

            return;
        }

        getSvgUrl(name).then((url) => {
            setUrl(url);
        });
    }, [name, src]);

    const iconClasses = classAttribute([
        'icon-base',
        (typeof props.onClick === 'function') ? 'action-icon' : '',
        className ?? '',
    ]);

    if (url) {
        const anchor = [
            'icon',
            String(variant ?? ''),
        ].filter((str) => str.length > 0).join('-');

        const titleTag = title ? <title>{title}</title> : null;

        return useDefault ? (
            <img
                src={url}
                className={iconClasses}
                style={{
                    width,
                    height,
                    ...style
                }}
                alt={title ?? ''}
                title={title}
                {...rest}
            />
        ) : (
            <svg
                ref={ref}
                className={iconClasses}
                width={width}
                height={height}
                style={{
                    fill: color,
                    ...style
                }}
                {...rest}
            >
                {titleTag}
                <use href={`${url}#${anchor}`} width={width} height={height} />
            </svg>
        );
    }
    return null;
});

Icon.displayName = 'Icon';

Icon.propTypes = iconProps;

export default Icon;
