import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'components/menu/Menu';
import { rangeMsFromPeriod } from 'pages/safety_page/constants';
import { api } from 'services';
import { fetchRealTimeLocation } from 'store/actions/resources';
import { fetchDashboardLists } from 'store/actions/safety';
import { actions } from 'store/slices/safety';
import { getSafetyPeriodState } from 'store/selectors/safety';
import { testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { eventTypes } from 'utils/entities';
import { isValidCoordinate } from 'utils/helper';
import { HALF_MINUTE, allowedFormats, millisToAllowedFormat } from 'utils/timezone';
import './style.scss';

const useModalState = (incidentId, categoryId, resourceId) => {

    // const resourceId = 33045;

    const { THEFT } = eventTypes.categoryIds;

    const eventStates = (() => {
        const data = t(`lblEventCategories.${categoryId}.eventStates`, { returnObjects: true });
        let states = Object.entries(data).map(([k, v]) => ({ id: Number(k), label: v, addDivider: true }));
        states[states.length - 1].addDivider = false;
        return states;
    })();

    // TODO: for violations we default to the second option - this is hacky as it depends on the translation structure
    const defaultStateId = 0;

    const [currentStateId, setCurrentStateId] = useState(defaultStateId);
    const [currentStateObj, setCurrentStateObj] = useState(null);
    const [commentObj, setCommentObj] = useState(null);
    const [isUserChangedState, setIsUserChangedState] = useState(false);
    const dispatch = useDispatch();
    const curPeriod = useSelector(getSafetyPeriodState);

    const needSave = (currentStateObj && currentStateObj.status !== currentStateId) ||
        (!currentStateObj && eventStates.length > 0 && currentStateId > eventStates[0].id);

    // TODO: the meaning of currentStateId should be encapsulated and shared with the translated labels
    const isResolved = useCallback((stateId) => (categoryId === THEFT) && (stateId > 0), [categoryId]);

    const resolved = isResolved(currentStateObj?.status ?? defaultStateId);

    let intervalId = 0;

    const commentObjFromStateObj = (stateObj) => {
        if (stateObj.comment) {
            try {
                return JSON.parse(stateObj.comment);
            } catch (e) { /* TODO: log the error */ }
        }
        return {};
    };

    const commentObjWithLastLocation = (data, index) => {
        if (data[index]) {
            const objFromComment = commentObjFromStateObj(data[index]);
            if (objFromComment.lastLocation) {
                return objFromComment;
            } else {
                return commentObjWithLastLocation(data, index + 1);
            }
        }
        return {};
    };

    const fetchState = async (incidentId, resourceId) => {
        const data = await api.getIncidentState(incidentId, false);
        const stateObj = data[0] ?? undefined;
        if (stateObj && typeof stateObj === 'object') {
            setCurrentStateObj(stateObj);
            if (!isUserChangedState) {
                const newStateId = stateObj.status ?? defaultStateId;
                setCurrentStateId(newStateId);
            }

            let objFromComment = commentObjFromStateObj(stateObj);
            if (categoryId === THEFT) {
                if (stateObj?.status === defaultStateId) {
                    const locationObj = await fetchRealTimeLocation(resourceId);
                    if (locationObj) {
                        objFromComment = { ...objFromComment, lastLocation: locationObj };
                    }
                } else if (!objFromComment.lastLocation) {
                    objFromComment = commentObjWithLastLocation(data, 1);
                }
            }

            setCommentObj(objFromComment);
        }
    };

    useEffect(() => {
        const stopFetching = () => {
            clearInterval(intervalId);
            intervalId = 0;
        };

        fetchState(incidentId, resourceId);

        if (categoryId === THEFT) {
            intervalId = setInterval(() => {
                fetchState(incidentId, resourceId);
            }, HALF_MINUTE);

            if (resolved) {
                stopFetching();
            }
        }

        return stopFetching;
    }, [incidentId, resourceId, resolved, isUserChangedState]);

    const onStateChange = useCallback((e) => {
        setCurrentStateId(e.id);
        setIsUserChangedState(true);
    });

    const stateComponent = useCallback(() => {
        if (currentStateId < 0) return null;

        const currentState = eventStates.find((item) => item.id === currentStateId);
        let setByText = null;
        if (currentStateObj?.date) {
            const dttm = millisToAllowedFormat(currentStateObj.date, allowedFormats.DATE_TIME_LONG);
            const whoOnTime = t('lblSetByOnTime', { userName: currentStateObj.userName, dttm: dttm });
            setByText = <span className="modal-subtitle highlight">{whoOnTime}</span>;
        }

        return (<div className="modal-state-menu">
            <Menu
                buttonClasses={[testBy.CLASS.BUTTON_MODAL_STATE]}
                items={eventStates}
                onClick={onStateChange}
            >
                <span className="modal-state">{currentState?.label}</span>
            </Menu>
            {setByText}
        </div>);
    }, [currentStateId, onStateChange, currentStateObj]);

    const saveState = useCallback(async (lastLocation) => {
        const comment = lastLocation ? JSON.stringify({ lastLocation }) : null;
        const resp = await api.setIncidentState(incidentId, currentStateId, comment);
        if (resp) {
            const rangeMs = rangeMsFromPeriod(curPeriod);
            dispatch(fetchDashboardLists(rangeMs));
            dispatch(actions.setIncidentStatus({ id: incidentId, status: currentStateId }));
        }

    }, [incidentId, currentStateId]);

    const lastLocation = commentObj?.lastLocation;
    if (lastLocation) {
        const objPosition = { lat: lastLocation?.lat, lng: lastLocation?.lng };
        if (!isValidCoordinate(objPosition)) {
            console.log('useModalState lastLocation is invalid: ' + JSON.stringify(objPosition));
        }
    }

    const command = commentObj?.command;
    const hasMessage = Boolean(commentObj?.message);
    return { stateComponent, saveState, needSave, lastLocation, resolved, currentStateId, command, hasMessage };
};

export default useModalState;
