import React from 'react';
import PropTypes from 'utils/propTypes';
// Components
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { testBy } from 'utils/constants';
import './style.scss';
// import { format } from 'date-fns';


// The base date picker.
// Supporting dates adapters (Luxon Datetime)
export const BaseDatePicker = (props) => {

    const { className, adapter, ...rest } = props;

    const adapted = adapter?.adaptProps?.(rest);
    const locale = adapter?.fnsLocale?.();

    return (
        <DayPicker
            className={`pf-date-picker ${className ?? ''}`}
            modifiersClassNames={{
                range_end: `rdp-day_range_end ${testBy.CLASS.DATE_RANGE_END}`,
                range_start: `rdp-day_range_start ${testBy.CLASS.DATE_RANGE_START}`,
                selected: `rdp-day_selected ${testBy.CLASS.DATE_SELECTED}`,
                today: 'date-today'
            }}
            locale={locale}
            {...(adapted ?? rest)}
        />
    );
};

BaseDatePicker.propTypes = {
    className: PropTypes.string,
    adapter: PropTypes.func,
};