import { createSlice } from '@reduxjs/toolkit';
import { reducerNames } from 'store/constants';
import { dataKeys } from 'utils/entities/driver';
import { LOGOUT_USER } from 'store/actionTypes';
import { getFilterActionMatcher, getFiltersInitialState, getFiltersReducers } from 'store/common/filters';
import { filterSchema, filterType } from 'components/filters/filter_schema';
import { filterKeys } from 'utils/entities';
import { defaultPeriodOptionDriver } from 'pages/safety_page/constants';

const filters = filterSchema([
    filterType(filterKeys.DRIVERS),
    filterType(filterKeys.RESOURCES),
]);

const initialState = {
    period: defaultPeriodOptionDriver,
    fetching: {},
    ...getFiltersInitialState(filters, dataKeys.name),
    searchParams: null,
    searchValue: '',
    paging: {
        pageNumber: 1,
        maxCount: 100,
        filteredRows: undefined,
        totalRows: undefined
    },
    items: [],
    kpis: []
};


const slice = createSlice({
    name: reducerNames.SAFETY_DRIVERS,
    initialState,
    reducers: {
        resetState: () => initialState,
        clearState: (state, action) => {
            // Like resetting the state except of state that still need to keep according to requirements.
            const copy = { ...initialState };
            delete copy.filtersManager;
            delete copy.params;
            delete copy.searchParams;
            delete copy.searchValue;
            return { ...state, ...copy };
        },
        setFetching: (state, action) => {
            state.fetching = { ...state.fetching, ...action.payload };
        },
        setItems: (state, action) => {
            state.items = action.payload;
        },
        addItems: (state, action) => {
            state.items = state.items.concat(action.payload);
        },
        updateItemsImages: (state, action) => {
            let drivers = [ ...state.items ];
            const driverImages = action.payload;
            drivers.forEach((d) => {
                const driverImage = driverImages.find((img) => img.id === d.imageUrl);
                if (driverImage) {
                    d.image = driverImage.url;
                }
            });

            state.items = drivers;
        },
        updateParams: (state, action) => {
            const { params } = state;
            if (!action.payload) return state;
            state.params = { ...params, ...action.payload };
        },
        setParams: (state, action) => {
            state.params = action.payload;
        },
        setSearchParams: (state, action) => {
            state.searchParams = action.payload;
        },
        setSearchValue: (state, action) => {
            state.searchValue = action.payload;
        },
        nextPaging: (state) => {
            state.paging.pageNumber++;
        },
        setPagingDataInfo: (state, action) => {
            const { totalRows, filteredRows } = action.payload ?? {};
            state.paging.totalRows = totalRows;
            state.paging.filteredRows = filteredRows;
        },
        resetPaging: (state) => {
            state.paging = initialState.paging;
        },
        setKpis: (state, action) => {
            state.kpis = action.payload;
        },
        setPeriod: (state, action) => {
            const { period } = state;
            state.period = { ...period, ...action.payload };
        },
        ...getFiltersReducers(initialState)
    },
    extraReducers: (builder) => {
        builder.addCase(LOGOUT_USER, (state, action) => initialState);
    }
});


export default slice.reducer;
export const { name, actions, getInitialState } = slice;
export const filterActionMatcher = getFilterActionMatcher(slice.actions);
