import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Utils
import { dataKeys } from 'utils/entities';
import { t } from 'utils/dictionary';
import { iconsName } from 'assets';
import { rangeMsFromPeriod } from 'pages/safety_page/constants';
import { modalsIds } from 'components/modal/ModalsConstants';
import { setAttr, testBy } from 'utils/constants';
// Redux
import { actions as dashboardActions } from 'store/slices/dashboard';
import { getDashboardWidgets } from 'store/selectors/dashboard';
import actions, { fetchWidget, postManualEvent } from 'store/actions/safety';
import { getIncidentTypes } from 'store/selectors/incidentTypes';
import { openModal } from 'store/actions/popup';
import { getFetchingState } from 'store/selectors/safety';
// Components
import { IncidentsWidget } from 'pages/safety_page/features';
import { CreateManualEvent } from 'components';
import iframeMessage from 'utils/iframe_message/IframeMessage';




const IncidentsView = (props) => {

    const dispatch = useDispatch();

    const {
        style,
        classNames = [],
        period,
    } = props;

    const widgets = useSelector(getDashboardWidgets);
    const incidentTypes = useSelector(getIncidentTypes);
    const fetching = useSelector(getFetchingState);

    const [showCreateEvent, setShowCreateEvent] = useState(undefined);


    const onClickVideo = useCallback(({ widget, item }) => {
        dispatch(openModal({ id: modalsIds.VIDEO_PLAYER_MODAL, data: item }));
    }, []);

    const onClickItem = useCallback(({ widget, item }) => {
        dispatch(openModal({ data: item }));
    }, []);

    const onClickSeeMore = useCallback(({ widget }) => {
        const ids = widget.params.categories?.split(',').map((v) => Number(v));
        dispatch(actions.forceFilterCategoriesSelections({ ids, data: incidentTypes }));
        
        iframeMessage.isChild = true;
        let target = window.location.origin;
        iframeMessage.send({ action: 'seeMoreIncidents_msg'}, '*');
    }, [incidentTypes]);

    const setWidgetSort = useCallback((data) => {
        const { widgetId, sort, isDesc } = data;
        dispatch(dashboardActions.updateWidgetParams({ id: widgetId, data: { sort, isDesc }}));
        dispatch(fetchWidget(widgetId, rangeMsFromPeriod(period)));
    }, [period]);

    const onClickSortBy = useCallback((data) => {
        const { widgetId, sort } = data;
        const { params } = widgets.find((w) => w.id === widgetId);
        const sortKey = sort ?? params.sort;
        const isDesc = (params.sort === sortKey) ? !params.isDesc : params.isDesc;
        setWidgetSort({ isDesc, sort: sortKey, widgetId });
    }, [widgets, setWidgetSort]);

    const onClickCreateEventTrigger = useCallback(() => {
        setShowCreateEvent(undefined);
    }, []);

    const onFinishPostManualEvent = useCallback(() => {
        setShowCreateEvent(false);
    }, []);

    const onSave = useCallback((data) => {
        const { driver, resource, date, location } = data ?? {};
        const params = {
            resourceId: resource?.id,
            driverId: driver?.id,
            eventTime: date?.toMillis(),
            ...location
        };
        dispatch(postManualEvent(params, onFinishPostManualEvent));
    }, [onFinishPostManualEvent]);

    const onCancel = useCallback(() => {
        setShowCreateEvent(false);
    }, []);

    const getActionsBar = useCallback((widgetId) => {
        const { params } = widgets.find((w) => w.id === widgetId);

        const createEvent = {
            widgetId,
            onClick: onClickCreateEventTrigger,
            icon: {
                name: iconsName.ADD,
                title: t('lblManualEvent'),
                className: 'create-event-btn',
                ...setAttr.action(testBy.ACTION.TOGGLE_CREATE_EVENT)
            },
            popover: {
                content: <CreateManualEvent onSave={onSave} onCancel={onCancel} isLoading={fetching.postManualEvent} />,
                className: 'create-manual-event',
                ...setAttr.feature(testBy.FEATURE.CREATE_EVENT_POPUP)
            },
            overlayProps: {
                rootClose: true,
                show: showCreateEvent
            }
        };

        const actionsBar = [
            {
                widgetId,
                sort: dataKeys.eventTime,
                icon: {
                    name: iconsName.TIME,
                    title: t('lblSortByKey', { by: t('lblTime') }),
                    className: params.sort === dataKeys.eventTime ? 'active' : '',
                    ...setAttr.action(testBy.ACTION.SORT_BY_TIME)
                },
                onClick: onClickSortBy
            },
            {
                widgetId,
                sort: dataKeys.severity,
                icon: {
                    name: iconsName.GAUGE,
                    title: t('lblSortByKey', { by: t('lblSeverity') }),
                    className: params.sort === dataKeys.severity ? 'active' : '',
                    ...setAttr.action(testBy.ACTION.SORT_BY_SEVERITY)
                },
                onClick: onClickSortBy
            },
            {
                widgetId,
                icon: {
                    name: iconsName.ARROW_FULL,
                    height: 18,
                    width: 8,
                    title: t('lblSortOrder', { order: !params?.isDesc ? t('lblAscending') : t('lblDescending') }),
                    className: `sort-icon ${!params?.isDesc ? 'ascending' : ''}`,
                    ...setAttr.action(testBy.ACTION.TOGGLE_SORT_ORDER)
                },
                onClick: onClickSortBy
            }
        ];

        return widgetId === 1 ? [createEvent, ...actionsBar] : actionsBar;

    }, [widgets, onClickSortBy, showCreateEvent, onSave, onCancel, onClickCreateEventTrigger, fetching.postManualEvent]);

    const widgetsUI = useMemo(() => widgets.map((w) => {
        const { id } = w;
        return (
            <IncidentsWidget
                key={id}
                widget={w}
                actionsBar={getActionsBar(id)}
                onClickSeeMore={onClickSeeMore}
                onClickItem={onClickItem}
                onClickVideo={onClickVideo}
            />
        );
    }), [onClickItem, onClickSeeMore, onClickVideo, widgets, getActionsBar]);


    return (
        <div className='incidents-view-container'>
            {widgetsUI}
        </div>
    );
};


export default IncidentsView;
