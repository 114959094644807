export const reducerNames = {
    DASHBOARD: 'dashboard',
    DRIVERS: 'drivers',
    INCIDENT_TYPES: 'incidentTypes',
    INSTALLER: 'installer',
    POPUP: 'popup',
    PROVISION: 'provision',
    RESOURCES: 'resources',
    SAFETY: 'safety',
    SAFETY_DRIVERS: 'safetyDrivers',
    SETTINGS: 'settings',
    THEME: 'theme',
    USER: 'user'
};