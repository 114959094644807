import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
// Utils
import { isElementVisibleInView } from 'utils/domHelper';
import { setAttr, testBy } from 'utils/constants';
// Components
import { Collapse, Spinner } from 'react-bootstrap';
import { TableBase, TableHeader } from 'components';
import { Header, HeaderMultiSortKeys } from './Header';
import './style.scss';



const LoadingSpinner = (props) => {

    const timerId = useRef();

    const { loading, onEnter, onExited, debounce } = props;
    const [spinner, setSpinner] = useState(false);

    useEffect(() => () => {
        if (timerId.current) {
            clearTimeout(timerId.current);
        }
    }, []);

    useEffect(() => {
        if (loading && !timerId.current) {
            setSpinner(true);
            timerId.current = setTimeout(() => {
                setSpinner(false);
                timerId.current = undefined;
            }, debounce);
        }
    }, [loading, spinner, debounce]);

    return (
        <Collapse
            in={loading || spinner}
            onEnter={onEnter}
            onExited={onExited}
        >
            <div className="collapse-area">
                <Spinner animation="grow" variant="success" />
            </div>
        </Collapse>
    );
};

export const Table = (props) => {

    const loadingSpinner = useRef(false);
    const {
        items,
        sort,
        onClickSort,
        onLoadMore,
        isLoading,
        renderCell,
        onClickRow,
        selectedId,
        columns,
        className,
    } = props;

    const handleClickSort = useCallback((header) => {
        onClickSort?.(header);
    }, [onClickSort]);

    const onWheel = useCallback((e, listRef, listWrapperElm) => {
        const childElm = listWrapperElm.getElementsByClassName(`recycle-table-row row-${items.length - 1}`)[0];
        if (!onLoadMore || loadingSpinner.current || !childElm) return;
        const visible = isElementVisibleInView(childElm, listWrapperElm, false, false);
        const isScrollingDown = e.deltaY > 0;
        if (isScrollingDown && visible) {
            // loadingSpinner.current = true;
            onLoadMore?.();
        }
    }, [items.length, onLoadMore]);

    const onStartLoadingSpinner = useCallback(() => {
        loadingSpinner.current = true;
    }, []);

    const onEndLoadingSpinner = useCallback(() => {
        loadingSpinner.current = false;
    }, []);

    const headers = useMemo(() => {
        const hdrs = columns.map((column, i) => {
            const { id, dataKey, width, multiSortKeys, style, sortKey } = column;
            return (
                <TableHeader
                    key={id ?? dataKey ?? i}
                    id={id}
                    dataKey={dataKey}
                    width={width}
                    style={style}
                >
                    {
                        multiSortKeys?.length ? (
                            <HeaderMultiSortKeys
                                onClickSort={handleClickSort}
                                sort={sort}
                                column={column}
                            />
                        ) : (
                            <Header
                                onClickSort={sortKey ? handleClickSort : undefined}
                                sort={sort}
                                column={column}
                            />
                        )
                    }

                </TableHeader>
            );
        });

        return hdrs;
    }, [columns, handleClickSort, sort]);

    return (
        <div
            className={`table-container ${className ?? ''}`}
            {...setAttr.feature(testBy.FEATURE.TABLE)}
        >
            <TableBase
                className='custom-table'
                renderCell={renderCell}
                items={items}
                rowHeight={78}
                offScreenRow={10}
                onClickRow={onClickRow}
                selectedId={selectedId}
                onWheel={onWheel}
            >
                {headers}
            </TableBase>
            <div className='table-bottom-bar'>
                <LoadingSpinner
                    loading={isLoading}
                    onEnter={onStartLoadingSpinner}
                    onExited={onEndLoadingSpinner}
                    debounce={1000}
                />
            </div>
        </div>
    );
};
