import React, { useMemo } from 'react';
// Utils
import PropTypes, { propTypes } from 'utils/propTypes';
// Components
import IncidentCard from 'pages/safety_page/features/incidents_widget/IncidentCard';
import './style.scss';




const IncidentsList = (props) => {
    const {
        classes = [],
        items = [],
        onClickItem,
        onClickVideo,
    } = props;


    const list = useMemo(() => {
        const lst = items.map((item) => (
            <IncidentCard
                key={item.id}
                onClick={onClickItem}
                onClickVideo={onClickVideo}
                item={item}
            />
        ));
        return lst;
    }, [items, onClickItem, onClickVideo]);

    return (
        <div className='incidents-list'>
            <div className='incidents-list-inner'>
                {list}
            </div>
        </div>
    );
};


IncidentsList.propTypes = {
    items: PropTypes.arrayOf(propTypes.incident),
    onClickItem: PropTypes.func,
    onClickVideo: PropTypes.func
};

export default IncidentsList;
