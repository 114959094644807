import React from 'react';
import { PageContainer } from 'layout/containers';
import { Text } from 'components/common';
import { iconsName } from 'assets';
import { IconBox } from 'pages/playground/icons-page/IconBox';
import { ColorsView } from 'pages/playground/icons-page/ColorsView';
import './style.scss';





const CheckBox = (props) => {
    const { label, checked, onChange, name } = props;
    return (
        <div className='checkbox-label'>
            <span>{label}</span>
            <input type='checkbox' onChange={onChange} checked={checked} name={name} />
        </div>
    );
};

const getIconNamesList = () => Object.values(iconsName);


export class IconsPage extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            fillColor: '#B0171F',
            strokeColor: '#B0171F',
            fill: true,
            stroke: false
        };
    }

    onClickFillColor = (fillColor) => {
        this.setState({ fillColor });
    };

    onClickStrokeColor = (strokeColor) => {
        this.setState({ strokeColor });
    };

    onToggleColor = (e) => {
        const { name, checked } = e.target;
        this.setState({ [name]: checked });
    };

    renderIcons = () => {
        const { strokeColor, fillColor, fill, stroke } = this.state;
        const iconsBox = getIconNamesList().map((name, i) => (
            <IconBox
                key={name}
                name={name}
                index={i + 1}
                icon={{
                    name,
                    width: 24,
                    height: 24,
                    stroke: stroke ? strokeColor : 'none',
                    fill: fill ? fillColor : 'none'
                }}
            />
        ));
        return iconsBox;
    };

    render() {

        const { fillColor, strokeColor, fill, stroke } = this.state;

        const count = getIconNamesList().length;

        return (
            <PageContainer
                classes={['icons-page']}
            >
                <Text text={`Icons ${count}`} />
                <div className='icons-panel'>
                    <div className='icons-wrapper'>
                        {this.renderIcons()}
                    </div>
                    <div className='icons-setting'>
                        <CheckBox
                            label='Fill'
                            name='fill'
                            onChange={this.onToggleColor}
                            checked={fill}
                        />
                        <div>
                            <ColorsView
                                onClick={this.onClickFillColor}
                                selected={fillColor}
                            />
                        </div>
                        <CheckBox
                            label='Stroke'
                            name='stroke'
                            onChange={this.onToggleColor}
                            checked={stroke}
                        />
                        <div>
                            <ColorsView
                                onClick={this.onClickStrokeColor}
                                selected={strokeColor}
                            />
                        </div>
                    </div>
                </div>
            </PageContainer>
        );
    }
}
