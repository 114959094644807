import { useCallback, useState } from 'react';
import PropTypes from 'utils/propTypes';
// Utils
import { iconsName } from 'assets';
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
// Components
import { Icon } from 'components/common';
import { Collapse } from 'react-bootstrap';
import './style.scss';


const { UI, ACTION } = testBy;



const CollapseTrigger = (props) => {
    const { open, className, onClick } = props;

    const handleClick = useCallback(() => {
        onClick?.(open);
    }, [onClick, open]);

    const testAttributes = setAttr.action(ACTION.TOGGLE_COLLAPSIBLE_AREA);

    return (
        <div
            className={`collapse-trigger ${className ?? ''}`}
            onClick={handleClick}
            {...testAttributes}
        >
            <Icon
                name={open ? iconsName.EXPAND_LESS : iconsName.EXPAND_MORE}
                useDefault
                title={open ? t('lblCollapse') : t('lblExpand')}
            />
        </div>
    );
};

CollapseTrigger.propTypes = {
    open: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func
};


const Collapsible = (props) => {

    const { className, children, defOpen } = props;

    const [open, setOpen] = useState(defOpen ?? true);

    const onClickCollapse = useCallback((open) => {
        setOpen(!open);
    }, []);

    return (
        <div
            className={`collapsible-container ${className ?? ''}`}
            {...setAttr.ui(UI.COLLAPSIBLE)}
        >
            <Collapse
                in={open}
            // appear
            >
                <div
                    className="collapse-area"
                    {...setAttr.ui(UI.COLLAPSIBLE_AREA)}
                >
                    {children}
                </div>
            </Collapse>
            <div
                className='collapse-bar'
            >
                <CollapseTrigger
                    open={open}
                    onClick={onClickCollapse}
                />
            </div>
        </div>
    );

};


Collapsible.propTypes = {
    defOpen: PropTypes.bool,
    open: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string
};

export default Collapsible;
