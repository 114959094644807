import { useCallback } from 'react';
// Utils
import { iconsName } from 'assets';
import { t } from 'utils/dictionary';
import { trends, severities } from 'utils/entities';
// Components
import { Icon, Text } from 'components/common';
import { Card } from 'layout/containers';
import './style.scss';



const SeverityCount = (props) => {
    const { level, count, onClick, selectedSeverities } = props;

    const severiry = severities[level];

    const onClickHandler = useCallback((e) => {
        e.stopPropagation();
        onClick?.(level);
    }, [onClick, level]);

    const className = `severity-count level${level ?? ''}${selectedSeverities?.length > 0 && !selectedSeverities.includes(level) ? ' not-selected' : ''}`;

    return (
        <div onClick={onClickHandler} title={`${t('lblSeverity')} ${t(severiry.lblKey)}`} className={className}>
            {count}
        </div>
    );
};

export const SeverityKpi = (props) => {

    const {
        id,
        value,
        name,
        severities = [],
        trend,
        onCardClick,
        onSeverityClick,
        selectedCategories,
        selectedSeverities
    } = props;

    const trendInfo = trends[trend];

    const hanldCardClick = useCallback(() => {
        onCardClick?.(id);
    }, [onCardClick, id]);

    const hanldSeverityClick = useCallback((severiry) => {
        onSeverityClick?.(id, severiry);
    }, [onSeverityClick, id]);

    const cardClassName = `severity-kpi-card-container${selectedCategories?.length > 0 && !selectedCategories.includes(id) ? ' not-selected' : ''}`;

    return (
        <Card className={cardClassName} onClick={hanldCardClick}>
            <div className='card-left'>
                <div className='kpi-value-wrapper'>
                    <Text text={value} className='kpi-value' />
                    {
                        trendInfo?.direction ? (
                            <Icon
                                className={trendInfo?.direction}
                                title={t(trendInfo?.lblKey)}
                                name={iconsName.ARROW_FULL}
                                width={24}
                                height={28}
                            />
                        ) : null
                    }
                </div>
                <Text className='kpi-name' text={name} />
            </div>
            <div className='card-right'>
                {severities.map((s) => (
                    <SeverityCount key={s.level} level={s.level} count={s.count} onClick={hanldSeverityClick} selectedSeverities={selectedSeverities} />
                ))}
            </div>
        </Card>
    );
};

export const SeverityKpis = ({ kpis, onCardClick, onSeverityClick, selectedCategories, selectedSeverities }) => {

    const items = kpis.map((item, i) => (
        <SeverityKpi
            key={item.id ?? item.name ?? i}
            onCardClick={onCardClick}
            onSeverityClick={onSeverityClick}
            selectedCategories={selectedCategories}
            selectedSeverities={selectedSeverities}
            {...item}
        />
    ));

    return (
        <div className='severity-kpis-container'>
            {items}
        </div>
    );
};
