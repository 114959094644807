import { eventTypes } from 'utils/entities';


const { COLLISION, VIOLATION, THEFT } = eventTypes;


export const getLocationField = (location) => {
    const { lat, lng } = location ?? {};
    if (lat === undefined || lng === undefined)
        return '';
    return `Lat: ${lat?.toFixed(3)}, Lng: ${lng?.toFixed(3)}`;
};


export const convertDetailsJson = (json, type) => {
    if (!json) return json;
    const unit = { name: 'km/h', formula: (val) => val };
    const data = JSON.parse(json);
    // Return the template from dictionary base on event type
    switch (type) {
        case COLLISION:
        case VIOLATION:
        case THEFT:
            return speedViolationTemp(data, unit);
        default:
            return json;
    }
};

export const speedViolationTemp = (data, unit = {}) => {
    const { speed, maxspeed, Speed, MaxSpeed } = data;
    const speedVal = speed ?? Speed;
    const maxSpeedVal = maxspeed ?? MaxSpeed;

    const { name, formula } = unit;
    const temp = 'Overspeeding';
    if (speedVal === undefined || maxSpeedVal === undefined)
        return temp;

    return `${temp} by ${formula(speedVal - maxSpeedVal)} ${name}`;
};