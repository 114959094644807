import { useMemo } from 'react';
import { useSpeedUnit } from 'hooks/measurements';
import { pf_t } from 'utils/dictionary';

export const useOverSpeedDescription = (json) => {

    const mSpeed = useSpeedUnit();

    return useMemo(() => {
        let description = '';
        let error = null;
        try {
            const osData = JSON.parse(json);
            const speed = parseInt(osData.Speed ?? 0, 10);
            if (speed > 0) {
                const speedLimit = parseInt(osData.RSSSpeedLimit ?? (osData.MaxSpeedAllowed ?? 0), 10);
                if (speedLimit > 0) {
                    const overSpeed = speed - speedLimit;
                    if (overSpeed > 0) {
                        description = pf_t.eventDescriptions.overSpeed(mSpeed.f(speed), mSpeed.f(speedLimit), mSpeed.f(overSpeed));
                        const delta = parseInt(osData.MaxSpeedViolationDelta ?? 0, 10);
                        if (delta > 0 && overSpeed !== delta) {
                            error = new Error(`overSpeed data has ${delta} delta which differs from calculated ${overSpeed} delta`);
                        }
                        return { description, error };
                    }
                }
                description = pf_t.eventDescriptions.overSpeed(mSpeed.f(speed));
                return { description, error };
            }
        } catch (e) {
            description = pf_t.eventDescriptions.overSpeed();
            error = e;
        }
        return { description, error };
    }, [json, mSpeed]);
};
