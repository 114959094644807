import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT_USER } from 'store/actionTypes';
import { reducerNames } from 'store/constants';


const initialState = {
    drivers: [],
    driverDetails: {},
    driverScore: {},
    driverMileage: {}
};


const slice = createSlice({
    name: reducerNames.DRIVERS,
    initialState,
    reducers: {
        setDrivers: (state, action) => {
            state.drivers = action.payload;
        },
        setDriverDetails: (state, action) => {
            state.driverDetails = action.payload;
        },
        updateDriverImage: (state, action) => {
            let driver = { ...state.driverDetails };
            const driverImages = action.payload;
            const driverImage = driverImages.find((img) => img.id === driver.imageUrl);
            if (driverImage) {
                driver.image = driverImage.url;
            }

            state.driverDetails = driver;
        },
        clearDriverDetails: (state, action) => {
            state.driverDetails = {};
        },
        setDriverScore: (state, action) => {
            state.driverScore = action.payload;
        },
        setDriverMileage: (state, action) => {
            state.driverMileage = action.payload;
        },
        clearDriverScore: (state, action) => {
            state.driverScore = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(LOGOUT_USER, (state, action) => initialState);
    }
});


export default slice.reducer;

export const { name, getInitialState, actions } = slice;