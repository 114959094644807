import { useCallback } from 'react';
// redux
import { useDispatch } from 'react-redux';
import { setRightPane, setSearchValue, setSelectedWo } from 'store/actions/installer';
// utils & constants
import { t } from 'utils/dictionary';
import { paneIds } from 'pages/installer_page/constants';
// components
import { Button, SearchInput } from 'components/common';

import './style.scss';

export const WoActionsBar = ({ search }) => {
    const dispatch = useDispatch();

    const onSearch = useCallback((searchObj) => {
        dispatch(setSearchValue(searchObj.value));
    }, [dispatch]);

    const onClearSearch = useCallback(() => {
        dispatch(setSearchValue(''));
    }, [dispatch]);

    const onClickNew = useCallback(() => {
        dispatch(setRightPane(paneIds.CREATE_WO));
        dispatch(setSelectedWo(null));
    }, [dispatch]);

    return (
        <div className="wo-actions-bar">
            <SearchInput
                name={'selectedFilter.name'}
                value={search}
                className={'wo-search'}
                placeholder={`${t('lblWoName')}, ${t('lblInstaller')}`}
                onClickClear={onClearSearch}
                onChange={onSearch}
                autoFocus
            />
            <Button
                label={t('lblNew')}
                onClick={onClickNew}
                classes={['button-v1']}
            />
        </div>
    );
};
