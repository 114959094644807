import { api } from 'services';
import { APIS } from 'utils/constants';
import { parseJwt, objToFirstLowerCase } from 'utils/helper';
import { getDateTime } from 'utils/timezone';
import { measureSystemAdapter } from 'utils/helpers';
import { actions } from 'store/slices/user';
import { LOGOUT_USER } from 'store/actionTypes';


export default actions;

const loginSetup = (token) => {
    const claims = objToFirstLowerCase(parseJwt(token) ?? {});
    const millisExp = claims.exp * 1000;
    const millisNow = getDateTime().toMillis();
    const isOk = millisNow < millisExp;
    if (isOk) {
        api.setToken(token);
        sessionStorage.setItem('token', token);
    }
    return isOk;
};

const logoutSetup = () => {
    api.removeToken();
    sessionStorage.removeItem('token');
};

export const reAuthenticate = () => {
    const token = sessionStorage.getItem('token');
    return loginSetup(token) ? token : undefined;
};

// login user rename
export const loginUser = ({ username, password, langId = 1 },tokenApiUrl) => async (dispatch, getState, extra) => {
    let token;
    if(tokenApiUrl==='_RAW_TOKEN_') {
        token = password;
        token = token.replace('Bearer ', '');    //Allow pasting it with or without the Bearer header
        try {
            parseJwt(token);
        }
        catch(e) {
            alert('Invalid JWT token in password field');
        }
    }
    else {
        const { data } = await fetchToken({ username, password, langId, tokenApiUrl });
        token = data?.token;
    }
    dispatch(authUserProcess(token));
};

export const authUserProcess = (token) => async (dispatch, getState, extra) => {
    if (token) {
        const isOk = loginSetup(token);
        if (isOk) {
            dispatch(fetchUserDefinition());
        }
    }
};

export const unAuthUser = () => {
    logoutSetup();
    return { type: LOGOUT_USER };
};

export const fetchUserDefinition = () => async (dispatch) => {
    dispatch(actions.setFetching({ name: 'definition', isFetching: true }));
    const { data } = await api.get(`${APIS.CONFIGURATIONS.DEFINITION}`);
    const definition = data?.data;
    if (definition) {
        const containerID = definition.site?.googleTagManagerKey;
        window.initGoogleTagManager(window, document, 'script', 'dataLayer', containerID);

        const measureSystem = measureSystemAdapter(definition.measureSystem);
        dispatch(actions.authUserSuccess({ ...definition, measureSystem }));
    }
    dispatch(actions.setFetching({ name: 'definition', isFetching: false }));
};

export const fetchToken = ({ username, password, langId, tokenApiUrl }) => api.post(tokenApiUrl, { username, password, langId });
