import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateTime, Settings } from 'luxon';
// Utils
import { getFixedOffsetUtc } from 'utils/timezone';
import { locales } from 'utils/dictionary';
// Selectors
import { getUserLocaleState } from 'store/selectors/user';



export class TimeZone extends React.PureComponent {

    static defaultLocale = Settings.defaultLocale;
    static defaultZone = Settings.defaultZone;

    constructor(props) {
        super(props);
        const { locale } = props;
        if (locale?.tz) {
            this.setUserLocale(locale);
        } else if (!Settings.defaultLocale) {
            Settings.defaultLocale = locales.enUS.locale;
        }
    }

    componentDidUpdate(prev) {
        const { locale } = this.props;
        if (locale && (locale !== prev.locale)) {
            this.setUserLocale(locale);
        }
    }

    componentWillUnmount() {
        const localInfo = {
            tz: 'local',
            locale: TimeZone.defaultLocale,
        };
        this.setUserLocale(localInfo);
    }

    setUserLocale = ({ tz, offset, dst = true, locale }) => {
        Settings.defaultLocale = locale?.toLowerCase();
        if (dst) {
            Settings.defaultZone = tz;
        }
        // If DST false or if the System not support the zone name we should use fixed offset
        const { isValid } = DateTime.local();
        if (!dst || !isValid) {
            Settings.defaultZone = getFixedOffsetUtc(offset).offsetFormat;
            if (!isValid) {
                console.log('tz not valid', { dst, offset, tz, defaultZone: Settings.defaultZone });
            }
        }
    };


    render() {

        const { children } = this.props;

        return (
            children
        );
    }
}



TimeZone.propTypes = {
    children: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
};

const mapStateToProps = (state) => ({
    locale: getUserLocaleState(state)
});

export const TimeZoneProvider = connect(mapStateToProps, null)(TimeZone);
