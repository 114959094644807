import React, { useMemo } from 'react';
import { iconsName } from 'assets';
import { Icon, Icons } from 'components/common';
import { getEventIcon } from 'utils/entities';
import { pf_t } from 'utils/dictionary';
import './style.scss';


const IncidentIcon = ({ width, height, severity, type }) => {

    const renderMainIcon = useMemo(() => {
        let attributes = {
            className: 'main-icon',
            width,
            height,
            title: pf_t.eventName(type)
        };
        if (type) {
            const icon = getEventIcon(type);
            attributes = { ...attributes, ...icon };
        } else {
            attributes = { ...attributes, name: iconsName.CAR_CHECKLIST, color: '#343434' };
        }
        return <Icon {...attributes} />;
    }, [height, type, width]);

    return (
        <div className="incident-icon-wrapper">
            {renderMainIcon}
            <Icons.Severity
                severity={severity}
            />
        </div>
    );
};

export default IncidentIcon;
