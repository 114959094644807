// import * as types from 'store/actionsTypes';
import { modalsIds, modalIdForCategory } from 'components/modal/ModalsConstants';
import { actions } from 'store/slices/popups';
import { actions as driversActions } from 'store/slices/drivers';
import { actions as resourceActions } from 'store/slices/resources';
import { actions as safetyActions } from 'store/slices/safety';


export const openModal = (payload) => async (dispatch) => {
    const id = payload.id ?? modalIdForCategory(payload.data?.categoryId);
    dispatch(actions.openModal({ ...payload, id }));
};

export const closeModal = (payload) => async (dispatch) => {
    dispatch(actions.closeModal(payload));
    if ([modalsIds.VIOLATION_MODAL, modalsIds.COLLISION_MODAL, modalsIds.THEFT_MODAL].includes(payload)) {
        dispatch(driversActions.clearDriverDetails());
        dispatch(driversActions.clearDriverScore());
        dispatch(resourceActions.clearResourceDetails());
    }
    if ([modalsIds.COLLISION_MODAL, modalsIds.VIDEO_PLAYER_MODAL].includes(payload)) {
        dispatch(safetyActions.clearVideoModalData());
    }
};

export default actions;
