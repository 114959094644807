import { useMemo } from 'react';
// Components
import { Text } from 'components/common';
import { testBy } from 'utils/constants';
import { classAttribute } from 'utils/helper';
import './style.scss';


export const KpiCard1 = ({ className, label, value, testAttributes }) => {

    const classes = useMemo(() => classAttribute([
        'kpi-v3',
        className ?? '',
    ]), [className]);

    return <div
        className={classes}
        {...testAttributes}
    >
        <Text className={`title ${testBy.CLASS.CARD_TITLE}`} text={label} title={label} />
        <Text className={`value ${testBy.CLASS.CARD_VALUE}`} text={value} title={value} />
    </div>;
};
