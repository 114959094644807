import { svgStringWithAttributes } from 'utils/domHelper';

const preloadSVGs = {
    ARROW_IN_CIRCLE: 'arrow_in_circle',
    FINISH_FLAG: 'finish_flag',
    PIN: 'pin',
    THEFT_FINISHED: 'theft_finished',
};

export const iconsName = {
    ...preloadSVGs,
    ADD: 'add',
    ADD_IMAGE: 'add_image',
    ADD_LOCATION: 'add_location',
    ARROW_FULL: 'arrow_full',
    BREAD_CRUMB_SEP: 'bread_crumb_sep',
    CALENDAR: 'calendar',
    CANCEL: 'cancel',
    CAR_CHECKLIST: 'car_checklist',
    CHAT: 'chat',
    CODE_BLOCK: 'code_block',
    COMMAND_ACK: 'command_ack',
    COMMAND_FAILED: 'command_failed',
    COMMAND_SENT: 'command_sent',
    DELETE: 'delete',
    DOWNLOAD_ARROW: 'download_arrow',
    DOWNLOAD: 'download',
    DROPDOWN_ARROW: 'dropdown_arrow',
    ECOLOGY_GLOBE_LEAF: 'ecology-globe-leaf',
    EXPAND: 'expand',
    EXPAND_LESS: 'expand_less',
    EXPAND_MORE: 'expand_more',
    FAVORITE: 'favorite',
    FINGER_PRINT: 'fingerprint',
    GAUGE: 'gauge',
    GRAPH_DESKTOP: 'graph_desktop',
    HIGH_DEF: 'high_def',
    INFO: 'info',
    LANGUAGE: 'language',
    LIST: 'list',
    MINIMIZE: 'minimize',
    MUTED: 'muted',
    NAV_ARROW_L: 'nav_arrow_left',
    NAV_ARROW_R: 'nav_arrow_right',
    NO_THUMBNAIL: 'no-thumbnail',
    NOTES: 'notes',
    NOTIFICATION: 'notification',
    PAUSE: 'pause',
    PDF_PRINT: 'pdf_print',
    PERSON: 'person',
    PF_LOGO_VERTICAL: 'pf_logo_vertical',
    PLAY: 'play',
    SEARCH: 'search',
    SEARCH_BOLDER: 'search_bolder',
    SEND: 'send',
    SETTINGS: 'settings',
    SEVERITY: 'severity',
    SHARE: 'share',
    SMALL_CAR: 'small_car',
    SNAPSHOT: 'snapshot',
    STEP_1: 'step_1',
    STEP_2: 'step_2',
    STEP_3: 'step_3',
    STEP_DONE: 'step_done',
    STEERING_WHEEL: 'steering_wheel',
    THREE_DOTS: 'three_dots',
    TIME: 'time',
    TOGGLE_OFF: 'toggle_off',
    TOOGLE_ON: 'toggle_on',
    TRIP_ROUTE: 'trip_route',
    TYPE_UNKNOWN: 'type_unknown',
    UNDERLINE_ARROW: 'underline_arrow',
    USER_AVATAR: 'user_avatar',
    VIDEO_DISABLED: 'video_disabled',
    VIDEO_ENABLED: 'video_enabled',
    VIDEO_PLAY: 'video_play',
    VOLUME: 'volume',
    WEB_API: 'web_api',
    // streamlinehq incident types
    ALERT_BELL: 'streamlinehq/alert-bell-notification-1',
    CAMERA_BLOCK: 'streamlinehq/technology-camera-access-block',
    CAMERA_VIBRATE: 'streamlinehq/camera-settings-frame-1',
    CHARGER: 'streamlinehq/charger',
    CIRCLE_LEFT: 'streamlinehq/navigation-left-circle-2',
    CIRCLE_RIGHT: 'streamlinehq/navigation-right-circle-2',
    COLLISION: 'streamlinehq/noise-pollution-traffic',
    CRIME_MASK: 'streamlinehq/crime-tools-criminal-mask-2',
    DASHBOARD_SPEED: 'streamlinehq/car-dashboard-speed',
    DASHBOARD_WARNING: 'streamlinehq/car-dashboard-warning',
    DIODE: 'streamlinehq/electronics-shottkey-diode',
    FENCE: 'streamlinehq/construction-fence',
    FOOD_DRINK: 'streamlinehq/fast-food-burger-drink',
    HAMMER_HOLD: 'streamlinehq/tools-hammer-hold',
    LOCK_PICK: 'streamlinehq/crime-tools-loackpick-unlock',
    PHONE_HOLD: 'streamlinehq/phone-hand-hold-1',
    PIN_BLOCK: 'streamlinehq/style-three-pin-block',
    PIN_WARNING: 'streamlinehq/style-three-pin-warning',
    SEAT_BELT: 'streamlinehq/fasten-seal-belt',
    SMOKING: 'streamlinehq/allowances-smoking',
    SOS: 'streamlinehq/emergency-assistant',
    SWITCH_OFF: 'streamlinehq/switch-off',
    TRIP: 'streamlinehq/trip-destination',
    TRUCK_FAST: 'streamlinehq/shipping-truck-fast',
    WHEEL_DISABLE: 'streamlinehq/auto-pilot-car-wheel-disable',
    WIFI_WARNING: 'streamlinehq/wifi-warning',
    YAWNING: 'streamlinehq/emoji-yawning-face-sleepy',
    ZIG_ZAG: 'streamlinehq/diagram-zig-zag-fall',
};

let svgUrls = {};
export const getSvgUrl = async (name) => {
    const importUrl = async () => {
        try {
            const { default: url } = await import(`../assets/svg/${name}.svg`);
            svgUrls[name] = url;
            return url;
        } catch (e) {
            // console.log(e);
        }
    };

    return svgUrls[name] ?? await importUrl();
};

let loadedSvg = {};
Object.values(preloadSVGs).forEach(async (name) => {
    const url = await getSvgUrl(name);
    if (url) {
        let request = new XMLHttpRequest();
        request.open('GET', url);
        request.onload = () => {
            if (request.status === 200 && request.getResponseHeader('content-type') === 'image/svg+xml') {
                loadedSvg[name] = request.response;
            }
        };
        request.send();
    }
});
const getLoadedSvg = (name) => loadedSvg[name];

const symbols = /[\r\n%#()<>?\[\\\]^`{|}]/g;
const encodeSvg = (data) => {
    let eData = data.replace(/'/g, '"');
    eData = eData.replace(/>\s{1,}</g, '><');
    eData = eData.replace(/\s{2,}/g, ' ');

    return eData.replace(symbols, encodeURIComponent);
};

export const getSvgDataUrl = (name, attributes) => {
    const svgString = getLoadedSvg(name);
    if (svgString) {
        return encodeSvg(`data:image/svg+xml,${svgStringWithAttributes(svgString, attributes)}`);
    }
    return '';
};
