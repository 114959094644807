// Utils
import { api } from 'services';
import { APIS } from 'utils/constants';
// Redux
import { actions } from 'store/slices/installer';

export default actions;

export const getWos = () => async (dispatch) => {
    const { data, status } = await api.get(APIS.INSTALLER.GET_WO);
    if (status === 200) {
        dispatch(actions.setWorkOrders(data.data));
    }
};

export const getInstallers = (params) => async (dispatch) => {
    const { data, status } = await api.get(APIS.INSTALLER.GET_INSTALLERS, params);
    if (status === 200) {
        dispatch(actions.setInstallers(data.data));
    }
};

export const createWo = (params) => async (dispatch) => {
    dispatch(actions.setIsFetching(true));
    const { status } = await api.post(APIS.INSTALLER.CREATE_WO, params);
    if (status === 200) {
        dispatch(getWos());
    }
    dispatch(actions.setIsFetching(false));
};

export const setSearchValue = (searchValue) => async (dispatch) => {
    dispatch(actions.setSearchValue(searchValue));
};

export const setRightPane = (id) => async (dispatch) => {
    dispatch(actions.setRightPane(id));
};

export const setSelectedWo = (id) => async (dispatch) => {
    dispatch(actions.setSelectedWo(id));
};