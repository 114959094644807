import { spinnerProps } from 'utils/propTypes';
import './style.scss';




export const Spinner = (props) => {

    const { isLoading, size = '', className = '' } = props;

    const getClassName = () => `load-spinner ${className} ${size}`;

    return isLoading ? <div className={getClassName()} /> : null;
};

Spinner.propTypes = spinnerProps;