
export const printObject = (obj, options) => {
    const defOptions = { replacer: null, space: 4, ...options };
    const json = JSON.stringify(obj, defOptions.replacer, defOptions.space);
    const output = colorJson(json);
    return output;
};

export const loadScript = (option) => {
    const defOptions = { async: false, ...option };
    const tag = document.createElement('script');
    tag.async = defOptions.async;
    tag.src = defOptions.src;
    document.getElementsByTagName('body')[0].appendChild(tag);
};

const colorJson = (jsonString) => {
    const jsonStr = jsonString.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return jsonStr.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, (match) => {
        var cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
    });
};

/**
 * Converting string in camel case to kabab case convention.
 * @param {Object} cssVariables - Object with valid properties names for css (prefix: -- & kabab case convention).
 * Example of valid cssVariables { --primary: 'red', --color-black: '#000' }.
 * @optional @param {String} tagName - Html tag name.
 * @default tagName - 'html'.
 * @returns {String} The converted string as kabab case convention.
 */
export const setCssVariables = (cssVariables, tagName = 'html') => {
    const html = document.getElementsByTagName(tagName)[0];
    Object.entries(cssVariables).forEach(([property, val]) => {
        if (property.startsWith('--')) {
            html.style.setProperty(property, val);
        }
    });
};

export const isElementVisibleInView = (elm, view, fullyVisible = true, horizontal = true) => {
    if (!elm || !view) return false;
    let isVisible = false;
    if (horizontal) {
        isVisible = isVisibleHorizontallyInView(elm, view, fullyVisible);
    } else {
        isVisible = isVisibleVerticallyInView(elm, view, fullyVisible);
    }
    return isVisible;
};

function isVisibleVerticallyInView(elm, view, fullyVisible) {
    const rect = elm.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;
    const { top, bottom } = view.getBoundingClientRect();
    let isVisible = false;
    if (fullyVisible) {
        isVisible = (elemTop >= top) && (elemBottom <= bottom);
    } else {
        isVisible = (elemTop >= top) && (elemTop < bottom);
    }
    return isVisible;
}

function isVisibleHorizontallyInView(elm, view, fullyVisible) {
    const rect = elm.getBoundingClientRect();
    const elemLeft = rect.left;
    const elemRight = rect.right;
    const { left, right } = view.getBoundingClientRect();
    let isVisible = false;
    if (fullyVisible) {
        isVisible = (elemLeft >= left) && (elemRight <= right);
    } else {
        isVisible = (elemLeft >= left) && (elemLeft < right);
    }
    return isVisible;
}

export const getWidthUnitValue = (width, def = {}) => {
    if (!width || width === Infinity) return def;
    const units = ['em', 'ex', '%', 'px', 'cm', 'mm', 'in', 'pt', 'pc'];
    const unitValue = { ...def };

    const unit = units.find((u) => `${width}`.includes(u) && typeof +`${width}`.replace(u, '') === 'number');
    const value = +`${width}`.replace(unit, '');
    if (unit) {
        unitValue.unit = unit;
    }
    if (!isNaN(value) && value) {
        unitValue.value = value;
    }
    return unitValue;
};

export const devLog = (...args) => {
    const devMode = getDevMode();
    if (devMode.log) {
        console.log(...args);
    }
};

export const svgStringWithAttributes = (svg, attributes) => {
    if (typeof attributes === 'object' && Object.values(attributes ?? {}).length > 0) {
        const p = new DOMParser();
        const doc = p.parseFromString(svg, 'text/xml');
        const svgEl = doc.getElementsByTagName('svg')[0];
        Object.keys(attributes).forEach((key) => {
            svgEl.setAttribute(key, attributes[key]);
        });
        const s = new XMLSerializer();
        return s.serializeToString(doc);
    }
    return svg;
};

export const getDevMode = () => {
    let mode = { ...config.devMode };
    let data = sessionStorage.getItem('devMode');
    try {
        data = JSON.parse(data);
        if (typeof data === 'object') {
            mode = { ...mode, ...data };
        }
    } catch (error) {
        // 
    }
    return mode;
};

// const options = {
//     subtitles: ['subtitle 1', 'subtitle 2'],
//     contextOpt: {
//         shadowColor: 'black',
//         shadowBlur: 3,
//         lineWidth: 3,
//         font: '18px Roboto',
//         fillStyle: '#fff',
//         textAlign: 'center',
//         textBaseline: 'bottom',
//     }
// };
export const getVideoSnapshot = (video, options = {}) => {
    const { subtitles = [], contextOpt } = options;
    const canvas = document.createElement('canvas');
    canvas.style.display = 'none';
    const body = document.getElementsByTagName('body')[0];
    body.appendChild(canvas);

    const context = canvas.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0);
    const center = video.videoWidth / 2;
    const bottom = video.videoHeight;
    const lineHeight = 20;

    Object.entries(contextOpt ?? {}).forEach(([property, value]) => {
        context[property] = value;
    });

    let subtitleGap = 4;
    for (let i = (subtitles.length - 1); i >= 0; i--) {
        context.fillText(subtitles[i], center, bottom - subtitleGap);
        subtitleGap += lineHeight;
    }
    const data = context.canvas.toDataURL('image/png');
    canvas.remove();
    return data;
};

export const animateFlash = (elm) => {
    elm.classList.add('screen-shot');
    setTimeout(() => {
        elm.classList.remove('screen-shot');
    }, 300);
};

export const textHighlight = (text, strHighlight) => {
    if (strHighlight && (typeof text === 'string' || typeof text === 'number')) {
        // Split the Results string with the splitter string:
        const parts = `${text}`.split(new RegExp(`(${escapeRegExp(strHighlight)})`, 'gi'));
        // Return the found results with the highlighted match:
        return parts.map((part, i) => (
            <span key={i} className={part.toLowerCase() === strHighlight.toLowerCase() ? 'highlight-text' : undefined}>
                {part}
            </span>
        ));
    }
    return text;
};

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
