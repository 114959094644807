import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Utils
import { iconsName } from 'assets';
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
// Store
import { fetchDriverDetails, fetchDriverImage } from 'store/actions/drivers';
import { getDriverDetailsState } from 'store/selectors/drivers';
// Components
import { Icon, Text } from 'components/common';
import { DriverInfo, ModalBlock, ModalDriverScore, ModalInfoRow } from 'components/modal/components';
import './style.scss';

const ModalDriverInfo = ({ driverId, driverName, eventTime, eventType }) => {
    const dispatch = useDispatch();
    const details = useSelector(getDriverDetailsState);

    useEffect(() => {
        dispatch(fetchDriverDetails(driverId));
    }, [dispatch, driverId]);

    useEffect(() => {
        if (!details?.image && details?.imageUrl) {
            dispatch(fetchDriverImage([details?.imageUrl]));
        }
    }, [dispatch, details?.imageUrl, details?.image]);

    const driverScore = useMemo(() => (eventTime && eventType) ?
        <ModalDriverScore
            driverId={driverId}
            eventTime={eventTime}
            eventType={eventType}
        /> : null,
    [driverId, eventTime, eventType]);

    return (
        <ModalBlock
            testAttributes={setAttr.ui(testBy.UI.INFO_DRIVER)}
        >
            <div className="modal-driver-info">
                {details?.image
                    ? <Icon src={details?.image} useDefault style={{ borderRadius: '50%' }} width={32} height={32} title={t('lblAssociatedDriver')} />
                    : <Icon name={iconsName.USER_AVATAR} width={32} height={32} title={t('lblAssociatedDriver')} />}
                <ModalInfoRow>
                    <label className="label-short">
                        <Text charsForTitle={20} text={driverName ?? 'N/A'} />
                    </label>
                    <DriverInfo
                        licenseExpiration={details?.licenseExpiration}
                        mobilePhoneNumber={details?.mobilePhoneNumber}
                    />
                </ModalInfoRow>
            </div>
            {driverScore}
        </ModalBlock>
    );
};

export default ModalDriverInfo;
