import React from 'react';
import { AppNavigationBar } from 'components';
import './style.scss';



class AppSideBar extends React.PureComponent {
    render() {

        return (
            <div className='app-side-bar'>
                <AppNavigationBar />
            </div>
        );
    }
}

export default AppSideBar;
