import { api } from 'services';
import { APIS } from 'utils/constants';
import { actions } from 'store/slices/drivers';



export default actions;

export const fetchDrivers = () => async (dispatch) => {
    const { data, status } = await api.get(APIS.DRIVERS.BY_ACCOUNT);
    if (status === 200) {
        dispatch(actions.setDrivers(data.data));
    }
};

export const fetchDriverDetails = (driverId) => async (dispatch) => {
    const params = { driverId };
    const { data, status } = await api.get(APIS.DRIVERS.DETAILS, { params });
    if (status === 200) {
        dispatch(actions.setDriverDetails(data.data));
    }
};

export const fetchDriverImage = (ids) => async (dispatch) => {
    if (ids?.length > 0){
        const { data, status } = await api.post(APIS.MEDIA.GET_IMAGES, { ids: ids, IsFromConnect: true } );
        if (status === 200) {
            dispatch(actions.updateDriverImage(data?.data));
        }
    }
};

export const fetchDriverScore = (driverId, incidentTypeId, startTime, endTime) => async (dispatch) => {
    Promise.all([
        api.get(APIS.INCIDENTS.DRIVER_REOCCURRENCE, { params: { driverId, incidentTypeId, startTime, endTime }}),
        api.get(APIS.DRIVERS.GET_DRIVER_SCORE, { params: { driverId }}),
    ]).then((responses) => {
        const { data: incidentsData, status: incidentsStatus } = responses[0];
        const { data: scoreData, status: scoreStatus } = responses[1];
        let scoreObj;
        if (incidentsStatus === 200) {
            scoreObj = { incidents: incidentsData.data };
        }
        if (scoreStatus === 200) {
            scoreObj = { ...scoreObj, ...{ score: scoreData.data }};
        }
        if (scoreObj) {
            dispatch(actions.setDriverScore(scoreObj));
        }
    });
};

export const fetchDriverMileage = (driverId) => async (dispatch) => {
    api.all([
        api.get(APIS.DRIVERS.GET_DRIVER_MILEAGE, { params: { driverId }}),
        api.get(APIS.DRIVERS.GET_DRIVER_SCORE, { params: { driverId }}),
    ]).then((responses) => {
        const payload = responses.reduce((a,b, i) => {
            if(b.status === 200){
                const driverData = i === 1 ? { score:b.data.data } : b.data.data;
                return   ({
                    ...a, ...driverData
                });
            }
            return a;
        },{});

        if(payload){
            dispatch(actions.setDriverMileage(payload));
        }
    });
};
