import React, { useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';




class AppInitialLoads extends React.PureComponent {

    render() {

        const { config } = this.props;

        return (
            <>
                <LoadGoogleScriptApi config={config} />
            </>
        );
    }
}

export default AppInitialLoads;


const LoadGoogleScriptApi = (props) => {

    const { config, onLoaded, onLoadError } = props;

    const { isLoaded, loadError, url } = useLoadScript({
        id: 'pf-google-map',
        googleMapsClientId: config.MAP.KEY,
        version: config.MAP.VERSION,
        libraries: config.MAP.LIBRARIES,
    });

    // console.log('load script', { url, isLoaded, loadError });

    useEffect(() => {
        if (isLoaded) {
            onLoaded?.();
        }
    }, [onLoaded, isLoaded]);

    useEffect(() => {
        if (loadError) {
            onLoadError?.();
        }
    }, [onLoadError, loadError]);


    return (
        <></>
    );
};