import React, { useCallback } from 'react';
// Utils
import { ROUTES, setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import PropTypes, { propTypes } from 'utils/propTypes';
// Components
import { Link } from 'react-router-dom';
import { WidgetBox } from 'components';
import { Button } from 'components/common';
import IncidentsList from 'pages/safety_page/features/incidents_widget/IncidentsList';
import './style.scss';




const IncidentsWidget = (props) => {
    const {
        classes = [],
        widget,
        actionsBar,
        onClickSeeMore,
        onClickItem,
        onClickVideo
    } = props;

    const { variant, title, items } = widget;

    const handleClickVideo = useCallback((item) => {
        onClickVideo?.({ widget, item });
    }, [onClickVideo, widget]);

    const handleClickItem = useCallback((item) => {
        onClickItem?.({ widget, item });
    }, [onClickItem, widget]);

    const handleClickSeeMore = useCallback(() => {
        onClickSeeMore?.({ widget });
    }, [onClickSeeMore, widget]);

    return (
        <WidgetBox
            variant={variant}
            title={title}
            actions={actionsBar}
        >
            <IncidentsList
                items={items}
                onClickItem={handleClickItem}
                onClickVideo={handleClickVideo}
            />
            <div className='bottom-widget'>
                <Link to={ROUTES.SAFETY_INCIDENTS.path}>
                    <Button
                        classes={['primary-light', 'gradient']}
                        onClick={handleClickSeeMore}
                        label={t('lblSeeMore')}
                        testAttributes={setAttr.action(testBy.ACTION.SEE_MORE)}
                    />
                </Link>
            </div>
        </WidgetBox>
    );
};


IncidentsWidget.propTypes = {
    widget: PropTypes.shape({
        items: PropTypes.arrayOf(propTypes.incident),
        title: PropTypes.string,
        variant: PropTypes.string
    }).isRequired,
    actionsBar: PropTypes.arrayOf(propTypes.actionBarProps),
    onClickSeeMore: PropTypes.func,
    onClickItem: PropTypes.func,
    onClickVideo: PropTypes.func
};

export default IncidentsWidget;
