import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Utils
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { getExclusiveRangeMs } from 'utils/local_date_time';
// Store
import { fetchDriverScore } from 'store/actions/drivers';
import { getDriverScoreState } from 'store/selectors/drivers';
// Components
import ModalInfoRow from 'components/modal/components/modal_info_row/ModalInfoRow';
import './style.scss';

const ModalDriverScore = ({ driverId, eventTime, eventType }) => {
    const dispatch = useDispatch();
    const scoreObj = useSelector(getDriverScoreState);

    useEffect(() => {
        const { startTime, endTime } = getExclusiveRangeMs.last30DaysFrom(eventTime);
        dispatch(fetchDriverScore(driverId, eventType, startTime, endTime));
    }, [dispatch, driverId, eventTime, eventType]);

    return (
        <ModalInfoRow
            testAttributes={setAttr.ui(testBy.UI.SCORE_ROW)}
        >
            <div className="driver-score-row">
                <div className="driver-score-block">
                    <span className="score-label reoccurance" {...setAttr.ui(testBy.UI.LABEL)}>{t('lblReOccurrence')} {t('lblLast30Days')}</span>
                    <span className="score-value">{scoreObj.incidents}</span>
                    <span className="score-label safety" {...setAttr.ui(testBy.UI.LABEL)}>{t('lblSafetyScore')}</span>
                    <span className="score-value">{scoreObj.score}</span>
                </div>
            </div>
        </ModalInfoRow>
    );
};

export default ModalDriverScore;
