import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Utils
import { getSvgUrl, iconsName } from 'assets';
import { modalsIds } from 'components/modal/ModalsConstants';
import { api } from 'services';
import { setAttr, testBy } from 'utils/constants';
import { pf_t, t } from 'utils/dictionary';
import { cameraIds } from 'utils/entities';
import { downloadZipAndSave, saveFiles } from 'utils/helpers/fileHelper';
import { isValidCoordinate } from 'utils/helper';
import { allowedFormats, millisToAllowedFormat } from 'utils/timezone';
// Components
import { Menu } from 'components';
import { Icon, Text } from 'components/common';
import CategoryModal from 'components/modal/category_modal/CategoryModal';
import {
    ModalBlock, ModalCoordsInfoRow, ModalDriverInfo, ModalInfoRow, ModalInfoSection,
    ModalResourceInfo, ModalSeverityInfoRow, ModalCollisionImages, ModalCollisionGraph,
    ModalWeather, VideoPlayer, ModalImpactImages
} from 'components/modal/components';
// Hooks
import useCollisionDetails from 'components/modal/hooks/use_collision_details/useCollisionDetails';
import useModalState from 'components/modal/hooks/use_modal_state/useModalState';
// Store
import { openModal } from 'store/actions/popup';
import { fetchIncidentMedia } from 'store/actions/safety';
import { getVideoModalData } from 'store/selectors/safety';
import './style.scss';

const CollisionModal = ({ data }) => {

    const dispatch = useDispatch();

    const { videos } = useSelector(getVideoModalData);

    const frontVideo = videos?.find((video) => video.cameraId === cameraIds.FRONT && video.url);
    const backVideo = videos?.find((video) => video.cameraId === cameraIds.BACK && video.url);
    const hasVideosToLoad = typeof data.videos === 'string';
    const eventName = pf_t.eventName(data.type);

    const [images, setImages] = useState([]);
    const [imagesFetched, setImagesFetched] = useState(false);

    const modalState = useModalState(data.id, data.categoryId);
    const details = useCollisionDetails(data.id, data.resourceName);

    const fetchImagesData = useCallback(async () => {
        const mediaData = await api.getAccidentMedia(data.id);
        const imagesData = mediaData?.find((item) => item.mediaTypeName === 'Image');

        if (Array.isArray(imagesData?.items)) {
            const ids = imagesData.items.map((item) => item.id);
            const originals = await api.getImages(ids, false);
            if (Array.isArray(originals)) {
                const thumbnails = await api.getImages(ids, true);
                const noThumb = await getSvgUrl(iconsName.NO_THUMBNAIL);
                const images = imagesData.items.map((item) => {
                    const thumbnail = Array.isArray(thumbnails) ? thumbnails.find((tb) => tb.id === item.id)?.url ?? '' : '';
                    return {
                        id: item.id,
                        descr: item.description,
                        thumbnail: thumbnail.length > 0 ? thumbnail : noThumb,
                        original: originals.find((o) => o.id === item.id)?.url ?? ''
                    };
                }).filter((item) => item.original.length > 0);
                setImages(images);
                setImagesFetched(true);
            } else {
                setImagesFetched(true);
            }
        } else {
            setImagesFetched(true);
        }
    }, [data.id]);

    useEffect(() => {
        if (data.id > 0) {
            fetchImagesData();
        }
    }, [data.id, fetchImagesData]);

    useEffect(() => {
        if (hasVideosToLoad) {
            dispatch(fetchIncidentMedia(data.videos));// "2022/10/4326/296437936_EXT_C1_638027252810247496.mp4,2022/10/4326/296437936_INT_C1_638027248987767421.mp4"
        }
    }, [data.videos, dispatch, hasVideosToLoad]);

    const downloadVideos = useCallback(() => {
        const dateFormat = millisToAllowedFormat(data.eventTime, allowedFormats.FILE_NAME_DATE_TIME);
        const filesTemp = [
            { type: 'mp4', name: `front_${dateFormat}`, url: frontVideo?.url },
            { type: 'mp4', name: `back_${dateFormat}`, url: backVideo?.url },
        ];
        downloadZipAndSave(filesTemp, 'videos');
    }, [data, frontVideo, backVideo]);

    const downloadImages = useCallback(async () => {
        const types = ['jpeg', 'png'];
        const filesTemp = images.map((img, i) => ({
            url: img.original,
            name: `image-${i + 1}`,
            type: types.find((t) => img.original.includes(`.${t}`)) ?? types[0]
        }));

        if (filesTemp.length) {
            const res = await downloadZipAndSave(filesTemp, 'images');
            if (!res) {
                const files = filesTemp.map((f) => ({ content: f.url }));
                saveFiles(files);
            }
        }
    }, [images]);

    const onClickMenu = useCallback((item) => {
        switch (item.id) {
            case 'videos':
                downloadVideos();
                break;
            case 'images':
                downloadImages();
                break;
            default:
                break;
        }
    }, [downloadVideos, downloadImages]);

    const onClickViewImg = useCallback((idx) => {
        dispatch(openModal({ id: modalsIds.GALLERY_MODAL, data: { imgData: images, currentIdx: idx, ...data }}));
    }, [data, dispatch, images]);

    const onCloseAndSave = useCallback(() => {
        modalState.saveState();
    }, [modalState]);

    const mediaAction = useMemo(() => {
        const imagesMenu = { label: t('lblImages'), id: 'images', disabled: !images.length, testAttributes: setAttr.action(testBy.ACTION.DOWNLOAD_IMAGES, !images.length) };
        const menuItems = [
            (frontVideo || backVideo) ? { label: t('lblVideos'), id: 'videos', addDivider: imagesMenu !== null, testAttributes: setAttr.action(testBy.ACTION.DOWNLOAD_VIDEOS) } : null,
            imagesMenu,
        ].filter((item) => item !== null);

        return menuItems.length > 0 ? (
            <Menu
                buttonClasses={[testBy.CLASS.BUTTON_DOWNLOAD]}
                items={menuItems}
                onClick={onClickMenu}
            >
                <Icon name={iconsName.DOWNLOAD} width={39} height={26} />
            </Menu>
        ) : null;
    }, [onClickMenu, frontVideo, backVideo, images]);

    const coordsInfoRow = useCallback(() => {
        const { position, points } = (details.accidentId > 0) ? details: { position: { lat: data.lat, lng: data.lng }, points: {}};
        const { after: pointsAfter, before: pointsBefore } = points;
        const iconLocationObj = {
            onClick: isValidCoordinate(position) ? () => {
                dispatch(openModal({ id: modalsIds.MAP_MODAL, data: { ...data, eventName, position, pointsAfter, pointsBefore }}));
            } : null
        };

        return <ModalCoordsInfoRow label={t('lblCoordinates')} lat={position.lat} lng={position.lng} iconObj={iconLocationObj} />;
    }, [data, details, dispatch, eventName]);

    const descriptionRow = useMemo(() => details.accidentId > 0 ?
        <ModalInfoRow label={t('lblDescription')} flow>{details.summaryTxt}</ModalInfoRow> :
        <ModalInfoRow label={t('lblDescription')}>{`N/A`}</ModalInfoRow>
    , [details.accidentId, details.summaryTxt]);

    const moreActions = [
        mediaAction,
    ];

    const address = useMemo(() => (details.accidentId > 0) ? details.address : data.address, [details, data.address]);

    const imagesComponent = useMemo(() => <ModalCollisionImages
        images={images}
        imagesLoaded={imagesFetched}
        onClickViewImage={onClickViewImg}
    />, [images, imagesFetched, onClickViewImg]);

    return (
        <CategoryModal
            modalId={modalsIds.COLLISION_MODAL}
            eventName={t('lblPossibleCollision')}
            stateComponent={modalState.stateComponent}
            data={data}
            moreActions={moreActions}
            handleCloseAndSaveClick={onCloseAndSave}
            needSave={modalState.needSave}
            hasMessage={modalState.hasMessage}
            testAttributes={setAttr.feature(testBy.FEATURE.MODAL_COLLISION)}
        >
            <ModalInfoSection>
                <ModalBlock
                    testAttributes={setAttr.ui(testBy.UI.INFO_DETAILS)}
                >
                    {descriptionRow}
                    <ModalInfoRow label={t('lblCollisionType')}>{eventName}</ModalInfoRow>
                    <ModalSeverityInfoRow severity={data.severity} />
                    <ModalInfoRow label={t('lblAirbagDeployment')}>{details.airBag}</ModalInfoRow>
                    <ModalInfoRow label={t('lblAddress')}><Text text={address} title={address} /></ModalInfoRow>
                    {coordsInfoRow()}
                    <ModalInfoRow label={t('lblWeatherConditions')}>{pf_t.weatherCondition(details.weather.condition)}</ModalInfoRow>
                    <ModalInfoRow label={t('lblIncidentId')}>{data.id}</ModalInfoRow>
                </ModalBlock>
                <ModalResourceInfo
                    resourceId={data.resourceId}
                />
                <ModalDriverInfo
                    driverId={data.driverId}
                    driverName={data.driverName}
                    eventTime={data.eventTime}
                    eventType={data.type}
                />
            </ModalInfoSection>
            <ModalBlock className="collision" fullSize>
                <div className="video-graph-wrapper">
                    <VideoPlayer
                        frontVideo={frontVideo}
                        backVideo={backVideo}
                        loading={hasVideosToLoad}
                        compact
                    />
                    <ModalCollisionGraph chartData={details.chartData} />
                </div>
                <ModalInfoRow>
                    <ModalWeather weatherData={details.weather} />
                    <ModalImpactImages impactOrientation={details.impactOrientation} />
                </ModalInfoRow>
                {imagesComponent}
            </ModalBlock>
        </CategoryModal>
    );
};

export default CollisionModal;
