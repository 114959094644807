import { api } from 'services';
import { APIS } from 'utils/constants';
import { actions } from 'store/slices/provision';

export default actions;

export const fetchAccounts = () => async (dispatch) => {
    const { data, status } = await api.get(APIS.PROVISION.GET_PROVISION_ACCOUNTS);
    if (status === 200) {
        dispatch(actions.setAccounts(data.data));
    }
};