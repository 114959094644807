import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Utils
import { iconsName } from 'assets';
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { allowedFormats, millisToAllowedFormat } from 'utils/timezone';
// Store
import { fetchResourceDetails } from 'store/actions/resources';
import { getResourceDetailsState } from 'store/selectors/resources';
// Components
import { Text } from 'components/common';
import { ModalBlock, ModalInfoRow } from 'components/modal/components';
import './style.scss';


const ModalResourceInfo = ({ resourceId }) => {
    const dispatch = useDispatch();
    const details = useSelector(getResourceDetailsState);
    const licenseExpiration = useMemo(() => details?.insurancePolicyExpiration > 0 ?
        `${t('lblLicenseExpiration')} ${millisToAllowedFormat(details.insurancePolicyExpiration, allowedFormats.MONTH_YEAR)}` : null, [details?.insurancePolicyExpiration]);

    useEffect(() => {
        dispatch(fetchResourceDetails(resourceId));
    }, [dispatch, resourceId]);

    return (
        <ModalBlock
            iconName={iconsName.SMALL_CAR}
            iconTitle={t('lblAssociatedVehicle')}
            testAttributes={setAttr.ui(testBy.UI.INFO_RESOURCE)}
        >
            <ModalInfoRow label={details?.name ?? 'N/A'}>
                {licenseExpiration}
            </ModalInfoRow>
            <ModalInfoRow
                testAttributes={setAttr.ui(testBy.UI.RESOURCE_ROW)}
            >
                <div className="resource-row">
                    <div className="resource-wrapper">
                        <span className="resource-label" {...setAttr.ui(testBy.UI.LABEL)}>{t('lblManufacturer')}</span>
                        <Text className="resource-value" charsForTitle={10} text={details?.manufacturer ?? 'N/A'} />
                    </div>
                    <div className="resource-wrapper">
                        <span className="resource-label" {...setAttr.ui(testBy.UI.LABEL)}>{t('lblModel')}</span>
                        <Text className="resource-value" charsForTitle={10} text={details?.model ?? 'N/A'} />
                    </div>
                    <div className="resource-wrapper">
                        <span className="resource-label" {...setAttr.ui(testBy.UI.LABEL)}>{t('lblColor')}</span>
                        <Text className="resource-value" charsForTitle={10} text={details?.color ?? 'N/A'} />
                    </div>
                </div>
            </ModalInfoRow>
        </ModalBlock>
    );
};

export default ModalResourceInfo;
