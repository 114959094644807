import React from 'react';
import PropTypes, { propTypes } from 'utils/propTypes';
import { connect } from 'react-redux';
// Utils
import dictionary, { locales } from 'utils/dictionary';
import { URLParams } from 'utils/constants';
import { THEMES_NAMES } from 'styles/theme';
import { api, listeners } from 'services';
// Selectors
import { getFetchingState, getUserInfo } from 'store/selectors/user';
// Actions
import { unAuthUser, reAuthenticate, authUserProcess } from 'store/actions/user';
import { setApplicationTheme } from 'store/actions/theme';
// Components
import { LoginPage } from 'pages';
import { AppTopBar, AppSideBar, AppPageRoutes } from 'layout';
import './style.scss';



class AppLayout extends React.PureComponent {

    static views = {
        TOP_BAR: 'topbar',
        SIDE_BAR: 'sidebar',
    };

    constructor(props) {
        super(props);
        const { setApplicationTheme, authUserProcess } = props;
        // setApplicationTheme(THEMES_NAMES.RANDOM);
        setApplicationTheme(THEMES_NAMES.DEFAULT);
        // setApplicationTheme(THEMES_NAMES.DARK);

        // Reading key & values as lower case (except the tk value)
        const query = new URLSearchParams(window.location.search.toLowerCase());
        this.setLanguage(~~query.get(URLParams.LANG_ID));

        /*
            Higher priority to query token.
            Using query token or session token but not both,
            Preventing confusion with which auth method used - that can happen when both session & query token are exists.
        */
        const tokenUrl = new URLSearchParams(window.location.search).get(URLParams.TOKEN);
        const hideViews = query.get(URLParams.HIDE_VIEWS);

        // Remove token to avoid exceeding the request header limit.
        query.delete(URLParams.TOKEN);
        window.history.replaceState(null, '', window.location.pathname + (query && query.size > 0 ? ('?' + query.toString()) : ''));

        const token = tokenUrl ?? reAuthenticate();
        if (token) {
            authUserProcess?.(token);
        }

        this.state = {
            hideViews: URLParams.getParamList(hideViews) ?? [],
        };
    }

    componentDidMount() {
        api.on(listeners.onAuthorized, this.onAuthorized);
        api.on(listeners.onUnauthorized, this.onUnauthorized);
    }

    componentWillUnmount() {
        api.removeAllListeners();
    }

    onAuthorized = (token) => {
        // const tokenClaims = objToFirstLowerCase(parseJwt(token));
        // let str = Object.entries(tokenClaims);
        // Object.entries(tokenClaims).forEach(([claim, value]) => {
        //     str += `${claim} - ${value} \n`;
        // });
        // alert(str);
    };

    onUnauthorized = (data) => {
        const { unAuthUser } = this.props;
        console.log('onUnauthorized', data);
        unAuthUser?.();
    };

    setLanguage = (langId) => {
        const locale = locales.byLangId(langId);
        if (langId && locale) {
            dictionary.changeLanguage(locale.langName);
        }
    };

    render() {

        const { TOP_BAR, SIDE_BAR } = AppLayout.views;

        const { user, fetching } = this.props;
        const { hideViews } = this.state;

        if (fetching.definition) {
            return <div>Loading...</div>;
        }
        // TODO - Remove login page before integration with Connect site
        if (!user?.account?.id) return <LoginPage />;

        return (
            <div className='app-layout-container'>
                {hideViews.includes(TOP_BAR) ? null : <AppTopBar />}
                <div className='app-main-view'>
                    {hideViews.includes(SIDE_BAR) ? null : <AppSideBar />}
                    <AppPageRoutes />
                </div>
            </div>
        );
    }
}

AppLayout.propTypes = {
    unAuthUser: PropTypes.func,
    setApplicationTheme: PropTypes.func,
    authUserProcess: PropTypes.func,
    user: propTypes.user,
    fetching: PropTypes.shape({
        definition: PropTypes.bool
    })
};

const mapStateToProps = (state) => ({
    user: getUserInfo(state),
    fetching: getFetchingState(state)
});

const mapDispatchToProps = ({
    setApplicationTheme,
    unAuthUser,
    authUserProcess
});

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
