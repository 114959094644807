import React, { useCallback } from 'react';
import { buttonProps } from 'utils/propTypes';
import './style.scss';



export default function Button(props) {
    const {
        label,
        classes = [],
        children,
        onClick,
        data,
        disabled,
        style,
        uppercase = false,
        testAttributes,
    } = props;

    const handleClick = useCallback(() => {
        if (disabled) return;
        onClick?.(data);
    }, [onClick, data, disabled]);

    const cssNames = ['button-container', disabled && 'disabled', uppercase && 'uppercase', ...classes].filter((css) => css).join(' ');
    return (
        <div
            className={cssNames}
            onClick={handleClick}
            style={style}
            {...testAttributes}
        >
            <span>{label}</span>
            {children}
        </div>
    );
}


Button.propTypes = buttonProps;