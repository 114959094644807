import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
// Utils
import { formatUnit, convertUnit, unitIds, unitFormats } from 'utils/helpers/measurements';
// Redux
import { getUserMeasurements } from 'store/selectors/user';



const uTool = { types: unitIds, ...unitFormats };

// const useMeasureUtils = () => {

//     const userMeasurements = useSelector(getUserMeasurements);
//     const userUnits = userMeasurements.units;

//     /**
//      * Converting value base on the unit factorA & factorB.
//      * @param {Number} val - Value to convert.
//      * @param {(Number) unit.unitId} unitId - Unit unitId.
//      * Return the converted value.
//     */
//     const toUnit = useCallback((val, unitId, units = userUnits) => {
//         const unit = units.find((u) => u.unitId === unitId);
//         return convertUnit(val, unit);
//     }, [userUnits]);

//     /**
//      * Formating & Converting value base on the unit factorA & factorB.
//      * @param {Number} val - Value to convert.
//      * @param {(Number) unit.unitId} unitId - Unit unitId.
//      * @param {(String) unitFormats.SOME_FORMAT} format - unitFormats options.
//      * Return the format.
//     */
//     const toFormat = useCallback((val, unitId, format, units = userUnits) => {
//         const unit = units.find((u) => u.unitId === unitId);
//         return formatUnit(val, unit, format);
//     }, [userUnits]);

//     const utils = { types: unitIds, formats: unitFormats };

//     return { ...unitFormats, unitIds };
// };

const useUserMeasureUnit = (unitId, options = {}) => {

    const userMeasurements = useSelector(getUserMeasurements);
    const unit = userMeasurements.units.find((u) => u.unitId === unitId);

    const { format: fTemp, toFix: fix } = options;

    /**
     * Converting value base on the unit factorA & factorB.
     * @param {Number} val - Value to convert.
     * Return the converted value.
    */
    const c = useCallback((val) => convertUnit(val, unit), [unit]);

    /**
     * Formating & Converting value base on the unit factorA & factorB.
     * @param {Number} val - Value to convert.
     * @param {(String) unitFormats.SOME_FORMAT} format - unitFormats options.
     * Return the format.
    */
    const f = useCallback(
        (val, format = fTemp, toFix = fix) => formatUnit(val, unit, { format, toFix }),
        [unit, fTemp, fix]
    );

    const measure = useMemo(() => ({ unit, f, c, uTool }), [unit, f, c]);
    return measure;
};

export const useSpeedUnit = (options) => useUserMeasureUnit(unitIds.SPEED, options);

export const useLengthUnit = (level = 4, options) => useUserMeasureUnit(level === 3 ? unitIds.LENGTH_L3 : unitIds.LENGTH_L4, options);
