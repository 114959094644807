import React, { useMemo } from 'react';
import { iconsName } from 'assets';
import { Icon, Text } from 'components/common';
import { testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { formatDateMillis, getTimeDesc } from 'utils/timezone';
import './style.scss';

export const commentRaw = (timeStamp, text, userName) => ({ timeStamp, text, userName });

const Comment = ({ timeStamp, text, userName }) => {

    const renderedUserName = useMemo(() => userName ?
        <Text className={`label ${testBy.CLASS.COMMENT_USER}`} text={userName} title={userName} /> :
        <span className="system"><Icon name={iconsName.SETTINGS} /><Text className={`label ${testBy.CLASS.COMMENT_USER}`} text={t('lblSystem')} /></span>
    , [userName]);

    return (
        <div className="comment-wrapper">
            <div className="comment-header">
                {renderedUserName}
                <Text
                    className={`comment-time ${testBy.CLASS.COMMENT_TIME}`}
                    text={getTimeDesc(timeStamp)}
                    title={formatDateMillis(timeStamp)}
                />
            </div>
            <div className={testBy.CLASS.COMMENT}>{text}</div>
        </div>
    );
};

export default Comment;
