import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
// Utils
import { t } from 'utils/dictionary';
import { setAttr, testBy } from 'utils/constants';
import PropTypes from 'utils/propTypes';
// Components
import Dropdown from 'react-bootstrap/Dropdown';
import { SplitButtonInput } from 'components/common';
import './style.scss';




// const filterByValue = (items, val) => items?.filter((item) => item.value?.includes(val?.toLowerCase()) || filterByValue(item.items));
const filterByValue = (items, val, field = 'value') => items?.filter((item) => item[field]?.includes(val?.toLowerCase()) || Array.isArray(item.items));
const filterEmptyGroups = (items) => items?.filter((item) => !Array.isArray(item.items) || item.items?.length);

const filterItems = (items, val) => {
    if (!items?.length) return items;
    const filtered = filterByValue(items, val)?.map((item) => ({ ...item, items: filterItems(item.items, val) }));
    return filterEmptyGroups(filtered);
};

const SelectItem = (props) => {

    const { item, active, onClick } = props;

    const handleClick = useCallback((e) => {
        onClick?.(item);
    }, [onClick, item]);

    return (
        <Dropdown.Item
            eventKey={item?.id}
            active={active}
            onClick={handleClick}
            title={item?.label}
        >
            {item?.label}
        </Dropdown.Item>
    );
};

SelectItem.propTypes = {
    onClick: PropTypes.func,
    active: PropTypes.bool,
    item: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string
    })
};


const SelectMenuItems = (props) => {

    const { items, onClick, selected } = props;

    const view = useMemo(() => items?.map((item, i) => {

        const { id, label } = item;
        const active =  Boolean(selected?.id) && id === selected?.id;
        const header = (item.items) ? <Dropdown.Header>{label}</Dropdown.Header> : null;

        const viewItem = (item.items) ?
            <SelectMenuItems onClick={onClick} selected={selected} items={item.items} /> :
            <SelectItem onClick={onClick} active={active} item={item} />;

        return (
            <React.Fragment key={id ?? i}>
                {header}
                {viewItem}
            </React.Fragment>
        );
    }), [items, onClick, selected]);

    return view;
};


SelectMenuItems.propTypes = {
    onClick: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.shape(SelectItem.propTypes)),
    selected: PropTypes.shape(SelectItem.propTypes),
};


const SelectMenu = React.forwardRef((props, ref) => {

    const {
        style,
        className,
        items,
        onClick,
        'aria-labelledby': labeledBy,
        selected
    } = props;

    const view = useMemo(() => items.length ? (
        <SelectMenuItems
            items={items}
            selected={selected}
            onClick={onClick}
        />
    ) : <Dropdown.Item disabled>{t('lblNoResults')}</Dropdown.Item>, [items, selected, onClick]);

    return (
        <div
            ref={ref}
            style={style}
            className={`dropdown-input-menu-container ${className}`}
            aria-labelledby={labeledBy}
            role="menu"
        >
            {view}
        </div>
    );
});

SelectMenu.propTypes = {
    onClick: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.shape(SelectItem.propTypes)),
    selected: PropTypes.shape(SelectItem.propTypes),
    style: PropTypes.shape({}),
    className: PropTypes.string
};


export const DropdownInput = (props) => {

    const prevMeta = useRef();

    const {
        items = [],
        placeholder,
        onSelect,
        name,
        selected,
        error,
        showOnFocus = true
    } = props;

    const [filterValue, setFilterValue] = useState('');
    const [show, setShow] = useState(false);
    const [focus, setFocus] = useState(undefined);

    const filteredItems = useMemo(() => filterItems(items, filterValue), [filterValue, items]);

    useEffect(() => {
        setFilterValue((prev) => show ? prev : '');
    }, [show]);

    const onChange = useCallback((e) => {
        setFilterValue(e.target.value);
    }, []);

    const onClickMenu = useCallback((item) => {
        onSelect?.({ name, item });
    }, [onSelect, name]);

    const onToggle = useCallback((_show, meta) => {
        const { source, originalEvent: { target }} = meta;

        const isInput = target.name === DropdownInput.inputName;
        const fromClick = source === 'click';
        let keepShow = undefined;

        if ((!showOnFocus && isInput)) {
            meta.originalEvent.stopPropagation();
            keepShow = false;
        }

        if (!_show && fromClick) {
            // Preventing hide from clicking on the input
            if (isInput || prevMeta.current) {
                meta.originalEvent.stopPropagation();
                keepShow = true;
            }
        }
        prevMeta.current = isInput;
        setShow(keepShow ?? _show);
    }, [showOnFocus]);

    const onFocus = useCallback(() => {
        setFocus(true);
    }, []);

    const onBlur = useCallback(() => {
        setFocus(false);
    }, []);

    const onClickButton = useCallback(() => {
        setFilterValue('');
    }, []);

    const getClassName = () => {
        const errorClass = ((focus !== undefined && !focus) && error) ? 'error' : '';
        const grey = focus ? 'grey' : '';
        return `${grey} ${errorClass}`;
    };

    return (
        <Dropdown
            className='dropdown-input-container basic-dropdown'
            show={show}
            onToggle={onToggle}
            {...setAttr.ui(testBy.UI.DROPDOWN_INPUT)}
        >
            <Dropdown.Toggle
                as={SplitButtonInput}
                placeholder={selected?.label ?? placeholder}
                onChange={onChange}
                value={filterValue}
                className={getClassName()}
                onBlur={onBlur}
                onFocus={onFocus}
                onClickButton={onClickButton}
                name={DropdownInput.inputName}
            />
            <Dropdown.Menu
                as={SelectMenu}
                selected={selected}
                items={filteredItems}
                onClick={onClickMenu}
            />
        </Dropdown>
    );
};

DropdownInput.inputName = 'dropdownInput';

DropdownInput.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape(SelectItem.propTypes)),
    selected: PropTypes.shape(SelectItem.propTypes),
    placeholder: PropTypes.string,
    onSelect: PropTypes.func,
    name: PropTypes.string,
    error: PropTypes.bool,
    showOnFocus: PropTypes.bool
};