export const levels = {
    TRACE: 1,
    DEBUG: 2,
    INFO: 3,
    WARN: 4,
    ERROR: 5, // Unhandled errors
    FATAL: 6 // Crushing errors
};


const log = (data, level = levels.INFO) => {
    if (!config.logLevel || level < config.logLevel) return;
    console.log('---log server---' + level, data);
    // Post log info to server api.post()
};

// FATAL - critical errors that casuing to crush.
// Usually runtime errors inside the render
const fatal = (data) => {
    log(data, levels.FATAL);
};

// ERROR - for any unhandled errors that not causing to crush
const error = (data) => {
    log(data, levels.ERROR);
};

export const logger = {
    fatal,
    error
};
