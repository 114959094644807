import ApiBase from 'services/api/api_base';
// Utils
import { listeners } from '../constants';
import { APIS } from 'utils/constants';
import { AxiosError } from 'axios';

// Main API gateway
class Api extends ApiBase {

    constructor(config) {
        super(config);
        this.axios.interceptors.response.use(this.onResponse, this.onResponseError);
        // this.axios.interceptors.request.use(this.onRequest, this.onRequestError);
    }

    // Overrides
    init(config = {}) {
        const { token, ...rest } = config;
        super.init(rest);
        if (token) {
            this.setToken(token);
        }
    }

    setToken(token) {
        // const expTk = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjBFOTFDQjY5M0Y0OUUzNzJFMkNEM0NCMDEwMzNGNUIzREIzMjI4Q0NSUzI1NiIsInR5cCI6ImF0K2p3dCIsIng1dCI6IkRwSExhVDlKNDNMaXpUeXdFRFAxczlzeUtNdyJ9.eyJuYmYiOjE2Nzc2ODY0OTEsImV4cCI6MTY3NzcwMDg5MSwiaXNzIjoiaHR0cHM6Ly9hdGxhcy5wb2ludGVyYmkuY29tL2F0bGFzIiwiYXVkIjoiZmxlZXRjb3JlYXBpIiwiY2xpZW50X2lkIjoiUUFjbGllbnQiLCJzdWIiOiIzOTIyIiwiYXV0aF90aW1lIjoxNjc3Njg2NDkxLCJpZHAiOiJsb2NhbCIsIkxhbmdJZCI6IjEiLCJVc2VySWQiOiIzOTIyIiwiQWNjb3VudElkIjoiNDMyNiIsIlVzZXJOYW1lIjoiMV92bGFkaSIsIk5hbWUiOiIhIS1OTyBDSEFOR0VTLSEhIiwiVXNlclR5cGUiOiIyIiwiUGVybWlzc2lvbnMiOiI0NTEwOTIzMTQ4NzAiLCJDbGllbnRJZCI6IjU5MTQ5MjciLCJVc2VyR3VpZCI6IkEwNjZCOUY3LUY4MjItNEZDRS05RUU3LTUyMzE0MjZCRjI1NyIsImp0aSI6IkMzMUE0Q0MwMjA4ODAyNTk0NDVCODBCNThDREE4NjI4IiwiaWF0IjoxNjc3Njg2NDkxLCJzY29wZSI6ImZsZWV0Y29yZWFwaSIsImFtciI6WyJwYXNzd29yZCJdfQ.k6SvoZWZiqfjC4uVd8q5Glo49Oe8vRN3Fhc63F9wI8YLulcHcVvhIHFjMmY-Wou-U97lMCnZEuqzumJnQeg6r3U5QuY1H6tWm-_5WpDnDcE2d8dNaEOc1VadJxWdZofwaUsI8UOJgnlVt8oUCRCnX0MOLFYqAxFdRf5akEdRjm90w0_uj4_yVWu0dy3yIPkjOwfk12YU5zWZZYxvNPqfq7yGCADJFhPchOEZvO0aoDS7KByTnpSDd5yq_xlAINdESlJUo7M6iFgDd1MS1nP7KLxUqu20F1jeqkIWCkjSUWxqr6h0tCuf2lPfHrh2HifpJUeAlDviwipdzu5aUWalRQ';
        super.setToken(token);
        this.emit(listeners.onAuthorized, token);
    }

    // Methods
    async getMediaToken(mediaUrls) {
        const { data, status } = await this.post(APIS.MEDIA.GET_VIDEOS, { urls: mediaUrls });
        let mediaUrlsToken = [];
        if (status === 200 && data.data) {
            mediaUrlsToken = data.data;
        }
        return mediaUrlsToken;
    }

    async uploadImages(file, description, incidentId, userName) {
        const formData = new FormData();
        formData.append('files[0].file', file);
        formData.append('files[0].description', description);
        formData.append('mediaTypeName', 'Image');
        formData.append('userName', userName);
        formData.append('accidentId', incidentId);
        const { error, data, status } = await this.post(APIS.MEDIA.UPLOAD_IMAGES, formData);

        if (status === 200 && data.data) {
            return data.data;
        } else if (error instanceof AxiosError) {
            return error.response?.data?.error;
        }
        return null;
    }

    // Listeners
    onResponse = (response) => response;

    onResponseError = async (error) => {
        const { response, message, request } = error;
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // console.log('onResponseError', error);
        switch (response.status) {
            case 401:
                this.emit(listeners.onUnauthorized, error);
                break;
            default:
                break;
        }
        return Promise.reject(error);
    };

    async getAccidentMedia(incidentId) {
        const params = { accidentId: incidentId };
        const { data, status } = await this.get(APIS.ACCIDENTS.GET_ACCIDENT_MEDIA, { params });

        if (status === 200 && data.data) {
            return data.data;
        }
        return null;
    }

    async getImages(idsArr, isThumb) {
        const { data, status } = await this.post(APIS.MEDIA.GET_IMAGES, { ids: idsArr, isThumb });

        if (status === 200 && data.data) {
            return data.data;
        }
        return null;
    }

    async deleteImage(incidentId, imgId, userName) {
        const params = { accidentId: incidentId, files: [{ path: imgId }], userName };
        const { error, data, status } = await this.delete(APIS.MEDIA.DELETE_IMAGES, { data: params });

        if (status === 200 && data.data) {
            return data.data;
        } else if (error instanceof AxiosError) {
            return error.response?.data?.error;
        }
        return null;
    }

    async setIncidentState(incidentId, statusId, comment) {
        const reqData = { incidentId, statusId, comment };
        const { data, status } = await this.post(APIS.INCIDENTS.SET_INCIDENT_STATE, reqData);

        if (status === 200 && data.data) {
            return data.data;
        }
        return null;
    }

    async getIncidentState(incidentId, isCurrent) {
        const params = { incidentId, isCurrent };
        const { data, status } = await this.get(APIS.INCIDENTS.GET_INCIDENT_STATE, { params });

        if (status === 200 && data.data) {
            return data.data;
        }
        return null;
    }

    async sendCommand(params) {
        const { data, status } = await this.post(APIS.COMMANDS.SEND_COMMAND, params);
        if (status === 200) {
            return data.data;
        }
    }

    async getCommandStatus(commandId) {
        const params = { rowId: commandId };
        const { data, status } = await this.get(APIS.COMMANDS.GET_COMMAND_STATUS, { params });
        if (status === 200) {
            return data.data;
        }
    }

    async getCommands(resourceId) {
        const params = { resourceId };
        const { data, status } = await this.get(APIS.COMMANDS.GET_COMMANDS, { params });
        if (status === 200) {
            return data.data;
        }
    }

    onRequest = (request) => request;
    onRequestError = (error) => Promise.reject(error);

}

export const api = new Api();
