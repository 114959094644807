import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getAppNavigationLinks } from 'store/selectors/navigation';
import NavigationBar from 'components/common/navigation/NavigationBar';
import './style.scss';






const AppNavigationBar = (props) => {
    const location = useLocation();
    const links = useSelector(getAppNavigationLinks);

    return (
        <div className='app-navigation-bar'>
            <NavigationBar
                links={links}
                pathname={location.pathname}
            />
        </div>
    );
};

export default AppNavigationBar;
