import { useCallback, useMemo, useState, useRef } from 'react';
import PropTypes, { propTypes } from 'utils/propTypes';
// Utils
import { getDateTime, formatDateTime, intlFormats, dateObjByUnits, outRangeDateTimes } from 'utils/timezone';
// import { setAttr, testBy } from 'utils/constants';
// Components
import { CustomDatePicker, CustomOverlayTrigger, DateTimeInput } from 'components';
import { TimePicker } from 'components/date-picker/date-time-picker/TimePicker';
import './style.scss';




const getTimeDuration = (date) => dateObjByUnits(date, ['hour', 'minute']);


export const DateTimePickerOverlay = (props) => {

    const ref = useRef();
    const now = useMemo(() => getDateTime(), []);

    const {
        overlayProps = {},
        onApply,
        step,
        selected,
        ...datePickerProps
    } = props;

    const { fromDate, toDate } = datePickerProps;

    const [date, setDate] = useState(selected ?? now);
    const [applied, setApplied] = useState(date);

    const appliedDate = useMemo(() => selected ?? applied, [selected, applied]);


    const isDisabled = useCallback((date) => {
        const inRange = outRangeDateTimes(date, fromDate, toDate);
        return inRange;
    }, [fromDate, toDate]);

    const onRef = useCallback((node) => {
        ref.current = node;
    }, []);

    const onSelect = useCallback((date) => {
        setDate(date);
        props.onSelect?.(date);
    }, [props]);

    const onSelectDate = useCallback((startOfDay) => {
        const dateTime = startOfDay.set(getTimeDuration(date));
        onSelect(!isDisabled(dateTime) ? dateTime : startOfDay);
    }, [onSelect, date, isDisabled]);

    const onSelectTime = useCallback((dateTime) => {
        if (isDisabled(dateTime)) return;
        onSelect(dateTime);
    }, [onSelect, isDisabled]);

    const hidePicker = useCallback(() => {
        ref.current?.click?.();
    }, []);

    const onHide = useCallback(() => {
        setDate(applied);
    }, [setDate, applied]);

    const onClickApply = useCallback(() => {
        setApplied(date);
        onApply?.(date);
        hidePicker();
    }, [onApply, date, hidePicker]);

    const onClickCancel = useCallback(() => {
        hidePicker();
    }, [hidePicker]);

    const footerProps = useMemo(() => ({
        buttons: [{ onClick: onClickCancel }, { onClick: onClickApply }]
    }), [onClickApply, onClickCancel]);

    const renderTrigger = useCallback(() => (
        <DateTimeInput
            value={formatDateTime(appliedDate, intlFormats.DATETIME_SHORT)}
        />
    ), [appliedDate]);

    const renderRightPanel = useCallback(() => (
        <div className='date-picker-side-panel'>
            <TimePicker
                onSelect={onSelectTime}
                selected={date}
                step={step}
                toDate={toDate}
                fromDate={fromDate}
            />
        </div>
    ), [onSelectTime, step, date, toDate, fromDate]);


    return (
        <CustomOverlayTrigger
            renderTrigger={props.renderTrigger ?? renderTrigger}
            onRef={onRef}
            onHide={onHide}
            {...overlayProps}
        >
            <CustomDatePicker
                className='date-time-picker'
                defaultMonth={appliedDate}
                captionLayout="dropdown-buttons"
                footerProps={footerProps}
                selected={date}
                onSelect={onSelectDate}
                mode="single"
                renderRightPanel={renderRightPanel}
                {...datePickerProps}
            />
        </CustomOverlayTrigger>
    );
};


DateTimePickerOverlay.propTypes = {
    onApply: PropTypes.func,
    selected: propTypes.dateTime,
    renderTrigger: PropTypes.func,
    overlayProps: PropTypes.shape({}),
    datePickerProps: PropTypes.shape({}),
    step: PropTypes.number,
};