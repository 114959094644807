import FileSaver from 'file-saver';
// Utils
import { api } from 'services';
import { APIS } from 'utils/constants';
import { eventTypes, severities } from 'utils/entities';
import { getExclusiveRangeMs, isMoreThanOneDayExclusiveRangeMs } from 'utils/local_date_time';
import { devLog } from 'utils/domHelper';
import { formatDateMillis, getDiffBetweenDates, getDateTime, intlFormats, formatDateTime } from 'utils/timezone';
import { t, pf_t } from 'utils/dictionary';
// import { mockFetch } from 'test/mock';
// Redux
import { actions as dashboardActions } from 'store/slices/dashboard';
import { actions } from 'store/slices/safety';
import { getDashboardWidgetIds, getDashboardWidgetWithUpdatedParams } from 'store/selectors/dashboard';
import { getIncidentLocationsState, getSafetyPeriodState } from 'store/selectors/safety';


export default actions;

const { COLLISION, THEFT, VIOLATION } = eventTypes.categoryIds;

const createTimeRanges = ({ date, count, unit }) => {
    const ranges = [];
    for (let step = 0; step < count; step++) {
        const start = date.plus({ [unit]: step });
        const end = start.plus({ [unit]: 1 }).toMillis();
        ranges.push({ start: start.toMillis(), end });
    }
    return ranges;
};

const getTimeRanges = ({ startMillis, endMillis, unit = 'days' }) => {
    const diff = getDiffBetweenDates(endMillis, startMillis, [unit]);
    const count = Math.abs(diff[unit]);
    const date = getDateTime(startMillis);
    // Creating Full ranges dates between the start - end by unit time
    const ranges = createTimeRanges({ date, count, unit });
    if (!Number.isInteger(count)) {
        const index = Math.max(ranges.length - 1, 0);
        ranges[index].end = endMillis;
    }
    return ranges;
};

export const fetchIncidents = (payload) => {
    const start = formatDateMillis(payload?.startTime);
    const end = formatDateMillis(payload?.endTime);
    devLog({ start, end });

    const params = {
        ...{
            sort: 'eventTime',
            isDesc: true,
            maxCount: 10,
            pageNumber: 1, // The page number + maxCount (the result per page)
        }, ...payload
    };

    return api.post(APIS.INCIDENTS.BY_ACCOUNT, params);
};

export const fetchWidget = (widgetId, apiParams) => async (dispatch, getState) => {
    const widget = getDashboardWidgetWithUpdatedParams(widgetId, apiParams)(getState());
    if (!widget) return;

    const { params } = widget;
    const { data, status } = await fetchIncidents(params);
    if (status === 200) {
        dispatch(dashboardActions.setWidget({ ...widget, items: data.data.incidents }));
    } else {
        dispatch(actions.fetchIncidentsFailure());
    }
};

const fetchKpis = ({ startTime, endTime } = {}) => async (dispatch) => {
    const categories = `${COLLISION},${THEFT},${VIOLATION}`;
    const { error, data } = await api.get(APIS.INCIDENTS.COUNT_BY_ACCOUNT, { params: { startTime, endTime, categories }});
    if (!error && data?.data) {
        const kpis = { start: startTime, end: endTime, data: data.data };
        dispatch(actions.setIncidentsCount(kpis));
    }
};

const fetchIncidentsCountPerRange = ({ startTime, endTime, categories = `${COLLISION},${THEFT},${VIOLATION}` }) => async (dispatch, getState) => {
    const period = getSafetyPeriodState(getState());
    // TODO - set resolution to hours based on one day period
    const timeResolution = period.id === 'TODAY' ? 'hours' : 'days';

    let range = { startTime, endTime };
    if (period.id !== 'TODAY') {
        range = getExclusiveRangeMs.last14DaysOrMore(startTime, endTime);
    }
    const ranges = getTimeRanges({ startMillis: range.startTime, endMillis: endTime, unit: timeResolution });
    // const rangesStr = ranges.map((r) => ({ ...r, startStr: formatDateMillis(r.start), endStr: formatDateMillis(r.end) }));
    // console.log('ranges', rangesStr);
    const { data, status } = await api.post(APIS.INCIDENTS.COUNT_BY_RANGE, { categories, ranges });
    if (status === 200) {
        dispatch(actions.setCountPerRange({ items: data.data, ranges, timeResolution }));
    }
};

export const fetchIncidentsCountPerType = ({ startTime, endTime }) => async (dispatch, getState) => {
    const { data, status } = await api.get(APIS.INCIDENTS.COUNT_PER_TYPE, { params: { startTime, endTime }});
    if (status === 200) {
        dispatch(actions.setTypeChartBar(data.data));
    }
};

export const fetchDashboardLists = (params) => async (dispatch, getState) => {
    const widgetIds = getDashboardWidgetIds(getState());
    widgetIds.forEach((id) => {
        dispatch(fetchWidget(id, params));
    });
};

export const fetchDashboardData = (params) => (dispatch) => {
    dispatch((fetchDashboardLists(params)));
    dispatch(fetchKpis(params));
    dispatch(fetchIncidentsCountPerRange(params));
    dispatch(fetchIncidentLocations(params));
};

const fetchIncidentLocations = (rangeMs) => async (dispatch, getState) => {
    const approxLocations = isMoreThanOneDayExclusiveRangeMs(rangeMs);
    if (approxLocations) {
        const { heatmapPrecision } = getIncidentLocationsState(getState());
        const params = {
            ...rangeMs,
            precisionLevel: heatmapPrecision,
        };
        const { data, status } = await api.get(APIS.INCIDENTS.LOCATIONS, { params });
        if (status === 200) {
            dispatch(actions.fetchIncidentsLocations(data.data));
        }
    } else {
        const { data, status } = await fetchIncidents({ ...rangeMs, maxCount: 0 });
        if (status === 200 && Array.isArray(data.data.incidents)) {
            dispatch(actions.fetchIncidentsLocations(data.data.incidents));
        }
    }
};

export const fetchIncidentMedia = (videos) => async (dispatch, getState) => {
    if (typeof videos === 'string') {
        const { data, status } = await api.post(APIS.MEDIA.GET_VIDEOS, { urls: videos.split(',') });
        if (status === 200 && data.data) {
            const videos = data.data.map((url, i) => {
                const video = {
                    url,
                    cameraId: i + 1
                };
                return video;
            });
            dispatch(actions.setVideoModalData({ videos }));
        }
    }

    // const { error, data, status } = await fetchIncidentMediaMock();
    // dispatch(actions.openVideoModal({ open: true, data: { ...data, incident }}));
};

// const fetchIncidentMediaMock = () => {
//     const data = {
//         videos: [
//             {
//                 cameraId: 1,
//                 url: 'https://www.w3schools.com/tags/movie.mp4'
//             },
//             {
//                 cameraId: 2,
//                 url: 'https://w3schools.com/tags/mov_bbb.mp4'
//             }
//         ]
//     };
//     return mockFetch(data, { wait: 2000 });
// };

export const fetchSeverityKpis = ({ startTime, endTime } = {}) => async (dispatch) => {
    const categories = `${COLLISION},${THEFT},${VIOLATION}`;

    const { data, status } = await api.get(APIS.INCIDENTS.SEVERITIES, { params: { startTime, endTime, categories }});

    if (status === 200 && data.data) {
        dispatch(actions.setIncidentsSeverity({ data: data.data }));
    }
};

export const getAccidentTimeLineData = async (incidentId) => {
    const params = { incidentId };
    const { data, status } = await api.get(APIS.ACCIDENTS.TIME_LINE_DATA, { params });

    if (status === 200 && data.data) {
        return data;
    }
};

export const getAccidentDetails = async (incidentId) => {
    const params = { incidentId };
    const { data, status } = await api.get(APIS.ACCIDENTS.DETAILS, { params });

    if (status === 200 && data.data) {
        return data;
    }
};

export const getAccidentWeather = async (incidentId) => {
    const params = { incidentId };
    const { data, status } = await api.get(APIS.ACCIDENTS.WEATHER, { params });

    if (status === 200 && data.data) {
        return data;
    }
};

export const requestAndDownloadIncidents = async (payload) => {
    const { data, status } = await fetchIncidents(payload);
    if (status === 200 && data.data) {
        downloadIncidentsCsv(data.data?.incidents || []);
    }
};

const downloadIncidentsCsv = (incidents) => {
    let resourceCsv = '\uFEFF'; // BOM to make sure the file is opened in UTF-8 in Office

    // headers
    resourceCsv += `"${t('lblDriver')}",`;
    resourceCsv += `"${t('lblResource')}",`;
    resourceCsv += `"${t('lblIncidentType')}",`;
    resourceCsv += `"${t('lblDateTime')}",`;
    resourceCsv += `"${t('lblSeverity')}",`;
    resourceCsv += `"${t('lblState')}"\n`;

    const statuses = t('lblEventStates', { returnObjects: true });

    // body
    incidents.forEach((incident) => {
        resourceCsv += `"${incident.driverName || ''}",`;
        resourceCsv += `"${incident.resourceName || ''}",`;
        resourceCsv += `"${pf_t.eventName(incident.type)}",`;
        resourceCsv += `"${incident.eventTime ? formatDateMillis(incident.eventTime) : 'N/A'}",`;
        resourceCsv += `"${t(severities[incident.severity].lblKey)}",`;
        resourceCsv += `"${statuses[incident.statusId] ?? t(`lblEventCategories.${incident.categoryId}.defaultState`)}"\n`;
    });

    const file = new File([resourceCsv], { type: 'text/csv;charset=utf-8' });
    const now = getDateTime();
    const fileName = `${t('lblIncidents')} ${formatDateTime(now, intlFormats.DATETIME_SHORT)}`;

    FileSaver.saveAs(file, `${fileName}.csv`);
};

export const postManualEvent = (params, cb) => async (dispatch) => {
    dispatch(actions.setFetching({ postManualEvent: true }));
    const { data, error } = await api.post(APIS.INCIDENTS.INSERT_MANUAL_INCIDENT, params);
    // data.data - event id
    dispatch(actions.setFetching({ postManualEvent: false }));
    cb?.();
};
