import { useCallback, useState, useEffect } from 'react';
import PropTypes, { propTypes } from 'utils/propTypes';
// Utils
import { getDiffBetweenDates, getDateTime } from 'utils/timezone';
// Components
import { CustomDatePicker } from 'components';




export const CustomRangePicker = (props) => {

    const {
        children,
        selected,
        onCustomSelect,
        testAttributes,
        ...rest
    } = props;

    const [betweenPreRange, setBetweenPreRange] = useState([]);

    useEffect(() => {
        setBetweenPreRange([]);
    }, [selected]);

    const onDayMouseEnter = useCallback((date, activeModifiers) => {
        if (!date || !selected?.from) return;
        let range = { start: selected?.from, end: date };
        if (selected?.from?.toMillis() > date?.toMillis()) {
            range = { start: date, end: selected.from };
        }
        const diff = getDiffBetweenDates(range.end.toMillis(), range.start.toMillis());
        const inBetween = diff.days - 1;
        if (inBetween > 0) {
            const betweenPreRange = new Array(inBetween).fill(getDateTime(range.start.plus({ day: 1 }).toMillis())).map((date, day) => date.plus({ day }));
            setBetweenPreRange(betweenPreRange);
        }
    }, [selected]);

    const customSelect = useCallback((range, date) => {
        if (selected?.from && selected?.to) {
            // Reselecting the start range selection (custom behavior)
            onCustomSelect?.({ from: date, to: undefined });
            return;
        }
        onCustomSelect?.(range, date);
    }, [onCustomSelect, selected]);

    return (
        <CustomDatePicker
            mode='range'
            numberOfMonths={2}
            captionLayout="dropdown-buttons"
            selected={selected}
            // showOutsideDays
            onDayMouseEnter={(selected?.from && !selected?.to) ? onDayMouseEnter : undefined}
            modifiers={{
                [CustomDatePicker.modifiersStyles.betweenPreRange]: betweenPreRange
            }}
            onSelect={customSelect}
            testAttributes={testAttributes}
            {...rest}
        >
            <div className='date-picker-side-panel'>
                {children}
            </div>
        </CustomDatePicker>
    );
};


CustomRangePicker.propTypes = {
    children: PropTypes.node,
    selected: propTypes.dateRange,
    onCustomSelect: PropTypes.func,
};