import { createSelector } from 'reselect';
import { ROUTES } from 'utils/constants';
import { getDevMode } from 'utils/domHelper';



// SELECTORS
export const getPageLinksList = createSelector(
    [], // Fileting routes by permissions
    // To filter also the sub routes
    () => Object.entries(ROUTES).map(([key, route]) => ({ key, ...route }))
);

export const getAppNavigationLinks = createSelector(
    [getPageLinksList],
    (allLinks) => {
        const linksForSideBar = [
            // ROUTES.SAFETY.path,
            ROUTES.SAFETY_DASHBOARD.path,
            ROUTES.SUSTAINABILITY_SUSTAINABILITY_HOME.path,

        ];
        const devMode = getDevMode();
        if (devMode.playground) {
            linksForSideBar.push(ROUTES.PLAYGROUND.path);
        }
        const mainLinks = allLinks.filter((link) => linksForSideBar.includes(link.path));
        const navLinks = mainLinks.map((link) => {
            const children = link.subIds?.map((id) => allLinks.find((l) => l.id === id)) ?? [];
            return { ...link, children };
        });
        return navLinks;
    }
);

export const getSafetyBreadCrumbs = createSelector(
    [],
    () => {
        const { SAFETY, SAFETY_DASHBOARD, SAFETY_INCIDENTS, SAFETY_DRIVERS } = ROUTES;
        // Temperley disabling to link safety landing page
        const disabledCrumb = { ...SAFETY, disabled: true };
        const breadCrumbs = {
            [SAFETY.path]: [disabledCrumb],
            [SAFETY_DASHBOARD.path]: [disabledCrumb, SAFETY_DASHBOARD],
            [SAFETY_INCIDENTS.path]: [disabledCrumb, SAFETY_DASHBOARD, SAFETY_INCIDENTS],
            [SAFETY_DRIVERS.path]: [disabledCrumb, SAFETY_DRIVERS],
        };

        return breadCrumbs;
    }
);

export const getSustainabilityBreadCrumbs = createSelector(
    [],
    () => {
        const { SUSTAINABILITY, SUSTAINABILITY_SUSTAINABILITY_HOME } = ROUTES;
        // Disable link to sustainability root
        const disabledCrumb = { ...SUSTAINABILITY, disabled: true };
        const breadCrumbs = {
            [SUSTAINABILITY.path]: [disabledCrumb],
            [SUSTAINABILITY_SUSTAINABILITY_HOME.path]: [disabledCrumb, SUSTAINABILITY_SUSTAINABILITY_HOME]
        };

        return breadCrumbs;
    }
);
