
// Custom attributes - use for unit tests & automations 
export const testAttr = {
    ACTION: 'data-pf-action',
    ACTION_DISABLED: 'data-pf-action-disabled',
    ID: 'data-pf-id',
    FEATURE: 'data-pf-feature',
    PAGE: 'data-pf-page',
    SCREEN: 'data-pf-screen',
    UI: 'data-pf-ui', // Can use for features and layout test selectors
};

// Test attribute values for find in tests (pages, features, layout, ids, etc..)
export const testBy = {
    ACTION: {
        // attribute for elememt that trigering actions
        ADD: 'ADD',
        APPLY: 'APPLY',
        CANCEL: 'CANCEL',
        CHART_BAR: 'CHART_BAR',
        CHART_ICON: 'CHART_ICON',
        CLEAR: 'CLEAR',
        CLOSE: 'CLOSE',
        DELETE: 'DELETE',
        DOWNLOAD_CSV_FILE: 'DOWNLOAD_CSV_FILE',
        DOWNLOAD_IMAGES: 'DOWNLOAD_IMAGES',
        DOWNLOAD_VIDEOS: 'DOWNLOAD_VIDEOS',
        LOG_OUT: 'LOG_OUT',
        LOGIN: 'LOGIN',
        MUTE: 'MUTE',
        NAVIGATE: 'NAVIGATE',
        OPEN_VIDEOS_PLAYER: 'OPEN_VIDEOS_PLAYER',
        PAUSE: 'PAUSE',
        PLAY: 'PLAY',
        PRINT: 'PRINT',
        SAVE: 'SAVE',
        SEARCH: 'SEARCH',
        SEE_MORE: 'SEE_MORE',
        SELECT_CUSTOM: 'SELECT_CUSTOM',
        SELECT_LAST_7_DAYS: 'SELECT_LAST_7_DAYS',
        SELECT_LAST_30_DAYS: 'SELECT_LAST_30_DAYS',
        SELECT_MONTH: 'SELECT_MONTH',
        SELECT_THIS_MONTH: 'SELECT_THIS_MONTH',
        SELECT_THIS_WEEK: 'SELECT_THIS_WEEK',
        SELECT_TODAY: 'SELECT_TODAY',
        SELECT_YEAR: 'SELECT_YEAR',
        SEND: 'SEND',
        SORT: 'SORT',
        SORT_BY_SEVERITY: 'SORT_BY_SEVERITY',
        SORT_BY_TIME: 'SORT_BY_TIME',
        TAKE_SNAPSHOT: 'TAKE_SNAPSHOT',
        TOGGLE_COLLAPSIBLE_AREA: 'TOGGLE_COLLAPSIBLE_AREA',
        TOGGLE_COMMENTS: 'TOGGLE_COMMENTS',
        TOGGLE_CREATE_EVENT: 'TOGGLE_CREATE_EVENT',
        TOGGLE_FILTER: 'TOGGLE_FILTER',
        TOGGLE_FILTER_PANEL: 'TOGGLE_FILTER_PANEL',
        TOGGLE_OTHER_FILTER_CHIPS: 'TOGGLE_OTHER_FILTER_CHIPS',
        TOGGLE_PERIODS_FILTER: 'TOGGLE_PERIODS_FILTER',
        TOGGLE_SORT_ORDER: 'TOGGLE_SORT_ORDER',
        TOOLTIP_TRIGGER: 'TOOLTIP_TRIGGER', // Locate the trigger element
        UNMUTE: 'UNMUTE',
    },
    CLASS: {
        BUTTON_DOWNLOAD: 'BUTTON_DOWNLOAD',
        BUTTON_MODAL_STATE: 'BUTTON_MODAL_STATE',
        CARD_TIME: 'CARD_TIME',
        CARD_TITLE: 'CARD_TITLE',
        CARD_VALUE: 'CARD_VALUE',
        COMMENT: 'COMMENT',
        COMMENT_TIME: 'COMMENT_TIME',
        COMMENT_USER: 'COMMENT_USER',
        DATE_RANGE_END: 'DATE_RANGE_END',
        DATE_RANGE_START: 'DATE_RANGE_START',
        DATE_SELECTED: 'DATE_SELECTED',
        FILTER_CHIP: 'FILTER_CHIP',
        FILTER_OPTION: 'FILTER_OPTION',
        FILTER_OTHER_CHIPS: 'FILTER_OTHER_CHIPS',
        FILTER_TRAIL: 'FILTER_TRAIL',
        ICON_HEADER: 'ICON_HEADER',
        IMAGE_COLLISION: 'IMAGE_COLLISION',
        IMAGES_LOADED: 'IMAGES_LOADED',
        PERIOD_SELECTED: 'PERIOD_SELECTED',
        SEVERITY_ICON: 'SEVERITY_ICON',
    },
    FEATURE: {
        BREADCRUMBS: 'BREADCRUMBS',
        COMMENTS_POPUP: 'COMMENTS_POPUP',
        CREATE_EVENT_POPUP: 'CREATE_EVENT_POPUP',
        DIALOG_CONFIRMATION: 'DIALOG_CONFIRMATION',
        DRIVER_LICENSE_POPOVER: 'DRIVER_LICENSE_POPOVER',
        FILTER_INFO: 'FILTER_INFO',
        FILTER_POPUP: 'FILTER_POPUP',
        FILTER_SEARCH: 'FILTER_SEARCH',
        FILTER_WIDGET: 'FILTER_WIDGET',
        HEAT_MAP: 'HEAT_MAP',
        INCIDENT_TYPE_CHART: 'INCIDENT_TYPE_CHART',
        KPI_CARD: 'KPI_CARD',
        LOGIN_FORM: 'LOGIN_FORM',
        MODAL_COLLISION: 'MODAL_COLLISION',
        MODAL_DRIVER: 'MODAL_DRIVER',
        MODAL_GALLERY: 'MODAL_GALLERY',
        MODAL_MAP: 'MODAL_MAP',
        MODAL_THEFT: 'MODAL_THEFT',
        MODAL_VIDEO_PLAYER: 'MODAL_VIDEO_PLAYER',
        MODAL_VIOLATION: 'MODAL_VIOLATION',
        PERIOD_DATE_PICKER: 'PERIOD_DATE_PICKER',
        PERIODS_FILTER: 'PERIODS_FILTER',
        TABLE: 'TABLE',
        WIDGET_BOX: 'WIDGET_BOX',
    },
    PAGE: {
        INSTALLER: 'INSTALLER',
        SAFETY: 'SAFETY',
        PROVISIONING: 'PROVISIONING',
    },
    SCREEN: {
        SAFETY_DASHBOARD: 'SAFETY_DASHBOARD',
        SAFETY_DRIVERS: 'SAFETY_DRIVERS',
        SAFETY_INCIDENTS: 'SAFETY_INCIDENTS',
    },
    UI: {
        ACTION_BAR: 'ACTION_BAR',
        APP_TOP_BAR: 'APP_TOP_BAR',
        BODY: 'BODY',
        CAPTION: 'CAPTION',
        CARD_CONTENTS: 'CARD_CONTENTS',
        CELL: 'CELL',
        COLLAPSIBLE: 'COLLAPSIBLE',
        COLLAPSIBLE_AREA: 'COLLAPSIBLE_AREA',
        CONTROL: 'CONTROL',
        DROPDOWN_INPUT: 'DROPDOWN_INPUT',
        DURATION: 'DURATION',
        FOOTER: 'FOOTER',
        HEADER: 'HEADER',
        INCIDENTS_CARD: 'INCIDENTS_CARD',
        INFO_DETAILS: 'INFO_DETAILS',
        INFO_DRIVER: 'INFO_DRIVER',
        INFO_RESOURCE: 'INFO_RESOURCE',
        ITEM: 'ITEM',
        KPI_DRIVER_CARD: 'KPI_DRIVER_CARD',
        LABEL: 'LABEL',
        LINK: 'LINK',
        LIST: 'LIST',
        MENU: 'MENU',
        MULTI_CELL_VALUE: 'MULTI_CELL_VALUE',
        RESOURCE_ROW: 'RESOURCE_ROW',
        ROW: 'ROW',
        SCORE_ROW: 'SCORE_ROW',
        SEARCH_FIELD: 'SEARCH_FIELD',
        SPLIT_BUTTON: 'SPLIT_BUTTON',
        SUBTITLE: 'SUBTITLE',
        TAB: 'TAB',
        TITLE: 'TITLE',
        TOOLTIP: 'TOOLTIP', // Tooltip container element
        TRIP_DETAILS: 'TRIP_DETAILS',
        VALUE: 'VALUE',
        VIDEO_PLAYER: 'VIDEO_PLAYER',
        VIDEO_PROGRESS_BAR: 'VIDEO_PROGRESS_BAR',
    }
};

export const setAttr = {
    action: (value, disabled) => ({ [disabled ? testAttr.ACTION_DISABLED : testAttr.ACTION]: value }),
    feature: (value) => ({ [testAttr.FEATURE]: value }),
    page: (value) => ({ [testAttr.PAGE]: value }),
    screen: (value) => ({ [testAttr.SCREEN]: value }),
    ui: (value) => ({ [testAttr.UI]: value }),
};