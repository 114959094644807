import React, { useCallback, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Utils
import { t } from 'utils/dictionary';
import { formatDateMillis, intlFormats } from 'utils/timezone';
import { iconsName } from 'assets';
import { dataKeys } from 'utils/entities/driver';
import { useLengthUnit } from 'hooks';
import { rangeMsFromPeriod } from 'pages/safety_page/constants';
import { modalsIds } from 'components/modal/ModalsConstants';
import { testBy, setAttr } from 'utils/constants';
import { textHighlight } from 'utils/domHelper';
// Redux
import { getDriverItems, getParams, getSortOption } from 'store/selectors/safetyDrivers';
import { requestAndDownloadSaftyDrivers } from 'store/actions/safetyDrivers';
import { openModal } from 'store/actions/popup';
// Components
import { Table, MultiFieldsCell, CellWrapper } from 'components';

const { ACTION } = testBy;

export const DriverTable = (props) => {

    const dispatch = useDispatch();
    const { onClickSort, onLoadMore, isLoading, markText } = props;

    const filterParams = useSelector(getParams);
    const items = useSelector(getDriverItems);
    const activeSort = useSelector(getSortOption);

    const [selectedId, setSelectedId] = useState('');
    const [isDownloading, setIsDownloading] = useState(false);

    const mLength = useLengthUnit();

    const formatDistance = useCallback((value) => {
        if (typeof value !== 'number') return 'N/A';
        return mLength.f(value);
    }, [mLength]);

    const downloadDriversList = useCallback(async () => {
        if (isDownloading) return;

        setIsDownloading(true);
        const { period, ...restParams } = filterParams;
        let params = { ...restParams, maxCount: null };
        let formats = { length: formatDistance };

        if (!params.startTime || !params.endTime) {
            const rangeMs = rangeMsFromPeriod(period);
            params = { ...params, ...rangeMs };
        }

        await requestAndDownloadSaftyDrivers(params, formats);
        setIsDownloading(false);
    }, [filterParams, isDownloading, formatDistance]);

    const onClickRow = useCallback((data) => {
        const { item } = data;
        if (item.id !== selectedId) setSelectedId(item.id);
        dispatch(openModal({ data: item, id: modalsIds.DRIVER_MODAL }));
    }, [selectedId]);



    const columnMap = useMemo(() => ({
        main: {
            id: 'main',
            label: `${t('lblName')} \n ${t('lblResource')}`,
            dataKey: dataKeys.name,
            icon: { name: iconsName.PERSON },
            multiSortKeys: [
                { key: dataKeys.name, label: t('lblName') },
                { key: dataKeys.licenseplate, label: t('lblResource') }
            ],
            width: '20%',
            style: { minWidth: '350px' }
        },
        group: {
            id: 'group',
            label: t('lblDriverGroup'),
            dataKey: dataKeys.group,
            sortKey: dataKeys.group,
            // width: '12%',
            style: { minWidth: '120px' }
        },
        licenseExpiration: {
            id: 'licenseExpiration',
            label: t('lblLicenseExpiration'),
            dataKey: dataKeys.licenseExpiration,
            sortKey: dataKeys.licenseExpiration,
            // width: '12%',
            style: { minWidth: '140px' }
        },
        safetyScore: {
            id: 'safetyScore',
            label: t('lblSafetyScore'),
            dataKey: dataKeys.safetyScore,
            sortKey: dataKeys.safetyScore,
            width: '12%',
            style: { minWidth: '120px' }
        },
        distanceDriven: {
            id: 'distanceDriven',
            label: t('lblDistanceDriven'),
            dataKey: dataKeys.distance,
            sortKey: dataKeys.distance,
            width: '12%',
            style: { minWidth: '140px' }
        },
        trips: {
            id: 'trips',
            label: t('lblTrips'),
            dataKey: dataKeys.drivenTrips,
            sortKey: dataKeys.drivenTrips,
            width: '12%',
        },
        highRiskTrips: {
            id: 'highRiskTrips',
            label: t('lblHighRiskTrips'),
            dataKey: dataKeys.highRiskTrips,
            sortKey: dataKeys.highRiskTrips,
            width: '12%',
            style: { minWidth: '120px' }
        },
        // actions: {
        //     id: 'actions',
        //     width: '60px'
        // },
        download: {
            id: 'download',
            width: '60px',
            icon: {
                name: iconsName.DOWNLOAD_ARROW,
                title: isDownloading ? `${t('lblDownloading')}...` : t('lblDownloadCsv'),
                onClick: downloadDriversList,
                className: `click-able-icon${isDownloading ? ' disabled' : ''}`,
                ...setAttr.action(ACTION.DOWNLOAD_CSV_FILE, isDownloading)
            }
        },
    }), [downloadDriversList, isDownloading]);

    const columns = useMemo(() => Object.values(columnMap), [columnMap]);

    const renderValue = useCallback((value) => {
        if (!markText || value === undefined) return value;
        return textHighlight(value, markText);
    }, [markText]);

    const renderCell = useCallback((data) => {

        const { main, licenseExpiration, distanceDriven } = columnMap;

        const { item, column, index } = data;
        let value = item?.[column?.dataKey];

        switch (column?.id) {
            case licenseExpiration.id:
                value = value ? formatDateMillis(value, intlFormats.DATE_FULL) : 'N/A';
                break;
            case distanceDriven.id:
                value = formatDistance(value);
                break;
            case main.id:
                value = (
                    <MultiFieldsCell
                        data={item}
                        dataFields={[dataKeys.name, dataKeys.licenseplate]}
                        icon={item.image ? { src: item.image, useDefault: true, style: { borderRadius: '50%' }} : { name: iconsName.USER_AVATAR }}
                        renderValue={renderValue}
                    />
                );
                break;
            // case actions.id:
            //     value = <ActionsCell data={item} />;
            //     break;
            default:
                break;
        }
        return <CellWrapper>{value}</CellWrapper>;
    }, [formatDistance, columnMap, renderValue]);

    return (
        <Table
            className='drivers-table'
            columns={columns}
            isLoading={isLoading}
            items={items}
            sort={activeSort}
            onClickSort={onClickSort}
            onLoadMore={onLoadMore}
            renderCell={renderCell}
            // onClickRow={onClickRow}
            selectedId={selectedId}
        />
    );
};
