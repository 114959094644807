import React, { useCallback, useEffect, useState } from 'react';
import { InfoWindowF, Marker } from '@react-google-maps/api';
import { isValidCoordinate } from 'utils/helper';
import { Map } from 'components/google_maps';
import { ModalBlock, ModalInfoRow } from 'components/modal/components';

const PositionsMap = ({ markerIcons = {}, pointsAfter, pointsBefore, position, info, ...props }) => {

    const [ mapReady, setMapReady ] = useState(false);
    const [ mapFeatures, setMapFeatures ] = useState(null);
    const [ infoOpen, setInfoOpen ] = useState(false);

    const validPosition = isValidCoordinate(position);

    const toggleInfo = useCallback(() => {
        setInfoOpen(!infoOpen);
    }, [infoOpen]);

    const renderMarkersFor = (key, points) => Array.isArray(points) ? points.map((point, idx) => (
        <Marker
            key={`${key}${idx}`}
            position={point}
            options={{
                clickable: false,
                icon: markerIcons[key]
            }}
        />)
    ) : null;

    useEffect(() => {
        if (mapReady && validPosition) {
            const infoWindow = Array.isArray(info) ? <InfoWindowF
                position={position}
                onCloseClick={toggleInfo}
            >
                <ModalBlock compact>
                    {info.map((obj, idx) => <ModalInfoRow key={idx} label={obj.label}>{obj.value}</ModalInfoRow>)}
                </ModalBlock>
            </InfoWindowF> : null;

            const marker = <Marker
                position={position}
                options={{
                    animation: google.maps.Animation.DROP,
                    clickable: (infoWindow !== null),
                    icon: markerIcons.event
                }}
                onClick={toggleInfo}
            >{infoOpen && infoWindow}</Marker>;

            setMapFeatures(<>
                {renderMarkersFor('before', pointsBefore)}
                {marker}
                {renderMarkersFor('after', pointsAfter)}
            </>);
        }
    }, [mapReady, infoOpen]);

    const onMapLoad = useCallback((map) => {
        setMapReady(true);
        if (validPosition) {
            if (Array.isArray(pointsAfter) || Array.isArray(pointsBefore)) {
                const bounds = new google.maps.LatLngBounds();
                if (Array.isArray(pointsAfter)) {
                    pointsAfter.forEach((point) => {
                        bounds.extend(point);
                    });
                }
                if (Array.isArray(pointsBefore)) {
                    pointsBefore.forEach((point) => {
                        bounds.extend(point);
                    });
                }
                map.fitBounds(bounds);
            } else {
                map.setCenter(position);
            }
        }
    }, [validPosition, position, pointsAfter, pointsBefore]);

    return (
        <Map
            onLoad={onMapLoad}
            center={position}
            {...props}
        >
            {mapFeatures}
        </Map>
    );
};

export default PositionsMap;
