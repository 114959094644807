import React from 'react';
import './style.scss';

const ModalInfoSection = ({ children }) => (
    <div
        className="modal-info-section"
    >
        {children}
    </div>
);

export default ModalInfoSection;
