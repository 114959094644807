import React, { useState, useMemo, useEffect, useCallback } from 'react';
// Utils
import { iconsName, getSvgDataUrl } from 'assets';
import { getDateTime } from 'utils/timezone';
import { isValidCoordinate } from 'utils/helper';
import { useTrip } from 'pages/safety_page/features/maps/trip_map/useTrip';
// Components
import TripMap from 'pages/safety_page/features/maps/trip_map/TripMap';
import { TripMarker } from 'pages/safety_page/features/maps/trip_map/TripUiItems';




const TheftMap = (props) => {

    const {
        resolved,
        lastLocation,
        event,
    } = props;

    const { loadTrip, loaded, isLoading, items } = useTrip();

    const { eventTime, resourceId } = event;
    const [startTrace, setStartTrace] = useState(isValidCoordinate(event) ? event : undefined);
    const [endTrace, setEndTrace] = useState(isValidCoordinate(lastLocation) ? lastLocation : undefined);

    const getValidParams = useCallback(() => {
        if (!lastLocation?.eventTime || !eventTime) return;
        const end = lastLocation?.eventTime ?? getDateTime().toMillis();
        return { resourceId, start: eventTime, end };
    }, [lastLocation?.eventTime, resourceId, eventTime]);

    const isDiffPoints = useCallback((p1, p2) => p1?.lat !== p2?.lat || p1?.lng !== p2?.lng, []);

    useEffect(() => {
        const params = getValidParams();
        if (!loaded && params) {
            loadTrip(params);
        }
    }, [loadTrip, loaded, getValidParams]);

    useEffect(() => {
        if (isValidCoordinate(event)) {
            setStartTrace((prev) => isDiffPoints(event, prev) ? event : prev);
        }
    }, [event, isDiffPoints]);

    useEffect(() => {
        if (isValidCoordinate(lastLocation)) {
            setEndTrace((prev) => isDiffPoints(lastLocation, prev) ? lastLocation : prev);
        }
    }, [lastLocation, isDiffPoints]);

    const points = useMemo(() => [startTrace, ...items, endTrace].filter(isValidCoordinate), [items, startTrace, endTrace]);
    const center = useMemo(() => {
        if (isValidCoordinate(points[0])) return points[0];
    }, [points]);

    const lastLocationIcon = useMemo(() => ({ url: getSvgDataUrl(iconsName.PIN, { fill: '#000' }) }), []);
    const resulvedIcon = useMemo(() => resolved ? ({
        scaledSize: new google.maps.Size(30, 30),
        url: getSvgDataUrl(iconsName.THEFT_FINISHED),
        anchor: new google.maps.Point(16, 16),
    }) : undefined, [resolved]);

    const endPointModifier = useMemo(() => ({ icon: resulvedIcon ?? lastLocationIcon }), [resulvedIcon, lastLocationIcon]);
    // const startPointModifier = useMemo(() => points.length === 1 ? endPointModifier : undefined, [points.length, endPointModifier]);

    const modifiers = useMemo(() => ({
        point: { icon: { url: getSvgDataUrl(iconsName.PIN, { fill: '#000', opacity: 0.3 }) }},
        // startPoint: startPointModifier,
        endPoint: endPointModifier,
        linePath: {
            strokeOpacity: 0,
            strokeWeight: 0,
            icons: [{
                icon: {
                    path: 'M 0,-1 0,1',
                    strokeOpacity: 1,
                    strokeWeight: 3,
                    scale: 4
                },
                offset: '0',
                repeat: '20px',
            }]
        }
    }), [endPointModifier]);

    const additionMarker = useMemo(() => {
        if (points.length !== 1) return null;
        return <TripMarker point={points[0]} options={{ ...endPointModifier, zIndex: 3 }} />;
    }, [points, endPointModifier]);

    return (
        <TripMap
            center={center}
            points={points}
            isLoading={isLoading}
            reducePointsByPixels={30}
            modifiers={modifiers}
        >
            {additionMarker}
        </TripMap>
    );
};

export default TheftMap;
