import React from 'react';
import { Icon, Text } from 'components/common';
import './style.scss';



export const IconBox = (props) => {
    const { icon, name, index } = props;
    return (
        <div className='icon-box'>
            <Icon
                {...icon}
            />
            <div className='icon-info'>
                <Text className='icon-index' text={index} />
                <Text text={name} title={name} />
            </div>
        </div>
    );
};
