import AppTopBar from 'layout/app_top_bar/AppTopBar';
import AppSideBar from 'layout/app_side_bar/AppSideBar';
import AppPageRoutes from 'layout/app_page_routes/AppPageRoutes';
import AppLayout from 'layout/app_layout/AppLayout';


export default AppLayout;

export {
    AppTopBar,
    AppSideBar,
    AppPageRoutes,
};
