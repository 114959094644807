import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'utils/propTypes';
// Components
import { Overlay, Tooltip } from 'react-bootstrap';
import './style.scss';




/*
    overlayProps={{
        onHide: closePicker,
        target: ref.current,
        show: showPicker,
        placement: 'bottom',
        rootClose: true,
    }}
*/
export const CustomOverlay = (props) => {

    const { className, children, ...rest } = props;

    return (
        <Overlay
            className={`custom-overlay ${className}`}
            {...rest}
        >
            {(childProps) => (
                <Tooltip
                    className='custom-tooltip'
                    {...childProps}
                >
                    {children}
                </Tooltip>
            )}
        </Overlay>
    );
};

CustomOverlay.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};


export const CustomOverlayTrigger = (props) => {

    const targetRef = useRef();

    const { className, children, renderTrigger, onRef, onHide, onShouldHide, ...rest } = props;

    const [show, setShow] = useState(false);

    const setRef = useCallback((node) => {
        targetRef.current = node;
        onRef?.(node);
    }, [onRef]);

    const handleOnHide = useCallback((e) => {
        const shouldHide = onShouldHide?.(e) ?? true;
        setShow(!shouldHide);
        if (shouldHide) {
            onHide?.();
        }
    }, [onHide, onShouldHide]);

    const onClickTrigger = useCallback((e) => {
        setShow(true);
    }, []);

    const trigger = renderTrigger?.();

    if (!trigger) {
        return children;
    }

    return (
        <>
            <div
                ref={setRef}
                onClick={onClickTrigger}
            >
                {trigger}
            </div>
            <Overlay
                className={`custom-overlay ${className}`}
                show={show}
                rootClose
                target={targetRef.current}
                container={targetRef.current}
                onHide={handleOnHide}
                {...rest}
            >
                {(childProps) => (
                    <Tooltip
                        className='custom-tooltip'
                        {...childProps}
                    >
                        {children}
                    </Tooltip>
                )}
            </Overlay>
        </>
    );
};


CustomOverlayTrigger.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    renderTrigger: PropTypes.func,
    onRef: PropTypes.func,
    onHide: PropTypes.func,
};