import { useCallback, useMemo } from 'react';
// Utils
import { iconsName } from 'assets';
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { classAttribute } from 'utils/helper';
// Components
import Icon from 'components/common/icons/Icon';
import './style.scss';


const SearchInput = (props) => {

    const {
        id,
        name,
        value,
        onChange,
        onClickClear,
        className,
        iconProps = {},
        autoFocus,
        placeholder
    } = props;

    const handleOnChange = useCallback(({ target }) => {
        const { name, value } = target;
        onChange?.({ name, value });
    }, [onChange]);

    const handleOnClickClear = useCallback(() => {
        onClickClear?.({ name });
    }, [onClickClear, name]);

    const classes = useMemo(() => classAttribute([
        'search-input-container',
        className ?? '',
    ]), [className]);

    return (
        <div
            className={classes}
            {...setAttr.ui(testBy.UI.SEARCH_FIELD)}
        >
            <input
                id={id}
                name={name}
                placeholder={placeholder || t('lblSearch')}
                value={value}
                onChange={handleOnChange}
                autoFocus={autoFocus}
                type="text"
            />
            {
                Boolean(iconProps) && (
                    <Icon
                        name={!value ? iconsName.SEARCH : iconsName.CANCEL}
                        onClick={!value ? undefined : handleOnClickClear}
                        useDefault
                        {...iconProps}
                    />
                )
            }
        </div>
    );
};

export default SearchInput;
