import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Utils
import { iconsName } from 'assets';
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { formatDateMillis, intlFormats } from 'utils/timezone';
// Components
import { TripsList } from 'components';
import { BaseModal, Text } from 'components/common';
import { DriverInfo, DriverLicensePopover, ModalBlock, ModalBlockTitle, ModalContainer, ModalInfoRow, ModalInfoSection, ModalResourceInfo } from 'components/modal/components';
import { modalsIds } from 'components/modal/ModalsConstants';
import { TripsKpis } from 'components/modal/trips-kpis/TripsKpis';
import { fetchDriverDetails, fetchDriverMileage, fetchDriverImage } from 'store/actions/drivers';
import { closeModal } from 'store/actions/popup';
import { getDriverDetailsState, getDriverKpis } from 'store/selectors/drivers';
import './style.scss';


const DriverModal = ({ data }) => {

    const dispatch = useDispatch();
    const details = useSelector(getDriverDetailsState);
    const kpis = useSelector(getDriverKpis);

    useEffect(() => {
        dispatch(fetchDriverDetails(data.driverId));
        dispatch(fetchDriverMileage(data.driverId));
    }, [dispatch, data.driverId]);

    useEffect(() => {
        if (!details?.image && details?.imageUrl) {
            dispatch(fetchDriverImage([details?.imageUrl]));
        }
    }, [dispatch, details?.imageUrl, details?.image]);

    const getDriverInfo = useCallback(() => (
        <div className="modal-subtitle">
            <DriverInfo
                licenseExpiration={details?.licenseExpiration}
                mobilePhoneNumber={details?.mobilePhoneNumber}
            />
        </div>
    ), [details?.licenseExpiration, details?.mobilePhoneNumber]);

    const formattedTime = useCallback((time) => time ? formatDateMillis(time, intlFormats.DATE_SHORT) : 'N/A', []);

    const licenseRowValue = useMemo(() => (
        details?.licenseNum ?
            <DriverLicensePopover
                imageUrl={details?.imageUrl}
                licenseExpiration={details?.licenseExpiration}
                licenseNum={details?.licenseNum}
                licenseType={details?.licenseType}
                text={details.licenseNum}
            /> : 'N/A'
    ), [details?.imageUrl, details?.licenseExpiration, details?.licenseNum, details?.licenseType]);

    const onClickClose = useCallback(() => {
        dispatch(closeModal(modalsIds.DRIVER_MODAL));
    }, [dispatch]);

    return (
        <BaseModal
            onClickClose={onClickClose}
            icon={
                details?.image
                    ? {
                        src: details?.image,
                        useDefault: true,
                        style: { borderRadius: '50%' },
                        width: 50,
                        height: 50
                    }
                    : { name: iconsName.USER_AVATAR, stroke: '#000', title: t('lblDriverImage') }
            }
            headerText={data.name}
            stateComponent={getDriverInfo}
            testAttributes={setAttr.feature(testBy.FEATURE.MODAL_DRIVER)}
        >
            <ModalContainer>
                <ModalInfoSection>
                    <TripsKpis
                        className="trips-kpis"
                        kpis={kpis}
                    />
                    <ModalBlock
                        testAttributes={setAttr.ui(testBy.UI.INFO_DETAILS)}
                    >
                        <ModalBlockTitle className="title" title={t('lblDriverDetails')} icon={{ name: iconsName.USER_AVATAR, title: t('lblAssociatedDriver') }} />
                        <ModalInfoRow label={t('lblDriverGroupTags')}>{details?.group}</ModalInfoRow>
                        <ModalInfoRow label={t('lblNationalId')}>{details?.nationalId}</ModalInfoRow>
                        <ModalInfoRow label={t('lbldriverLicenseNumber')}>{licenseRowValue}</ModalInfoRow>
                        <ModalInfoRow label={t('lblEmail')}>{details?.email}</ModalInfoRow>
                        <ModalInfoRow label={t('lblDateOfBirth')}>{formattedTime(details?.dateOfBirth)}</ModalInfoRow>
                        <ModalInfoRow label={t('lblEmployeeSince')} subLabel={`(${t('lblCreationDate')})`}>{formattedTime(details?.employeeSince)}</ModalInfoRow>
                        <ModalInfoRow label={t('lblDriverCode')} subLabel={`(${t('lblDallasKey')})`}>{details?.driverCode}</ModalInfoRow>
                        <ModalInfoRow label={t('lblPsychophysicalExamination')}>{formattedTime(details?.psychophysicalExamination)}</ModalInfoRow>
                        <ModalInfoRow label={t('lblAnotherPhone')} subLabel={details?.anotherContact ? `(${details?.anotherContact})` : ''}>{details?.anotherPhone ?? 'N/A'}</ModalInfoRow>
                        <ModalInfoRow label={t('lblFreeText')}><Text text={details?.freeText ?? 'N/A'} title={details?.freeText} /></ModalInfoRow>
                    </ModalBlock>
                    <ModalResourceInfo
                        resourceId={details?.resourceId ?? 0}
                    />
                </ModalInfoSection>
                <ModalBlock
                    className="trips-list-block"
                    testAttributes={setAttr.ui(testBy.UI.TRIP_DETAILS)}
                >
                    <ModalBlockTitle
                        className="title"
                        title={`${t('lblTrips')} (${t('lblLast30Days').toLowerCase()})`}
                        icon={{ name: iconsName.TRIP_ROUTE }}
                    />
                    <TripsList
                        trips={[]}
                        resource={{
                            id: details?.resourceId ?? 0,
                            name: data.resourceName
                        }}
                    />
                </ModalBlock>
            </ModalContainer>
        </BaseModal>
    );
};

export default DriverModal;
