import { DateTime, Settings, Duration } from 'luxon';
import { pf_t, t } from 'utils/dictionary';


const {
    DATETIME_FULL,
    DATE_FULL,
    DATE_SHORT,
    DATE_MED,
    DATETIME_FULL_WITH_SECONDS,
    DATETIME_MED,
    DATE_MED_WITH_WEEKDAY,
    DATETIME_SHORT,
    TIME_24_SIMPLE,
    TIME_24_WITH_LONG_OFFSET,
    TIME_24_WITH_SECONDS,
    TIME_24_WITH_SHORT_OFFSET,
    TIME_SIMPLE,
    TIME_WITH_LONG_OFFSET,
    TIME_WITH_SECONDS,
    TIME_WITH_SHORT_OFFSET,
} = DateTime;

export const intlFormats = {
    DATETIME_FULL,
    DATE_FULL,
    DATE_SHORT,
    DATE_MED,
    DATETIME_FULL_WITH_SECONDS,
    DATETIME_MED,
    DATE_MED_WITH_WEEKDAY,
    DATETIME_SHORT,
    TIME_24_SIMPLE,
    TIME_24_WITH_LONG_OFFSET,
    TIME_24_WITH_SECONDS,
    TIME_24_WITH_SHORT_OFFSET,
    TIME_SIMPLE,
    TIME_WITH_LONG_OFFSET,
    TIME_WITH_SECONDS,
    TIME_WITH_SHORT_OFFSET,
};

export const SECOND = 1000;
export const HALF_MINUTE = SECOND * 30;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;

export const allowedFormats = {
    DATE_TIME_LONG: 'yyyy-MM-dd hh:mm:ssa',
    MONTH_YEAR: 'MM/yyyy',
    FILE_NAME_DATE_TIME: "yyyy-mm-dd'T'HH-mm-ss's'",
};

const allUnits = ['year', 'month', 'day', 'hour', 'minute', 'second', 'millisecond'];

const isHaveAmPmES = (strDateTime) => (strDateTime.replace(/\s/g, '').endsWith('a.m.') || strDateTime.replace(/\s/g, '').endsWith('p.m.'));

const getAmPmFrom24HourES = (strDateTime24) => {
    const hours = parseInt(strDateTime24.substr(0, strDateTime24.indexOf(':')).split(' ')[1], 10);
    return ((hours >= 12) ? ' p. m.' : ' a. m.');
};

const getAdjustedMidDayToHour12ES = (hour, strDateTime) => {
    if (hour === '0' || hour === '00') {
        return strDateTime.replace(`${hour}:`, '12:');
    }
    return strDateTime;
};


// General utils for dates

export const getNowMillis = () => DateTime.local().toMillis();

/**
     * Return DateTime instance.
     * @param {Number} millis - Date as milliseconds
     * @param [options={zone}] - the unit or units (such as 'hours' or 'days') to include in the duration
*/
export const getDateTime = (millis, options) => millis ? DateTime.fromMillis(millis, options) : DateTime.local(options);


/**
     * Return the difference between the given date to current date (now).
     * @param {Number} millis - Date as milliseconds
     * @param [unit=['milliseconds']] - the unit or units (such as 'hours' or 'days') to include in the duration
*/
export const getDiffFromNow = (millis, units = ['days']) => getDiffBetweenDates(getNowMillis(), millis, units);


/**
     * Return the difference between dateString1 and dateString2.
     * @param {Number} fromMillis - Date as string
     * @param {Number} toMillis - Date as string
     * @param [unit=['milliseconds']] - the unit or units units (such as 'hours' or 'days') to include in the duration
*/
export const getDiffBetweenDates = (fromMillis, toMillis, units = ['days']) => {
    const fromDate = getDateTime(fromMillis);
    const toDate = getDateTime(toMillis);
    return fromDate.diff(toDate, units).toObject();
};


/**
 * Return formatted datetime string.
 * Default function to format datetime.
 * Use this in all cases, which not require moment.js date
 * @param {DateTime} dateTime - Luxon DateTime instance.
 * @param {DateTime.DateTimeFormatOptions} formatOpts - DateTimeFormatOptions.
 */
export const formatDateTime = (dateTime, formatOpts = DateTime.DATETIME_SHORT_WITH_SECONDS, options = {}) => {

    const { locale } = options;
    const localeName = locale ?? Settings.defaultLocale?.toLowerCase() ?? '';

    if (localeName.startsWith('es-mx')) {
        const esMxFormat = {
            ...formatOpts,
            hour12: true
        };

        const dateStr = dateTime.toLocaleString(esMxFormat);
        const dateComponent = dateStr.substr(0, dateStr.indexOf(':')).split(' ');
        const esMXTimeString = getAdjustedMidDayToHour12ES(dateComponent[1], dateStr);

        if (!isHaveAmPmES(dateStr)) {
            return esMXTimeString + getAmPmFrom24HourES(dateTime.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS).toLowerCase());
        }
        return esMXTimeString;
    }

    return dateTime.toLocaleString(formatOpts);
};


/**
 * Return formatted datetime string.
 * Default function to format datetime.
 * Use this in all cases, which not require moment.js date
 * @param {Number} millis - date as milliseconds.
 * @param {DateTime.DateTimeFormatOptions} formatOpts - DateTimeFormatOptions.
 */
export const formatDateMillis = (millis, formatOpts = DateTime.DATETIME_SHORT_WITH_SECONDS) => formatDateTime(getDateTime(millis), formatOpts);


/**
 * Return bool for 2 date with same start of.
 * Useful for check same day, mounth, year, hour etc..
 * @param {DateTime} date1 - Luxon DateTime instance.
 * @param {DateTime} date2 - Luxon DateTime instance.
 */
export const isStartOfSameDateTime = (date1, date2, startOf = 'day') => date1.startOf(startOf).toMillis() === date2.startOf(startOf).toMillis();


/**
 * Return bool for 2 date with same start of.
 * Useful for check same day, mounth, year, hour etc..
 * @param {Number} millis1 - Milliseconds.
 * @param {Number} millis2 - Milliseconds.
 */
export const isStartOfSameDate = (millis1, millis2, startOf) => {
    const dateTime1 = getDateTime(millis1);
    const dateTime2 = getDateTime(millis2);
    return isStartOfSameDateTime(dateTime1, dateTime2, startOf);
};


// Custom helper for dates

/**
 * Return time description with labels
 * @param {Number} millis - millis as date.
 */
export const getTimeDesc = (millis) => {

    const now = getDateTime();
    const date = getDateTime(millis);

    const timeDiff = getDiffFromNow(date.toMillis(), ['hours', 'minutes', 'seconds']);
    const { hours } = timeDiff;
    let shortTimeFormat;
    if (hours <= 6) {
        shortTimeFormat = hours ?
            pf_t.timeAgo(timeDiff.hours).hours :
            pf_t.timeAgo(timeDiff.minutes).minutes;
    } else if (isStartOfSameDateTime(now, date)) {
        shortTimeFormat = `${t('lblToday')} ${formatDateTime(date, DateTime.TIME_SIMPLE)}`;
    }
    return shortTimeFormat || formatDateTime(date, DateTime.DATETIME_SHORT);
};

export const millisToAllowedFormat = (millis, format) => millis ? (Object.values(allowedFormats).includes(format) ? DateTime.fromMillis(millis).toFormat(format) : millis) : '';

export const getFixedOffsetUtc = (fractionalHours) => {
    // offset like 1.5 will formated to UTC+1:30
    if (fractionalHours === 0) return 'UTC';
    const MINUTES_IN_HOUR = 60;
    const minutes = ~~(Math.abs((fractionalHours % 1)) * MINUTES_IN_HOUR);
    const hours = ~~fractionalHours;
    const time = minutes ? `${hours}:${minutes}` : hours;
    const plus = fractionalHours > 0 ? '+' : '';
    const offsetFormat = `UTC${plus}${time}`;
    return { minutes, hours, offsetFormat };
};

export const durationFromObject = (obj, opts) => Duration.fromObject(obj, opts);

export const dateObjByUnits = (date, units = allUnits) => units.reduce((obj, unit) => ({ ...obj, [unit]: date.get(unit) }), {});
export const outRangeDateTimes = (date, start, end) => date < start || date > end;
export const sameMillis = (date1, date2) => (date1?.isValid && date2?.isValid) && +date1 === +date2;

// /**
//  * Return lblToday, lblYesterday...
//  * @param {string} date - date format.
//  */
// function getDayNameFromDate(date) {
//     const DATE = formatDateStr(date, DateTime.DATE_SHORT);
//     switch (DATE) {
//         case (DateTime.local().toLocaleString()):
//             return 'lblToday';
//         case (DateTime.local().minus({ days: 1 }).toLocaleString()):
//             return 'lblYesterday';
//         default:
//             return 'lblOlder';
//     }
// }

