import { checkboxStates } from 'components/common';
import { t } from 'utils/dictionary';

export const filterSchema = (filterTypes) => Array.isArray(filterTypes) ? filterTypes : [filterTypes];

export const filterType = (name, options = [], selections = {}, appliedSelections = {}, summary = '') => (typeof name !== 'string') ? null : {
    name,
    label: name,
    options,
    selections,
    appliedSelections,
    summary,
};

export const filterOption = (id, label, value = 0, icons = [], options = [], visible = true) => (typeof id !== 'string' && typeof id !== 'number') ? null : {
    id,
    icons,
    label: label ?? 'Missing filter option label',
    options,
    value,
    visible,
};

/* Utils */

const setAllFilterValues = (filter, value) => {
    if (filter && Array.isArray(filter.options)) {
        const updatedOptions = filter.options.map((option) => {
            option.value = value;
            if (Array.isArray(option.options)) {
                option.options.forEach((option) => { option.value = value; });
            }
            return option;
        });
        return { ...filter, options: updatedOptions };
    }
    return filter;
};

const filterWithSelections = (filter) => {
    if (filter && Array.isArray(filter.options)) {
        let selections = {};
        filter.options.forEach((option) => {
            if (option.value > 0) {
                selections[option.id] = { label: option.label, value: option.value };
                if (Array.isArray(option.options) && option.options.length > 0) {
                    let subSelections = {};
                    option.options.forEach((option) => {
                        if (option.value > 0) {
                            subSelections[option.id] = { label: option.label, value: option.value };
                        }
                    });
                    selections[option.id]['selections'] = subSelections;
                }
            }
        });
        return { ...filter, options: [], selections: selections, summary: summaryFromSelections(selections) };
    }
    return filter;
};

const summaryFromSelections = (selections) => {
    let summary = '';
    const numSelections = Object.keys(selections ?? {}).length;
    if (numSelections > 0) {
        let what = t('lblOption');
        const numSubSelections = Object.values(selections).reduce((num, obj) => num + Object.keys(obj.selections ?? {}).length, 0);
        if (numSubSelections > 0) {
            what += numSubSelections > 1 ? 's' : '';
            summary += `${numSubSelections} ${what}, `;
            what = t('lblGroup');
        }
        what += numSelections > 1 ? 's' : '';
        summary += `${numSelections} ${what} ${t('lblSelected')}`;
    }
    return summary;
};

const filterOptionsFromData = (filter, search, data) => {
    const { selections, name } = filter;
    if (Array.isArray(data)) {
        // mixed ungrouped/grouped data not supported
        const unGroupedData = data.filter((datum) => datum.id);
        if (unGroupedData.length > 0) {
            return optionsWithVisibility(data.map((datum) => {
                const id = `${name}_${datum.id}`;
                return filterOption(id, datum.name ?? '', selections[id]?.value ?? 0, datum.icons ?? []);
            }), search);
        }

        const groupedData = data.filter((datum) => datum.groupId && Array.isArray(datum.items));
        return groupedData.map((datum) => {
            const groupId = `${name}_group_${datum.groupId}`;
            const items = optionsWithVisibility(datum.items.map((item) => {
                const itemId = `${name}_${item.id}`;
                return filterOption(itemId, item.name, selections[groupId]?.selections[itemId]?.value ?? 0, item.icons ?? []);
            }), search);
            const visible = items.filter((option) => option.visible).length > 0;
            return filterOption(groupId, datum.groupName ?? '', selections[groupId]?.value ?? 0, datum.icons ?? [], items, visible);
        });
    }

    return [];
};

const optionsWithVisibility = (options, search) => {
    const lcSearch = search.toLowerCase();
    return options.map((option) => ({ ...option, visible: option.label.toLowerCase().includes(lcSearch) }));
};

const filterSelectionsToCSV = (filter) => {
    let ids = [];
    const { name, appliedSelections } = filter;
    const getStrippedIds = (selections) => Object.keys(selections).map((id) => id.replace(`${name}_`, ''));
    const groupIds = Object.keys(appliedSelections).filter((id) => id.includes(`${name}_group_`));
    if (groupIds.length > 0) {
        Object.values(appliedSelections).forEach((obj) => {
            ids = ids.concat(getStrippedIds(obj.selections ?? {}));
        });
    } else {
        ids = getStrippedIds(appliedSelections);
    }
    return { [`${name}`]: ids.join(',') };
};

const filterTrailData = (filters) => {
    let data = [];
    if (Array.isArray(filters)) {
        const selectionData = (selections, label) => {
            let data = [];
            Object.keys(selections).forEach((id) => {
                data.push({ [id]: `${label.toUpperCase()}: ${selections[id].label}` });
            });
            return data;
        };
        filters.forEach((filter) => {
            const { label, name, appliedSelections } = filter;
            const groupIds = Object.keys(appliedSelections).filter((id) => id.includes(`${name}_group_`));
            if (groupIds.length > 0) {
                Object.values(appliedSelections).forEach((obj) => {
                    data = data.concat(selectionData(obj.selections ?? {}, t(label)));
                });
            } else {
                data = data.concat(selectionData(appliedSelections, t(label)));
            }
        });
    }
    return data;
};

const filtersWithDeletedSelection = (filters, selectionId) => {
    const [filterName] = selectionId.split('_');
    const filterIndex = filters.findIndex((f) => f.name === filterName);
    const filter = { ...filters[filterIndex] ?? {}};
    if (filter.appliedSelections) {
        Object.keys(filter.appliedSelections).every((groupId) => {
            if (groupId === selectionId) {
                delete (filter.appliedSelections[selectionId]);
                return false;
            } else {
                const subSelections = Object.keys(filter.appliedSelections[groupId].selections ?? {});
                if (subSelections.length > 0) {
                    subSelections.every((id) => {
                        if (id === selectionId) {
                            delete (filter.appliedSelections[groupId].selections[id]);
                            return false;
                        }
                        return true;
                    });

                    if (Object.keys(filter.appliedSelections[groupId].selections ?? {}).length === 0) {
                        delete (filter.appliedSelections[groupId]);
                    }
                }
            }
            return true;
        });
        filter.selections = filter.appliedSelections;
        filter.summary = summaryFromSelections(filter.selections);
        filters[filterIndex] = filter;
    }
    return filters;
};

const hasAppliedChanges = (filter) => {
    const { appliedSelections } = filter ?? {};
    return Object.keys(appliedSelections ?? {}).length > 0;
};

const hasPendingChanges = (filter) => {
    const areSame = (ar1, ar2) => {
        if (Array.isArray(ar1) && Array.isArray(ar2)) {
            return ar1.every((el) => ar2.includes(el)) && ar2.every((el) => ar1.includes(el));
        }
        return false;
    };
    const { selections, appliedSelections } = filter ?? {};
    if (areSame(Object.keys(selections ?? {}), Object.keys(appliedSelections ?? {}))) {
        // if selections are empty some returns false
        return Object.keys(selections ?? {}).some((key) => !areSame(Object.keys(selections[key]?.selections ?? {}), Object.keys(appliedSelections[key]?.selections ?? {})));
    }
    return true;
};

const someFilterWithPendingChanges = (filters) => {
    if (filters && Array.isArray(filters)) {
        return filters.some((filter) => hasPendingChanges(filter));
    }
    return false;
};

const filterWithForcedSelections = (filter, selectionIds, data, checkboxValue = checkboxStates.SELECTED) => {
    if (Array.isArray(selectionIds)) {
        // reset currently applied selections
        filter.selections = filter.appliedSelections;
        // build options from data applying current selections
        const options = filterOptionsFromData(filter, '', data);
        // force option selections in selectionIds
        options.forEach((option) => {
            if (selectionIds.includes(option.id)) {
                if (Array.isArray(option.options)) {
                    option.options.forEach((subOption) => {
                        subOption.value = checkboxValue;
                    });
                }
                option.value = checkboxValue;
            } else if (Array.isArray(option.options) && option.options.length > 0) {
                option.options.forEach((subOption) => {
                    if (selectionIds.includes(subOption.id)) {
                        subOption.value = checkboxValue;
                    }
                });
                option.value = option.options.every((subOption) => subOption.value > 0) ? checkboxStates.SELECTED :
                    (option.options.some((subOption) => subOption.value > 0) ? checkboxStates.PART_SELECTED : checkboxStates.UNSELECTED);
            }
        });
        // update selections from options
        const updatedFilter = filterWithSelections({ ...filter, options });
        // apply updated selections
        return { ...updatedFilter, appliedSelections: updatedFilter.selections };
    }
    return filter;
};

export const filterUtils = {
    appliedSelectionsToCSV: filterSelectionsToCSV,
    filtersWithDeletedSelection,
    filterWithForcedSelections,
    filterWithSelections,
    hasAppliedChanges,
    hasPendingChanges,
    optionsFromData: filterOptionsFromData,
    setAllValues: setAllFilterValues,
    someFilterWithPendingChanges,
    trailData: filterTrailData
};
