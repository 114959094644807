import { DateTime, Settings } from 'luxon';
import * as fnsLocales from 'date-fns/locale';
// import { formatDateTime } from 'utils/timezone';



const localeToFnsCode = {
    'en-us': 'en-US',
    'es-es': 'es',
    'pt-br': 'pt-BR',
    'en-za': 'en-ZA',
};


/**
 * Return luxon datetime from JS date according to user TZ.
 * @param {Date} dateJs - date as milliseconds.
 */
const jsDateToLuxonDateTime = (date) => {
    /*
        The native JS date work with UTC time and manipulating outputs and inputs according to local machine.
        We going to use only outputs value (year, month, day, hour, etc..) for creating luxon DateTime.
    */
    if (!date || !(date instanceof Date)) return date;
    const luxonDate = DateTime.fromObject({
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        hour: date.getHours(),
        minute: date.getMinutes(),
        second: date.getSeconds(),
        millisecond: date.getMilliseconds(),
    });
    // console.log('js to luxon', { js: date.toLocaleString(), luxon: formatDateTime(luxonDate) });
    return luxonDate;
};

const luxonDateTimeToJsDate = (date) => {
    if (!date || !(date instanceof DateTime)) return date;
    const jsDate = new Date(date.year, date.month - 1, date.day, date.hour, date.minute, date.second, date.millisecond);
    // console.log('luxon to js', { js: jsDate.toLocaleString(), luxon: formatDateTime(date) });
    return jsDate;
};


const isMatch = (data) => [typeof data === 'object', data instanceof DateTime, Array.isArray(data)].some((match) => match);

const notRelevant = (data) => [
    data === undefined,
    data === null,
    typeof data === 'function',
    data instanceof Event,
    data instanceof Element,
    data instanceof SyntaxError,
    data instanceof PointerEvent
].some((match) => match);



// func(...convertDates(args));
const cb = (func, fromJsDates) => (...args) => func?.(...fromJsDates(args.slice(0, args.length - 1)));

export class LuxonAdapter {

    static FnsLocale;

    static fromJsDate = (jsDate) => jsDateToLuxonDateTime(jsDate);

    static toJsDate = (luxonDate) => luxonDateTimeToJsDate(luxonDate);

    static toJsDates = (data) => {

        if (!isMatch(data) || notRelevant(data) || data instanceof Date) return data;

        if (data instanceof DateTime) {
            return LuxonAdapter.toJsDate(data);
        }
        if (Array.isArray(data)) {
            return data.map((v) => LuxonAdapter.toJsDates(v));
        }
        if (typeof data === 'object' && Boolean(data)) {
            const copy = { ...data };
            Object.entries(data).forEach(([prop, value]) => {
                copy[prop] = LuxonAdapter.toJsDates(value);
            });
            return copy;
        }
    };

    static fromJsDates = (data) => {

        if (!isMatch(data) || notRelevant(data) || data instanceof DateTime) return data;

        if (data instanceof Date) {
            return LuxonAdapter.fromJsDate(data);
        }
        if (Array.isArray(data)) {
            return data.map((v) => LuxonAdapter.fromJsDates(v));
        }
        if (typeof data === 'object' && Boolean(data)) {
            const copy = { ...data };
            Object.entries(data).forEach(([prop, value]) => {
                copy[prop] = LuxonAdapter.fromJsDates(value);
            });
            return copy;
        }
    };

    static fromJsDatesCallbacks = (props) => {
        const copy = { ...props };
        Object.entries(props).forEach(([prop, value]) => {
            if (typeof value === 'function') {
                copy[prop] = cb(value, LuxonAdapter.fromJsDates);
            }
        });
        return copy;
    };

    static adaptProps = (props) => {
        // Taking care of js dates from component callbacks outputs, only first level props.
        const adapted = LuxonAdapter.fromJsDatesCallbacks(props);
        // Taking care of inputs js dates from components props, all level props (nested object).
        return LuxonAdapter.toJsDates(adapted);
    };

    static fnsLocale = () => {
        const locale = Settings.defaultLocale.toLowerCase();
        const fnsCode = localeToFnsCode[locale];
        if (fnsCode === LuxonAdapter.FnsLocale?.code) return LuxonAdapter.FnsLocale;

        const fns = Object.values(fnsLocales);
        let found = fns.find((f) => f.code?.toLowerCase() === locale);
        if (!found) {
            found = fns.find((f) => f.code?.toLowerCase() === locale.toLowerCase().split('-')[0]);
        }
        LuxonAdapter.FnsLocale = found;
        return LuxonAdapter.FnsLocale;
    };
}