import React, { useCallback } from 'react';
// Components
import { SplitButton } from 'components/common';
import './style.scss';




export const SplitButtonInput = React.forwardRef((props, ref) => {

    const {
        onClick,
        icon,
        className,
        onClickButton,
        autocomplete = 'off',
        ...rest
    } = props;

    const handleClick = useCallback((e) => {
        e.preventDefault();
        onClick?.(e);
    }, [onClick]);

    return (
        <SplitButton
            ref={ref}
            onClick={handleClick}
            icon={icon}
            className={className}
            onClickButton={onClickButton}
        >
            <input
                autoComplete={autocomplete}
                type="text"
                {...rest}
            />
        </SplitButton>
    );
});
