import React, { useMemo } from 'react';
import { IconDropdown, useDayPicker } from 'react-day-picker';
import { Menu } from 'components/menu/Menu';
import { setAttr, testBy } from 'utils/constants';
import './style.scss';




/**
 * Render a styled select component – displaying a caption and a custom
 * drop-down icon.
 */
export const Dropdown = (props) => {

    const { onChange, value: currVal, children, caption, className, style } = props;
    const dayPicker = useDayPicker();

    const options = useMemo(() => React.Children.map(children, (option) => {
        if (React.isValidElement(option)) {
            const { children, value } = option.props;
            const actionType = value > 11 ? testBy.ACTION.SELECT_YEAR : testBy.ACTION.SELECT_MONTH;
            const testAttributes = setAttr.action(`${actionType}_${value}`);
            return { id: value, target: { value }, label: children, active: value === currVal, testAttributes };
        }
        return option;
    }), [children, currVal]);

    const IconDropdownComponent = dayPicker.components?.IconDropdown ?? IconDropdown;

    return (
        <div className={className} style={style}>
            <Menu
                items={options}
                onClick={onChange}
                classes={['calendar-dropdown']}
            >
                <div
                    className={dayPicker.classNames.caption_label}
                    style={dayPicker.styles.caption_label}
                >
                    {caption}
                    <IconDropdownComponent
                        className={dayPicker.classNames.dropdown_icon}
                        style={dayPicker.styles.dropdown_icon}
                    />
                </div>
            </Menu>
        </div>
    );
};