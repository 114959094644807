import PropTypes, { propTypes } from 'utils/propTypes';
// Components
import Button from 'components/common/buttons/Button';
import { Spinner } from 'components/common/spinner/Spinner';



export const ButtonSpinner = (props) => {
    const {
        spinner,
        isLoading,
        children,
        ...rest
    } = props;

    return (
        <Button {...rest}>
            {children}
            <Spinner
                size='sm'
                isLoading={isLoading}
                {...spinner}
            />
        </Button>
    );
};


ButtonSpinner.propTypes = {
    spinner: propTypes.spinnerProps,
    isLoading: PropTypes.bool,
    children: PropTypes.node,
    rest: propTypes.buttonProps
};