import React, { useCallback, useMemo } from 'react';
// Utils
import { iconsName } from 'assets';
import { getLocationField } from 'utils/fieldsTemplates';
import { formatDateMillis, getTimeDesc } from 'utils/timezone';
import { pf_t, t } from 'utils/dictionary';
import { setAttr, testBy } from 'utils/constants';
import PropTypes, { propTypes } from 'utils/propTypes';
// Components
import { Icon, Icons, Text } from 'components/common';
import './style.scss';


const { UI, ACTION } = testBy;


const IncidentCard = (props) => {
    const {
        style,
        item,
        onClickVideo,
        onClick,
    } = props;

    const {
        eventTime,
        driverName,
        severity,
        videos,
        address,
        lat,
        lng,
        type,
        resourceName,
    } = item;

    const handleClickVideo = useCallback((e) => {
        onClickVideo?.(item);
        e.stopPropagation();
    }, [onClickVideo, item]);

    const handleClick = useCallback(() => {
        onClick?.(item);
    }, [onClick, item]);

    const eventName = pf_t.eventName(type);

    const videoButton = useMemo(() => {
        const name = videos ? iconsName.VIDEO_ENABLED : iconsName.VIDEO_DISABLED;
        const style = videos ? { margin: 4 } : { opacity: 0.8, margin: 4 };
        const title = videos ? null : t('lblNoVideoAvailable');
        return (
            <Icon
                style={style}
                name={name}
                width={22}
                height={22}
                onClick={videos ? handleClickVideo : null}
                title={title}
                useDefault
                {...setAttr.action(ACTION.OPEN_VIDEOS_PLAYER, !videos)}
            />
        );
    }, [videos, handleClickVideo]);


    return (
        <div
            className="incident-card"
            style={style}
            {...setAttr.ui(UI.INCIDENTS_CARD)}
        >
            <div className="section" onClick={handleClick}>
                <Icons.Incident
                    severity={severity}
                    width={40}
                    height={40}
                    type={type}
                />
                <div className="fields-wrapper">
                    <Text
                        text={driverName ?? resourceName ?? ''}
                        className="title"
                    />
                    <Text
                        text={eventName}
                        className="sub-title"
                    />
                    <div>
                        <Text
                            text={address ?? getLocationField({ lat, lng })}
                            title={address ?? getLocationField({ lat, lng })}
                            className="text-small"
                        />
                        <Text
                            text={` | `}
                            className="text-separator"
                        />
                        <Text
                            text={getTimeDesc(eventTime)}
                            title={formatDateMillis(eventTime)}
                            className={`text-small ${testBy.CLASS.CARD_TIME}`}
                        />
                    </div>
                </div>
                <div className="right-side">
                    {/* <div className="right-side-section">
                        <Icon
                            name={iconsName.CHAT}
                            width={22}
                            height={22}
                            style={{margin: 4}}
                        />
                        <Icon
                            name={iconsName.NOTIFICATION}
                            width={22}
                            height={22}
                            style={{margin: 4}}
                        />
                    </div> */}
                    <div className="right-side-section">
                        {videoButton}
                    </div>
                </div>
            </div>
        </div>
    );
};


IncidentCard.propTypes = {
    item: propTypes.incident,
    style: PropTypes.shape({}),
    onClickVideo: PropTypes.func,
    onClick: PropTypes.func
};

export default IncidentCard;
