import { useCallback, useMemo } from 'react';
import { iconsName } from 'assets';
//utils
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { classAttribute } from 'utils/helper';
// Components
import { Icon } from 'components/common';
import { ProgressBar, ProgressBarContainer } from 'components';
import './style.scss';


const Control = ({ children }) => <div className="control-item" {...setAttr.ui(testBy.UI.CONTROL)}>{children}</div>;

const SoundController = ({ disabled, isMuted, minimise, onClickVolume, onVolumeRangeChange, volume }) => {
    const iconClasses = classAttribute([
        'button',
        disabled ? 'disable' : '',
    ]);

    return (
        <div className="volume-wrapper">
            <Control>
                <Icon
                    title={t('lblVolume')}
                    className={iconClasses}
                    name={isMuted ? iconsName.MUTED : iconsName.VOLUME}
                    onClick={disabled ? null : onClickVolume}
                    width={35 / (minimise ? 2 : 1)}
                    height={25 / (minimise ? 2 : 1)}
                    {...setAttr.action((isMuted ? testBy.ACTION.UNMUTE : testBy.ACTION.MUTE), disabled)}
                />
            </Control>
            <div className="volume-bar">
                <ProgressBarContainer
                    disabled={disabled}
                    value={!isMuted ? volume : 0}
                    min={0}
                    max={100}
                    onChange={onVolumeRangeChange}
                />
            </div>
        </div>
    );
};

const MediaProgressBarController = ({ bufferedTime, currentTime, disabled, duration, min, minimise, onVideoRangeTimeChange }) => (
    <div className="playing-bar" {...setAttr.ui(testBy.UI.VIDEO_PROGRESS_BAR)}>
        <ProgressBarContainer
            disabled={disabled}
            minimise={minimise}
            min={min}
            max={duration}
            value={currentTime}
            onChange={onVideoRangeTimeChange}
        >
            <ProgressBar
                className="progress-buffer-value"
                max={bufferedTime}
                value={bufferedTime}
            />
        </ProgressBarContainer>
    </div>
);

const VideoController = (props) => {
    const {
        bufferedTime,
        currentTime,
        disabled,
        duration,
        isfullScreen,
        isMuted,
        isPlay,
        minimise,
        onClickFullscreen,
        onClickVideoHandler,
        onClickVolume,
        onVideoRangeTimeChange,
        onVolumeRangeChange,
        volume,
    } = props;

    const handleOnClickVideo = useCallback(()=>{
        onClickVideoHandler?.(isPlay);
    },[isPlay, onClickVideoHandler]);

    const handleOnClickFullscreen = useCallback(()=>{
        onClickFullscreen?.(isfullScreen);
    },[isfullScreen, onClickFullscreen]);

    const currentTimeFormat = Math.floor(currentTime / 60) + ':' + ('0' + Math.floor(currentTime % 60)).slice(-2);
    const durationFormat = Math.floor(duration / 60) + ':' + ('0' + Math.floor(duration % 60)).slice(-2);

    const controllerClasses = useMemo(() => classAttribute([
        'player-controllers-wrapper',
        minimise ? 'minimised' : '',
    ]), [minimise]);

    const iconClasses = useMemo(() => classAttribute([
        'button',
        disabled ? 'disable' : '',
    ]), [disabled]);

    return (
        <div className="video-controller-container">
            <MediaProgressBarController
                bufferedTime={bufferedTime}
                currentTime={currentTime}
                disabled={disabled}
                duration={duration}
                min={0}
                minimise={minimise}
                onVideoRangeTimeChange={onVideoRangeTimeChange}
            />
            <div className={controllerClasses}>
                <div className="control-section">
                    <Control>
                        <Icon
                            title={t('lblPlayPause')}
                            className={iconClasses}
                            name={isPlay ? iconsName.PAUSE : iconsName.PLAY}
                            onClick={disabled? null : handleOnClickVideo}
                            width={22 / (minimise ? 2 : 1)}
                            height={21 / (minimise ? 2 : 1)}
                            {...setAttr.action((isPlay ? testBy.ACTION.PAUSE : testBy.ACTION.PLAY), disabled)}
                        />
                    </Control>
                    <SoundController
                        disabled={disabled}
                        isMuted={isMuted}
                        minimise={minimise}
                        onClickVolume={onClickVolume}
                        onVolumeRangeChange={onVolumeRangeChange}
                        volume={volume}
                    />
                </div>
                <div className="control-section">
                    <Control>
                        <span className="duration" {...setAttr.ui(testBy.UI.DURATION)}>
                            {currentTimeFormat}/{durationFormat}
                        </span>
                    </Control>
                    <Control>
                        <Icon
                            name={iconsName.HIGH_DEF}
                            useDefault
                            style={{ width: (minimise ? '70%' : '100%') }}
                        />
                    </Control>
                    <Control>
                        <Icon
                            title={!isfullScreen ? t('lblFullscreen') : t('lblMinimize')}
                            className={iconClasses}
                            name={!isfullScreen ? iconsName.EXPAND : iconsName.MINIMIZE}
                            onClick={disabled? null : handleOnClickFullscreen}
                            width={24 / (minimise ? 2 : 1)}
                            height={20 / (minimise ? 2 : 1)}
                        />
                    </Control>
                </div>
            </div>
        </div>
    );
};

export default VideoController;
