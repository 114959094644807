import React, { useCallback } from 'react';
// Utils
import { iconsName } from 'assets';
// Components
import { SplitButtonInput } from 'components/common';





export const DateTimeInput = (props) => {

    const {
        placeholder,
        value
    } = props;

    const onChange = useCallback((e) => {
        // setValue(e.target.value);
    }, []);

    return (
        <SplitButtonInput
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            icon={{ name: iconsName.CALENDAR, width: 14, height: 14 }}
        />
    );
};
