import { useMemo } from 'react';
// Utils
import { errorCode } from 'utils/constants';
// Components
import { PageContainer } from 'layout/containers';
import { PageNotFound } from 'components/errors';
import './style.scss';




export const ErrorPage = (props) => {

    const {
        code = errorCode.PAGE_NOT_FOUND,
        message
    } = props;

    const content = useMemo(() => {
        let content = null;
        switch (code) {
            case errorCode.PAGE_NOT_FOUND:
            default:
                content = <PageNotFound message={message} />;
                break;
        }
        return content;
    }, [code, message]);

    return (
        <PageContainer>
            <div className="error-page-container">
                {content}
            </div>
        </PageContainer>
    );
};
