export const selectBasicStyle = {
    control: (provided, state) => ({
        ...provided,
        borderWidth: '1px',
        borderRadius: '6px',
    }),
    input: (provided) => ({
        ...provided,
        input: {
            boxShadow: 'none',
        },
    }),
    option: (provided, { isSelected, isFocused }) => ({
        ...provided,
        backgroundColor: isSelected ? '#E7EBE9' : isFocused ? '#F2F2F2' : undefined,
        color: '#0C2923'
    }),
};

export const selectBasicTheme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: 'silver',
    },
});