import { useCallback, useEffect, useRef, useMemo } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'store/actions/popup';
import { getIncidentsLocations } from 'store/selectors/safety';
// Components
import { Map, HeatmapLayerF } from 'components/google_maps';
import IncidentMarker from 'pages/safety_page/features/maps/safety_map/IncidentMarker';
import { HeaderWrapper } from 'layout/containers';
// Utils
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';


const SafetyMap = (props) => {
    const { caption } = props;
    const dispatch = useDispatch();
    const mapRef = useRef();
    const heatmapRef = useRef();
    const periodLabel = useRef();
    const locations = useSelector(getIncidentsLocations);

    const weightedLocations = useMemo(() => {
        if (!mapRef.current) return [];

        return locations.filter((p) => p.isValid).map((obj) => {
            const { lat, lng, count } = obj;
            return {
                ...obj,
                location: new google.maps.LatLng(lat, lng),
                weight: count,
            };
        });
    }, [locations]);

    useEffect(() => {
        if (!mapRef.current) return;
        if (heatmapRef.current) {
            heatmapRef.current.setData(weightedLocations);
        }
        if (mapRef.current && weightedLocations.length > 0 && periodLabel.current !== caption) {
            periodLabel.current = caption;
        	const bounds = new google.maps.LatLngBounds();
        	weightedLocations.forEach(({ location }) => {
        		bounds.extend(location);
        	});
        	mapRef.current.fitBounds(bounds);
        }
    }, [caption, weightedLocations]);

    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const onHeatmapLoad = useCallback((heatmapLayer) => {
        heatmapRef.current = heatmapLayer;
    }, []);

    const onClickMarker = useCallback((data) => {
        dispatch(openModal({ data }));
    }, [dispatch]);

    const renderMarkers = useCallback((items) => {
        const markers = items.map((obj) => {
            if (obj.id > 0) {
                let clickData = { ...obj };
                delete clickData.location;
                delete clickData.weight;
                return <IncidentMarker
                    clickData={clickData}
                    key={obj.id}
                    location={obj.location}
                    onClick={onClickMarker}
                    severity={obj.severity}
                />;
            }
            return null;
        });
        return <>{markers}</>;
    }, [onClickMarker]);

    const renderHeatmap = useCallback(() => {
        const options = {
            radius: 25,
            opacity: 0.7,
            gradient: ['rgba(0, 0, 0, 0)', '#00FFFF', '#DFB45F', '#FF6400', '#FF0000'],
        };
        return <HeatmapLayerF
            onLoad={onHeatmapLoad}
            options={options}
            data={[]}
        />;
    }, [onHeatmapLoad]);

    const mapFeatures = useMemo(() => {
        if (mapRef.current) {
            const preciseLocations = weightedLocations.every((obj) => obj.weight === undefined);
            if (preciseLocations) {
                heatmapRef.current?.setMap(null);
                return renderMarkers(weightedLocations);
            } else if (heatmapRef.current) {
                heatmapRef.current.setMap(mapRef.current);
                return null;
            } else {
                return renderHeatmap();
            }
        }
        return null;
    }, [renderHeatmap, renderMarkers, weightedLocations]);

    return (
        <HeaderWrapper
            caption={caption}
            header={t('lblHeatMap')}
            testAttributes={setAttr.feature(testBy.FEATURE.HEAT_MAP)}
        >
            <Map onLoad={onMapLoad}>
                {mapFeatures}
            </Map>
        </HeaderWrapper>
    );
};


export default SafetyMap;
