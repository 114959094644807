import { eventTypes } from 'utils/entities';
import { t } from 'utils/dictionary';

const { COLLISION, THEFT, VIOLATION } = eventTypes.categoryIds;

export const modalsIds = {
    VIOLATION_MODAL: 1,
    VIDEO_PLAYER_MODAL: 2,
    COLLISION_MODAL: 3,
    GALLERY_MODAL: 4,
    THEFT_MODAL: 5,
    MAP_MODAL: 6,
    DRIVER_MODAL: 7,
};

export const modalIdForCategory = (categoryId) => {
    switch (categoryId) {
        case COLLISION:
            return modalsIds.COLLISION_MODAL;
        case THEFT:
            return modalsIds.THEFT_MODAL;
        case VIOLATION:
            return modalsIds.VIOLATION_MODAL;
        default:
            return null;
    }
};

export const CommandIds = {
    START_EMERGENCY_TRACK: 10,
    STOP_EMERGENCY_TRACK: 11,
    START_IMMOBILIZE: 0,
    STOP_IMMOBILIZE: 1
};

export const CommandStatus = {
    ACK: 4,
    NOT_ACK: -1,
    PENDING: 2
};

export const CommandType = { Immobilizer: 'Immobilizer', EmergencyTrack: 'EmergencyTrack' };

export const getCommandText = (type, isStart) => {
    if(type === CommandType.Immobilizer) {
        return isStart ? t('lblStartImmobilize') : t('lblStopImmobilize');
    } else {
        return isStart ? t('lblStartEmergencyTrack') :t('lblStopEmergencyTrack');
    }
};

export const getCommandStatusName = (state) => {
    switch (state) {
        case CommandStatus.ACK:
            return t('lblSent');
        case CommandStatus.PENDING:
            return t('lblPending');
        case CommandStatus.NOT_ACK:
            return t('lblFailed');
        default:
            return 'N/A';
    }
};