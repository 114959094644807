import React from 'react';
// Utils
import PageTopBar from 'components/page_top_bar/PageTopBar';
// Redux
import actions from 'store/actions/safety';
import { getSustainabilityBreadCrumbs } from 'store/selectors/navigation';
import {  getSafetyPeriodState } from 'store/selectors/safety';
import { useSelector } from 'react-redux';

export const SustainabilityHomeScreen = (props) => {

    const breadCrumbs = useSelector(getSustainabilityBreadCrumbs);
    const curPeriod = useSelector(getSafetyPeriodState);

    return (
        <div>
            <PageTopBar
                breadCrumbs={breadCrumbs}
                curPeriod={curPeriod}
                setPeriod = {actions.setPeriod}
            />
            <section>

            </section>
        </div>
    );
};
