import { EventEmitter } from 'events';
import axios from 'axios';

class ApiBase extends EventEmitter {

    baseUrl = '';

    constructor(config) {
        super();
        this.axios = axios.create(config);
    }

    init(config = {}) {
        const { baseUrl } = config;
        this.baseUrl = baseUrl ?? '';
    }

    addBaseUrl(path) {
        if (path?.trim?.().toLowerCase().startsWith('http')) {
            return path;
        }
        // Adding base url to relative path
        return `${this.baseUrl}${path}`;
    }

    setToken(token) {
        this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    removeToken() {
        this.axios.defaults.headers.common['Authorization'] = '';
    }

    async get(path, config) {
        try {
            return await this.axios.get(this.addBaseUrl(path), config);
        } catch (error) {
            return { error };
        }
    }

    async post(path, data, config) {
        try {
            return await this.axios.post(this.addBaseUrl(path), data, config);
        } catch (error) {
            return { error };
        }
    }

    async delete(path, config) {
        try {
            return await this.axios.delete(this.addBaseUrl(path), config);
        } catch (error) {
            return { error };
        }
    }

    async all(requests) {
        try {
            /* // eslint-disable-next-line import/no-named-as-default-member */
            return await axios.all(requests);
        } catch (error) {
            return { error };
        }
    }

}


export default ApiBase;
