import { isValidCoordinate } from 'utils/helper';

export const getBoundsCenter = (paths) => {
    const bounds = new google.maps.LatLngBounds();
    paths.forEach((point) => {
        bounds.extend(point);
    });
    const center = bounds.getCenter();
    return { lat: center.lat(), lng: center.lng() };
};

export const isInsidePoly = (point, paths) => {
    const polygon = new google.maps.Polygon({ paths });
    return google.maps.geometry.poly.containsLocation(point, polygon);
};

export const getNearestLocation = (point, fromPoints) => {
    if (!point || !fromPoints?.length) return undefined;
    let minDistance = google.maps.geometry.spherical.computeDistanceBetween(point, fromPoints[0]);
    let nearest = fromPoints[0];
    for (let i = 1; i < fromPoints.length; i++) {
        const toPoint = fromPoints[i];
        const distance = google.maps.geometry.spherical.computeDistanceBetween(point, toPoint);
        if (distance < minDistance) {
            nearest = toPoint;
            minDistance = distance;
        }
    }
    return nearest;
};

export const getDistanceInMeters = (from, to) => {
    if (!from || !to) return;
    const distance = google.maps.geometry.spherical.computeDistanceBetween(from, to);
    return distance;
};

export const getLocationAddress = (location) => {
    const geocoder = new google.maps.Geocoder();
    return new Promise((resolve, reject) => {
        geocoder.geocode(
            { location },
            (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                    // console.log(results[0]);
                        return resolve(results[0].formatted_address);
                    } else {
                        console.log('No results found');
                    }
                } else {
                    // Geocoder failed due to: OVER_QUERY_LIMIT
                    console.log('Geocoder failed due to: ' + status);
                }
                reject(status);
            }
        );

    });
};

export const computeHeading = (from, to) => {
    if (!from || !to) return;
    return google.maps.geometry.spherical.computeHeading(from, to);
};

export const parseCoordinate = (str) => {
    if (typeof str !== 'string') return;
    const [lat, lng] = str.split(',').map((str) => parseFloat(str.trim()));
    const coordinate = { lat, lng };
    return isValidCoordinate(coordinate) ? coordinate : undefined;
};

export const toFixedLatLng = (coordinate, fixedNum = 6) => {
    if (!isValidCoordinate(coordinate)) return;
    const { lat, lng } = coordinate;
    return {
        lat: parseFloat(lat.toFixed(fixedNum)),
        lng: parseFloat(lng.toFixed(fixedNum))
    };
};
