import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Utils
import { iconsName } from 'assets';
import { setAttr, testBy } from 'utils/constants';
import { downloadZipAndSave, saveFiles } from 'utils/helpers/fileHelper';
import { cameraIds, getEventIcon } from 'utils/entities';
import { allowedFormats, millisToAllowedFormat } from 'utils/timezone';
import { animateFlash, getVideoSnapshot } from 'utils/domHelper';
import { t } from 'utils/dictionary';
// Store
import { closeModal } from 'store/actions/popup';
import { getVideoModalData } from 'store/selectors/safety';
import { fetchIncidentMedia } from 'store/actions/safety';
// Components
import { BaseModal } from 'components/common';
import { modalsIds } from 'components/modal/ModalsConstants';
import { SnapshotContainer, VideoPlayer } from 'components/modal/components';
import './style.scss';

const { FRONT, BACK } = cameraIds;

const VideoPlayerModal = ({ data }) => {
    const dispatch = useDispatch();

    const { videos } = useSelector(getVideoModalData);
    const videosRef = useRef();

    const frontVideo = videos?.find((video) => video.cameraId === FRONT && video.url);
    const backVideo = videos?.find((video) => video.cameraId === BACK && video.url);
    const hasVideosToLoad = typeof data.videos === 'string';
    const isSnapshots = (videos && videos[0]?.url.includes('.jpg')) || false;

    useEffect(() => {
        if (hasVideosToLoad) {
            dispatch(fetchIncidentMedia(data.videos));// "2022/10/4326/296437936_EXT_C1_638027252810247496.mp4,2022/10/4326/296437936_INT_C1_638027248987767421.mp4"
        }
    }, [data.videos, dispatch, hasVideosToLoad]);

    const onVideosRef = useCallback((refs) => {
        videosRef.current = refs;
    }, []);

    const onClickClose = useCallback(() => {
        dispatch(closeModal(modalsIds.VIDEO_PLAYER_MODAL));
    }, [dispatch]);

    const onClickDownload = useCallback(() => {
        const dateFormat = millisToAllowedFormat(data.eventTime, allowedFormats.FILE_NAME_DATE_TIME);
        const filesTemp = [
            { type: 'mp4', name: `front_${dateFormat}`, url: frontVideo?.url },
            { type: 'mp4', name: `back_${dateFormat}`, url: backVideo?.url },
        ];
        downloadZipAndSave(filesTemp, 'videos');
    }, [data, backVideo, frontVideo]);

    const onClickSnapshot = useCallback(() => {
        const dateFormat = millisToAllowedFormat(data.eventTime, allowedFormats.FILE_NAME_DATE_TIME);
        const names = { [FRONT]: 'front', [BACK]: 'back' };
        Object.entries(videosRef.current ?? {}).forEach(([id, video]) => {
            if (video?.src) {
                animateFlash(video);
                const name = `Snapshot_${names[id] ?? id}_${dateFormat}`;
                const content = getVideoSnapshot(video);
                saveFiles({ content, name });
            }
        });
    }, [data]);

    const headerButtons = useMemo(() => {
        if (isSnapshots) return;
        const hasVideos = videos?.some((v) => v.url);
        return [
            // { name: iconsName.SHARE, onClick: null, title: t('lblShare') },
            {
                name: iconsName.DOWNLOAD,
                onClick: (hasVideos ? onClickDownload : null),
                testAttributes: setAttr.action(testBy.ACTION.DOWNLOAD_VIDEOS, !hasVideos),
                title: t('lblDownload'),
            },
            {
                name: iconsName.SNAPSHOT,
                onClick: (hasVideos ? onClickSnapshot : null),
                testAttributes: setAttr.action(testBy.ACTION.TAKE_SNAPSHOT, !hasVideos),
                title: t('lblSnapshot'),
            },
        ];
    }, [onClickDownload, onClickSnapshot, videos, isSnapshots]);

    return (
        <BaseModal
            onClickClose={onClickClose}
            icon={getEventIcon(data.type)}
            headerText={data.typeName}
            resourceName={data.resourceName}
            eventTime={data.eventTime}
            headerButtons={headerButtons}
            className='videos-modal'
            testAttributes={setAttr.feature(testBy.FEATURE.MODAL_VIDEO_PLAYER)}
        >
            {isSnapshots ?
                <SnapshotContainer
                    snapshots={videos}
                />
                :
                <VideoPlayer
                    frontVideo={frontVideo}
                    backVideo={backVideo}
                    loading={hasVideosToLoad}
                    onVideosRef={onVideosRef}
                />
            }
        </BaseModal>
    );
};

export default VideoPlayerModal;
