import { useMemo } from 'react';
import { Text } from 'components/common';
import { setAttr, testBy } from 'utils/constants';
import { classAttribute } from 'utils/helper';
import './style.scss';

const HeaderWrapper = ({ caption, children, classNames, header, testAttributes }) => {

    const classes = useMemo(() => classAttribute([
        'header-wrapper',
        classNames ?? '',
    ]), [classNames]);

    return (
        <div
            className={classes}
            {...testAttributes}
        >
            <div className='wrapper-header'>
                <Text upperCase text={header} className='header' {...setAttr.ui(testBy.UI.HEADER)} />
                <Text text={caption} className='caption' {...setAttr.ui(testBy.UI.CAPTION)} />
            </div>
            <div className='wrapper-body'>
                {children}
            </div>
        </div>
    );
};

export default HeaderWrapper;