import React, { useMemo } from 'react';
// Utils
import { iconsName } from 'assets';
import { setAttr, testBy } from 'utils/constants';
// Components
import { Icon } from 'components/common';
import './style.scss';
import { classAttribute } from 'utils/helper';


export const SplitButton = React.forwardRef((props, ref) => {

    const { children, className, onClick, icon, onClickButton } = props;

    const classes = useMemo(() => classAttribute([
        'split-button-container',
        className ?? '',
    ]), [className]);

    return (
        <div
            ref={ref}
            onClick={onClick}
            className={classes}
            {...setAttr.ui(testBy.UI.SPLIT_BUTTON)}
        >
            {children}
            <div
                onClick={onClickButton}
                className="split-button-icon"
            >
                <Icon
                    name={iconsName.EXPAND_MORE}
                    width={24}
                    height={24}
                    {...icon}
                />
            </div>
        </div>
    );
});
