import React, { useCallback, useMemo } from 'react';
// Utils
import { classAttribute } from 'utils/helper';
import { setAttr, testBy } from 'utils/constants';
// Components
import DropdownBootstrap from 'react-bootstrap/Dropdown';
import './style.scss';


const Item = (props) => {
    const { onClick, item } = props;
    const { active, label, disabled, testAttributes } = item;

    const handleClick = useCallback((e) => {
        onClick?.(item, e);
    }, [onClick, item]);

    return (
        <DropdownBootstrap.Item
            onClick={handleClick}
            active={active}
            disabled={disabled}
            {...{ ...setAttr.ui(testBy.UI.ITEM), ...testAttributes }}
        >
            {label}
        </DropdownBootstrap.Item>
    );
};


export const Menu = (props) => {

    const {
        buttonClasses = [],
        children,
        classes = [],
        items = [],
        onClick
    } = props;

    const renderItems = useCallback(() => {
        const menuItems = items.map((item, i) => {
            const { id, label, addDivider } = item;
            return (
                <React.Fragment
                    key={id ?? label ?? i}
                >
                    <Item
                        onClick={onClick}
                        item={item}
                    />
                    {addDivider ? <DropdownBootstrap.Divider /> : null}
                </React.Fragment>
            );
        });
        return menuItems;
    }, [items, onClick]);

    const dropdownClasses = useMemo(() => {
        const classArray = Array.isArray(classes) ? classes : [];
        return classAttribute(classArray.concat(['basic-menu']));
    }, [classes]);

    return (
        <DropdownBootstrap
            className={dropdownClasses}
            {...setAttr.ui(testBy.UI.MENU)}
        >
            <DropdownBootstrap.Toggle size="sm" className={buttonClasses.join(' ')}>
                {children}
            </DropdownBootstrap.Toggle>
            <DropdownBootstrap.Menu>
                {renderItems()}
            </DropdownBootstrap.Menu>
        </DropdownBootstrap>
    );
};
