import React, { useState, useEffect, useCallback }  from 'react';
import './style.scss';

export const checkboxStates = {
    UNSELECTED: 0,
    PART_SELECTED: 1,
    SELECTED: 2
};

const Checkbox = (props) => {
    const { name, children, handleChange, value } = props;

    const [selected, setSelected] = useState(0);

    const onChange = useCallback((e) => {
        setSelected(handleChange?.(e) ?? 0);
    }, [handleChange]);

    useEffect(() => {
        setSelected(value);
    }, [value]);

    return (
        <label
            className="check-box-container"
        >
            <input
                name={name}
                onChange={onChange}
                type="checkbox"
                checked={selected}
                value={selected}
            />
            <span className='checkmark' />
            {children}
        </label>
    );
};

export default Checkbox;