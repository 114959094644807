import React, { useMemo } from 'react';
// Utils
import { iconsName } from 'assets';
import { t } from 'utils/dictionary';
import { setAttr, testBy } from 'utils/constants';
import PropTypes, { propTypes } from 'utils/propTypes';
// Components
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon } from 'components/common';
import { HoverTooltip, TooltipItems } from 'components';
import './style.scss';

const { UI, ACTION } = testBy;




const NavItem = (props) => {
    const { path, active, icon, children, tooltip } = props;
    return (
        <HoverTooltip
            tooltip={tooltip}
            className='nav-tooltip'
            enableHover
        >
            <Link
                to={path}
                className={active ? 'active' : ''}
                {...setAttr.ui(UI.LINK)}
                {...setAttr.action(ACTION.NAVIGATE)}
            >
                <div className='side-nav-link'>
                    <Icon
                        className={icon.stroke ? 'stroke-color' : ''}
                        name={icon?.name}
                        width={35}
                        height={35}
                    />
                    {children}
                </div>
            </Link>
        </HoverTooltip>
    );
};

NavItem.propTypes = {
    children: PropTypes.node,
    path: PropTypes.string,
    active: PropTypes.bool,
    icon: propTypes.iconProps,
    tooltip: PropTypes.node
};


const ExpandableNavItem = (props) => {

    const { path, lblKey, icon, expanded, id, children, pathname } = props;

    const subLinks = useMemo(() => {
        if (!children?.length) return null;
        return (
            <Accordion.Body>
                {
                    children.map((link) => {
                        const { id, icon, path, lblKey } = link;
                        return (
                            <NavItem
                                path={path}
                                active={pathname === path}
                                icon={icon}
                                item={link}
                                key={id}
                                tooltip={<TooltipItems title={t(lblKey)} />}
                            />
                        );
                    })
                }
            </Accordion.Body>
        );
    }, [children, pathname]);

    return (
        <Accordion.Item
            eventKey={id}
            className={`${expanded ? 'expanded' : ''} ${subLinks ? 'has-sub-links' : ''}`}
        >
            <Accordion.Header>
                <NavItem
                    path={path}
                    active={pathname === path}
                    icon={icon}
                    tooltip={<TooltipItems title={t(lblKey)} items={expanded ? [] : children} />}
                >
                    {
                        subLinks && !expanded ? (
                            <Icon
                                className='nav-expand-icon'
                                name={iconsName.EXPAND_MORE}
                                width={16}
                                height={16}
                            />
                        ) : null
                    }
                </NavItem>
            </Accordion.Header>
            {subLinks}
        </Accordion.Item>
    );
};

ExpandableNavItem.propTypes = {
    path: PropTypes.string,
    lblKey: PropTypes.string,
    icon: propTypes.iconProps,
    expanded: PropTypes.bool,
    id: PropTypes.number,
    children: PropTypes.arrayOf(propTypes.link),
    pathname: PropTypes.string
};


const NavigationBar = (props) => {

    const { links, pathname } = props;

    const activeNavLink = useMemo(() => links.find((l) => pathname === l.path || l.children?.find((l) => l.path === pathname)),
        [links, pathname]);

    const navLinks = useMemo(() => links.map((link) => (
        <ExpandableNavItem
            key={link.path}
            {...link}
            expanded={activeNavLink?.id === link.id}
            pathname={pathname}
        />
    )), [links, pathname, activeNavLink]);

    return (
        <Accordion
            activeKey={activeNavLink?.id}
            className='app-side-navs'
        >
            {navLinks}
        </Accordion>
    );
};


NavigationBar.defaultProps = {
    links: PropTypes.arrayOf(propTypes.link),
    pathname: PropTypes.string
};

export default NavigationBar;
