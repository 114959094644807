/**
 * Check if field contain only white spaces or empty string.
 * Pass only strings
 * @param {String} str - The string to check.
 * @returns {Boolean} Return false is string has value or true if not.
 */
export const isEmptyString = (str) => (!(typeof str === 'string' && str.replace(/\s/g, '').length));

// Enable to pass opacity for hex color
export const hexColorToRgba = (hex, opacity = 1) => {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        const rgba = `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
        return rgba;
    }
    throw new Error('Bad Hex');
};

// Not include max value
export const getRandomInt = (max) => Math.floor(Math.random() * max);
// Not include max value
export const getRandomIntFromRange = (min, max) => ~~(Math.random() * (max - min) + min);
export const getRandomItem = (list) => list[getRandomInt(list.length)];

export const isNumeric = (val) => !Array.isArray(val) && (val - parseFloat(val) + 1) >= 0; // taken from jQuery
export const inRange = (min, num, max) => isNumeric(num) && (num >= min) && (num <= max);
export const isValidCoordinate = ({ lat, lng } = {}) => inRange(-90, lat, 90) && inRange(-180, lng, 180);

export const isInt = (val) => (/^\d*$/.test(val));

/**
 * Check if 'value' is 'empty' base on the items in the empty array.
 * @param {any} value - The value to be checked.
 * @param {Array} emptyItems - @optional list of items for consider empty value.
 * @default emptyItems - [undefined, null].
 * @returns {Boolean} true if empty.
 */
export const isEmptyValue = (value, moreEmptyItems = []) => {
    // For example if only 0 or '' or 'N/A' are consider as empty for specific value you can pass ['N/A'] or [''] or [0], etc...
    const emptyItems = [undefined, null, ...moreEmptyItems];
    for (let i = 0; i < emptyItems.length; i++) {
        if (value === emptyItems[i]) {
            return true;
        }
    }
    return false;
};

export const toFirstCapitalString = (value) => (value !== undefined && value !== null) ? String(value).replace(/[a-z]/, (char) => char.toUpperCase()) : '';

// const toFirstLowerString = (value) => (typeof value === 'string') ? String(value).replace(/[a-z]/, (char) => char.toLowerCase()) : '';

/**
 * Converting string in camel case to kabab case convention.
 * @param {String} camelCase - String in camel case convention.
 * @param {options.prefix} options - @optional Adding prefix to the value.
 * @returns {String} The converted string as kabab case convention.
 */
export const camelCaseToKebabCase = (camelCase, options) => {
    const settings = { prefix: '', ...options };
    let kababCase = settings.prefix;
    for (let i = 0; i < camelCase.length; i++) {
        const letter = camelCase[i];
        const lowerCase = letter.toLowerCase();
        if (letter !== lowerCase) {
            kababCase += '-';
        }
        kababCase += lowerCase;
    }
    return kababCase;
};

/**
 * Replacing the first letter with lower or upper case.
 * @param {String} str - string.
 * @param {Boolean} upperCase - true | false.
 * @default upperCase true.
 * @returns {String} str - starting with upper or lower case base on upperCase param.
 */
export const toFirstLetterCase = (str, upperCase = true) => {
    const letter = str?.trim?.()[0];
    if (typeof letter === 'string') {
        const letterCase = upperCase ? letter.toUpperCase() : letter.toLowerCase();
        if (letter !== letterCase) {
            return str.replace(letter, letterCase);
        }
    }
    return str;
};

/**
 * Replacing the first letter of all property names with lower case.
 * @param {Object} obj - object.
 * @returns {Object} obj - with property names that starting with lower case.
 */
export const objToFirstLowerCase = (obj = {}) => {
    const properties = {};
    Object.entries(obj).forEach(([property, value]) => {
        const name = toFirstLetterCase(property, false);
        properties[name] = value;
    });
    return properties;
};

const b64DecodeUnicode = (str) => decodeURIComponent([...atob(str)].map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
/**
 * Parsing JWT token.
 * @param {String} token - JWT token.
 * @returns {Object} The parsed token.
 */
export const parseJwt = (token) => token && JSON.parse(b64DecodeUnicode(token.split('.')[1]?.replace('-', '+')?.replace('_', '/')));

export const classAttribute = (classArray) => {
    if (Array.isArray(classArray)) {
        return classArray.filter((str) => (typeof str === 'string' && str.length > 0)).join(' ');
    }
    if (typeof classArray === 'string') {
        return classArray;
    }
    return '';
};
