import React, { useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Utils
import { pf_t, t } from 'utils/dictionary';
import { formatDateMillis } from 'utils/timezone';
import { iconsName } from 'assets';
import { dataKeys, getEventIcon } from 'utils/entities';
import { rangeMsFromPeriod } from 'pages/safety_page/constants';
import { setAttr, testBy } from 'utils/constants';
import { textHighlight } from 'utils/domHelper';
// Redux
import { getParams, getSortOption, getIncidentsItems } from 'store/selectors/safety';
import { openModal } from 'store/actions/popup';
import { requestAndDownloadIncidents } from 'store/actions/safety';
// Components
import { Table, SeverityCell, MultiFieldsCell, CellWrapper } from 'components';



const { ACTION } = testBy;

const IncidentTable = (props) => {

    const { onClickSort, onLoadMore, isLoading, markText } = props;

    const filterParams = useSelector(getParams);
    const dispatch = useDispatch();
    const items = useSelector(getIncidentsItems);
    const activeSort = useSelector(getSortOption);

    const [selectedId, setSelectedId] = useState('');
    const [isDownloading, setIsDownloading] = useState(false);

    const lblEventStates = useMemo(() => t('lblEventStates', { returnObjects: true }), []);

    const onClickRow = useCallback((data) => {
        const { item } = data;
        if (item.id !== selectedId) setSelectedId(item.id);
        dispatch(openModal({ data: item }));
    }, [selectedId]);

    const downloadIncidentsList = useCallback(async () => {
        if (isDownloading) return;

        setIsDownloading(true);
        const { period, ...restParams } = filterParams;
        let params = { ...restParams, maxCount: null };
        if (!params.startTime || !params.endTime) {
            const rangeMs = rangeMsFromPeriod(period);
            params = { ...params, ...rangeMs };
        }

        await requestAndDownloadIncidents(params);
        setIsDownloading(false);
    }, [filterParams, isDownloading]);

    const columnMap = useMemo(() => ({
        summary: {
            id: 'summary',
            label: t('lblSummary'),
            dataKey: dataKeys.driverName,
            icon: { name: iconsName.PERSON },
            multiSortKeys: [
                { key: dataKeys.driverName, label: t('lblDriver') },
                { key: dataKeys.resourceName, label: t('lblResource') }
            ],
            width: '22%',
            style: { minWidth: '300px' }
        },
        type: {
            id: 'type',
            label: t('lblIncidentType'),
            dataKey: dataKeys.type,
            sortKey: dataKeys.type,
            style: { minWidth: '135px' }
        },
        time: {
            id: 'time',
            label: t('lblDateTime'),
            dataKey: dataKeys.eventTime,
            sortKey: dataKeys.eventTime,
            style: { minWidth: '140px' }
        },
        severity: {
            id: 'severity',
            label: t('lblSeverity'),
            dataKey: dataKeys.severity,
            sortKey: dataKeys.severity,
            width: '15%',
            style: { minWidth: '140px' }
        },
        statusId: {
            id: 'statusId',
            label: t('lblState'),
            dataKey: dataKeys.statusId,
            sortKey: dataKeys.statusId,
            style: { minWidth: '120px' }
        },
        id: {
            id: 'id',
            label: t('lblId'),
            dataKey: dataKeys.id,
        },
        // actions: {
        //     id: 'actions',
        //     width: '60px'
        // },
        download: {
            id: 'download',
            width: '60px',
            icon: {
                name: iconsName.DOWNLOAD_ARROW,
                title: isDownloading ? `${t('lblDownloading')}...` : t('lblDownloadCsv'),
                onClick: downloadIncidentsList,
                className: `click-able-icon${isDownloading ? ' disabled' : ''}`,
                ...setAttr.action(ACTION.DOWNLOAD_CSV_FILE, isDownloading)
            }
        },
    }), [downloadIncidentsList, isDownloading]);

    const columns = useMemo(() => Object.values(columnMap), [columnMap]);

    const renderValue = useCallback((value) => {
        if (!markText || value === undefined) return value;
        return textHighlight(value, markText);
    }, [markText]);

    const renderCell = useCallback((data) => {

        const { summary, type, time, severity, statusId, id } = columnMap;
        const { item, column, index } = data;
        const eventName = pf_t.eventName(item.type);
        let value = item?.[column?.dataKey];

        switch (column?.id) {
            case time.id:
                value = formatDateMillis(value);
                break;
            case type.id:
                value = renderValue(eventName);
                break;
            case severity.id:
                value = <SeverityCell level={value} />;
                break;
            case summary.id:
                value = (
                    <MultiFieldsCell
                        data={item}
                        dataFields={[dataKeys.driverName, dataKeys.resourceName]}
                        icon={getEventIcon(item.type)}
                        title={eventName}
                        renderValue={renderValue}
                    />
                );
                break;
            // case actions.id:
            //     value = <ActionsCell data={item} />;
            //     break;
            case statusId.id:
                value = lblEventStates[value] ?? t(`lblEventCategories.${item.categoryId}.defaultState`);
                break;
            case id.id:
                value = markText === value ? renderValue(value) : value;
                break;
            default:
                break;
        }
        return <CellWrapper>{value}</CellWrapper>;
    }, [columnMap, lblEventStates, renderValue, markText]);

    return (
        <Table
            columns={columns}
            isLoading={isLoading}
            items={items}
            sort={activeSort}
            onClickSort={onClickSort}
            onLoadMore={onLoadMore}
            renderCell={renderCell}
            onClickRow={onClickRow}
            selectedId={selectedId}
        />
    );
};

export default IncidentTable;
