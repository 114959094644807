import { combineReducers } from '@reduxjs/toolkit';
// State slices
import userReducer, * as userSlice from 'store/slices/user';
import themesReducer, * as themesSlice from 'store/slices/themes';
import popupReducer, * as popupSlice from 'store/slices/popups';
import safetyReducer, * as safetySlice from 'store/slices/safety';
import driversReducer, * as driversSlice from 'store/slices/drivers';
import installerReducer, * as installerSlice from 'store/slices/installer';
import provisionReducer, * as provisionSlice from 'store/slices/provision';
import resourcesReducer, * as resourcesSlice from 'store/slices/resources';
import incidentTypesReducer, * as incidentTypesSlice from 'store/slices/incidentTypes';
import safetyDriversReducer, * as safetyDriversSlice from 'store/slices/safetyDrivers';
import dashboardReducer, * as dashboardSlice from 'store/slices/dashboard';


export const initialState = {
    [userSlice.name]: userSlice.getInitialState(),
    [themesSlice.name]: themesSlice.getInitialState(),
    [safetySlice.name]: safetySlice.getInitialState(),
    [popupSlice.name]: popupSlice.getInitialState(),
    [driversSlice.name]: driversSlice.getInitialState(),
    [resourcesSlice.name]: resourcesSlice.getInitialState(),
    [incidentTypesSlice.name]: incidentTypesSlice.getInitialState(),
    [safetyDriversSlice.name]: safetyDriversSlice.getInitialState(),
    [dashboardSlice.name]: dashboardSlice.getInitialState(),
    [provisionSlice.name]: provisionSlice.getInitialState(),
    [installerSlice.name]: installerSlice.getInitialState(),
};

const rootReducer = combineReducers({
    [userSlice.name]: userReducer,
    [themesSlice.name]: themesReducer,
    [safetySlice.name]: safetyReducer,
    [popupSlice.name]: popupReducer,
    [driversSlice.name]: driversReducer,
    [resourcesSlice.name]: resourcesReducer,
    [incidentTypesSlice.name]: incidentTypesReducer,
    [safetyDriversSlice.name]: safetyDriversReducer,
    [dashboardSlice.name]: dashboardReducer,
    [provisionSlice.name]: provisionReducer,
    [installerSlice.name]: installerReducer,
});

export default rootReducer;
