import './style.scss';

const SnapshotContainer = ({ snapshots }) => (
    <div className="snapshot-container">
        <img alt={snapshots[0]?.cameraId} src={snapshots[0]?.url} />
        <img alt={snapshots[1]?.cameraId} src={snapshots[1]?.url} />
    </div>
);


export default SnapshotContainer;