import React, { useMemo } from 'react';
import useSendSMS from 'components/modal/hooks/use_send_sms/useSendSMS';
import { t } from 'utils/dictionary';
import { allowedFormats, millisToAllowedFormat } from 'utils/timezone';
import './style.scss';

const DriverInfo = ({ licenseExpiration, mobilePhoneNumber, smsAction }) => {
    const expiration = useMemo(() => licenseExpiration > 0 ?
        `${t('lblLicenseExpiration')} ${millisToAllowedFormat(licenseExpiration, allowedFormats.MONTH_YEAR)}` : null, [licenseExpiration]
    );
    const mobilePhone = mobilePhoneNumber ? `+${mobilePhoneNumber}` : null;

    const smsButton = useSendSMS(mobilePhone, smsAction);

    return (
        <span className="driver-info">
            <span>{expiration}</span>
            <span className="driver-phone-number"><a href={`tel: ${mobilePhone}`} title={t('lblCallDriver')}>{mobilePhone}</a></span>
            {smsButton}
        </span>
    );
};

export default DriverInfo;
