import { LoginForm } from 'pages/login_page/features';
import './style.scss';



export const LoginPage = () => (
    <div className='login-page'>
        <LoginForm />
    </div>
);
