import React, { useCallback, useMemo, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { iconsName } from 'assets';
import { Icon, Text } from 'components/common';
import ModalInfoRow from 'components/modal/components/modal_info_row/ModalInfoRow';
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { formatDateMillis, intlFormats } from 'utils/timezone';
import './style.scss';

const DriverLicensePopover = ({ imageUrl, licenseExpiration, licenseNum, licenseType, text }) => {

    const [show, setShow] = useState(false);

    const handleOnMouseEnter =  useCallback(() => {
        setShow(true);
    }, [setShow]);

    const handleOnMouseLeave =  useCallback(() => {
        setShow(false);
    }, [setShow]);

    const licenseTypeValue = useMemo(() => {
        if (!licenseType) {
            return 'N/A';
        }

        const index = licenseType.indexOf('|');
        return index === -1 ? licenseType : `${licenseType.substring(0, index - 1)}...`;
    }, [licenseType]);

    const popoverRows = useMemo(() => {
        const popoverItems = [
            {
                label: <div
                    className="overlay-title"
                    {...setAttr.ui(testBy.UI.TITLE)}
                >
                    <Icon
                        className="info-icon"
                        name={iconsName.INFO}
                    />
                    <span>{t('lblDriverLicenseId')}</span>
                </div>
            },
            { label: t('lblNumber'), value: licenseNum ?? 'N/A' },
            { label: t('lblType'), value: licenseTypeValue, title: licenseType },
            { label: t('lblExpirationDate'), value: licenseExpiration ? formatDateMillis(licenseExpiration, intlFormats.DATE_SHORT) : 'N/A' },
            { label: t('lblImage'), value: imageUrl ? <a target="blank" href={imageUrl}>{t('lblOpenFile')}</a> : <span>{t('lblOpenFile')}</span> }
        ];
        return popoverItems.map(({ label, value, title }) => <ModalInfoRow key={label} label={label} title={title}>{value}</ModalInfoRow>);
    }, [imageUrl, licenseExpiration, licenseNum, licenseType, licenseTypeValue]);

    const infoPopover = useMemo(() => (
        <Popover
            id="popover-basic"
            className="overlay-container"
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            {...setAttr.feature(testBy.FEATURE.DRIVER_LICENSE_POPOVER)}
        >
            <Popover.Body>
                {popoverRows}
            </Popover.Body>
        </Popover>
    ), [handleOnMouseEnter, handleOnMouseLeave, popoverRows]);

    return(
        <OverlayTrigger
            show={show}
            placement="right"
            overlay={infoPopover}
        >
            <Text
                className="has-tooltip"
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                text={text}
            />
        </OverlayTrigger>
    );
};

export default DriverLicensePopover;
