import React, { useMemo } from 'react';
// Utils
import { t } from 'utils/dictionary';
import { setAttr, testBy } from 'utils/constants';
import PropTypes, { propTypes } from 'utils/propTypes';
// Components
import { Button } from 'components/common';
import './style.scss';





export const Footer = (props) => {

    const { buttons = [], show = true } = props;
    const [cancelBtn, applyBtn, ...moreBtns] = buttons;

    const moreBtnsUi = useMemo(() => moreBtns?.map((button, i) => (
        <Button key={button.id ?? i} {...button} />
    )), [moreBtns]);

    if (!show) return null;

    return (
        <div
            className='footer-v1'
        >
            <Button
                label={t('lblCancel')}
                classes={['primary-light', 'gradient']}
                testAttributes={setAttr.action(testBy.ACTION.CANCEL)}
                {...cancelBtn}
            />
            <Button
                label={t('lblApply')}
                classes={['primary-light', 'gradient']}
                testAttributes={setAttr.action(testBy.ACTION.APPLY)}
                {...applyBtn}
            />
            {moreBtnsUi}
        </div>
    );
};


Footer.propTypes = {
    show: PropTypes.bool,
    buttons: PropTypes.arrayOf(propTypes.buttonProps)
};