import React, { useMemo } from 'react';
// Utils
import { LuxonAdapter } from 'utils/timezone/luxonAdapter';
import PropTypes from 'utils/propTypes';
// Components
import { Footer } from 'components/common';
import { BaseDatePicker } from 'components/date-picker/BaseDatePicker';
import { IconDropdown, IconRight, IconLeft, Dropdown } from 'components/date-picker/custom-ui/defaultUI';
import './style.scss';




const defaultUI = { IconDropdown, IconLeft, IconRight, Dropdown };


export const CustomDatePicker = (props) => {

    const {
        children,
        renderFooter,
        components,
        testAttributes,
        className,
        renderRightPanel,
        footerProps = { show: true, buttons: [] },
        ...rest
    } = props;

    const pickerUI = useMemo(() => ({ ...defaultUI, ...components }), [components]);

    return (
        <div className={`custom-date-picker ${className ?? ''}`} {...testAttributes}>
            <div className='custom-date-picker-wrapper'>
                {children}
                <BaseDatePicker
                    adapter={LuxonAdapter}
                    components={pickerUI}
                    modifiersStyles={{
                        [CustomDatePicker.modifiersStyles.betweenPreRange] : { background: '#e7edff' },
                        disabled: { opacity: 0.5 }
                    }}
                    {...rest}
                />
                {renderRightPanel?.()}
            </div>
            {renderFooter?.()}
            <Footer {...footerProps} />
        </div>
    );
};

CustomDatePicker.modifiersStyles = {
    betweenPreRange: 'betweenPreRange'
};

CustomDatePicker.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    renderFooter: PropTypes.func,
    components: PropTypes.shape(defaultUI),
    renderRightPanel: PropTypes.func,
    footerProps: PropTypes.shape(Footer.propTypes),
};