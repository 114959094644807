import React from 'react';
// Utils
import { ROUTES } from 'utils/constants';
// Components
import { PageContainer } from 'layout/containers';
import { Navigate } from 'react-router-dom';



export class HomePage extends React.PureComponent {
    render() {

        return (
            <PageContainer>
                {/* No home page yet */}
                <Navigate to={ROUTES.SAFETY.path} />
            </PageContainer>
        );
    }
}
