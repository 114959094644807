import './style.scss';


const ModalImpactImages = ({ impactOrientation }) => {

    const orientation = impactOrientation.replace(' ', '_');
    switch (orientation) {
        case 'down':
        case 'front':
        case 'front_left':
        case 'front_right':
        case 'init':
        case 'rear':
        case 'rear_left':
        case 'rear_right':
        case 'side_left':
        case 'side_right':
        case 'up':
            return <div className='impact-image'><img alt='' src={`${process.env.PUBLIC_URL}/impacted_panel_images/car_${orientation}.png`} /></div>;
        default:
            return null;
    }
};

export default ModalImpactImages;