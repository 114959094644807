
import { createSlice } from '@reduxjs/toolkit';
import { filterSchema, filterType } from 'components/filters/filter_schema';
import { LOGOUT_USER } from 'store/actionTypes';
import { getFilterActionMatcher, getFiltersInitialState, getFiltersReducers } from 'store/common/filters';
import { reducerNames } from 'store/constants';
import { filterKeys, eventTypes } from 'utils/entities';

const { COLLISION, VIOLATION, THEFT } = eventTypes.categoryIds;

const filters = filterSchema([
    filterType(filterKeys.RESOURCES),
    filterType(filterKeys.DRIVERS),
    filterType(filterKeys.TYPES),
    filterType(filterKeys.SEVERITIES),
    filterType(filterKeys.MEDIA),
]);

const initialState = {
    ...getFiltersInitialState(filters),
    widgets: [
        {
            id: 1,
            lblTitle: 'lblCollisionsThefts', // t('lblCollisionsThefts')
            variant: 'secondary',
            items: [],
            params: {
                categories: `${COLLISION},${THEFT}`,
            }
        },
        {
            id: 2,
            lblTitle: 'lblViolations', // t('lblViolations')
            items: [],
            params: {
                categories: `${VIOLATION}`,
            }
        }
    ]
};

const slice = createSlice({
    name: reducerNames.DASHBOARD,
    initialState,
    reducers: {
        setWidget: (state, action) => {
            const widget = action.payload;
            const index = state.widgets.findIndex((w) => w.id === widget.id);
            if (index !== -1) {
                state.widgets[index] = widget;
            }
        },
        updateWidgetParams: (state, action) => {
            const { id, data } = action.payload;
            const index = state.widgets.findIndex((w) => w.id === id);
            if (index !== -1) {
                const params = state.widgets[index].params;
                state.widgets[index].params = { ...params, ...data };
            }
        },
        ...getFiltersReducers(initialState)
    },
    extraReducers: (builder) => {
        builder.addCase(LOGOUT_USER, (state, action) => initialState);
    }
});

export default slice.reducer;
export const { name, actions, getInitialState } = slice;
export const filterActionMatcher = getFilterActionMatcher(slice.actions);
