import React, { useCallback, useRef, useState } from 'react';
import { Modal, Overlay, Popover } from 'react-bootstrap';
import { setAttr, testBy } from 'utils/constants';
import './style.scss';

const ContextPopover = React.forwardRef(({ body, className, footer, headerIcon, onAfterHide, onBeforeShow, placement, title, testAttributes }, ref) => {

    const popoverRef = useRef();
    const [show, setShow] = useState(false);

    const onHide = useCallback(() => {
        setShow(false);
        onAfterHide?.();
    }, [onAfterHide]);

    const onClick = useCallback(() => {
        if (!show) {
            onBeforeShow?.();
        }
        setShow(true);
    }, [show, onBeforeShow]);

    return (<>
        <div onClick={onClick} ref={ref}></div>
        <Overlay
            onHide={onHide}
            placement={placement}
            rootClose
            show={show}
            target={ref}
        >
            {(props) => (
                <Popover
                    className={className}
                    id="context-popover"
                    {...testAttributes}
                    {...props}
                >
                    <div ref={popoverRef}>
                        <Modal
                            backdrop={false}
                            className="dialog"
                            container={popoverRef}
                            onHide={onHide}
                            show={show}
                        >
                            <Modal.Header
                                closeButton
                                {...setAttr.ui(testBy.UI.HEADER)}
                            >
                                <span className="title">{title}</span>
                                {headerIcon}
                            </Modal.Header>
                            <Modal.Body
                                {...setAttr.ui(testBy.UI.BODY)}
                            >
                                {body}
                            </Modal.Body>
                            <Modal.Footer
                                className={footer ? '' : 'hidden'}
                                {...(footer ? setAttr.ui(testBy.UI.FOOTER) : null)}
                            >
                                {footer}
                            </Modal.Footer>
                        </Modal>
                    </div>
                </Popover>
            )}
        </Overlay>
    </>);
});

export default ContextPopover;
