import { useCallback } from 'react';
// Utils
import { testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
// Components
import { DropdownBox, Text } from 'components/common';
import Icon from 'components/common/icons/Icon';

const SimpleSelect = (props) => {

    const { onClickOption, options = [], selectedId, icon = {}, children, hideMenu, buttonTestAttributes } = props;
    const lblKey = options.find((op) => op.id === selectedId)?.lblKey ?? '';

    const renderDropdownButton = useCallback(() => {
        const { name, width, ...rest } = icon;
        return (
            <div className='button-label'>
                {icon.name ? (
                    <Icon
                        name={name}
                        width={width ?? 14}
                        {...rest}
                    />
                ) : null
                }
                <Text text={t(lblKey)} className={testBy.CLASS.PERIOD_SELECTED} />
            </div>
        );
    }, [icon, lblKey]);

    const renderOptions = () => options.map((option) => {
        const { id, lblKey } = option;
        return (
            <DropdownBox.Item
                key={id}
                onClick={onClickOption}
                active={selectedId === id}
                {...option}
            >
                {t(lblKey)}
            </DropdownBox.Item>
        );
    });

    return (
        <DropdownBox.Box
            classes={['simple-select']}
            renderButton={renderDropdownButton}
            hideMenu={hideMenu}
            buttonTestAttributes={buttonTestAttributes}
        >
            {renderOptions()}
            {children}
        </DropdownBox.Box>
    );
};

export default SimpleSelect;