import React from 'react';
import { setAttr } from 'utils/constants';
import './style.scss';


const PageContainer = (props) => {
    const {
        children,
        style,
        classes = [],
        name
    } = props;

    return (
        <div
            className={`page-container ${classes.join(' ')}`}
            style={style}
            {...setAttr.page(name)}
        >
            {children}
        </div>
    );
};

export default PageContainer;
