import React from 'react';
import { connect } from 'react-redux';
// Utils
import { iconsName } from 'assets';
import { t } from 'utils/dictionary';
import { setAttr, testBy } from 'utils/constants';
import PropTypes, { propTypes } from 'utils/propTypes';
// Redux
import { unAuthUser } from 'store/actions/user';
import { getUserInfo } from 'store/selectors/user';
// Components
import { Menu } from 'components';
import { Icon, Text } from 'components/common';
import './style.scss';


const { UI, ACTION } = testBy;

class AppTopBar extends React.PureComponent {

    onClickMenu = (item) => {
        const { unAuthUser } = this.props;
        unAuthUser?.();
    };

    renderAppIcon = () => (
        <div className='pf-icon-logo-wrapper'>
            <Icon
                name={iconsName.PF_LOGO_VERTICAL}
                height={50}
                width={220}
            />
        </div>
    );

    renderUserMenu = () => {
        const { user } = this.props;
        const items = [
            { label: t('lblLogout'), testAttributes: setAttr.action(ACTION.LOG_OUT) },
        ];
        return (
            <Menu
                items={items}
                onClick={this.onClickMenu}
            >
                <div className='button-label'>
                    <Text text={user.loginName} />
                    <Icon
                        name={iconsName.PERSON}
                        width={28}
                        height={28}
                    />
                </div>
            </Menu>
        );
    };

    render() {

        return (
            <div
                className='app-top-bar-container'
                {...setAttr.ui(UI.APP_TOP_BAR)}
            >
                {this.renderAppIcon()}
                <div>
                    {this.renderUserMenu()}
                </div>
            </div>
        );
    }
}

AppTopBar.propTypes = {
    unAuthUser: PropTypes.func,
    user: propTypes.user,
};

const mapStateToProps = ((state) => ({
    user: getUserInfo(state),
}));

const mapDispatchToProps = ({
    unAuthUser
});

export default connect(mapStateToProps, mapDispatchToProps)(AppTopBar);
