import { isAnyOf } from '@reduxjs/toolkit';
import { checkboxStates, Icons } from 'components/common';
import { filterUtils } from 'components/filters/filter_schema';
import { createSelector } from 'reselect';
import { t } from 'utils/dictionary';
import { filterKeys } from 'utils/entities';

export const getFiltersInitialState = (filters, sortBy) => {
    const params = (sortBy === undefined) ? {} : {
        sort: sortBy,
        isDesc: true,
    };
    return {
        filtersManager: {
            enableFilter: false,
            filters,
            defaultTab: filters?.[0]?.name, // This is the filter name
            searches: {}, // Has filter names as keys and saves the search value for each filter
        },
        params,
    };
};

const updateParamsFromFilterState = (state) => {
    let params = {};
    let enableFilter = false;
    state.filtersManager.filters.forEach((filter) => {
        params = { ...params, ...filterUtils.appliedSelectionsToCSV(filter) };
        enableFilter = enableFilter || Object.keys(filter.selections).length > 0;
    });
    state.filtersManager.enableFilter = enableFilter;

    state.params = { ...state.params, ...params };
    Object.keys(state.params).forEach((key) => {
        if (state.params[key] === '') {
            delete (state.params[key]);
        }
    });
};

const updateParamsStrippingFilters = (state) => {
    state.filtersManager.filters.forEach((filter) => {
        const { name } = filter;
        delete (state.params[name]);
    });
};

export const getFiltersReducers = (initialState) => ({
    applyFilters: (state) => {
        state.filtersManager.filters.forEach((filter) => {
            filter.appliedSelections = filter.selections;
        });
        updateParamsFromFilterState(state);
    },
    clearFilters: (state) => {
        state.filtersManager = { ...initialState.filtersManager };
        updateParamsStrippingFilters(state);
    },
    changeFilterGroupSelection: (state, action) => {
        const { ids, data, filterName, filterKey, isRemove = false } = action.payload;
        const categories = ids.map((id) => `${filterName}_${id}`);
        const idx = state.filtersManager.filters.findIndex((f) => f.name === filterKey);
        const typesFilter = state.filtersManager.filters[idx];
        const checkboxValue = isRemove ? checkboxStates.UNSELECTED : checkboxStates.SELECTED;
        state.filtersManager.filters[idx] = filterUtils.filterWithForcedSelections(typesFilter, categories, data, checkboxValue);
        updateParamsFromFilterState(state);
    },
    enableDisableFilters: (state, action) => {
        if (action.payload) {
            updateParamsFromFilterState(state);
        }
        else {
            state.filtersManager.enableFilter = false;
            updateParamsStrippingFilters(state);
        }
    },
    forceFilterCategoriesSelections: (state, action) => {
        const { ids, data } = action.payload;
        const categories = ids.map((id) => `types_group_${id}`);
        const idx = state.filtersManager.filters.findIndex((f) => f.name === filterKeys.TYPES);
        const typesFilter = state.filtersManager.filters[idx];
        typesFilter.appliedSelections = {};
        state.filtersManager.filters[idx] = filterUtils.filterWithForcedSelections(typesFilter, categories, data);
        updateParamsFromFilterState(state);
    },
    removeFilter: (state, action) => {
        state.filtersManager.filters = filterUtils.filtersWithDeletedSelection(state.filtersManager.filters, action.payload);
        updateParamsFromFilterState(state);
    },
    resetFilters: (state) => {
        state.filtersManager.filters.forEach((filter) => {
            filter.selections = filter.appliedSelections;
        });
    },
    setFilterSearch: (state, action) => {
        const { name, value } = action.payload;
        state.filtersManager.searches[name] = value;
    },
    updateFilterSelections: (state, action) => {
        const filterWithSelections = action.payload;
        const idx = state.filtersManager.filters.findIndex((f) => f.name === filterWithSelections.name);
        state.filtersManager.filters[idx] = filterWithSelections;
    }
});

export const getFilterOptIndexes = (id, filters) => {
    for (let i = 0; i < filters.options.length; i++) {
        const option = filters.options[i];
        const index = option.options.findIndex((op) => op.id === id);
        if (index !== -1) {
            return { option: index, group: i };
        }
    }
};

export const addFilterOption = (id, filters) => {
    const indexes = getFilterOptIndexes(id, filters);
    let update;
    if (indexes) {
        const option = filters.options[indexes.group]?.options[indexes.option];
        update = { ...option, value: checkboxStates.SELECTED };
    }
    if (update) {
        const options = filters.options.map((op) => ({
            ...op,
            options: [...op.options],
        }));
        const filtersC = { ...filters, options };

        filtersC.options[indexes.group].options[indexes.option] = update;
        const checked = filtersC.options[indexes.group].options.filter((p) => p.value > 0).length;
        const count = filtersC.options[indexes.group].options.length;
        filtersC.options[indexes.group].value = checked === count ? checkboxStates.SELECTED : checked ? checkboxStates.PART_SELECTED : checkboxStates.UNSELECTED;
        return filterUtils.filterWithSelections(filtersC);
    }
};

export const getFiltersSelectors = (filtersManagerSelector, filtersSelector, pagingSelector, searchValueState) => ({
    filterEnabled: createSelector(
        [filtersManagerSelector],
        (filtersManager) => filtersManager.enableFilter
    ),
    filterInfo: createSelector(
        [filtersManagerSelector, pagingSelector, searchValueState],
        (filtersManager, paging, search) => {
            const { filteredRows, totalRows } = paging;
            const params = filterUtils.trailData(filtersManager.filters);
            const active = filtersManager.enableFilter && Boolean(params?.length);
            return {
                filteredRows,
                totalRows,
                params,
                active,
                search
            };
        }
    ),
    filterOption: (filterType, optionId) => createSelector(
        [filtersSelector],
        (filters) => {
            const [type] = filters.filter((template) => template.name === filterType);
            const [option] = type?.options ? type.options.filter((option) => option.id === optionId) : [];
            return option;
        }
    ),
    filterSummary: (filterType) => createSelector(
        [filtersManagerSelector],
        (filtersManager) => {
            const idx = filtersManager.filters.findIndex((f) => f.name === filterType);
            return filtersManager.filters[idx]?.summary ?? '';
        }
    ),
    filterTrailData: createSelector(
        [filtersManagerSelector],
        (filtersManager) => filterUtils.trailData(filtersManager.filters)
    ),
    filtersManager: createSelector(
        [filtersManagerSelector, filtersSelector],
        (filtersManager, filters) => ({ ...filtersManager, filters })
    )
});

export const setFiltersFromState = ({ filters, searches }, drivers, resources, types) => {
    const withOptions = filters.map((f) => {
        const filter = { ...f };
        const search = searches[filter.name] ?? '';
        switch (filter.name) {
            case filterKeys.RESOURCES:
                filter.label = t('lblResources');
                return { ...filter, options: filterUtils.optionsFromData(filter, search, resources) };
            case filterKeys.DRIVERS:
                filter.label = t('lblDrivers');
                return { ...filter, options: filterUtils.optionsFromData(filter, search, drivers) };
            case filterKeys.TYPES:
                filter.label = t('lblTypes');
                return { ...filter, options: filterUtils.optionsFromData(filter, search, types) };
            case filterKeys.SEVERITIES: {
                filter.label = t('lblSeverity');
                const severityData = [
                    { id: 2, name: `${t('lblSeverity')} ${t('lblSeverityLevel.2')}`, icons: [<Icons.Severity key='2' severity='2' />] },
                    { id: 1, name: `${t('lblSeverity')} ${t('lblSeverityLevel.1')}`, icons: [<Icons.Severity key='1' severity='1' />] },
                    { id: 0, name: `${t('lblSeverity')} ${t('lblSeverityLevel.0')}`, icons: [<Icons.Severity key='0' severity='0' />] },
                ];
                return { ...filter, options: filterUtils.optionsFromData(filter, search, severityData) };
            }
            case filterKeys.STATUSES: {
                filter.label = t('lblStates');
                const statuses = getStatusesOptions();
                return { ...filter, options: filterUtils.optionsFromData(filter, search, statuses) };
            }
            case filterKeys.MEDIA: {
                filter.label = t('lblMedia');
                const data = [
                    { id: 'videos', name: t('lblVideos') },
                ];
                return { ...filter, options: filterUtils.optionsFromData(filter, search, data) };
            }
            default:
                return filter;
        }
    });

    return withOptions;
};

export const getStatusesOptions = () => {
    const uniqKeys = {};
    const statuses = [];
    const lblEventCategories = t('lblEventCategories', { returnObjects: true });
    Object.keys(lblEventCategories).forEach((cat) => {
        const { eventStates } = lblEventCategories[cat];
        Object.keys(eventStates).forEach((statusId) => {
            if (uniqKeys[statusId]) return;

            const item = { id: statusId, name: eventStates[statusId] };
            statuses.push(item);
            uniqKeys[statusId] = statusId;
        });
    });

    return statuses;
};

export const getFilterActionMatcher = (actions) => isAnyOf(
    actions.applyFilters,
    actions.clearFilters,
    actions.enableDisableFilters,
    actions.removeFilter
);
