import React, { useCallback } from 'react';
import { Icon } from 'components/common';
import { classAttribute } from 'utils/helper';
import './style.scss';

const ModalBlock = ({ className, children, fullSize, compact, iconName = '', iconTitle = '', testAttributes }) => {
    const renderIcon = useCallback(() => (iconName.length > 0) ?
        <div><Icon name={iconName} title={iconTitle} /></div>  : null
    , [iconName, iconTitle]);

    const blockClasses = classAttribute([
        'modal-block',
        className ?? '',
        fullSize ? 'full-size' : '',
        compact ? 'compact' : '',
        iconName.length > 0 ? 'with-icon' : '',
    ]);

    return (
        <div
            className={blockClasses}
            {...testAttributes}
        >
            {fullSize ? children : (<>
                {renderIcon()}
                <div className="modal-block-content">
                    {children}
                </div>
            </>)}
        </div>
    );
};

export default ModalBlock;
