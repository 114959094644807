import React, { useState, useCallback, useMemo } from 'react';
// import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
// Utils
import { iconsName } from 'assets';
import { locales, supportedLanguages, useTranslation } from 'utils/dictionary';
import { testBy, setAttr } from 'utils/constants';
// Actions
import { loginUser } from 'store/actions/user';
// Components
import { Menu } from 'components';
import { Button, Icon, TextInput } from 'components/common';
import { Card } from 'layout/containers';
import SimpleSelect from 'components/common/dropdowns/simple_select/SimpleSelect';
import './style.scss';


const { ACTION } = testBy;

const regionOptions = [
    { id: 'https://unity-dev.powerfleet.com/fleetcore.api/token', lblKey: 'Dev' },
    { id: 'https://unity-qa.powerfleet.com/fleetcore.api/token', lblKey: 'QA' },
    { id: 'https://unity-psim.powerfleet.com/fleetcore.api/token', lblKey: 'PSIM' },
    { id: '_RAW_TOKEN_', lblKey: 'RAW Token' }, //This option allows you to paste a bearer token into the password box
];


const LoginForm = (props) => {

    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();

    const [form, setForm] = useState({});
    const [selectedRegionId, setSelectedRegionId] = useState(regionOptions[0].id);


    const onInputChange = useCallback(({ target }) => {
        setForm((prev) => ({ ...prev, [target.name]: target.value }));
    }, []);

    const onSubmit = useCallback(() => {
        dispatch(loginUser(form, selectedRegionId));
    }, [form, dispatch, selectedRegionId]);

    const onClickLangMenu = useCallback((item) => {
        const { langName, langId } = item;
        i18n.changeLanguage(langName);
        setForm((prev) => ({ ...prev, langId }));
    }, [i18n]);

    const langMenu = useMemo(() => {
        const languages = locales.toList().filter((l) => supportedLanguages.includes(l.langName))
            .map(({ lblName, langName, langId }) => {
                const active = langName === i18n.language;
                return ({ label: t(lblName), langName, langId, active });
            });
        return (
            <Menu
                items={languages}
                onClick={onClickLangMenu}
            >
                <Icon
                    name={iconsName.LANGUAGE}
                    width={24}
                    height={24}
                />
                {i18n.language}
            </Menu>
        );
    }, [onClickLangMenu, i18n, t]);


    return (
        <Card>
            <div
                className="login-form"
                {...setAttr.feature(testBy.FEATURE.LOGIN_FORM)}
            >
                <div>
                    <Icon
                        name={iconsName.PF_LOGO_VERTICAL}
                        height={45}
                        width={210}
                    />
                </div>
                <div>
                    <TextInput
                        label={t('lblUsername')}
                        name='username'
                        value={form.username}
                        onChange={onInputChange}
                    />
                    <TextInput
                        label={t('lblPassword')}
                        name='password'
                        type="password"
                        value={form.password}
                        onChange={onInputChange}
                    />
                    <SimpleSelect label="Region" options={regionOptions} selectedId={selectedRegionId} onClickOption={(props) => { setSelectedRegionId(props.id); }} />
                </div>
                <div className='buttons-wrapper'>
                    <Button
                        label={t('lblLogin')}
                        onClick={onSubmit}
                        classes={['primary', 'fill']}
                        uppercase
                        testAttributes={setAttr.action(ACTION.LOGIN)}
                    />
                    {langMenu}
                </div>
            </div>
        </Card>
    );
};


export default LoginForm;
