import React from 'react';
// Utils
import { iconsName, getSvgDataUrl } from 'assets';
import PropTypes, { propTypes } from 'utils/propTypes';
// Components
import { MarkerClustererF } from '@react-google-maps/api';
import { TripEndMarker, TripMarker, TripStartMarker } from 'pages/safety_page/features/maps/trip_map/TripUiItems';
import './style.scss';





export class TripCluster extends React.PureComponent {

    static calculator = (markers, /* numStyles */) => ({ text: markers.length, index: 0 });

    static clusterStyles = [{
        textColor: 'transparent',
        url: getSvgDataUrl(iconsName.PIN, { fill: '#000', opacity: 0.3 }),
        height: 24,
        width: 17,
        anchorText: [0, 0]
    }];

    constructor(props) {
        super(props);
        this.cluster = undefined;
    }

    onClustererLoad = (cluster) => {
        this.cluster?.clearMarkers?.();
        this.cluster = cluster;
    };

    onClustererUnmount = (cluster) => {
        this.cluster?.clearMarkers?.();
        cluster?.clearMarkers();
    };

    isTripStartOrEnd = (index, length) => index === 0 || index === length - 1;

    renderTripStartEnd = () => {
        const { points, modifiers = {}, heading } = this.props;
        const [start, end] = points.filter((_, i) => this.isTripStartOrEnd(i, points.length));
        return (
            <>
                <TripStartMarker
                    point={start}
                    options={modifiers.startPoint}
                    heading={heading}
                />
                <TripEndMarker
                    point={end}
                    options={modifiers.endPoint}
                />
            </>
        );
    };

    renderMarkers = (clusterer) => {
        const { points, modifiers = {}} = this.props;
        return points.map((point, i) => {
            if (this.isTripStartOrEnd(i, points.length)) return null;
            return (
                <TripMarker
                    key={point?.id ?? `index-${i}`}
                    point={point}
                    clusterer={clusterer}
                    options={modifiers.point}
                />
            );
        });
    };

    render() {

        const { hideTripMarkers, noCluster } = this.props;

        return (
            <>
                {
                    noCluster ? null : (
                        <MarkerClustererF
                            averageCenter={false}
                            gridSize={25}
                            minimumClusterSize={5}
                            // maxZoom={17}
                            // batchSize={200}
                            styles={TripCluster.clusterStyles}
                            calculator={TripCluster.calculator}
                            onLoad={this.onClustererLoad}
                            ignoreHidden
                        >
                            {this.renderMarkers}
                        </MarkerClustererF>
                    )
                }
                {hideTripMarkers ? null : this.renderMarkers()}
                {this.renderTripStartEnd()}
            </>
        );
    }
}


TripCluster.propTypes = {
    noCluster: PropTypes.bool,
    hideTripMarkers: PropTypes.bool,
    points: PropTypes.arrayOf(propTypes.tripPoint)
};