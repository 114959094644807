import { useCallback, useState, useRef, useMemo, useEffect } from 'react';
// Utils
import { iconsName } from 'assets';
import { isValidCoordinate } from 'utils/helper';
import { parseCoordinate, toFixedLatLng } from 'services/api/google_api';
// Components
import { MarkerF } from '@react-google-maps/api';
import { UserMap } from 'components/google_maps';
import { CustomOverlayTrigger } from 'components';
import { SplitButtonInput, Footer } from 'components/common';
import './style.scss';





const LocationMarker = (props) => {

    const {
        position,
        onPositionChange,
        ...markerProps
    } = props;

    const onDragEnd = useCallback((e) => {
        onPositionChange?.(e.latLng.toJSON());
    }, [onPositionChange]);

    if (!isValidCoordinate(position)) return null;

    return (
        <MarkerF
            position={position}
            onDragEnd={onDragEnd}
            draggable
            {...markerProps}
        />
    );
};

const LocationInput = (props) => {

    const {
        placeholder,
        value,
        onChange,
        name
    } = props;

    const [location, setLocation] = useState(parseCoordinate(value));
    const [blur, setBlur] = useState(false);

    useEffect(() => {
        setLocation(parseCoordinate(value));
    }, [value]);

    const handleChange = useCallback((e) => {
        const { value } = e.target;
        const location = parseCoordinate(value);
        setLocation(location);
        onChange?.({ value, location });
    }, [onChange]);

    const onBlur = useCallback(() => {
        setBlur(true);
    }, []);

    const showError = value && (blur && !location);

    return (
        <SplitButtonInput
            placeholder={placeholder}
            onChange={handleChange}
            value={value}
            icon={{ name: iconsName.ADD_LOCATION, width: 20, height: 20 }}
            className={showError ? 'error' : ''}
            onBlur={onBlur}
            name={name}
        />
    );
};



export const LocationPicker = (props) => {

    const triggerRef = useRef();
    const mapRef = useRef();

    const {
        overlayProps = {},
        placeholder,
        position,
        onApply,
        onLocationInputChange
    } = props;

    const toLatLngStr = useCallback(({ lat, lng }) => `${lat}, ${lng}`, []);

    const [marker, setMarker] = useState({ position });
    const [inputValue, setInputValue] = useState(isValidCoordinate(position) ? toLatLngStr(position) : '');

    const onLoadMap = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const onUnmountMap = useCallback(() => {
        setMarker({ position });
    }, [position]);

    const onRef = useCallback((node) => {
        triggerRef.current = node;
    }, []);

    const onClickMap = useCallback((e) => {
        const position = toFixedLatLng(e.latLng.toJSON());
        if (position) {
            setMarker((prev) => ({ ...prev, position }));
        }
    }, []);

    const onPositionChange = useCallback((latLng) => {
        const position = toFixedLatLng(latLng);
        if (position) {
            setMarker((prev) => ({ ...prev, position }));
        }
    }, []);

    const panTo = useCallback((coordinate) => {
        if (!isValidCoordinate(coordinate)) return;
        mapRef.current?.panTo?.(coordinate);
    }, []);

    const onInputChange = useCallback(({ value, location }) => {
        setInputValue(value);
        setMarker((prev) => ({ ...prev, position: location }));
        panTo(location);
        onLocationInputChange?.({ value, location });
    }, [panTo, onLocationInputChange]);

    const hidePicker = useCallback(() => {
        triggerRef.current?.click?.();
    }, []);

    const onClickApply = useCallback(() => {
        const { position } = marker;
        if (!isValidCoordinate(position)) return;
        setInputValue(toLatLngStr(position));
        onApply?.(position);
        hidePicker();
    }, [marker, onApply, hidePicker, toLatLngStr]);

    const onShouldHide = useCallback((e) => e.target?.name !== 'locationInput', []);

    const renderTrigger = useCallback(() => (
        <LocationInput
            value={inputValue}
            onChange={onInputChange}
            placeholder={placeholder}
            name='locationInput'
        />
    ), [placeholder, onInputChange, inputValue]);

    const buttons = useMemo(() => ([
        { onClick: hidePicker },
        {
            onClick: onClickApply,
            disabled: !isValidCoordinate(marker.position)
        }
    ]), [onClickApply, hidePicker, marker.position]);

    return (
        <CustomOverlayTrigger
            renderTrigger={props.renderTrigger ?? renderTrigger}
            onRef={onRef}
            onShouldHide={onShouldHide}
            {...overlayProps}
        >
            <div className='map-location-picker'>
                <UserMap
                    onClick={onClickMap}
                    onLoad={onLoadMap}
                    onUnmount={onUnmountMap}
                    center={position}
                >
                    <LocationMarker
                        {...marker}
                        onPositionChange={onPositionChange}
                    />
                </UserMap>
                <Footer buttons={buttons} />
            </div>
        </CustomOverlayTrigger>
    );
};
