import React from 'react';
// Utils
import { testBy, ROUTES } from 'utils/constants';
// Components
import { useLocation, Navigate } from 'react-router-dom';
import { PageContainer } from 'layout/containers';

import { ErrorBoundary } from 'components/errors';
import { SustainabilityHomeScreen } from './features';



const { PAGE } = testBy;


export const SustainabilityPage = (props) => {

    const location = useLocation();

    const getScreen = (name, props) => {
        switch (name) {
            case ROUTES.SUSTAINABILITY_SUSTAINABILITY_HOME.path:
                return <SustainabilityHomeScreen location={location} {...props} />;
            default:
                return <Navigate replace to={`${ROUTES.SUSTAINABILITY.path}`} />;
        }
    };

    return (
        <PageContainer
            name={PAGE.USTAINABILITY}
        >
            <ErrorBoundary boundary='sustainability page'>
                {getScreen(location.pathname, {})}
            </ErrorBoundary>
        </PageContainer>
    );
};
