import { createSelector } from 'reselect';
import { reducerNames } from 'store/constants';


// STATES
const getModalsState = (state) => state[reducerNames.POPUP].modals;
// const getDialogsState = state => state[reducerNames.POPUP].dialogs;
// const getWindowsState = state => state[reducerNames.POPUP].windows;


// SELECTORS
export const getCurrentModal = createSelector(getModalsState, (data) => data && data.length > 0 ? data[data.length-1] : null);
// export const getDialogs = createSelector(getDialogsState, (data) => data);
// export const getWindows = createSelector(getWindowsState, (data) => data);
