// The data keys that need to display in the Driver table cells
export const dataKeys = {
    name: 'name',
    licenseplate: 'licenseplate',
    group: 'group',
    licenseExpiration: 'licenseExpiration',
    safetyScore: 'safetyScore',
    distance: 'distance',
    drivenTrips: 'drivenTrips',
    highRiskTrips: 'highRiskTrips'
};