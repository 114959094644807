import React from 'react';
import { iconsName } from 'assets';
import { Icon } from 'components/common';
import { testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { severities } from 'utils/entities';

const SeverityIcon = ({ severity, width = 16, title, ...rest }) => {

    const { color, lblKey, level: variant } = severities[severity] ?? {};

    return (
        <Icon
            className={`severity-icon ${testBy.CLASS.SEVERITY_ICON}`}
            name={iconsName.SEVERITY}
            width={width}
            height={width}
            color={color}
            variant={variant}
            title={title ?? `${t('lblSeverity')} ${t(lblKey)}`}
            {...rest}
        />
    );
};

export default SeverityIcon;
