// Utils
import { isValidCoordinate } from 'utils/helper';
import { iconsName, getSvgDataUrl } from 'assets';
// Components
import { MarkerF, PolylineF } from '@react-google-maps/api';
import './style.scss';




export const TripMarker = (props) => {
    const { point, options, clusterer } = props;
    if (!isValidCoordinate(point)) return null;
    return (
        <MarkerF
            position={point}
            clusterer={clusterer}
            noClustererRedraw
            options={{
                clickable: false,
                icon: {
                    url: getSvgDataUrl(iconsName.PIN, { fill: '#000' }),
                },
                zIndex: 1,
                ...options,
            }}
        />
    );
};

export const TripStartMarker = (props) => {
    const { point, heading, options } = props;
    return (
        <TripMarker
            point={point}
            options={{
                icon: {
                    url: getSvgDataUrl(iconsName.ARROW_IN_CIRCLE, { transform: `rotate(${heading ?? 0})` }),
                    anchor: new google.maps.Point(11.5, 10.5),
                },
                ...options,
            }}
        />
    );
};

export const TripEndMarker = (props) => {
    const { point, options } = props;
    return (
        <TripMarker
            point={point}
            options={{
                icon: {
                    url: getSvgDataUrl(iconsName.FINISH_FLAG),
                    anchor: new google.maps.Point(0, 18),
                },
                ...options
            }}
        />
    );
};

export const TripLinePath = (props) => {
    const { points, options } = props;
    return (
        <PolylineF
            path={points}
            options={{
                strokeOpacity: 1,
                strokeWeight: 3,
                strokeColor: '#e21818',
                ...options
            }}
        />
    );
};