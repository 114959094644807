import React from 'react';
// Utils
import { APIS } from 'utils/constants';
import './style.scss';



const ResourceIcon = (props) => {
    const { color, direction, icon, state, baseUrl } = props;
    const url = `${baseUrl ?? APIS.CORE.GET_IMAGE}?direction=${direction}&state=${state}&resource=${icon}_unity&color=${encodeURIComponent(color)}`;
    return (
        <img className='resource-icon' src={url} alt='resource icon' />
    );
};

export default ResourceIcon;
