// The data keys that need to display in the Incident table cells
export const dataKeys = {
    driverName: 'driverName',
    resourceId: 'resourceId',
    resourceName: 'resourceName',
    type: 'type',
    typeName: 'typeName',
    eventTime: 'eventTime',
    severity: 'severity',
    statusId: 'statusId',
    id: 'id',
};

export const filterKeys = {
    DRIVERS: 'drivers',
    MEDIA: 'media',
    RESOURCES: 'resources',
    SEVERITIES: 'severities',
    STATUSES: 'statuses',
    TYPES: 'types',
};
