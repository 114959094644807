import { useCallback, useState, useMemo } from 'react';
// Utils
import { periodOptions as options, rangeMsFromPeriod, periodIds } from 'pages/safety_page/constants';
import { setAttr, testBy } from 'utils/constants';
import { getDateTime } from 'utils/timezone';
// Components
import { OptionsList, CustomOverlay, CustomRangePicker } from 'components';


const toPickerRange = (period) => {
    const { startTime, endTime } = period?.rangeMs ?? {};
    const range = {};
    // if (!startTime) return range;
    range.from = startTime ? getDateTime(startTime) : undefined;
    range.to = endTime ? getDateTime(endTime) : undefined;
    return range;
};

const toPeriodRangeMs = (range) => {
    const { from, to } = range ?? {};
    const rangeMs = { startTime: from?.toMillis(), endTime: to?.toMillis() };
    return rangeMs;
};

const getEndOfDay = (date) => {
    if (!date) return;
    return date.endOf('day');
};


export const PeriodPicker = (props) => {

    const {
        onApply,
        onCancel,
        selected,
        validateApply,
        overlayProps = {}
    } = props;

    const [period, setPeriod] = useState(selected);
    const [month, setMonth] = useState(getDateTime(selected?.rangeMs?.startTime));

    const onSelect = useCallback((range) => {
        const custom = options.find((p) => p.id === periodIds.CUSTOM);
        const rangeMs = toPeriodRangeMs({ ...range, to: getEndOfDay(range.to) });
        setPeriod({ ...custom, rangeMs });
    }, []);

    const onClickPeriodItem = useCallback((item) => {
        const rangeMs = rangeMsFromPeriod(item) ?? period.rangeMs;
        if (rangeMs?.startTime) {
            setMonth(getDateTime(rangeMs.startTime));
        }
        setPeriod({ ...item, rangeMs });
    }, [period]);

    const onClickApply = useCallback(() => {
        let adjusted = adjustedEndTimePeriod(period);
        const rangeMs = rangeMsFromPeriod(adjusted);
        adjusted = { ...period, rangeMs };
        setPeriod(adjusted);
        onApply?.(adjusted);
    }, [period, onApply]);

    const footerProps = useMemo(() => ({
        buttons: [
            { onClick: onCancel },
            { onClick: onClickApply, disabled: !validateApply?.(period, selected) }
        ]
    }), [onClickApply, onCancel, period, validateApply, selected]);

    const adjustedEndTimePeriod = (period) => {
        const { startTime, endTime } = period.rangeMs ?? {};
        if (!startTime) return period;
        const end = getEndOfDay(getDateTime(endTime ?? startTime));
        return { ...period, rangeMs: { startTime, endTime: end.toMillis() }};
    };

    const getMaxRange = (range) => range?.from && range?.to ? undefined : 30;

    const selectedRange = toPickerRange(period);
    const max = getMaxRange(selectedRange);

    return (
        <CustomOverlay
            placement='bottom'
            rootClose
            {...overlayProps}
        >
            <CustomRangePicker
                fromYear={getDateTime().year - 2}
                toDate={getDateTime().endOf('day')}
                max={max}
                month={month}
                onMonthChange={setMonth}
                onCustomSelect={onSelect}
                selected={selectedRange}
                footerProps={footerProps}
                testAttributes={setAttr.feature(testBy.FEATURE.PERIOD_DATE_PICKER)}
            >
                <OptionsList
                    selectedId={period.id}
                    onClick={onClickPeriodItem}
                    options={options}
                />
            </CustomRangePicker>
        </CustomOverlay>
    );
};
