import React, { useCallback } from 'react';
import { Collapse } from 'react-bootstrap';
import { Checkbox, checkboxStates, Icon, Text } from 'components/common';
import { iconsName } from 'assets';
import './style.scss';
import { testBy } from 'utils/constants';

const SelectableOption = (props) => {
    const { option, onSelectOption, collapsedGroups, onCollapseClick, onSelectChildOption=null, level='0', ...rest } = props;
    const thisLevel = parseInt(level, 10) + 1;

    const isOpen = collapsedGroups[option.id] ?? true;

    const levelClass = (level) => `selectableOption optionLevel${level}`;

    const setValueIfHasOptions = useCallback(() => {
        if (option.options?.length > 0) {
            const allSelected = option.options.every((option) => option.value > checkboxStates.UNSELECTED);
            if (allSelected) {
                option.value = checkboxStates.SELECTED;
            } else {
                const someSelected = option.options.some((option) => option.value > checkboxStates.UNSELECTED);
                option.value = someSelected ? checkboxStates.PART_SELECTED : checkboxStates.UNSELECTED;
            }
        }
    }, [option]);
    setValueIfHasOptions();

    const handleClick = useCallback((e) => {
        const allVisibleSelected = option.options.filter((option) => option.visible).every((option) => option.value > checkboxStates.UNSELECTED);
        const value = (option.value < (allVisibleSelected ? checkboxStates.PART_SELECTED : checkboxStates.SELECTED)) ? checkboxStates.SELECTED : checkboxStates.UNSELECTED;
        option.value = value;
        option.options = option.options.map((option) => option.visible ? { ...option, value } : option);

        if (typeof onSelectChildOption === 'function') {
            onSelectChildOption(e);
        } else {
            setValueIfHasOptions();
            onSelectOption(option, e);
        }

        return option.value;
    }, [option]);

    const handleChildClick = useCallback((e) => {
        setValueIfHasOptions();
        onSelectOption(option, e);
    }, [option]);

    const renderSubOptions = () => option.options.map((subOption, idx) => subOption.visible ?
        <SelectableOption
            key={idx}
            option={subOption}
            onSelectOption={onSelectOption}
            onSelectChildOption={(e) => handleChildClick(e)}
            level={thisLevel}
            collapsedGroups={collapsedGroups}
            onCollapseClick={onCollapseClick}
        /> : null
    );

    const renderCollapseIcon = () => (option.options.length > 0) ? (
        <div className='collapse-icon'>
            <Icon
                onClick={() => onCollapseClick(option.id)}
                name={isOpen ? iconsName.EXPAND_LESS : iconsName.EXPAND_MORE}
                useDefault />
        </div>
    ) : null;

    return (
        <div className={levelClass(thisLevel)} {...rest}>
            <div className='option-header'>
                {renderCollapseIcon()}
                <Checkbox name={`option_${option.id}`} value={option.value} handleChange={handleClick}>
                    {option.icons.map((icon, i) => (
                        <span className='optionIcon' key={i}>{icon}</span>
                    ))}
                    <Text title={option.label} text={option.label} className={testBy.CLASS.FILTER_OPTION} />
                </Checkbox>
            </div>
            <Collapse
                in={isOpen}
            >
                <div className="collapse-area">
                    {renderSubOptions()}
                </div>
            </Collapse>
        </div>
    );
};

export default SelectableOption;