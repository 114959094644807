import { useCallback, useRef, useState, useMemo } from 'react';
import { fetchTripInfo } from 'store/actions/trips';



// params -> { resourceId, start, end }
export const useTrip = () => {

    const loadedRef = useRef(false);
    const [data, setData] = useState({ items: [] });
    const [isLoading, setIsLoading] = useState(false);

    const loadTrip = useCallback(async (params) => {
        loadedRef.current = true;
        setIsLoading(true);
        const tripData = await fetchTripInfo(params);
        setIsLoading(false);
        setData(tripData);
        return tripData;
    }, []);

    return useMemo(() => ({
        isLoading,
        ...data,
        loaded: loadedRef.current,
        loadTrip
    }), [isLoading, data, loadTrip]);
};