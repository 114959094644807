import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/actions/popup';
import { iconsName, getSvgDataUrl } from 'assets';
import { getEventIcon } from 'utils/entities';
import { modalsIds } from 'components/modal/ModalsConstants';
import { BaseModal } from 'components/common';
import { ModalContainer, ModalTwinScreens } from 'components/modal/components';
import PositionsMap from 'pages/safety_page/features/maps/positions_map/PositionsMap';
import StreetViewMap from 'pages/safety_page/features/maps/street_view_map/StreetViewMap';
import { setAttr, testBy } from 'utils/constants';
import './style.scss';

const MapModal = ({ data }) => {

    const dispatch = useDispatch();

    const onClickClose = useCallback(() => {
        dispatch(closeModal(modalsIds.MAP_MODAL));
    }, [dispatch]);

    const headerButtons = [
        // { name: iconsName.SHARE },
    ];

    const markerIcons = useMemo(() => {
        const eventIconStyle = (Array.isArray(data.pointsAfter) || Array.isArray(data.pointsBefore)) ?
            { fill: '#FD7668', stroke: '#000', viewBox: '0 0 16 20' } : { fill: '#000' };

        return {
            after: {
                url: getSvgDataUrl(iconsName.PIN, { fill: '#FDF569', transform: 'scale(0.8)', stroke: '#000', viewBox: '0 0 16 20' }),
                anchor: new google.maps.Point(8, 20),
            },
            before: {
                url: getSvgDataUrl(iconsName.PIN, { fill: '#00E64D', transform: 'scale(0.8)', stroke: '#000', viewBox: '0 0 16 20' }),
                anchor: new google.maps.Point(8, 20),
            },
            event: {
                url: getSvgDataUrl(iconsName.PIN, eventIconStyle),
            }
        };
    }, [data.pointsAfter, data.pointsBefore]);

    const collisionMapLegend = useMemo(() => (Array.isArray(data.pointsAfter) || Array.isArray(data.pointsBefore)) ?
        <div className="map-legend">
            <div><img src={markerIcons.before.url} alt="" /><span>{'Before accident'}</span></div>
            <div><img src={markerIcons.event.url} alt="" /><span>{'Collision point'}</span></div>
            <div><img src={markerIcons.after.url} alt="" /><span>{'After accident'}</span></div>
        </div> : null
    , [data.pointsAfter, data.pointsBefore, markerIcons.after.url, markerIcons.before.url, markerIcons.event.url]);

    return (
        <BaseModal
            onClickClose={onClickClose}
            icon={getEventIcon(data.type)}
            headerText={data.eventName}
            resourceName={data.resourceName}
            eventTime={data.eventTime}
            headerButtons={headerButtons}
            className="map-modal"
            testAttributes={setAttr.feature(testBy.FEATURE.MODAL_MAP)}
        >
            <ModalContainer>
                <ModalTwinScreens
                    gap="16px"
                    contents1={<>
                        <PositionsMap
                            markerIcons={markerIcons}
                            pointsAfter={data.pointsAfter}
                            pointsBefore={data.pointsBefore}
                            position={data.position}
                            info={data.info}
                            options={{ streetViewControl: false }}
                            zoom={16}
                        />
                        {collisionMapLegend}
                    </>
                    }
                    contents2={
                        <StreetViewMap
                            position={data.position}
                            zoom={16}
                        />
                    }
                />
            </ModalContainer>
        </BaseModal>
    );
};

export default MapModal;
