import { createSlice } from '@reduxjs/toolkit';
import { reducerNames } from 'store/constants';

const initialState = {
    accounts: [],
};

const slice = createSlice({
    name: reducerNames.PROVISION,
    initialState,
    reducers: {
        setAccounts: (state, action) => {
            state.accounts = action.payload;
        },
    },
});


export default slice.reducer;

export const { name, getInitialState, actions } = slice;