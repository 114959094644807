import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { iconsName } from 'assets';
import { BaseModal } from 'components/common';
import { ConfirmationDialog } from 'components/common/dialogs/confirmation_dialog/ConfirmationDialog';
import { modalsIds } from 'components/modal/ModalsConstants';
import { ModalContainer } from 'components/modal/components';
import useComments from 'components/modal/hooks/use_comments/useComments';
import { openModal, closeModal } from 'store/actions/popup';
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { getEventIcon } from 'utils/entities';

const CategoryModal = (props) => {

    const {
        children,
        data,
        eventName,
        handleCloseAndSaveClick,
        hasMessage,
        icon,
        modalId,
        moreActions,
        needSave,
        renderFooter,
        stateComponent,
        testAttributes,
    } = props;

    const dispatch = useDispatch();

    const commentTriggerRef = useRef();

    const commentsPopover = useComments(data?.id, data?.statusId, commentTriggerRef);

    const headerButtons = [
        {
            name: iconsName.VIDEO_PLAY,
            onClick: ((data?.videos) ? () => {
                dispatch(openModal({ id: modalsIds.VIDEO_PLAYER_MODAL, data }));
            } : null),
            testAttributes: setAttr.action(testBy.ACTION.OPEN_VIDEOS_PLAYER, !data?.videos),
            title: ((data?.videos) ? t('lblOpenVideo') : t('lblNoVideoAvailable')),
        },
        // {name: iconsName.SHARE, onClick: null},
        {
            name: iconsName.PDF_PRINT,
            onClick: () => {
                const rootClassList = document.getElementById('root').classList;
                rootClassList.toggle('hideForPrint', true);
                window.print();
                rootClassList.toggle('hideForPrint', false);
            },
            testAttributes: setAttr.action(testBy.ACTION.PRINT),
            title: t('lblPrintToPdf'),
        },
        {
            contents: commentsPopover,
            name: iconsName.NOTES,
            onClick: () => commentTriggerRef.current?.click(),
            stroke: true,
            style: { stroke: hasMessage ? 'rgb(22, 112, 92)' : 'rgb(79, 78, 78)' },
            testAttributes: setAttr.action(testBy.ACTION.TOGGLE_COMMENTS),
            title: t('lblComments'),
        },
    ];

    const onClickClose = useCallback(async (withSave) => {
        if (needSave && !withSave) {
            const result = await ConfirmationDialog(t('lblConfirmCloseModal'), { title: t('lblClose'), okText: t('lblYes') });
            if (!result) return;
        }
        if (withSave) {
            await handleCloseAndSaveClick?.();
        }
        dispatch(closeModal(modalId));
    }, [dispatch, handleCloseAndSaveClick, modalId, needSave]);

    const closeButtonSettings = { withSave: needSave, label: needSave ? t('lblSaveAndClose') : null, class: needSave ? 'data-changed' : null };

    return (
        <BaseModal
            onClickClose={onClickClose}
            icon={icon ?? { ...getEventIcon(data?.type), title: data?.typeName }}
            headerText={eventName}
            stateComponent={stateComponent}
            resourceName={data?.resourceName}
            eventTime={data?.eventTime}
            headerButtons={headerButtons}
            moreActions={moreActions}
            renderFooter={renderFooter}
            closeButtonSettings={closeButtonSettings}
            testAttributes={testAttributes}
        >
            <ModalContainer>
                {children}
            </ModalContainer>
        </BaseModal>
    );
};

export default CategoryModal;
