import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT_USER } from 'store/actionTypes';
import { reducerNames } from 'store/constants';


const initialState = {
    modals: [],
    dialogs: [],
    windows: [],
};

const slice = createSlice({
    name: reducerNames.POPUP,
    initialState,
    reducers: {
        openModal: (state, { payload }) => {
            state.modals.push(payload);
        },
        closeModal: (state, { payload }) => {
            state.modals = state.modals.filter((m) => m.id !== payload);
        },
        openDialog: (state, { payload }) => {
            state.dialogs.push(payload);
        },
        closeDialog: (state, { payload }) => {
            state.dialogs = state.dialogs.filter((d) => d.id !== payload);
        },
        openWindow: (state, { payload }) => {
            state.windows.push(payload);
        },
        closeWindow: (state, { payload }) => {
            state.windows = state.windows.filter((d) => d.id !== payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(LOGOUT_USER, (state, action) => initialState);
    }
});

export default slice.reducer;

export const { name, actions, getInitialState } = slice;
