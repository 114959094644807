import React, { useCallback, useMemo, useRef } from 'react';
import { Marker } from '@react-google-maps/api';
import { getSvgDataUrl, iconsName } from 'assets';
import { severities } from 'utils/entities';

const IncidentMarker = ({ clickData, location, onClick, severity }) => {

    const markerRef = useRef();

    const severityColour = severities[severity].color;

    const regIcon = useMemo(() => ({
        url: getSvgDataUrl(iconsName.PIN, { fill: severityColour, stroke: '#000' })
    }), [severityColour]);

    const smallIcon = useMemo(() => ({
        anchor: new google.maps.Point(8, 20),
        url: getSvgDataUrl(iconsName.PIN, { fill: severityColour, stroke: '#000', transform: 'scale(0.8)', viewBox: '0 0 16 20' })
    }), [severityColour]);

    const onClickMarker = useCallback(() => {
        onClick?.(clickData);
    }, [clickData, onClick]);

    const onMouseOut = useCallback(() => {
        markerRef.current.marker.setIcon(smallIcon);
        markerRef.current.marker.setZIndex(1);
    }, [smallIcon]);

    const onMouseOver = useCallback(() => {
        markerRef.current.marker.setZIndex(100);
        markerRef.current.marker.setIcon(regIcon);
    }, [regIcon]);

    return <Marker
        onClick={onClickMarker}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
        optimised
        options={{
            clickable: true,
            icon: smallIcon
        }}
        position={location}
        ref={markerRef}
        zIndex={1}
    />;
};

export default IncidentMarker;
