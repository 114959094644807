import { useCallback, useMemo } from 'react';
// Utils
import { testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import './style.scss';

const ModalCollisionImages = ({ images, imagesLoaded, onClickViewImage }) => {

    const loadedMarker = useMemo(() => imagesLoaded ? <div className={testBy.CLASS.IMAGES_LOADED}></div> : null, [imagesLoaded]);

    const onClick = useCallback((ev) => {
        const wrapperEl = ev.target.closest('[data-index]');
        const index = (wrapperEl instanceof Element) ? parseInt(wrapperEl.getAttribute('data-index'), 10) : 0;
        onClickViewImage(index);
    }, [onClickViewImage]);

    return (
        <div className="collision-images">
            {images.slice(0, 4).map((img, idx) => (
                <div key={img.id} className={`${testBy.CLASS.IMAGE_COLLISION} collision-image`} data-index={idx} onClick={onClick}>
                    <img src={img.thumbnail} alt='gallery' />
                </div>
            ))}
            <div className={`${testBy.CLASS.IMAGE_COLLISION} collision-image view-all`} onClick={onClick}>
                <div>{t('lblTotalPhotos', { count: images.length })}</div>
                <div>{`${t('lblViewAll')} ->`}</div>
            </div>
            {loadedMarker}
        </div>
    );
};

export default ModalCollisionImages;
