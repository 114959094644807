import React, { useState } from 'react';
import { confirmable, createConfirmation } from 'react-confirm';
import { TextInput } from 'components/common';
import BaseDialog from 'components/common/dialogs/BaseDialog';
import './style.scss';

const InputDialogModal = ({ show, proceed, text, inputLabelText, confirmation, okText, cancelText, inputType }) => {

    const [inputValue, setInputValue] = useState('');

    const onInputChange = (e) => {
        setInputValue(e.target.value);
    };

    return (
        <BaseDialog
            show={show}
            onCancelClick={() => proceed(null)}
            onOkClick={(inputValue == null || inputValue.length === 0) ? null : () => proceed(inputValue)}
            title={confirmation}
            okText={okText}
            cancelText={cancelText}
            className="input"
        >
            {text && <div className='dialog-text'>{text}</div>}
            <TextInput
                label={inputLabelText}
                name='inputValue'
                value={inputValue}
                onChange={onInputChange}
                type={inputType}
                autoFocus
            />
        </BaseDialog>
    );
};

const confirmLow = createConfirmation(confirmable(InputDialogModal));

export const InputDialog = (message, options = {}) => confirmLow(Object.assign({ confirmation: message }, options));
