import React from 'react';
import { getDevMode } from 'utils/domHelper';
// Utils
import { logger, levels } from 'utils/logger';
// Components
import { ErrorFallbackUI, DevErrorFallbackUI } from './ErrorMessages';
import './style.scss';



const { FATAL } = levels;

const getErrorEventInfo = (e) => {
    const { error, filename, lineno, colno, message } = e;
    return {
        message,
        stack: error?.stack,
        filename,
        lineno,
        colno,
    };
};

const getClientInfo = () => {
    const { onLine, userAgent } = window.clientInformation;
    return {
        onLine,
        userAgent,
        time: Date.now()
    };
};


export class ErrorBoundary extends React.PureComponent {

    // static getDerivedStateFromError(error) {
    //     return { hasError: true };
    // }

    // Unhandled exceptions
    onError = (e) => {
        if (!e.error) return;
        const { boundary } = this.props;
        const clientInfo = getClientInfo();
        const errorEventInfo = getErrorEventInfo(e);
        const errorInfo = {
            ...clientInfo,
            ...errorEventInfo,
            boundary,
            level: FATAL
        };
        logger.fatal(errorInfo);
        this.setState({ errorInfo, hasError: true });
    };

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorInfo: {}
        };
    }

    componentDidMount() {
        const { root } = this.props;
        if (root) {
            window.addEventListener('error', this.onError);
        }
    }

    componentWillUnmount() {
        const { root } = this.props;
        if (root) {
            window.removeEventListener('error', this.onError);
        }
    }

    componentDidCatch(error, info) {
        const { boundary, onCatch } = this.props;
        const clientInfo = getClientInfo();
        // Information for log
        const errorInfo = {
            message: error.message,
            stack: error.stack,
            ...info,
            ...clientInfo,
            boundary,
            level: FATAL
        };
        // FATAL error
        logger.fatal(errorInfo);
        this.setState({ errorInfo, hasError: true });
        onCatch?.(errorInfo);
    }

    resetError = () => {
        this.setState({ errorInfo: {}, hasError: false });
    };

    renderFallbackUI = () => {
        const { fallbackUI: FallbackUI } = this.props;
        const { errorInfo } = this.state;
        const devMode = getDevMode();
        if (devMode.fallbackUI) {
            return <DevErrorFallbackUI errorInfo={errorInfo} resetError={this.resetError} />;
        }
        return <FallbackUI errorInfo={errorInfo} resetError={this.resetError} />;
    };

    render() {

        const { children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return this.renderFallbackUI();
        }

        return children;
    }
}


ErrorBoundary.defaultProps = {
    fallbackUI: ErrorFallbackUI
};
