// Utils
import { iconsName } from 'assets';
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { trends } from 'utils/entities';

// Components
import { Icon, Text } from 'components/common';
import { Card } from 'layout/containers';
import './style.scss';


export const SimpleKpi = ({ value, name, icon, trend }) => {

    const trendInfo = trends[trend];

    return (
        <Card
            className="kpi-card"
            testAttributes={setAttr.feature(testBy.FEATURE.KPI_CARD)}
        >
            {
                icon ? (
                    <div className="section kpi-icon">
                        <Icon
                            width={35}
                            height={35}
                            title={name}
                            {...icon}
                        />
                    </div>
                ) : null
            }

            <div
                className="kpi-card-right"
                {...setAttr.ui(testBy.UI.CARD_CONTENTS)}
            >
                <div className="kpi-value-wrapper">
                    <Text
                        className={`kpi-value ${testBy.CLASS.CARD_VALUE}`}
                        text={value}
                    />
                    {
                        trendInfo?.direction ? (
                            <Icon
                                className={trendInfo?.direction}
                                title={t(trendInfo?.lblKey)}
                                name={iconsName.ARROW_FULL}
                                width={24}
                                height={24}
                            />
                        ) : null
                    }
                </div>
                <Text
                    className={testBy.CLASS.CARD_TITLE}
                    text={name}
                />
            </div>
        </Card>
    );
};

export const SimpleKpis = (props) => {
    const { kpis } = props;
    const items = kpis.map((item) => <SimpleKpi
        key={item.name}
        {...item}
    />);

    return (
        <div className="kpis-container">
            {items}
        </div>
    );
};
