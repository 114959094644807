import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Map from 'components/google_maps/Map';
import { getSiteDefaultCoordinates } from 'store/selectors/user';
import { isValidCoordinate } from 'utils/helper';

const UserMap = ({ center, children, ...rest }) => {

    const defaultCoordinates = useSelector(getSiteDefaultCoordinates);

    const mapCenter = useMemo(() => {
        if (isValidCoordinate(center ?? {})) {
            return center;
        }
        if (isValidCoordinate(defaultCoordinates ?? {})) {
            return defaultCoordinates;
        }
        return undefined;
    }, [center, defaultCoordinates]);

    return (
        <Map
            center={mapCenter}
            {...rest}
        >
            {children}
        </Map>
    );
};

export default UserMap;
