import React, { useCallback, useMemo } from 'react';
// Utils
import { testBy, setAttr } from 'utils/constants';
import { getWidthUnitValue } from 'utils/domHelper';
import { classAttribute } from 'utils/helper';
// Components
import { RecycleList } from 'components';
import './style.scss';



const { UI } = testBy;


const HeadersWrapper = (props) => {

    const { children, className, columns } = props;

    const headers = useMemo(() => React.Children.map(children, (header) => {
        if (React.isValidElement(header)) {
            const { style, id, ...rest } = header.props;
            const column = columns.find((c) => c.id === id);
            return React.cloneElement(header, { ...rest, id, style: { ...column?.style, ...style }});
        }
        return header;
    }), [children, columns]);

    const classes = useMemo(() => classAttribute([
        'recycle-table-headers-wrapper',
        className ?? '',
    ]), [className]);

    return (
        <div
            className={classes}
            { ...setAttr.ui(UI.HEADER) }
        >
            {headers ?? children}
        </div>
    );
};

export const TableHeader = (props) => {

    const { style, dataKey, children, onClick, id, label, ...rest } = props;

    const handleClick = useCallback(() => {
        onClick?.({ dataKey, id, label, ...rest });
    }, [onClick, dataKey, id, label, rest]);

    const classes = useMemo(() => classAttribute([
        'recycle-table-header',
        onClick ? 'click-able' : '',
    ]), [onClick]);

    return (
        <div
            className={classes}
            style={style}
            onClick={handleClick}
            {...setAttr.ui(UI.CELL)}
        >
            {children ?? label ?? dataKey ?? null}
        </div>
    );
};


const Cell = (props) => {

    const { item, index, column, renderCell, onClick } = props;

    const handleClick = useCallback(() => {
        onClick?.({ item, column, index });
    }, [onClick, item, column, index]);

    if (!item || index === undefined) {
        return null;
    }

    const { dataKey, style } = column;
    const cell = renderCell?.({ item, column, index, style });

    return (
        <div
            className="recycle-table-cell"
            style={style}
            onClick={handleClick}
            {...setAttr.ui(UI.CELL)}
        >
            {cell ?? item[dataKey]}
        </div>
    );
};


const Row = React.memo((props) => {

    const {
        data,
        dataIndex,
        top,
        height,
        renderCell,
        columns,
        selected,
        onClickRow
    } = props;

    const item = data[dataIndex];

    const cells = useMemo(() => columns.map((column, i) => (
        <Cell
            key={column.id ?? i}
            column={column}
            item={item}
            renderCell={renderCell}
            index={dataIndex}
            onClick={onClickRow}
        />
    )), [columns, item, renderCell, dataIndex, onClickRow]);

    const classes = useMemo(() => classAttribute([
        'recycle-table-row',
        selected ? 'selected' : '',
        `row-${dataIndex}`,
        'list-row',
    ]), [dataIndex, selected]);

    return (
        <div
            className={classes}
            style={{ top, height }}
            {...setAttr.ui(UI.ROW)}
        >
            {cells}
        </div>
    );
});


export class TableBase extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            hasVerticalScroll: false,
        };
    }

    onScrollBarChange = (scrollBar) => {
        const { hasVerticalScroll } = this.state;
        if (scrollBar.isVertical !== hasVerticalScroll) {
            // this.setState({ hasVerticalScroll: scrollBar.isVertical });
        }
    };

    getColumnsFromHeadersProps = () => {

        const { children } = this.props;
        const columns = [];
        let totalLeftWidth = '100%';
        const calc = (params) => `calc(${params})`;

        const headers = React.Children.toArray(children);
        const hasWidth = headers.filter(({ props }) => getWidthUnitValue(props.width).value);
        const withoutWidth = headers.length - hasWidth.length;

        hasWidth.forEach((header) => {
            const { unit, value } = getWidthUnitValue(header.props.width, { unit: '%' });
            totalLeftWidth = calc(`${totalLeftWidth} - ${value}${unit}`);
        });

        const autoWidth = `calc(${totalLeftWidth} / ${withoutWidth})`;

        headers.forEach((header) => {
            if (!React.isValidElement(header)) return;
            const { dataKey, width, style, id, label } = header.props;
            const { unit, value } = getWidthUnitValue(width, { unit: '%' });
            const columnWidth = value ? `${value}${unit}` : undefined;
            // const flex = `0 0 ${columnWidth ?? autoWidth}`;
            const flex = `1 1 ${columnWidth ?? autoWidth}`;
            const calWidth = style?.minWidth ?? columnWidth ?? autoWidth;
            const column = { dataKey, id, label, style: { ...style, width: calWidth, flex }};
            // const column = { dataKey, id, label, style: { ...style, minWidth, flex } };
            columns.push(column);
        });
        return columns;
    };

    renderRow = ({ dataIndex, data, ...rowProps }) => {

        const {
            renderCell,
            selectedId,
            onClickRow
        } = this.props;

        const item = data[dataIndex];
        const selected = Boolean(selectedId && (selectedId === item?.id));

        return (
            <Row
                renderCell={renderCell}
                columns={this.columns}
                selected={selected}
                data={data}
                dataIndex={dataIndex}
                onClickRow={onClickRow}
                {...rowProps}
            />
        );
    };

    render() {

        const {
            items = [],
            rowHeight,
            className,
            offScreenRow = 10,
            children,
            selectedId,
            onWheel,
        } = this.props;

        const { hasVerticalScroll } = this.state;

        this.columns = this.getColumnsFromHeadersProps();

        const containerClasses = classAttribute([
            'recycle-table-container',
            className ?? '',
        ]);

        const headerClasses = classAttribute([
            hasVerticalScroll ? 'vertical-scroll' : '',
        ]);

        return (
            <div
                className={containerClasses}
            >
                <HeadersWrapper
                    columns={this.columns}
                    className={headerClasses}
                >
                    {children}
                </HeadersWrapper>
                <RecycleList
                    rowComponent={this.renderRow}
                    items={items}
                    rowHeight={rowHeight}
                    offScreenRow={offScreenRow}
                    onScrollBarChange={this.onScrollBarChange}
                    selectedId={selectedId}
                    onWheel={onWheel}
                />
            </div>
        );
    }
}
