import React, { useCallback, useMemo } from 'react';
// Utils
import { locales, supportedLanguages, useTranslation } from 'utils/dictionary';
// Components
import { Button } from 'components/common';
import './style.scss';


export const LanguageSettings = (props) => {

    const { i18n, t } = useTranslation();

    const onClick = useCallback((data) => {
        i18n.changeLanguage(data.value);
    }, [i18n]);

    const langBtns = useMemo(() => {
        const langBtns = locales.toList()
            .filter((l) => supportedLanguages.includes(l.langName))
            .map(({ lblName, langName, id }) => {
                const currentLang = langName === i18n.language;
                return (
                    <Button
                        key={id}
                        label={t(lblName)}
                        classes={['primary-light', currentLang ? 'fill' : 'gradient']}
                        disabled={currentLang}
                        data={{ value: langName }}
                        onClick={onClick}
                    />
                );
            });
        return langBtns;
    }, [onClick, i18n, t]);

    return (
        <div>
            <div>
                {t('lblSelectLanguage')}
            </div>
            <div>
                {langBtns}
            </div>
        </div>
    );
};


