import React from 'react';
import { FixedList } from 'react-recycled-list';
import { setAttr, testBy } from 'utils/constants';
import { classAttribute } from 'utils/helper';
import './style.scss';



export class RecycleList extends React.PureComponent {

    constructor(props) {
        super(props);
        this.listRef = React.createRef();
        this.wrapperElm = null;
        this.scrollBar = {
            isVertical: false,
            isHorizontal: false
        };
    }

    onRef = (ref) => {
        if (!ref) return;
        this.listRef.current = ref;
        this.wrapperElm = this.listRef.current.listWindowRef?.current;
    };

    onWheel = (e) => {
        const { onWheel } = this.props;
        onWheel?.(e, this.listRef.current, this.wrapperElm);
    };

    onVisibleRowChange = (info) => {
        /*
            const visibilityInfo {
                firstVisibleRowIndex: number;
                firstVisibleDataIndex: number;
                lastVisibleRowIndex: number;
                lastVisibleDataIndex: number;
                lastRowIndex: number;
            }
        */
        // console.log('onVisibleRowChange', info);
        this.checkScrollBarChange();
    };

    onRenderedRowChange = (info) => {
        /*
            const renderInfo {
                firstRenderedRowIndex: number;
                firstRenderedDataIndex: number;
                lastRenderedRowIndex: number;
                lastRenderedDataIndex: number;
                lastRowIndex: number;
            }
        */
        // console.log('onRenderedRowChange', info);
    };

    onScrollBarChange = (scrollBar) => {
        const { onScrollBarChange } = this.props;
        onScrollBarChange?.(scrollBar);
    };

    checkScrollBarChange = () => {
        if (!this.wrapperElm || !this.listRef.current) return;
        const { items, rowHeight } = this.props;
        const { clientHeight, scrollHeight } = this.wrapperElm;
        const verticalScroll = items.length * rowHeight > clientHeight;
        // const verticalScroll = scrollHeight > clientHeight;
        // console.log('onScrollBarChange', {
        // 	...this.scrollBar,
        // 	verticalScroll,
        // 	scrollHeight,
        // 	clientHeight,
        // });
        if (this.scrollBar.isVertical !== verticalScroll) {
            this.scrollBar.isVertical = verticalScroll;
            this.onScrollBarChange(this.scrollBar);
        }
    };

    render() {

        const {
            rowComponent,
            items,
            rowHeight,
            column,
            className,
            onWheel,
            width = 0,
            height = 0,
            ...rest
        } = this.props;

        const listClasses = classAttribute([
            'recycle-list',
            className ?? '',
        ]);

        return (
            <div
                className='list-container'
                { ...setAttr.ui(testBy.UI.LIST) }
            >
                <FixedList
                    listWindowProps={{
                        className: 'recycle-list-parent',
                        onWheel: this.onWheel
                    }}
                    listProps={{
                        className: listClasses,
                    }}
                    height={height}
                    width={width}
                    rowComponent={rowComponent}
                    data={items}
                    rowHeight={rowHeight}
                    column={column}
                    ref={this.onRef}
                    // onVisibleRowChange={this.onVisibleRowChange} This event throw error in AbstractList.tsx onScrollChange
                    // onRenderedRowChange={this.onRenderedRowChange}
                    {...rest}
                />
            </div>
        );
    }
}
