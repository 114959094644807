import { batch } from 'react-redux';
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
// Utils
import { rangeMsFromPeriod } from 'pages/safety_page/constants';
import actions, { fetchSafetyDrivers, fetchDriverImages } from 'store/actions/safetyDrivers';
import * as selectors from 'store/selectors/safetyDrivers';
import { filterActionMatcher } from 'store/slices/safetyDrivers';


const updateDriversParamsListener = createListenerMiddleware();

updateDriversParamsListener.startListening({
    matcher: isAnyOf(
        actions.nextPaging,
        actions.updateParams,
        actions.setSearchParams,
        filterActionMatcher
    ),
    effect: async (action, listenerApi) => {

        const isUpdateParams = isAnyOf(
            actions.updateParams,
            actions.setSearchParams,
            filterActionMatcher
        )(action);

        const isPaging = action.type === actions.nextPaging.type;

        listenerApi.cancelActiveListeners();
        await listenerApi.delay(500);

        const state = listenerApi.getState();
        const { pageNumber, maxCount } = selectors.getPagingState(state);
        const { period, ...paramsData } = selectors.getParams(state);
        const searchParams = selectors.getSearchParams(state);

        let params = { ...paramsData };
        if (!params.startTime || !params.endTime || isUpdateParams) {
            const rangeMs = rangeMsFromPeriod(period);
            params = { ...params, ...rangeMs };
        }
        const paramsWitSearch = { ...params, ...searchParams };

        const updateParameters = async () => {
            listenerApi.dispatch(actions.setFetching({ isPaging: false, isLoading: true }));
            if (pageNumber > 1) {
                listenerApi.dispatch(actions.resetPaging());
            }

            const { data, status } = await fetchSafetyDrivers({ ...paramsWitSearch, pageNumber: 1, maxCount });
            if (status === 200) {
                const { drivers, filteredRows, totalRows } = data.data;

                const driverImageIds = drivers.filter((d) => d.imageUrl).map((d) => d.imageUrl);
                listenerApi.dispatch(fetchDriverImages(driverImageIds));
                batch(() => {
                    listenerApi.dispatch(actions.setItems(drivers));
                    listenerApi.dispatch(actions.setPagingDataInfo({ filteredRows, totalRows }));
                });
            }

            batch(() => {
                listenerApi.dispatch(actions.setParams(params));
                listenerApi.dispatch(actions.setFetching({ isPaging: false, isLoading: false }));
            });
        };

        if (isUpdateParams) {
            await updateParameters();
        }

        if (isPaging) {
            listenerApi.dispatch(actions.setFetching({ isPaging: true, isLoading: false }));
            const { data, status } = await fetchSafetyDrivers({ ...paramsWitSearch, pageNumber, maxCount });
            if (status === 200) {
                const { drivers, filteredRows, totalRows } = data.data;

                const driverImageIds = drivers.filter((d) => d.imageUrl).map((d) => d.imageUrl);
                listenerApi.dispatch(fetchDriverImages(driverImageIds));
                batch(() => {
                    listenerApi.dispatch(actions.addItems(drivers));
                    listenerApi.dispatch(actions.setPagingDataInfo({ filteredRows, totalRows }));
                });
            }

            listenerApi.dispatch(actions.setFetching({ isPaging: false, isLoading: false }));
        }

    },
});

export default updateDriversParamsListener;
