import React from 'react';
// Utils
import { t } from 'utils/dictionary';
import { iconsName } from 'assets';
// Components
import ModalInfoRow from 'components/modal/components/modal_info_row/ModalInfoRow';
import './style.scss';

const ModalCoordsInfoRow = ({ label, lat, lng, iconObj, highlight }) => {
    const iconObject = (iconObj && typeof iconObj === 'object') ? { ...iconObj, name: iconsName.PIN, useDefault: true, title:t('lblOpenMap') } : null;

    return (
        <ModalInfoRow iconObj={iconObject} highlight={highlight} subLabel="(Lat, long)">
            <label>{label}</label>
            <span className='coordinates'>
                <span>{lat ?? t('lblUnknown')}</span>
                <span>{lng ?? t('lblUnknown')}</span>
            </span>
        </ModalInfoRow>
    );
};

export default ModalCoordsInfoRow;
