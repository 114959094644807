import { useCallback, useMemo, useState } from 'react';
import { iconsName } from 'assets';
import { ContextPopover, Icon, SendInput } from 'components/common';
import { getCommandStatusName, getCommandText } from 'components/modal/ModalsConstants';
import Comment, { commentRaw } from 'components/modal/components/comment/Comment';
import { api } from 'services';
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import './style.scss';

const useComments = (incidentId, statusId, triggerRef) => {

    const [ renderedComments, setRenderedComments ] = useState();

    const getRawComment = (stateObj) => {
        if (stateObj.date) {
            if (typeof stateObj.comment === 'string') {
                try {
                    const objFromComment = JSON.parse(stateObj.comment);
                    if (objFromComment.message) {
                        // user comment
                        return commentRaw(stateObj.date, objFromComment.message, stateObj.userName);
                    }
                    if (objFromComment.command) {  // TODO: compose message for a command
                        const  commandName = getCommandText(objFromComment.command.type, objFromComment.command.isStart);
                        const status = getCommandStatusName(objFromComment.command.commandState);
                        const commandTxt = t('lblCommandWithStatusByUser',
                            { name: commandName, status: status, user: stateObj.userName } );
                        return commentRaw(stateObj.date, commandTxt);
                    }
                } catch (e) {
                    // assume we want to display the string value of stateObj.comment field 
                    return commentRaw(stateObj.date, stateObj.comment, stateObj.userName);
                }
            }

            if (stateObj.status !== undefined && stateObj.userName) {
                const comment = t('lblStateChangedBy', {
                    state: t(`lblEventStates.${stateObj.status}`),
                    username: stateObj.userName
                });
                return commentRaw(stateObj.date, comment); // this will show as system
            }
        }

        return null;
    };

    const loadComments = useCallback(async () => {
        const data = await api.getIncidentState(incidentId, false);
        const comments = data.map((item) => getRawComment(item))
            .filter((comment) => comment !== null);
        const renderedComments = comments.map((commentRaw, idx) => <Comment key={idx} { ...commentRaw } />);
        setRenderedComments(renderedComments);
    }, [incidentId]);

    const onCommentSend = useCallback(async (comment) => {
        if (typeof comment === 'string' && comment.length > 0) {
            const message = JSON.stringify({ message: comment });
            const resp = await api.setIncidentState(incidentId, statusId, message);
            if (resp) {
                loadComments();
            }
        }
    }, [incidentId, loadComments, statusId]);

    const commentsPopover = useMemo(() => {
        const footer = <SendInput
            autoFocus
            maxLength={255}
            name="send-comment"
            onClickSend={onCommentSend}
            placeholder={t('lblSendComments')}
        />;
        const headerIcon = <Icon
            name={iconsName.NOTES}
            fill="transparent"
            stroke="#000"
        />;
        return <ContextPopover
            ref={triggerRef}
            body={renderedComments}
            className="comments-popover"
            footer={footer}
            headerIcon={headerIcon}
            onBeforeShow={loadComments}
            placement="bottom-end"
            title={t('lblComments')}
            testAttributes={setAttr.feature(testBy.FEATURE.COMMENTS_POPUP)}
        />;
    }, [loadComments, onCommentSend, renderedComments, triggerRef]);

    return commentsPopover;
};

export default useComments;
