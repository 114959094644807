import { createSelector } from 'reselect';
import { getFiltersSelectors, setFiltersFromState } from 'store/common/filters';
import { reducerNames } from 'store/constants';
import { getDriversState } from 'store/selectors/drivers';
import { getIncidentTypes } from 'store/selectors/incidentTypes';
import { getResourcesState } from 'store/selectors/resources';
import { t } from 'utils/dictionary';
import { dataKeys } from 'utils/entities';

const getDashboardWidgetsState = (state) => state[reducerNames.DASHBOARD].widgets;
const getFiltersManagerState = (state) => state[reducerNames.DASHBOARD].filtersManager;
const getFilterParams = (state) => state[reducerNames.DASHBOARD].params;

export const getDashboardWidgets = createSelector(
    [getDashboardWidgetsState],
    (widgets) => {
        const defParams = { sort: dataKeys.severity, isDesc: true };
        const enriched = widgets.map((w) => {
            const { items, lblTitle, params } = w;
            return {
                ...w,
                params: { ...defParams, ...params },
                title: `${t(lblTitle)} (${items.length} ${t('lblUnread')})`
            };
        });
        return enriched;
    }
);

export const getDashboardWidgetIds = createSelector(
    [getDashboardWidgetsState],
    (widgets) => widgets.map((w) => w.id)
);

export const getDashboardWidgetWithUpdatedParams = (widgetId, apiParams) => createSelector(
    [getDashboardWidgets, getFiltersManagerState, getFilterParams],
    (widgets, filtersManager, filterParams) => {
        const widget = widgets.find((w) => w.id === widgetId);
        if (!widget) return null;

        filtersManager.filters.forEach((filter) => {
            const { name } = filter;
            delete (widget.params[name]);
        });

        const widgetParams = { ...{
            maxCount: 50,
            pageNumber: 1,
            statuses: '0',
            sort: dataKeys.severity,
            isDesc: true
        }, ...widget.params, ...filterParams, ...apiParams };

        return { ...widget, params: widgetParams };
    }
);

const getPagingState = () => {};
const getSearchValueState = () => {};

// Filters
const getDashboardFilters = createSelector(
    [getFiltersManagerState, getDriversState, getResourcesState, getIncidentTypes],
    setFiltersFromState
);

export const filtersSelectors = getFiltersSelectors(getFiltersManagerState, getDashboardFilters, getPagingState, getSearchValueState);
