import { iconsName } from 'assets';
import { colors } from 'styles/theme';
import { eventTypes } from 'utils/entities';


export const cameraIds = {
    FRONT: 1,
    BACK: 2,
};

export const trends = {
    1: { direction: 'up', lblKey: 'lblTrendUp' },
    0: { direction: 'right', lblKey: 'lblTrendNeutral' },
    [-1]: { direction: 'down', lblKey: 'lblTrendDown' }
};

export const severities = {
    0: { lblKey: 'lblSeverityLevel.0', color: colors.yellowV2, level: 0 }, // t('lblSeverityLevel.0')
    1: { lblKey: 'lblSeverityLevel.1', color: colors.orangeV2, level: 1 }, // t('lblSeverityLevel.1')
    2: { lblKey: 'lblSeverityLevel.2', color: colors.redV2, level: 2 }, // t('lblSeverityLevel.2')
};

const eventIcons = {
    1: { name: iconsName.COLLISION, stroke: '#000' },
    2: { name: iconsName.PIN_WARNING, stroke: '#000' },
    3: { name: iconsName.LOCK_PICK, stroke: '#000' },
    4: { name: iconsName.LOCK_PICK, stroke: '#000' },
    5: { name: iconsName.SOS, stroke: '#000' },
    6: { name: iconsName.CHARGER, stroke: '#000' },
    7: { name: iconsName.SOS, stroke: '#000' },
    8: { name: iconsName.SWITCH_OFF, stroke: '#000' },
    9: { name: iconsName.ALERT_BELL, stroke: '#000' },
    10: { name: iconsName.WIFI_WARNING, stroke: '#000' },
    11: { name: iconsName.DIODE, stroke: '#000' },
    12: { name: iconsName.LOCK_PICK, stroke: '#000' },
    13: { name: iconsName.CRIME_MASK, stroke: '#000' },
    14: { name: iconsName.CRIME_MASK, stroke: '#000' },
    15: { name: iconsName.CRIME_MASK, stroke: '#000' },
    16: { name: iconsName.CRIME_MASK, stroke: '#000' },
    17: { name: iconsName.CRIME_MASK, stroke: '#000' },
    18: { name: iconsName.CRIME_MASK, stroke: '#000' },
    19: { name: iconsName.HAMMER_HOLD, stroke: '#000' },
    20: { name: iconsName.HAMMER_HOLD, stroke: '#000' },
    21: { name: iconsName.HAMMER_HOLD, stroke: '#000' },
    22: { name: iconsName.HAMMER_HOLD, stroke: '#000' },
    23: { name: iconsName.WIFI_WARNING, stroke: '#000' },
    24: { name: iconsName.WIFI_WARNING, stroke: '#000' },
    25: { name: iconsName.WIFI_WARNING, stroke: '#000' },
    26: { name: iconsName.WIFI_WARNING, stroke: '#000' },
    27: { name: iconsName.CRIME_MASK, stroke: '#000' },
    28: { name: iconsName.SOS, stroke: '#000' },
    29: { name: iconsName.CAMERA_VIBRATE, stroke: '#000' },
    30: { name: iconsName.PIN_BLOCK, stroke: '#000' },
    31: { name: iconsName.TRIP, stroke: '#000' },
    32: { name: iconsName.DASHBOARD_SPEED, stroke: '#000' },
    33: { name: iconsName.DASHBOARD_SPEED, stroke: '#000' },
    34: { name: iconsName.DASHBOARD_WARNING, stroke: '#000' },
    35: { name: iconsName.ZIG_ZAG, stroke: '#000' },
    36: { name: iconsName.TRUCK_FAST, stroke: '#000' },
    37: { name: iconsName.FENCE, stroke: '#000' },
    38: { name: iconsName.PIN_BLOCK, stroke: '#000' },
    39: { name: iconsName.PIN_BLOCK, stroke: '#000' },
    40: { name: iconsName.WHEEL_DISABLE, stroke: '#000' },
    41: { name: iconsName.CAMERA_BLOCK, stroke: '#000' },
    43: { name: iconsName.SMOKING, stroke: '#000' },
    44: { name: iconsName.FOOD_DRINK, stroke: '#000' },
    45: { name: iconsName.PHONE_HOLD, stroke: '#000' },
    46: { name: iconsName.SEAT_BELT, stroke: '#000' },
    47: { name: iconsName.CHARGER, stroke: '#000' },
    48: { name: iconsName.DASHBOARD_WARNING, stroke: '#000' },
    49: { name: iconsName.TRUCK_FAST, stroke: '#000' },
    50: { name: iconsName.CIRCLE_LEFT, stroke: '#000' },
    51: { name: iconsName.CIRCLE_RIGHT, stroke: '#000' },
    52: { name: iconsName.YAWNING, stroke: '#000' },
};
export const getEventIcon = (type) => eventIcons[type] ?? { name: iconsName.TYPE_UNKNOWN, fill: '#000' };

const { COLLISION, THEFT, VIOLATION } = eventTypes.categoryIds;
const categoryIcons = {
    [COLLISION]: { name: iconsName.COLLISION, stroke: '#343434' },
    [THEFT]: { name: iconsName.SOS, stroke: '#343434' },
    [VIOLATION]: { name: iconsName.ALERT_BELL, stroke: '#343434' }
};
export const getCategoryIcon = (id) => categoryIcons[id] ?? { name: iconsName.WEB_API, fill: '#343434' };
