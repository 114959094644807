import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { supportedLanguages, locales } from 'utils/dictionary/constants';


const ns = ['translation'];

const getLangName = () => {
    const langName = sessionStorage.getItem('langName');
    return supportedLanguages.includes(langName) ? langName : locales.enUS.langName;
};


i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: getLangName(),
        fallbackLng: supportedLanguages,
        ns,
        lowerCaseLng: true,
        load: 'currentOnly',
        locales: supportedLanguages,
        debug: false,
        // debug: process.env.NODE_ENV === 'development',
        initImmediate: false,
        backend: {
            loadPath: `${process.env.PUBLIC_URL}/translates/{{lng}}.json`,
        },
        // special options for react-i18next
        // learn more: https://react.i18next.com/components/i18next-instance
        react: {
            useSuspense: true
        },
        interpolation: {
            escapeValue: false,
            skipOnVariables: true,
            // alwaysFormat: false,
        },
        saveMissing: false,
        // saveMissingPlurals: false,
    })
    .then((t) => {
    }).catch((error) => {
        console.log('Error dictionaty', error);
    });


export default i18next;
