import React, { useCallback, useEffect, useState } from 'react';
// Utils
import { t } from 'utils/dictionary';
import { setAttr, testBy } from 'utils/constants';
// Components
import { Button, SearchInput, checkboxStates } from 'components/common';
import { FilterTabs, SelectableOption, SelectionInfo } from 'components/filters';
import { filterUtils } from 'components/filters/filter_schema';
import { ConfirmationDialog } from 'components/common/dialogs/confirmation_dialog/ConfirmationDialog';
import './style.scss';

const FilterPopover = (props) => {

    const {
        triggerRef,
        filters,
        defaultTab,
        searches,
        applyFilters,
        resetFilters,
        updateFilter,
        getSelectionSummary,
        onSearchOptionsChange,
        onClickSearchClear,
    } = props;

    const [collapsedGroups, setCollapsedGroups] = useState({});

    const selectFilter = useCallback((filters, tabName) => {
        const idx = filters.findIndex((f) => f.name === tabName);
        return filters[idx];
    });

    useEffect(() => {
        updateFilter(selectedFilter);
    }, [searches]);

    const [selectedTab, setSelectedTab] = useState(defaultTab);
    const selectionSummary = getSelectionSummary(selectedTab);
    let selectedFilter = selectFilter(filters, selectedTab);

    const onClickTab = useCallback((filters, tab) => {
        setSelectedTab(tab?.name);
        const filter = selectFilter(filters, tab?.name);
        updateFilter(filter);
    }, []);

    const onSelectOption = useCallback(() => {
        updateFilter(selectedFilter);
    }, [selectedFilter]);

    const handleCollapseClick = useCallback((optionId) => {
        setCollapsedGroups((prev) => {
            const oldValue = prev[optionId] ?? true;
            return { ...prev, [optionId]: !oldValue };
        });
    }, [setCollapsedGroups]);

    const renderSelections = () => (
        <div className='selections'>
            {
                selectedFilter.options.map((option, idx) => option.visible ? (
                    <SelectableOption
                        key={idx}
                        option={option}
                        onSelectOption={onSelectOption}
                        collapsedGroups={collapsedGroups}
                        onCollapseClick={handleCollapseClick}
                    />
                ) : null)
            }
        </div>
    );

    return (
        <div
            className='filter-popover'
            { ...setAttr.feature(testBy.FEATURE.FILTER_POPUP) }
        >
            <div className='top-section'>
                <FilterTabs
                    filters={filters}
                    selectedTab={selectedTab}
                    onClick={onClickTab}
                />
                <div className='right-section'>
                    <div className='title'>{t('lblSelectionTitle', { title: selectedFilter.label }).toUpperCase()}</div>
                    <SearchInput
                        name={selectedFilter.name}
                        value={searches[selectedFilter.name] ?? ''}
                        onClickClear={onClickSearchClear}
                        onChange={onSearchOptionsChange}
                        autoFocus
                    />
                    <SelectionInfo
                        info={selectionSummary}
                        initialLinkIndex={selectionSummary.length ? 1 : 0}
                        links={[t('lblSelectAll'), t('lblUnselectAll')]}
                        onClickLink={(linkIndex) => {
                            const value = (linkIndex === 0) ?
                                checkboxStates.SELECTED :
                                checkboxStates.UNSELECTED;
                            const filter = filterUtils.setAllValues(selectedFilter, value);
                            updateFilter(filter);
                        }}
                    />
                    {renderSelections()}
                </div>
            </div>
            <div className='bottom-section'>
                <Button
                    label={t('lblCancel')}
                    classes={['primary-light', 'gradient']}
                    onClick={async () => {
                        if (filterUtils.someFilterWithPendingChanges(filters)) {
                            if (await ConfirmationDialog(t('lblFilterChangeWarning'))) {
                                resetFilters();
                            } else {
                                return false;
                            }
                        }
                        triggerRef.current.click();
                    }}
                    testAttributes={setAttr.action(testBy.ACTION.CANCEL)}
                />
                <Button
                    label={t('lblApply')}
                    classes={['primary-light', 'gradient']}
                    onClick={() => {
                        applyFilters();
                        triggerRef.current.click();
                    }}
                    testAttributes={setAttr.action(testBy.ACTION.APPLY)}
                />
            </div>
        </div>
    );
};

export default FilterPopover;
