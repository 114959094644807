import React, { useCallback, useRef, useState } from 'react';
import { iconsName } from 'assets';
import { Icon } from 'components/common';
import { colors } from 'styles/theme';
import { setAttr, testBy } from 'utils/constants';
import './style.scss';

const SendInput = ({ autoFocus, maxLength, name, onClickSend, placeholder } ) => {

    const [ value, setValue ] = useState('');
    const wrapRef = useRef();

    const handleOnChange = useCallback((e) => {
        const value = e.target.value;
        if (value.length <= maxLength) {
            setValue(value);
            if (wrapRef.current instanceof HTMLElement) {
                wrapRef.current.dataset.replicatedValue = value;
            }
        }
    }, [maxLength]);

    const handleOnClick = useCallback((e) => {
        onClickSend?.(value);
        setValue('');
        if (wrapRef.current instanceof HTMLElement) {
            wrapRef.current.dataset.replicatedValue = '';
        }
    }, [value, onClickSend]);

    return (
        <div className="send-input">
            <div className="grow-wrap" ref={wrapRef}>
                <textarea
                    autoFocus={autoFocus}
                    name={name}
                    onChange={handleOnChange}
                    placeholder={placeholder}
                    rows={1}
                    value={value}
                />
            </div>
            <Icon
                fill={value.length ? colors.black : colors.grayLight}
                name={iconsName.SEND}
                onClick={value.length ? handleOnClick : null}
                {...setAttr.action(testBy.ACTION.SEND, value.length === 0)}
            />
        </div>
    );
};

export default SendInput;
