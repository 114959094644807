import React from 'react';
import { useDispatch } from 'react-redux';
// Utils
import { setAttr, testBy } from 'utils/constants';
import { pf_t, t } from 'utils/dictionary';
import { isValidCoordinate } from 'utils/helper';
import { formatDateMillis } from 'utils/timezone';
// Components
import ViolationMap from 'pages/safety_page/features/maps/violation_map/ViolationMap';
import CategoryModal from 'components/modal/category_modal/CategoryModal';
import { ModalBlock, ModalCoordsInfoRow, ModalDriverInfo, ModalInfoRow, ModalInfoSection, ModalResourceInfo, ModalSeverityInfoRow } from 'components/modal/components';
import { modalsIds } from 'components/modal/ModalsConstants';
// Hooks
import useModalState from 'components/modal/hooks/use_modal_state/useModalState';
import { useOverSpeedDescription } from 'hooks';
// Store
import { openModal } from 'store/actions/popup';

const ViolationModal = ({ data }) => {

    const dispatch = useDispatch();

    const modalState = useModalState(data.id, data.categoryId);

    const eventName = pf_t.eventName(data.type);

    const getDescription = () => {
        if (data.overSpeed) {
            const { description, error } = useOverSpeedDescription(data.overSpeed);
            if (error) {
                console.log(`Error for incident ID ${data.id}: ${error.message}`);
                // TODO: server side log
            }
            return description;
        }
        return pf_t.eventDescriptions.byType(data.type);
    };

    const position = { lat: data.lat, lng: data.lng };

    const iconLocationObj = { onClick: isValidCoordinate(position) ? () => {
        const info = [
            { label: t('lblIncidentType'), value: eventName },
            { label: t('lblDriverName'), value: data.driverName },
            { label: t('lblOccurredOn'), value: formatDateMillis(data.eventTime) },
        ];
        dispatch(openModal({ id: modalsIds.MAP_MODAL, data: { ...data, info, eventName, position }}));
    } : null };

    const onCloseAndSave = () => {
        modalState.saveState();
    };
    // TODO - Cleaning all hard coded mock from modal info
    return (
        <CategoryModal
            modalId={modalsIds.VIOLATION_MODAL}
            eventName={t('lblViolation')}
            stateComponent={modalState.stateComponent}
            data={data}
            handleCloseAndSaveClick={onCloseAndSave}
            needSave={modalState.needSave}
            hasMessage={modalState.hasMessage}
            testAttributes={setAttr.feature(testBy.FEATURE.MODAL_VIOLATION)}
        >
            <ModalInfoSection>
                <ModalBlock
                    testAttributes={setAttr.ui(testBy.UI.INFO_DETAILS)}
                >
                    <ModalInfoRow label={t('lblIncidentType')}>{eventName}</ModalInfoRow>
                    <ModalSeverityInfoRow severity={data.severity} />
                    <ModalInfoRow label={t('lblDescription')}>{getDescription()}</ModalInfoRow>
                    <ModalInfoRow label={t('lblAddress')}>{data.address}</ModalInfoRow>
                    <ModalCoordsInfoRow label={t('lblCoordinates')} lat={data.lat} lng={data.lng} iconObj={iconLocationObj} />
                    <ModalInfoRow label={t('lblIncidentId')}>{data.id}</ModalInfoRow>
                    <ModalInfoRow label={t('lblInsideLandMark')}>{data.geoName}</ModalInfoRow>
                </ModalBlock>
                <ModalResourceInfo
                    resourceId={data.resourceId}
                />
                <ModalDriverInfo
                    driverId={data.driverId}
                    driverName={data.driverName}
                    eventTime={data.eventTime}
                    eventType={data.type}
                />
            </ModalInfoSection>
            <ModalBlock fullSize>
                <ViolationMap eventTime={data.eventTime} resourceId={data.resourceId} position={position} />
            </ModalBlock>
        </CategoryModal>);
};

export default ViolationModal;
