import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// Utils
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { cameraIds } from 'utils/entities';
// Components
import { ModalTwinScreens, VideoController } from 'components/modal/components';
import './style.scss';


const Video = ({ src, controls, videoRef, ...rest }) => (
    <video
        src={src}
        controls={controls}
        ref={videoRef}
        {...rest}
    />
);

const VideoPlayer = ({ frontVideo, backVideo, loading, compact, onVideosRef }) => {

    const fullScreenRef = useRef();
    const videosElementRef = useRef({ [cameraIds.FRONT]: undefined, [cameraIds.BACK]: undefined });

    const [bufferedTime, setBufferedTime] = useState({ [cameraIds.FRONT]: 0, [cameraIds.BACK]: 0 });
    const [currentTime, setCurrentTime] = useState({ [cameraIds.FRONT]: 0, [cameraIds.BACK]: 0 });
    const [canPlay, setCanPlay] = useState({ [cameraIds.FRONT]: false, [cameraIds.BACK]: false });
    const [duration, setDuration] = useState({ [cameraIds.FRONT]: 0, [cameraIds.BACK]: 0 });
    const [isfullScreen, setIsfullScreen] = useState(false);
    const [isMuted, setMuted] = useState(false);
    const [isPlay, setIsplay] = useState({});
    const [volume, setVolume] = useState(100);

    const maxBufferedTime = Number.parseInt(Math.max(bufferedTime[cameraIds.FRONT], bufferedTime[cameraIds.BACK]));
    const maxCurrentTime = Number.parseInt(Math.max(currentTime[cameraIds.FRONT], currentTime[cameraIds.BACK]));
    const maxDuration = Number.parseInt(Math.max(duration[cameraIds.FRONT], duration[cameraIds.BACK]));

    const enabled = useMemo(() => {
        if (frontVideo && backVideo) {
            return canPlay[cameraIds.FRONT] && canPlay[cameraIds.BACK];
        }
        if (frontVideo) {
            return canPlay[cameraIds.FRONT];
        }
        if (backVideo) {
            return canPlay[cameraIds.BACK];
        }
        return false;
    }, [frontVideo, backVideo, canPlay]);

    const loadingMask = useMemo(() => {
        if (enabled) {
            return null;
        }
        const message = loading ? t('lblVideosLoading') : t('lblVideosNotAvailable');
        return <div className="widget-mask"><p>{message}</p></div>;
    }, [enabled, loading]);

    useEffect(() => {
        if (document.addEventListener) {
            document.addEventListener('fullscreenchange', exitFullScreenHandler);
            document.addEventListener('webkitfullscreenchange', exitFullScreenHandler);
            document.addEventListener('mozfullscreenchange', exitFullScreenHandler);
            document.addEventListener('MSFullscreenChange', exitFullScreenHandler);
        }

        function exitFullScreenHandler() {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                setIsfullScreen(false);
            }
        }
        //clean up event listener
        return () => {
            document.addEventListener('fullscreenchange', exitFullScreenHandler);
            document.addEventListener('webkitfullscreenchange', exitFullScreenHandler);
            document.addEventListener('mozfullscreenchange', exitFullScreenHandler);
            document.addEventListener('MSFullscreenChange', exitFullScreenHandler);
        };
    }, []);

    const onClickVideoHandler = useCallback((isPlay) => {
        if (!isPlay) {
            if (videosElementRef.current[cameraIds.FRONT]?.currentSrc !== '') {
                videosElementRef.current[cameraIds.FRONT]?.play();
            }
            if (videosElementRef.current[cameraIds.BACK]?.currentSrc !== '') {
                videosElementRef.current[cameraIds.BACK]?.play();
            }
        } else {
            if (videosElementRef.current[cameraIds.FRONT]?.currentSrc !== '') {
                videosElementRef.current[cameraIds.FRONT]?.pause();
            }
            if (videosElementRef.current[cameraIds.BACK]?.currentSrc !== '') {
                videosElementRef.current[cameraIds.BACK]?.pause();
            }
        }
    }, []);

    const onVideoPlay = useCallback((e) => {
        setIsplay((prev) => ({ ...prev, [+e.target.id]: true }));
    }, [setIsplay]);

    const onVideoPause = useCallback((e) => {
        setIsplay((prev) => ({ ...prev, [+e.target.id]: false }));
    }, [setIsplay]);

    const onLoadedMetaData = useCallback((e) => {
        setDuration((prev) => ({ ...prev, [+e.target.id]: e.target.duration }));
    }, [setDuration]);

    const onCanPlay = useCallback((e) => {
        setCanPlay((prev) => ({ ...prev, [+e.target.id]: true }));
    }, []);

    const onProgress = useCallback((e) => {
        let loadStartPercentage = 0;
        let loadEndPercentage = 0;

        try {
            let range = 0;
            let bf = e.target.buffered;
            let time = e.target.currentTime;
            while (!(bf.start(range) <= time && time <= bf.end(range))) {
                range += 1;
            }
            loadStartPercentage = bf.start(range) / e.target.duration;
            loadEndPercentage = bf.end(range) / e.target.duration;
        } catch (e) {
            console.log(e);
        }

        let loadPercentage = (loadEndPercentage - loadStartPercentage) * 100;
        setBufferedTime((prev) => ({ ...prev, [+e.target.id]: loadPercentage }));
    }, [setBufferedTime]);

    const onTimeUpdate = useCallback((e) => {
        setCurrentTime((prev) => ({ ...prev, [+e.target.id]: e.target.currentTime }));
    }, [setCurrentTime]);

    const onVideoRefReady = useCallback((ref) => {
        if (!ref) return;
        ref.volume = 1;
        ref.onplay = onVideoPlay;
        ref.onpause = onVideoPause;
        ref.onloadedmetadata = onLoadedMetaData;
        ref.onprogress = onProgress;
        ref.ontimeupdate = onTimeUpdate;
        ref.oncanplay = onCanPlay;
        setMuted(ref.muted);
        videosElementRef.current[ref.id] = ref;
        onVideosRef?.(videosElementRef.current);
    }, [onVideoPlay, onVideoPause, onLoadedMetaData, onTimeUpdate, onCanPlay, onProgress, onVideosRef]);

    const onClickVolume = useCallback(() => {
        setMuted(!isMuted);
    }, [setMuted, isMuted]);

    const onVolumeRangeChange = useCallback((e) => {
        const volume = +e.target.value;
        if (videosElementRef.current[cameraIds.FRONT]) {
            videosElementRef.current[cameraIds.FRONT].volume = volume / 100;
        }
        if (videosElementRef.current[cameraIds.BACK]) {
            videosElementRef.current[cameraIds.BACK].volume = volume / 100;
        }
        if (isMuted && volume) {
            setMuted(false);
        }
        setVolume(volume);
    }, [isMuted]);

    const onVideoRangeTimeChange = useCallback((e) => {
        const currentTimeValue = +e.target.value;
        if (videosElementRef.current[cameraIds.FRONT]) {
            videosElementRef.current[cameraIds.FRONT].currentTime = currentTimeValue;
        }
        if (videosElementRef.current[cameraIds.BACK]) {
            videosElementRef.current[cameraIds.BACK].currentTime = currentTimeValue;
        }
        setCurrentTime((prev) => ({ ...prev, [+e.target.id]: currentTimeValue }));
    }, []);

    const onFullScreenRefReady = (ref) => {
        fullScreenRef.current = ref;
    };

    const onClickFullscreen = useCallback((isfullScreen) => {
        if (!isfullScreen) {
            fullScreenRef.current.requestFullscreen()
                .then(() => {
                    setIsfullScreen(true);
                }).catch(() => {
                    setIsfullScreen(false);
                });
        } else {
            document.exitFullscreen()
                .then(() => {

                }).catch(() => {

                }).finally(() => {
                    setIsfullScreen(false);
                });
        }
    }, []);

    const minimise = compact && !isfullScreen;
    const gap = minimise ? '2px' : '8px';

    return (
        <div
            className='video-player-container'
            ref={onFullScreenRefReady}
            style={{ gap: gap }}
            { ...setAttr.ui(testBy.UI.VIDEO_PLAYER) }
        >
            <ModalTwinScreens
                gap={gap}
                contents1={<Video videoRef={onVideoRefReady}
                    src={frontVideo?.url}
                    id={cameraIds.FRONT}
                    muted={isMuted}
                    crossOrigin="anonymous"
                />}
                contents2={<Video videoRef={onVideoRefReady}
                    src={backVideo?.url}
                    id={cameraIds.BACK}
                    muted={isMuted}
                    crossOrigin="anonymous"
                />}
            />
            <VideoController
                bufferedTime={maxBufferedTime}
                currentTime={maxCurrentTime}
                disabled={!enabled}
                duration={maxDuration}
                isfullScreen={isfullScreen}
                isMuted={isMuted}
                isPlay={isPlay[cameraIds.FRONT] || isPlay[cameraIds.BACK]}
                minimise={minimise}
                onClickFullscreen={onClickFullscreen}
                onClickVideoHandler={onClickVideoHandler}
                onClickVolume={onClickVolume}
                onVideoRangeTimeChange={onVideoRangeTimeChange}
                onVolumeRangeChange={onVolumeRangeChange}
                volume={volume}
            />
            {loadingMask}
        </div>
    );
};

export default VideoPlayer;
