import FileSaver from 'file-saver';
// Utils
import { api } from 'services';
import { APIS } from 'utils/constants';
import { dataKeys } from 'utils/entities/driver';
import { formatDateMillis, intlFormats, getDateTime, formatDateTime } from 'utils/timezone';
import { t } from 'utils/dictionary';
// Redux
import { actions } from 'store/slices/safetyDrivers';


export default actions;

export const fetchSafetyDrivers = (payload) => {
    const { pageNumber = 1, maxCount = 10, ...rest } = payload;
    const params = {
        sort: dataKeys.name,
        isDesc: true,
        maxCount,
        pageNumber, // The page number + maxCount (the result per page)
        ...rest,
    };

    return api.post(APIS.DRIVERS.GET_SAFETY_DRIVERS, params);
};

export const fetchDriverImages = (ids) => async (dispatch) => {
    if (ids?.length > 0){
        const { data, status } = await api.post(APIS.MEDIA.GET_IMAGES, { ids: ids, IsFromConnect: true } );
        if (status === 200) {
            dispatch(actions.updateItemsImages(data?.data));
        }
    }
};

export const fetchSafetyDriversKpi = ({ startTime, endTime } = {}) => async (dispatch) => {
    const { data, status } = await api.get(APIS.DRIVERS.GET_SAFETY_KPI, { params: { startTime, endTime }});
    if (status === 200 && data.data) {
        dispatch(actions.setKpis(data.data));
    }
};

export const requestAndDownloadSaftyDrivers = async (parmas, formats) => {
    const { data, status } = await fetchSafetyDrivers(parmas);
    if (status === 200 && data.data) {
        downloadDriversCsv(data.data.drivers, formats);
    }
};

const downloadDriversCsv = (drivers, formats = {}) => {
    let resourceCsv = '\uFEFF'; // BOM to make sure the file is opened in UTF-8 in Office
    const { length } = formats;

    // headers
    resourceCsv += `"${t('lblName')}",`;
    resourceCsv += `"${t('lblResource')}",`;
    resourceCsv += `"${t('lblDriverGroup')}",`;
    resourceCsv += `"${t('lblLicenseExpiration')}",`;
    resourceCsv += `"${t('lblSafetyScore')}",`;
    resourceCsv += `"${t('lblDistanceDriven')}",`;
    resourceCsv += `"${t('lblTrips')}",`;
    resourceCsv += `"${t('lblHighRiskTrips')}"\n`;

    // body
    drivers.forEach((driver) => {
        resourceCsv += `"${driver.name || ''}",`;
        resourceCsv += `"${driver.licenseplate || ''}",`;
        resourceCsv += `"${driver.group || ''}",`;
        resourceCsv += `"${driver.licenseExpiration ? formatDateMillis(driver.licenseExpiration, intlFormats.DATE_FULL) : 'N/A'}",`;
        resourceCsv += `"${driver.safetyScore}",`;
        resourceCsv += `"${length?.(driver.distance)}",`;
        resourceCsv += `"${driver.drivenTrips}",`;
        resourceCsv += `"${driver.highRiskTrips}"\n`;
    });

    const file = new File([resourceCsv], { type: 'text/csv;charset=utf-8' });
    const now = getDateTime();
    const fileName = `${t('lblDriversList')} ${formatDateTime(now, intlFormats.DATETIME_SHORT)}`;

    FileSaver.saveAs(file, `${fileName}.csv`);
};