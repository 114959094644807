import { getRandomItem, camelCaseToKebabCase } from 'utils/helper';

export const colors = {
    sunflower: '#f1c40f',
    yellow: 'rgb(240, 240, 52)',
    yellowV2: '#FFD561',
    orange: '#f39c12',
    orangeV2: '#f37224',
    carrot: '#e67e22',
    pumpkin: '#d35400',
    alizarin: '#e74c3c',
    pomegranate: '#c0392b',
    turquoise: '#1abc9c',
    turquoiseLight: '#7cdae1',
    greensea: '#16705c',
    greenReef: '#167063',
    greenLight: '#4bd291',
    greenDark: '#114f41',
    emerald: '#2ecc71',
    nephritis: '#27ae60',
    peterriver: '#3498db',
    belizehole: '#2980b9',
    amethyst: '#9b59b6',
    wisteria: '#8e44ad',
    pink1: '#ec04b2',
    pink: '#ca0349',
    pinkDark: '#f50057',
    pinkLight: '#e42669',
    wetasphalt: '#34495e',
    midnightblue: '#2c3e50',
    clouds: '#ecf0f1',
    silver: '#bdc3c7',
    concrete: '#95a5a6',
    asbestos: '#7f8c8d',
    midnightblue2: '#1A202C',
    white: '#FFF',
    black: '#000',
    black2: '#121212',
    whitePink: '#FBDEE8',
    purpleFade: '#828294',
    grayLightest: '#fafafa',
    grayLighter: '#f2f0ed',
    grayLight: '#e0ddd9',
    gray: '#b5b2af',
    grayDark: '#94908d',
    grayDarker: '#4f4e4e',
    grayDarkest: '#343434',
    grayBlack: '#2c2a29',
    shallowPink: '#B3AFAF',
    blueReplyLight: '#4A6572',
    blueReply: '#344955',
    blueReplyDark: '#232F34',
    redRuby: '#841C26',
    brightMaroon: '#BA274A',
    dodgerBlue: '#2191FB',
    middleBlueGreen: '#8CDEDC',
    blueCuracao: '#54b1ad',
    red: '#f13939',
    redV2: '#e21818',
    redDark: '#a6192e',
    redLight: '#e4002b',
    blueDarket: '#0e263f',
    brownLightest: '#b94a48',
    brownYell: '#c09853',
    grayHsla: 'hsla(0, 0%, 100%, .5)',
    greenSeaTurquoise: 'linear-gradient(to bottom, #16705c 0%, #7cdae1 100%)',
    midDarkGreen: '#00644E',
    disabledGray: '#848984'
};


export const THEMES_NAMES = {
    DEFAULT: 'DEFAULT',
    DARK: 'DARK',
    RANDOM: 'RANDOM',
};

// All the global css variables will be generated from that theme...
export const defaultTheme = {
    // Theme properties names
    primary: colors.greenReef,
    primaryDark: colors.greenDark,
    primaryLight: colors.blueCuracao,
    secondary: colors.red,
    secondaryDark: colors.redDark,
    secondaryLight: colors.redLight,
    surface: colors.grayLightest,
    surfaceDark: colors.grayDark,
    surfaceLight: colors.white,
    textPrimary: colors.white,
    textPrimaryDark: colors.white,
    textPrimaryLight: colors.white,
    textSecondary: colors.white,
    textSecondaryDark: colors.white,
    textSecondaryLight: colors.white,
    textSurface: colors.grayDarker,
    textSurfaceDark: colors.white,
    textSurfaceLight: colors.grayDarkest,
    textDef: colors.grayBlack,
    icon: colors.grayDarkest,
    iconBg: colors.grayLighter,
    sideBar: colors.greenSeaTurquoise,
    success: colors.greensea,
    error: colors.brownLightest,
    danger: colors.brownYell,
    warning: colors.brownYell,
    info: colors.peterriver,
    link: colors.pinkLight,
    linkActive: colors.pinkDark,
    linkIcon: colors.white,
    linkIconBg: colors.greensea,
    button: colors.red,
    buttonDisabled: colors.grayLight,
    buttonActive: colors.redDark,
    checkboxBorderColour: colors.grayDarker,
    checkboxHoverBg: 'transparent',
    checkboxCheckedBg: colors.turquoiseLight,
    // Global colors variables for css.
    // Add the prefix 'color' to css colors variables for naming convention.
    colorRed: colors.red,
    colorGreenLight: colors.greenLight,
    colorGreenSea: colors.greensea,
    colorYellow: colors.yellow,
    colorBlueCuracao: colors.blueCuracao,
    colorTurquoiseLight: colors.turquoiseLight,
    colorBlack2: colors.black2,
};

const darkTheme = {
    ...defaultTheme,
    primary: colors.blueReply,
    primaryDark: colors.blueReplyLight,
    primaryLight: colors.blueReplyDark,
    secondary: colors.orange,
    secondaryDark: colors.dodgerBlue,
    secondaryLight: colors.greensea,
    surface: colors.blueReplyDark,
    surfaceDark: colors.blueDarket,
    surfaceLight: colors.blueReplyLight,
    textPrimary: colors.white,
    textPrimaryDark: colors.white,
    textPrimaryLight: colors.white,
    textSurface: colors.white,
    textSurfaceDark: colors.white,
    textSurfaceLight: colors.white,
    textDef: colors.grayLightest,
    icon: colors.belizehole,
    success: colors.greensea,
    error: colors.brownLightest,
    danger: colors.brownYell,
    warning: colors.brownYell,
    info: colors.peterriver,
    link: colors.pinkLight,
    linkActive: colors.orange,
    linkIcon: colors.orange,
    linkIconBg: colors.blueReplyDark,
    button: colors.red,
    buttonDisabled: colors.grayLight,
    buttonActive: colors.redDark,
};

export const createRandomTheme = () => {
    const theme = {};
    Object.keys(defaultTheme).forEach((property) => {
        theme[property] = getRandomItem(Object.values(colors));
    });
    return theme;
};

export const themeToCssVariables = (theme) => {
    const cssVariables = {};
    Object.entries(theme).forEach(([property, value]) => {
        const cssVariableName = camelCaseToKebabCase(property, { prefix: '--' });
        cssVariables[cssVariableName] = value;
    });
    return cssVariables;
};

const themes = {
    [THEMES_NAMES.DEFAULT]: defaultTheme,
    [THEMES_NAMES.DARK]: darkTheme,
    [THEMES_NAMES.RANDOM]: createRandomTheme(),
};

export default themes;
