import './style.scss';



export const InfoBar = (props) => {
    const { children, className } = props;
    return (
        <div className={`info-bar ${className ?? ''}`}>
            {children}
        </div>
    );

};
