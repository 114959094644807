import { createSlice } from '@reduxjs/toolkit';
import { reducerNames } from 'store/constants';

const slice = createSlice({
    name: reducerNames.INCIDENT_TYPES,
    initialState: {
        incidentTypes: [],
    },
    reducers: {
        setIncidentTypes: (state, action) => {
            state.incidentTypes = action.payload;
        }
    }
});


export default slice.reducer;

export const { name, getInitialState, actions } = slice;