import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import debounce from 'lodash.debounce';
// Utils
import { getDistanceInMeters, computeHeading } from 'services/api/google_api';
import PropTypes, { propTypes } from 'utils/propTypes';
// Components
import { Spinner } from 'components/common';
import { UserMap } from 'components/google_maps';
import { TripLinePath } from 'pages/safety_page/features/maps/trip_map/TripUiItems';
import { TripCluster } from 'pages/safety_page/features/maps/trip_map/TripCluster';
import './style.scss';



// const getTimeDiff = (a, b) => Math.abs(a?.eventTime - b?.eventTime);

// const reduceByTime = (points = [], options) => {
//     const { time, excludeIndex } = options;
//     if (!time) return points;
//     let prev;
//     const reduced = points.filter((p, i) => {
//         prev = points[i - 1];
//         if (!prev) return true;
//         if (excludeIndex.includes(i)) return true;
//         const timeDiff = getTimeDiff(prev, p);
//         return (isNaN(timeDiff) || timeDiff > time);
//     });
//     return reduced;
// };

// const reduceByMeters = (points = [], options) => {
//     const { meters, excludeIndex } = options;
//     if (!meters) return points;
//     let prev;
//     const reduced = points.filter((p, i) => {
//         prev = points[i - 1];
//         if (!prev) return true;
//         if (excludeIndex.includes(i)) return true;
//         const distance = getDistanceInMeters(prev, p);
//         return (distance > meters);
//     });
//     return reduced;
// };

const distanceInPx = (p1, p2, pxSize) => Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y)) / pxSize;

// const reduceByPixels = (points = [], options, map) => {

//     const { px, excludeIndex } = options;
//     if (!px) return points;

//     const bounds = map.getBounds();
//     const projection = map.getProjection();
//     const pxSize = Math.pow(2, -map.getZoom());

//     let prev;
//     const reduced = points.filter((point, i) => {
//         prev = points[i - 1];
//         if (!prev) return true;
//         if (excludeIndex.includes(i)) return true;
//         if (!bounds.contains(point)) return false;

//         const p1 = projection.fromLatLngToPoint(prev);
//         const p2 = projection.fromLatLngToPoint(point);
//         const pxDiff = distanceInPx(p1, p2, pxSize);
//         return (pxDiff > px);
//     });
//     return reduced;
// };

// const reducePoints = (points = [], map, { minThreshold = 0, ...rest } = {}) => {
//     if (points.length < minThreshold) return points;
//     const options = { excludeIndex: [0, points.length - 1], ...rest };

//     let results = reduceByPixels(points, options, map);
//     results = reduceByTime(results, options);
//     results = reduceByMeters(results, options);
//     return results;
// };


const reduceMarkersByPixels = ({ coordinates = [], distance, map, excludeIndex }) => {

    if (!distance || !map || !coordinates?.length) return coordinates;

    const bounds = map.getBounds();
    const projection = map.getProjection();
    const pxSize = Math.pow(2, -map.getZoom());

    const reduced = coordinates.filter((coordinate, i) => {
        const prev = coordinates[i - 1];
        if (!prev) return true;
        if (excludeIndex.includes(i)) return true;
        if (!bounds.contains(coordinate)) return false;

        const point1 = projection.fromLatLngToPoint(prev);
        const point2 = projection.fromLatLngToPoint(coordinate);
        const px = distanceInPx(point1, point2, pxSize);
        return (px > distance);
    });
    return reduced;
};


const TripMap = (props) => {

    const mapRef = useRef();

    const {
        center,
        points,
        isLoading,
        hideTripMarkers,
        children,
        modifiers = {},
        reducePointsByPixels = 0 // sequence markers
    } = props;

    const [mapReady, setMapReady] = useState(false);
    const [reducedMarkers, setReducedMarkers] = useState([]);

    useEffect(() => {
        if (mapReady) {
            if (points.length > 1) {
                let bounds = new google.maps.LatLngBounds();
                points.forEach((point) => {
                    bounds.extend(point);
                });
                mapRef.current.fitBounds(bounds, { bottom: 10, top: 10, right: 10, left: 10 });
            }
        }
    }, [mapReady, points]);

    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
        setMapReady(true);
    }, []);

    const reduceMarkers = useCallback(() => {
        if (!mapRef.current || !mapReady) return;
        const reduced = reduceMarkersByPixels({
            coordinates: points,
            distance: reducePointsByPixels,
            map: mapRef.current,
            excludeIndex: [0, points.length - 1]
        });
        setReducedMarkers(reduced);
    }, [mapReady, points, reducePointsByPixels]);

    const onBoundsChanged = useMemo(() => reducePointsByPixels ? debounce(reduceMarkers, 100) : undefined, [reduceMarkers, reducePointsByPixels]);

    const heading = useMemo(() => parseInt(computeHeading(points[0], points[1]) ?? 0), [points]);

    const dimmedClass = isLoading ? 'dimmed' : '';

    return (
        <div className={`${dimmedClass} map`}>
            <Spinner isLoading={isLoading} className='map-spinner' />
            <UserMap
                onLoad={onMapLoad}
                center={center}
                onBoundsChanged={onBoundsChanged}
            >
                <>
                    <TripLinePath points={points} options={modifiers.linePath} />
                    <TripCluster
                        points={reducePointsByPixels ? reducedMarkers : points}
                        modifiers={modifiers}
                        hideTripMarkers={hideTripMarkers}
                        heading={heading}
                        noCluster
                    />
                    {children}
                </>
            </UserMap>
        </div>
    );
};


TripMap.propTypes = {
    center: propTypes.coordinate,
    points: PropTypes.arrayOf(propTypes.tripPoint),
    isLoading: PropTypes.bool,
    hideTripMarkers: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    modifiers: PropTypes.exact({
        point: propTypes.markerOptions,
        startPoint: propTypes.markerOptions,
        endPoint: propTypes.markerOptions,
        linePath: propTypes.polylineOptions
    }),
    reducePointsByPixels: PropTypes.number
};

export default TripMap;
