import { useMemo } from 'react';
// Utils
import { t } from 'utils/dictionary';
// Components
import './style.scss';




export const PageNotFound = (props) => {

    const { message } = props;

    return (
        <div className="error-404-container">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="300" height="300">
                    <path fill="#1F4E86" fillRule="evenodd" d="M150 299.5C67.433 299.5.5 232.567.5 150S67.433.5 150 .5 299.5 67.433 299.5 150 232.567 299.5 150 299.5zM251.601 48.398C224.463 21.26 188.38 6.314 150 6.314S75.537 21.26 48.399 48.398C21.26 75.537 6.314 111.62 6.314 150s14.946 74.463 42.085 101.601C75.537 278.74 111.62 293.686 150 293.686s74.463-14.946 101.601-42.085C278.74 224.463 293.686 188.38 293.686 150S278.74 75.537 251.601 48.398zM150 287.042c-75.686 0-137.042-61.356-137.042-137.042S74.314 12.958 150 12.958 287.042 74.314 287.042 150 225.686 287.042 150 287.042zm83.983-221.025C211.55 43.585 181.724 31.231 150 31.231c-31.724 0-61.55 12.354-83.983 34.786C43.585 88.45 31.231 118.275 31.231 150c0 31.724 12.354 61.55 34.786 83.983 22.433 22.432 52.259 34.786 83.983 34.786 31.724 0 61.55-12.354 83.983-34.786 22.432-22.433 34.786-52.259 34.786-83.983 0-31.725-12.354-61.55-34.786-83.983zm2.519 100.629H62.668a1.66 1.66 0 0 1-1.661-1.661v-29.97c0-.918.743-1.662 1.661-1.662h173.834c.917 0 1.661.744 1.661 1.662v29.97a1.66 1.66 0 0 1-1.661 1.661z" />
                </svg>
            </div>
            <div>
                <h1>404</h1>
                <h2>{t('lblErrorMessageTitle')}.</h2>
                <h3>{t('lblErrorPageNotFound')}.</h3>
                <h3>{message}</h3>
            </div>
        </div>
    );
};


// General fallback for fatal errors (crush)
// We can create more fallback UI variant for specific fatal errors like features
export const ErrorFallbackUI = (props) => {
    const { message, resetError } = props;
    return (
        <div className="error-fallback-ui-container">
            <h2>{t('lblErrorMessageTitle')}.</h2>
            <h3>{message}</h3>
            {resetError ? <button onClick={resetError}>{t('lblErrorReset')}</button> : null}
        </div>
    );
};


// This is fallback UI for development
export const DevErrorFallbackUI = (props) => {
    const { errorInfo, resetError } = props;
    const {
        message,
        userAgent,
        onLine,
        time,
        level,
    } = errorInfo;

    const jsInfo = useMemo(() => {
        const { stack, lineno, colno, filename } = errorInfo;
        if (!stack) return null;
        return (
            <div className='error-box'>
                <span>
                    {`filename: ${filename}, line: ${lineno}, column: ${colno}`}
                </span>
                <pre className='stack-trace'>{stack}</pre>
            </div>
        );
    }, [errorInfo]);

    const componentInfo = useMemo(() => {
        const { componentStack, boundary } = errorInfo;
        if (!componentStack) return null;
        return (
            <div className='error-box'>
                <span>
                    {`Component boundary: ${boundary}`}
                </span>
                <pre className='stack-trace'>{componentStack}</pre>
            </div>
        );
    }, [errorInfo]);

    return (
        <div className='error-view-container'>
            <div className='top'>
                <span>Something went wrong...</span>
                <span>{message}</span>
                <button onClick={resetError}>Try again</button>
            </div>
            <div className='error-info'>
                <div className='error-box'>
                    <span>Level</span>
                    <pre className='stack-trace'>{level}</pre>
                </div>
                <div className='error-box'>
                    <span>Client</span>
                    <pre className='stack-trace'>{userAgent}</pre>
                </div>
                <div className='error-box'>
                    <span>online</span>
                    <pre className='stack-trace'>{`${onLine}`}</pre>
                </div>
                <div className='error-box'>
                    <span>time</span>
                    <pre className='stack-trace'>{`${time}`}</pre>
                </div>
                {jsInfo}
                {componentInfo}
            </div>
        </div>
    );
};
