import React, { useCallback } from 'react';
import './style.scss';




const colorsHex = [
    '#B0171F',
    '#8B2252',
    '#FF34B3',
    '#CD69C9',
    '#8A2BE2',
    '#7B68EE',
    '#0000FF',
    '#3D59AB',
    '#B0C4DE',
    '#C6E2FF',
    '#1E90FF',
    '#4F94CD',
    '#00F5FF',
    '#00C5CD',
    '#00CED1',
    '#00C78C',
    '#00FA9A',
    '#008B45',
    '#FFFFFF',
    '#3D9140',
    '#C1CDC1',
    '#838B83',
    '#00FF00',
    '#66CD00',
    '#9ACD32',
    '#FFFF00',
    '#EEEE00',
    '#FFD700',
    '#FFC125',
    '#FF9912',
    '#FF8C00',
    '#FF4500',
    '#EE4000',
    '#1E1E1E',
    '#C0C0C0',
    '#000000',
];

const ColorBox = (props) => {
    const { color, onClick, selected } = props;

    const handleClick = useCallback(() => {
        onClick?.(color);
    }, [color, onClick]);

    return (
        <div
            className={`color-box ${selected ? 'selected' : ''}`}
            style={{ background: color }}
            onClick={handleClick}
        >
        </div>
    );
};

export const ColorsView = (props) => {
    const { colors = colorsHex, onClick, selected } = props;

    const renderColors = () => colors.map((color) => (
        <ColorBox
            key={color}
            color={color}
            selected={selected && (selected === color)}
            onClick={onClick}
        />
    ));

    return (
        <div>
            <div className='colors-box'>
                {renderColors()}
            </div>
        </div>
    );
};
