import { createListenerMiddleware } from '@reduxjs/toolkit';
import { rangeMsFromPeriod } from 'pages/safety_page/constants';
import { fetchDashboardData } from 'store/actions/safety';
import { getParams } from 'store/selectors/safety';
import { filterActionMatcher } from 'store/slices/dashboard';

const updateDashboardFilterListener = createListenerMiddleware();

updateDashboardFilterListener.startListening({
    matcher: filterActionMatcher,
    effect: async (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        await listenerApi.delay(500);

        const state = listenerApi.getState();
        const { period } = getParams(state);
        const rangeMs = rangeMsFromPeriod(period);
        // const rangeMs = period.rangeMs ?? rangeMsFromPeriod(period);
        listenerApi.dispatch(fetchDashboardData(rangeMs));
    }
});

export default updateDashboardFilterListener;
