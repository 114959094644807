import React from 'react';
// redux
import { connect } from 'react-redux';
import { getUserInfo } from 'store/selectors/user';
// Utils
import { ROUTES } from 'utils/constants';
// Components
import { Route, Routes } from 'react-router-dom';
import { SafetyPage, PlaygroundPage, ErrorPage, HomePage, SustainabilityPage } from 'pages';
import { IconsPage } from 'pages/playground/icons-page/IconsPage';
import './style.scss';
// import { ProvisioningPopup } from 'pages/provisioning_popup/ProvisioningPopup';
import { InstallerPage } from 'pages/installer_page/InstallerPage';


class AppPageRoutes extends React.PureComponent {

    render() {
        const { account } = this.props.user;
        const isTSP = account.id === -1;
        return (
            <div className='app-page-routes-container'>
                <Routes>
                    <Route path={`${ROUTES.HOME.path}`} element={<HomePage />} />
                    <Route path={`${ROUTES.SAFETY.path}/*`} element={<SafetyPage />} />
                    <Route path={ROUTES.PLAYGROUND.path}>
                        <Route path='' element={<PlaygroundPage />} />
                        <Route path='icons' element={<IconsPage />} />
                    </Route>
                    <Route path={`${ROUTES.SUSTAINABILITY.path}/*`} element={<SustainabilityPage />} />
                    {/* <Route path={`${ROUTES.PROVISIONING.path}/*`} element={isTSP ? <ProvisioningPopup /> : <ErrorPage />} /> */}
                    {<Route path={`${ROUTES.INSTALLER.path}/*`} element={isTSP ? <InstallerPage /> : <ErrorPage />} />}
                    {/* Returning 404 Error page but we can also redirect to default page if user is auth */}
                    <Route path='*' element={<ErrorPage />} />
                </Routes>
            </div>
        );
    }
}

AppPageRoutes.propTypes = {

};

const mapStateToProps = (state) => ({
    user: getUserInfo(state),
});

export default connect(mapStateToProps)(AppPageRoutes);