import { useMemo } from 'react';
// Utils
import { iconsName } from 'assets';
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { severities } from 'utils/entities';
// Components
import { Icon } from 'components/common';
import './style.scss';


export const CellWrapper = ({ children }) => <div className="cell-wrapper">{children}</div>;

export const SeverityCell = (props) => {
    const { level } = props;
    const { color, lblKey } = severities[level] ?? {};

    return (
        <div
            className="severity-cell-wrapper"
            style={{
                background: color,
            }}
        >
            {t(lblKey)}
        </div>
    );
};

export const ActionsCell = ({ data }) => (
    <div
        className="actions-cell-wrapper"
    >
        <Icon
            name={iconsName.THREE_DOTS}
            width={24}
            height={24}
        />
    </div>
);

export const MultiFieldsCell = ({ dataFields, data, icon = {}, title, renderValue }) => {

    const fields = useMemo(() => Array.isArray(dataFields) ? dataFields.map((key, i) => {
        const value = data[key];
        return <div
            key={key ?? i}
            {...setAttr.ui(testBy.UI.MULTI_CELL_VALUE)}
        >
            {renderValue?.(value) ?? value}
        </div>;
    }) : null, [data, dataFields, renderValue]);

    return (
        <div
            className="multi-fields-cell-wrapper"
        >
            <span className="icon-cell">
                <Icon
                    width='100%'
                    height='100%'
                    title={title}
                    {...icon}
                />
            </span>
            <div
                className="multi-fields-cell-fields"
            >
                {fields}
            </div>
        </div>
    );
};
