import { useCallback, useEffect, useState } from 'react';
// Utils
import { t } from 'utils/dictionary';
import { api } from 'services';
// Components
import { InputDialog } from 'components/common/dialogs/input_dialog/InputDialog';
import { ConfirmationDialog } from 'components/common/dialogs/confirmation_dialog/ConfirmationDialog';
import { CommandIds, CommandStatus, CommandType, getCommandText } from 'components/modal/ModalsConstants';

const retriesNumber = 12;

const useCommandSender = (incidentId, resourceId, immobilizerStatus, lastCommand, currentStateId) => {

    // const resourceId = 80564; // 190004 Immobilization (190004 Cello-CANiQ)
    // const resourceId = 23370; // 6002 Immobilization
    // const resourceId = 33045; // Emergence tracking (12344 - Sat)

    const isLastCommandStart = lastCommand?.isStart;
    const rowId = lastCommand?.rowId;

    const [commandState, setCommandState] = useState(null);
    const [commandStatusId, setCommandStatusId] = useState(null);
    const [command, setCommand] = useState(null);
    const [timer, setTimer] = useState(null);
    const [counter, setCounter] = useState(0);
    const [startCommandSent, setStartCommandSent] = useState(false);
    const [currentCommand, setCurrentCommand] = useState(null);

    useEffect(() => {
        getCommands();
    }, []);

    useEffect(() => {
        if (rowId) {
            setCommandStatusId(rowId);
            setCommandState(CommandStatus.PENDING);
        }
    }, [rowId]);

    useEffect(() => {
        if (immobilizerStatus != null && command && immobilizerStatus !== command.portStatus) {
            setCommand((com) => ({ ...com, portStatus: immobilizerStatus, text: getCommandText(CommandType.Immobilizer, immobilizerStatus === 0) }));
            setCommandState(null);
        }
    }, [immobilizerStatus]);

    useEffect(() => {
        if (isLastCommandStart == null || rowId) return;
        const id = isLastCommandStart ? CommandIds.STOP_EMERGENCY_TRACK : CommandIds.START_EMERGENCY_TRACK;
        setStartCommandSent(isLastCommandStart);
        if (command?.id && command.id !== id) {
            const text = isLastCommandStart ? t('lblStopEmergencyTrack') : t('lblStartEmergencyTrack');
            setCommand((com) => ({ ...com, id, text }));
            setCommandState(null);
        }
    }, [isLastCommandStart, command]);

    useEffect(() => {
        if (commandState === CommandStatus.ACK || commandState === CommandStatus.NOT_ACK || counter > retriesNumber) {
            if (counter > 0 && (commandState === CommandStatus.ACK || commandState === CommandStatus.NOT_ACK)) {
                const state = { command: {
                    isStart: currentCommand == null ? isLastCommandStart : currentCommand,
                    type: command.id ? CommandType.EmergencyTrack : CommandType.Immobilizer,
                    commandState
                }};
                sendIncidentState(state);
            }
            stopTimer();
            if (counter > retriesNumber) {
                setCommandState(CommandStatus.NOT_ACK);
            }
        }
    }, [commandState, counter]);

    useEffect(() => {
        if (!commandStatusId) return;
        let interval = setInterval(async () => {
            const status = await getCommandStatus(commandStatusId);
            setCommandState(status);
            setCounter((i) => i + 1);
        }, 10000);
        setTimer(interval);
        return () => {
            stopTimer(interval);
        };
    }, [commandStatusId]);

    const sendIncidentState = async (state) => {
        await api.setIncidentState(incidentId, currentStateId, JSON.stringify(state));
    };

    const stopTimer = (interval) => {
        clearInterval(interval ?? timer);
        setCommandStatusId(null);
        setTimer(null);
        setCounter(0);
    };

    const getCommandStatus = async (commandId) => (await api.getCommandStatus(commandId))?.status;

    const getCommands = async () => {
        let comm = null;
        const commands = await api.getCommands(resourceId);

        comm = commands?.find((command) => command.id === CommandIds.START_EMERGENCY_TRACK);
        if (comm && !startCommandSent) {
            setCommand({ ...comm, text: getCommandText(CommandType.EmergencyTrack, true) });
            return;
        }
        comm = commands?.find((command) => command.id === CommandIds.STOP_EMERGENCY_TRACK);
        if (comm) {
            setCommand({ ...comm, text: getCommandText(CommandType.EmergencyTrack, false) });
            return;
        }
        comm = commands?.find((command) => command.name === CommandType.Immobilizer);
        if (comm) {
            const { id, ...rest } = comm;
            setCommand({ ...rest, text: getCommandText(CommandType.Immobilizer, comm.portStatus === CommandIds.START_IMMOBILIZE && !immobilizerStatus) });
        }
    };

    const sendCommandHandler = useCallback(async (sendStop) => {
        if (sendStop && !startCommandSent) return;
        let pass = null;
        const confirmText = sendStop ? t('lblWouldLikeStopEmergencyTracking') : t('lblConfirmSendEmergTrackCommand');
        const inputDlgText = sendStop ? t('lblWouldLikeStopImmobilizing') : t('lblSendingCommandPassword');

        if (command.id) {
            const result = await ConfirmationDialog(confirmText, { title: t('lblConfirmation'), okText: t('lblSend') });
            if (!result) return;
        } else {
            pass = await InputDialog(t('lblConfirmation'), { text: inputDlgText, inputLabelText: t('lblPassword'), okText: t('lblSend'), inputType: 'password' });
            if (!pass) return;
        }

        const commandObj = { resourceId: String(resourceId), password: pass, ...command };
        const isStart = commandObj.id === CommandIds.START_EMERGENCY_TRACK || commandObj.portStatus === CommandIds.START_IMMOBILIZE;
        if (sendStop) {
            if (command.id) {
                commandObj.id = CommandIds.STOP_EMERGENCY_TRACK;
            } else {
                commandObj.portStatus = CommandIds.STOP_IMMOBILIZE;
            }
        }

        const { id } = await api.sendCommand(commandObj);
        if (id) {
            setCommandStatusId(id);
        }
        setCommandState(CommandStatus.PENDING);
        setCurrentCommand(isStart);
        if (id != null && !sendStop) {
            setStartCommandSent(isStart);
            const type = command.id ? CommandType.EmergencyTrack : CommandType.Immobilizer;
            const state = { command: { isStart, type, rowId: id, commandState: CommandStatus.PENDING }};
            await sendIncidentState(state);
        }

    }, [command]);

    const buttonTxt = command?.text;

    return [buttonTxt, commandState, sendCommandHandler];
};

export default useCommandSender;
