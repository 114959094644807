import React from 'react';
import './style.scss';

const ModalTwinScreens = ({ contents1, contents2, gap }) => (
    <div className="twin-screens" style={{ gap: gap }}>
        <div className="screen">{contents1}</div>
        <div className="screen">{contents2}</div>
    </div>
);

export default ModalTwinScreens;
