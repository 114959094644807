import React, { useCallback, useEffect, useMemo, useState } from 'react';
// Utils
import { iconsName } from 'assets';
import { setAttr, testBy } from 'utils/constants';
import { t } from 'utils/dictionary';
import { classAttribute } from 'utils/helper';
// Components
import { Icon } from 'components/common';
import './style.scss';




const SortByButton = (props) => {
    const { item, onClick, selected } = props;

    const handleClick = useCallback((e) => {
        e.stopPropagation();
        onClick?.(item);
    }, [item, onClick]);

    return (
        <span onClick={handleClick} className={selected ? 'bold-text' : ''}>
            {item.label}
        </span>
    );
};

const SortByMoreDataKeys = (props) => {
    const { items = [], onClick, sortKey } = props;

    const buttons = items.map((item, i) => {
        const separate = i > 0 ? '/' : null;
        return (
            <React.Fragment key={item.id ?? i}>
                {separate}
                <SortByButton onClick={onClick} item={item} selected={item.key === sortKey} />
            </React.Fragment>
        );
    });

    return (
        <span>
            <span className="sort-by-text">
                {` (${t('lblSortBy')}: `}{buttons}{`)`}
            </span>
        </span>
    );
};


export const Header = (props) => {

    const { onClickSort, sort = {}, column, children } = props;
    const { label, icon, sortKey } = column;

    const isSorted = Boolean(sortKey) && sort.dataKey === sortKey;

    const handleClickSort = useCallback(() => {
        onClickSort?.({ sort, sortKey });
    }, [onClickSort, sort, sortKey]);

    const headerClasses = useMemo(() => classAttribute([
        'table-header-wrapper',
        onClickSort ? 'click-able' : '',
    ]), [onClickSort]);

    const sortIconClasses = useMemo(() => isSorted ? classAttribute([
        'sort-icon',
        !sort?.isDesc ? 'ascending' : '',
    ]) : '', [isSorted, sort?.isDesc]);

    return (
        <div
            className={headerClasses}
            onClick={handleClickSort}
            {...setAttr.action(testBy.ACTION.SORT, !onClickSort)}
        >
            {
                icon?.name ? (
                    <Icon
                        name={icon?.name}
                        width={18}
                        height={18}
                        {...icon}
                    />
                ) : null
            }
            <span className="header-label">{label}</span>
            {children}
            {
                isSorted ? (
                    <Icon
                        name={iconsName.ARROW_FULL}
                        title={t('lblSortOrder', { order: !sort?.isDesc ? t('lblAscending') : t('lblDescending') })}
                        height={18}
                        width={8}
                        className={sortIconClasses}
                    />
                ) : null
            }
        </div>
    );
};

export const HeaderMultiSortKeys = (props) => {

    const { onClickSort, sort = {}, column } = props;
    const { multiSortKeys = [] } = column;

    const [column2, setColumn2] = useState({ ...column, sortKey: multiSortKeys[0].key });

    useEffect(() => {
        const sortKey = multiSortKeys.find((s) => s.key === sort.dataKey)?.key;
        if (!sortKey) return;
        setColumn2((prev) => ({ ...prev, sortKey }));
    }, [sort, multiSortKeys]);

    const onClickSortByMore = useCallback((item) => {
        const { key } = item;
        setColumn2((prev) => ({ ...prev, sortKey: key }));
        onClickSort?.({ sort, sortKey: key });
    }, [onClickSort, sort]);

    return (
        <Header
            onClickSort={onClickSort}
            sort={sort}
            column={column2}
        >
            {
                (multiSortKeys.length > 1) ? (
                    <SortByMoreDataKeys
                        onClick={onClickSortByMore}
                        items={multiSortKeys}
                        sortKey={sort.dataKey}
                    />
                ) : null
            }
        </Header>
    );
};
